import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { BASE_URL } from '../apiConfig'

export const updateBranchFeedback = createAsyncThunk(
    'updateBranchFeedback',
    async ({ jwtBearer, requestBody }) => {
        console.log('Request Body - ', requestBody)
        const options = {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + jwtBearer,
                'Content-Type': 'application/json' // Set content type to JSON
            },
            body: JSON.stringify(requestBody) // Wrap requestBody as needed
        }

        try {
            const response = await fetch(
                `${BASE_URL}mis/ops/update/feedback/`,
                options
            )
            console.log('Request Options - ', options)
            console.log('Response -- ', response)
            return response.json() // Assuming the response is JSON
        } catch (error) {
            console.log('Error', error)
            alert(error.message)
            throw error
        }
    }
)
export const updateFollowUpDate = createAsyncThunk(
    'updateBranchFeedback',
    async ({ jwtBearer, requestBody }) => {
        console.log('Request Body - ', requestBody)
        const options = {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + jwtBearer,
                'Content-Type': 'application/json' // Set content type to JSON
            },
            body: JSON.stringify(requestBody) // Wrap requestBody as needed
        }

        try {
            const response = await fetch(
                `${BASE_URL}mis/ops/update/feedback/`,
                options
            )
            console.log('Request Options - ', options)
            console.log('Response -- ', response)
            return response.json() // Assuming the response is JSON
        } catch (error) {
            console.log('Error', error)
            alert(error.message)
            throw error
        }
    }
)

const updateBranchFeedbackSlice = createSlice({
    name: 'updateBranchFeedback',
    initialState: {
        isLoading: false,
        data: null,
        isError: false,
        errorMessage: null
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(updateBranchFeedback.pending, state => {
            state.isLoading = true
            state.isError = false
            state.errorMessage = null
        })
        builder.addCase(updateBranchFeedback.fulfilled, (state, action) => {
            state.isLoading = false
            state.data = action.payload
            state.isError = false
            state.errorMessage = null
        })
        builder.addCase(updateBranchFeedback.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.errorMessage = action.error.message
        })
    }
})

export default updateBranchFeedbackSlice.reducer
