import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { BASE_URL } from '../apiConfig'

export const fetchPDDAppID = createAsyncThunk(
    'fetchPDDAppID',
    async ({ jwtBearer }) => {
        const response = await fetch(`${BASE_URL}pdd/get/app/id/`, {
            headers: {
                Authorization: `Bearer ${jwtBearer}` 
            }
        })
        return response.json()
    }
)
export const fetchNotAddedAppID = createAsyncThunk(
    'fetchNotAddedAppID',
    async ({ jwtBearer }) => {
        const response = await fetch(`${BASE_URL}pdd/get/not/added/app/id/`, {
            headers: {
                Authorization: `Bearer ${jwtBearer}`
            }
        })
        return response.json()
       
    }
 
)


export const fetchNotAddedAppIdReturn = createAsyncThunk(
    'fetchNotAddedAppIdReturn',
    async ({ jwtBearer }) => {
        const response = await fetch(`${BASE_URL}pdd/get/not/added/app/id/return/`, {
            headers: {
                Authorization: `Bearer ${jwtBearer}`
            }
        })
        return response.json()
       
    }
 
)




const pddDataSlice = createSlice({
    name: 'pddDataSlice',
    initialState: {
        isLoading: false,
        data: null,
        isError: false
    },
    extraReducers: builder => {
        builder.addCase(fetchPDDAppID.pending, (state, action) => {
            state.isLoading = true
        })
        builder.addCase(fetchPDDAppID.fulfilled, (state, action) => {
            state.isLoading = false
            state.data = action.payload
        })
        builder.addCase(fetchPDDAppID.rejected, (state, action) => {
            console.log('Error', action.payload)
            state.isError = true
        })

          // Handle the fetchNotAddedAppID async thunk cases
          builder.addCase(fetchNotAddedAppID.pending, (state, action) => {
            state.isLoading = true
        })
        builder.addCase(fetchNotAddedAppID.fulfilled, (state, action) => {
            state.isLoading = false
            state.notAddedData = action.payload  
        })
        builder.addCase(fetchNotAddedAppID.rejected, (state, action) => {
            console.log('Error', action.payload)
            state.isError = true
        })
          // Handle the fetchNotAddedAppID async thunk cases
          builder.addCase(fetchNotAddedAppIdReturn.pending, (state, action) => {
            state.isLoading = true
        })
        builder.addCase(fetchNotAddedAppIdReturn.fulfilled, (state, action) => {
            state.isLoading = false
            state.notAddedData = action.payload  
        })
        builder.addCase(fetchNotAddedAppIdReturn.rejected, (state, action) => {
            console.log('Error', action.payload)
            state.isError = true
        })
    
    }
})

export default pddDataSlice.reducer
