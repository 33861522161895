// export const BASE_URL = "http://127.0.0.1:8001/";
// export const CMA_BASE_URL = 'http://127.0.0.1:8000/'
// export const CMA_SUPPORT_URL = 'http://127.0.0.1:8004/'
// export const DIGITAL_JOURNEY_BASE_URL = "http://127.0.0.1:8003/";


// export const BASE_URL = "https://customer.zielfintech.com/uat/api/v1/dashboard/";
// export const CMA_BASE_URL ="https://customer.zielfintech.com/uat/api/v1/notification/";
// export const CMA_SUPPORT_URL = "https://customer.zielfintech.com/uat/api/v1/support/";
// export const DIGITAL_JOURNEY_BASE_URL = "https://customer.zielfintech.com/uat/api/v1/chat/";


export const BASE_URL = 'https://customer.zielfintech.com/api/v1/dashboard/'
export const CMA_BASE_URL ="https://customer.zielfintech.com/api/v1/notification/";
export const CMA_SUPPORT_URL = 'https://customer.zielfintech.com/api/v1/support/'
export const DIGITAL_JOURNEY_BASE_URL = "https://customer.zielfintech.com/api/v1/chat/";
