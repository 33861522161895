import React, { useState, useEffect } from 'react';
import { Table, Spin, Alert, Button, Drawer, Form, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getBankSummary } from '../../../redux/slice/postBankVerification';
import moment from 'moment';
import { MyStyledTable } from '../styles/styleComp';
import { HashLoader } from 'react-spinners';
import * as XLSX from 'xlsx';

function TransactionHistory({ jwtBearer }) {
  const dispatch = useDispatch();
  const { bankSummary, loading, error } = useSelector((state) => state.bankVerification);

  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [responseType, setResponseType] = useState('');
  const [showRawData, setShowRawData] = useState(false); // New state for toggling view

  const [dynamicFilters, setDynamicFilters] = useState({
    name: [], acc_no: [], ifsc: [], status: [],
  });

  const generateFilters = (data, key) => {
    const uniqueValues = [...new Set(data.map((item) => item[key]))];
    return uniqueValues.map((value) => ({ text: value, value }));
  };

  useEffect(() => {
    dispatch(getBankSummary({ jwtBearer }));
  }, [dispatch, jwtBearer]);

  useEffect(() => {
    if (bankSummary && bankSummary.length > 0) {
      setDynamicFilters({
        name: generateFilters(bankSummary, 'name'),
        acc_no: generateFilters(bankSummary, 'acc_no'),
        ifsc: generateFilters(bankSummary, 'ifsc'),
        status: generateFilters(bankSummary, 'status'),
      });
    }
  }, [bankSummary]);

  const handleRefresh = () => {
    dispatch(getBankSummary({ jwtBearer }));
  };

  const handleView = (type, data) => {
    setResponseType(type);
    setSelectedData(data);
    setDrawerVisible(true);
  };

  const handleDownloadReport = () => {
    const exportData = bankSummary.map((record) => ({
      Name: record.name,
      'Account Number': record.acc_no,
      IFSC: record.ifsc,
      Status: record.status,
      'Bank Response': JSON.stringify(record.bank_response, null, 2) || 'N/A',
      'IFSC Response': JSON.stringify(record.ifsc_response, null, 2) || 'N/A',
      'Updated At': record.updated_at
        ? moment.utc(record.updated_at).utcOffset('+05:30').format('DD-MM-DD HH:mm:ss')
        : 'N/A',
    }));

    const ws = XLSX.utils.json_to_sheet(exportData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Bank Transaction History');

    XLSX.writeFile(wb, 'Bank_Transaction_History.xlsx');
  };

  const columns = [
    {
      title: 'Name', dataIndex: 'name', key: 'name', filters: dynamicFilters.name,
      onFilter: (value, record) => record.name === value, filterSearch: true,
    },
    {
      title: 'Account Number', dataIndex: 'acc_no', key: 'acc_no', filters: dynamicFilters.acc_no,
      onFilter: (value, record) => record.acc_no === value, filterSearch: true,
    },
    {
      title: 'IFSC', dataIndex: 'ifsc', key: 'ifsc', filters: dynamicFilters.ifsc,
      onFilter: (value, record) => record.ifsc === value, filterSearch: true,
    },
    {
      title: 'Status', dataIndex: 'status', key: 'status', filters: dynamicFilters.status,
      onFilter: (value, record) => record.status === value,
    },
    {
      title: 'Bank Response', dataIndex: 'bank_response', key: 'bank_response',
      render: (bankResponse, record) => bankResponse ? (
        <Button type="link" onClick={() => handleView('bank_response', record.bank_response)} style={{ fontSize: '16px' }}>
          View
        </Button>
      ) : null,
    },
    {
      title: 'IFSC Response', dataIndex: 'ifsc_response', key: 'ifsc_response',
      render: (ifscResponse, record) => ifscResponse ? (
        <Button type="link" onClick={() => handleView('ifsc_response', record.ifsc_response)} style={{ fontSize: '16px' }}>
          View
        </Button>
      ) : null,
    },
    {
      title: 'Updated At', dataIndex: 'updatedAt', key: 'updatedAt',
      render: (text, record) => {
        const updatedAt = record.updated_at ? moment.utc(record.updated_at).utcOffset('+05:30').format('DD-MM-DD HH:mm:ss') : 'N/A';
        return <span>{updatedAt}</span>;
      },
    },
  ];

  return (
    <div style={{ padding: '30px 60px', background: '#f9f9f9', borderRadius: '10px' }}>
      <div style={{ display: 'flex', justifyContent: 's', alignItems: 'center', marginBottom: '20px' }}>



        <h2 style={{ margin: 0, fontSize: '24px', fontWeight: '500', textAlign: 'center', flex: 1 }}>
          Bank Transaction History
        </h2>

        <div style={{ display: 'flex', gap: '20px' }}>
          <Button
            type="primary"
            onClick={handleRefresh}
            style={{ fontSize: '14px' }}
          >
            Refresh
          </Button>

          <Button
            type="primary"
            onClick={handleDownloadReport}
            style={{ fontSize: '14px' }}
          >
            Download Report
          </Button>
        </div>

      </div>
      {error && <Alert message="Error" description={error} type="error" showIcon style={{ marginBottom: '20px' }} />}

           <Spin spinning={loading} indicator={<HashLoader color="#36d7b7" size={40} />}>
        <MyStyledTable
          dataSource={bankSummary}
          columns={columns}
          rowKey="key"
          pagination={{
            pageSize: 8,
            total: bankSummary?.length,
            showSizeChanger: false,
          }}
          loading={false} 
        />
      </Spin>

      <Drawer title={<div style={{ display: 'flex', justifyContent: 'space-between' }}><span>{responseType === "bank_response" ? "Bank Response" : "IFSC Response"}</span><Button type="default" onClick={() => setShowRawData(!showRawData)}>{showRawData ? "Show Form Data" : "Show Raw Data"}</Button></div>} width={1000} visible={drawerVisible} onClose={() => setDrawerVisible(false)}>
        {showRawData ? (
          <pre style={{ background: '#e5e5e5', padding: '20px' }}>{JSON.stringify(selectedData, null, 2)}</pre>
        ) : responseType === "ifsc_response" && selectedData ? (
          <Form layout="vertical">
            {Object.entries(selectedData).map(([key, value]) => (
              <Form.Item key={key} label={key} style={{ display: 'inline-block', width: 'calc(50% - 12px)', marginRight: '12px' }}>
                <Input value={value} readOnly />
              </Form.Item>
            ))}
          </Form>
        ) : responseType === "bank_response" && selectedData ? (
          <Form layout="vertical">
            {Object.entries(selectedData.data).map(([key, value]) => (
              <Form.Item key={key} label={key} style={{ display: 'inline-block', width: 'calc(50% - 12px)', marginRight: '12px' }}>
                <Input value={value} readOnly />
              </Form.Item>
            ))}
            {/* Additional fields for bank response */}
            <Form.Item label="Success" style={{ display: 'inline-block', width: 'calc(50% - 12px)', marginRight: '12px' }}>
              <Input value={selectedData.success ? "Yes" : "No"} readOnly />
            </Form.Item>
            <Form.Item label="Error Message" style={{ display: 'inline-block', width: 'calc(50% - 12px)', marginRight: '12px' }}>
              <Input value={selectedData.errorMessage || "N/A"} readOnly />
            </Form.Item>
            <Form.Item label="Error Code" style={{ display: 'inline-block', width: 'calc(50% - 12px)', marginRight: '12px' }}>
              <Input value={selectedData.errorCode || "N/A"} readOnly />
            </Form.Item>
            <Form.Item label="Request ID" style={{ display: 'inline-block', width: 'calc(50% - 12px)', marginRight: '12px' }}>
              <Input value={selectedData.requestId || "N/A"} readOnly />
            </Form.Item>
          </Form>
        ) : null}
      </Drawer>
    </div>
  );
}

export default TransactionHistory;
