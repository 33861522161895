import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Select,
  Button,
  InputNumber,
  Card,
  Tag,
  Modal,
} from "antd";
import {
  SearchOutlined,
  DropboxOutlined,
  CodeSandboxOutlined,
} from "@ant-design/icons";
import {
  StyledCard,
  RefreshButton,
  CardContainer,
} from "../../../style/styleComp";
import moment from 'moment'
import { useSelector, useDispatch } from "react-redux";
import { fetchPDD } from "../../../../../redux/slice/getPDDData";
import { fetchPDDHoToBranch } from "../../../../../redux/slice/getPDDHoToBranchData";
import { fetchPDDAppID } from "../../../../../redux/slice/getPDDAppID";
import { HashLoader } from "react-spinners";
import ReturningToBranchInTransitDrawer from "./ReturningToBranchInTransitDrawer";
import ReturningToBranchReceivedDrawer from "./ReturningToBranchReceivedDrawer";
import jwt_decode from "jwt-decode";
import { addMissingDocsHoToBranch } from "../../../../../redux/slice/pddAddMissingDocsHoToBranch";
import { updateHoToBranchStatus } from "../../../../../redux/slice/pddUpdateHoToBranchStatus";
import { RiRefreshLine } from "react-icons/ri";
import { useSpring, animated } from "react-spring";
import { motion } from "framer-motion";
import CargoArea from "../../../style/truck/CargoArea";
import Window from "../../../style/truck/Window";
import EngineHeadLight from "../../../style/truck/EngineHeadLight";
import BottomFrameReturning from "../../../style/truck/BottomFrameReturning";
import { LeftTyre } from "../../../style/truck/LeftTyre";
import { RightTyre } from "../../../style/truck/RightTyre";
import { Circle } from "../../../style/truck/Circle";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";
import truckAnimation from "../../../../../../assets/loaderTruck.json";
import { MyStyledTable } from "../../../style/styleComp";
const { Option } = Select;

const BranchHoToBranch = ({ jwtBearer, extraProp,viewTable,selectedButton,isRefreshing,loading,setLoading }) => {
  // console.log("Branch JWT - ", jwtBearer);
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);
  const [searchField, setSearchField] = useState("Select");
  const [enableSearch, setEnableSearch] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [modalVisible, setDetailModalVisible] = useState(false);
  const [returningToBranchReceivedDrawer, setReturningToBranchReceivedDrawer] =
    useState(false);
  const [selectedCardData, setSelectedCardData] = useState(null);
  const [selectedAppIDs, setSelectedAppIDs] = useState([]);
  const [branches, setBranches] = useState([]);
  const [isAnimated, setIsAnimated] = useState(false);
  const [openBox, setOpenBox] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const floatButtonProps = useSpring({
    to: { transform: "translateY(0px)", opacity: 1 },
    from: { transform: "translateY(100px)", opacity: 0 },
    config: { tension: 200, friction: 10 },
  });

  const fetchData = () => {
    setLoading(true);
    Promise.all([
      dispatch(fetchPDDHoToBranch({ jwtBearer, movement: "branch" })),
      dispatch(fetchPDDAppID({ jwtBearer })),
    ])
      .then(() => setLoading(false))
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
    // const intervalId = setInterval(fetchData, 1000 * 60 * 5);
    // return () => clearInterval(intervalId);
  }, [dispatch, jwtBearer]);

  const chatData = useSelector(
    (state) => state.fetchPDDHoToBranch?.data?.message || []
  );
  const appIDData = useSelector(
    (state) => state.fetchPDDAppID?.data?.message || []
  );
  const trackingNumberFilters = [
    ...new Set(filteredData.map((record) => record.tracking_number)),
  ].map((value) => ({
    text: value,
    value: value,
  }));

  // console.log("pdd ho to brach - ", chatData);
  useEffect(() => {
    if (chatData && chatData.length > 0) {
      const groupedData = {};

      chatData.forEach((item) => {
        const {
          trk_no,
          app_id,
          status,
          verification,
          applicant_details,
          updated_at,
          created_at,
          vendor,
          branch,
          cal_file,
        } = item;

        // Initialize the group if it doesn't exist
        if (!groupedData[trk_no]) {
          groupedData[trk_no] = {
            status: item.status,
            branch: item.branch,
            product: item.product,
            tracking_number: trk_no,
            vendor: item.vendor,
            verification: item.verification,
            created_at: item.created_at,
            updated_at: item.updated_at,
            application_ids: [],
            applicant_details: {},
            statuses: [],
            verifications: [],
            application_id_statuses: [],
            cal_file: item.cal_file,
            cal_files: [],
          };
        }

        // Add application ID to the array (only if it's unique)
        if (app_id && !groupedData[trk_no].application_ids.includes(app_id)) {
          groupedData[trk_no].application_ids.push(app_id);
        }

        // Consolidate applicant details
        if (applicant_details) {
          // Ensure applicant_details is in the correct format (object or string)
          const details =
            typeof applicant_details === "string"
              ? JSON.parse(applicant_details)
              : applicant_details;
          groupedData[trk_no].applicant_details[app_id] = details;
        }

        if (status) {
          groupedData[trk_no].statuses.push({ app_id, status });
        }

        if (verification) {
          groupedData[trk_no].verifications.push({ app_id, verification });
        }
        if (cal_file === null) {
          groupedData[trk_no].cal_files.push({ app_id, cal_file: "" });
        } else {
          groupedData[trk_no].cal_files.push({ app_id, cal_file });
        }
      });

      Object.keys(groupedData).forEach((tracking_number) => {
        const verifications = groupedData[tracking_number].verifications;
        let finalVerification = "4";

        if (verifications.some((v) => v.verification === "8")) {
          finalVerification = "8";
        } else if (verifications.every((v) => v.verification === "5")) {
          finalVerification = "5";
        }

        // Set the final verification status
        groupedData[tracking_number].verification = finalVerification;
      });

      // Convert grouped object to an array
      const formattedData = Object.values(groupedData);

      // Set state with the formatted data
      setTableData(formattedData);
      setFilteredData(formattedData);
    }
  }, [chatData]);

  console.log("myTableDAta", tableData);
  console.log("myFilteredDAta", filteredData);

  // const branchArray = jwt_decode(jwtBearer).branch_id.replace(/[()]/g, '').split(',');
  const branchArray = extraProp.branch_id.replace(/[()]/g, "").split(",");

  const cleanAppIDData = appIDData.filter((app) =>
    branchArray.includes(app.BR_CD.split(" ")[0])
  );

  useEffect(() => {
    if (chatData && chatData.length > 0) {
      const formattedData = chatData.map((item) => ({
        ...item,
      }));
      setTableData(formattedData);
    }
  }, [chatData]);

  const statusCodeToText = (status) => {
    switch (status) {
      case "1":
        return "In-Transit";
      case "2":
        return "In-Query";
      case "3":
        return "Received";
      default:
        return "Unknown Status";
    }
  };

  const verificationCodeToText = (verification) => {
    switch (verification) {
      case "4":
        return "Pending at HO";
      case "5":
        return "Verified";
      case "8":
        return "Missing/Damaged Documents";
      default:
        return "Unknown Verification";
    }
  };
  const verificationCodeForTableToText = (verification) => {
    // console.log("verification status for table", verification);
    switch (verification) {
      case "4":
        return "Verification Pending At HO";
      case "5":
        return "Verified";
      case "8":
        return "Missing/Damaged Documents";
      default:
        return "Unknown Verification";
    }
  };

  const handleRowClick = (record) => {
    setSelectedCardData(record);
    setDetailModalVisible(true);
    setReturningToBranchReceivedDrawer(true);
  };

  const getColumns = (data) => {
    if (!data || data.length === 0) return [];

    const keys = Object.keys(data[0]);
    return keys.map((key) => ({
      title: (
        <strong style={{ fontSize: "18px" }}>
          {key.replace(/_/g, " ").toUpperCase()}
        </strong>
      ),
      dataIndex: key,
      key: key,
      render: (text) => (
        <span style={{ fontSize: "18px" }}>
          {Array.isArray(text) ? text.join(", ") : text}
        </span>
      ),
    }));
  };

    const myColumns = [
      {
        title: "POD Number",
        dataIndex: "tracking_number",
        key: "tracking_number",
        filters: trackingNumberFilters,
        onFilter: (value, record) => record.tracking_number === value,
        filterSearch: true,
      },
      {
        title: "Verification",
        dataIndex: "verification",
        key: "verification",
        render: (verification) => verificationCodeToText(verification),
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (status) => statusCodeToText(status),
      },
      { title: "Vendor", dataIndex: "vendor", key: "vendor" },
      { title: "Branch", dataIndex: "branch", key: "branch" },
      { title: "Created At", dataIndex: "created_at", key: "created_at" },
    ];
  
    // for table
    const myFormattedData = filteredData.map((item) => ({
      tracking_number: item.tracking_number,
      verification: item.verification,
      // verification: verificationCodeForTableToText(item.verification),
      status: item.status,
      vendor: item.vendor,
      branch: item.branch,
      created_at: moment(item.created_at).format("DD-MM-YYYY"),
      fullData: item,
    }));

  const columns = getColumns(chatData);
  // console.log("tableData: ", tableData);
  // console.log("filteredData: ", filteredData);

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);

    if (searchField !== "Select" && e.target.value) {
      // console.log("filteredData: ", filteredData);

      const filtered = filteredData.filter((data) => {
        if (searchField === "tracking_number") {
          return data.tracking_number
            ?.toLowerCase()
            .includes(e.target.value.toLowerCase());
        }
        if (searchField === "application_ids") {
          return data.application_ids.some((id) =>
            id.toLowerCase().includes(e.target.value.toLowerCase())
          );
        }
        return false;
      });
      setFilteredData(filtered);
    }
  };



  const handleSearchFieldChange = (value) => {
    setSearchField(value);
    setEnableSearch(false); // Enable search input when a field is selected
  };

  // console.log("filtered data - ", filteredData);

  const handleSelectChange = (value) => {
    if (!selectedAppIDs.includes(value)) {
      setSelectedAppIDs([...selectedAppIDs, value]);
    }
  };

  const handleRemoveAppID = (appID) => {
    setSelectedAppIDs(selectedAppIDs.filter((id) => id !== appID));
  };


  const handleCloseModal = () => {
    setDetailModalVisible(false);
    setSelectedCardData(null);
    setReturningToBranchReceivedDrawer(false);
  };

  const handleStatusChange = (updatedData) => {
    // console.log("Change Status - ", updatedData);
    const requestBody = {
      app_id: updatedData.app_id,
      status: updatedData.status,
    };

    dispatch(updateHoToBranchStatus({ jwtBearer, requestBody }))
      .then(() => {
        dispatch(fetchPDDHoToBranch({ jwtBearer, movement: "branch" }));
        setDetailModalVisible(false);
      })
      .catch((error) => {
        console.error("Error updating courier status:", error);
      });
  };

  const handleMissingDocs = (updatedData) => {
    // console.log("Missing doc - ", updatedData);
    const requestBody = {
      branch: updatedData.branch,
      tracking_number: updatedData.tracking_number,
      docs: updatedData.docs,
    };

    dispatch(addMissingDocsHoToBranch({ jwtBearer, requestBody }))
      .then(() => {
        dispatch(fetchPDDHoToBranch({ jwtBearer, movement: "branch" }));
        setDetailModalVisible(false);
      })
      .catch((error) => {
        console.error("Error updating courier status:", error);
      });
  };


  const groupByTrkNo = (data) => {
    return data.reduce((groups, item) => {
      const { trk_no } = item;
      if (!groups[trk_no]) {
        groups[trk_no] = [];
      }
      groups[trk_no].push(item);
      return groups;
    }, {});
  };

  // console.log("Branch group data - ", groupByTrkNo(filteredData));

  // console.log("my selected", selectedCardData);

  const handleCardClick = async (data) => {
    setSelectedCardData(data);
    // console.log("Card click - ", data);

    // Check status directly on the clicked item
    if (data.status === "1") {
      setDetailModalVisible(true);
      // console.log("in status 1");
    } else if (data.status === "3") {
      setReturningToBranchReceivedDrawer(true);
      // console.log("in status 3");
    } else {
      setDetailModalVisible(false);
      setReturningToBranchReceivedDrawer(false);
      // console.log("in else");
    }
  };

  const handleTableRowClick = (row) => {
    setSelectedCardData(data);
    // console.log("Card click - ", data);

    // Check status directly on the clicked item
    if (data.status === "1") {
      setDetailModalVisible(true);
      // console.log("in status 1");
    } else if (data.status === "3") {
      setReturningToBranchReceivedDrawer(true);
      // console.log("in status 3");
    } else {
      setDetailModalVisible(false);
      setReturningToBranchReceivedDrawer(false);
      // console.log("in else");
    }
  };

  // console.log("is received?", returningToBranchReceivedDrawer);
  return (
    <>
      <Form layout="inline">
        <div
          className="selectAndsearch"
          style={{ display: "flex", margin: "0 30px" }}
        >
          <Form.Item style={{ marginBottom: "20px" }}>
            <Select
              value={searchField}
              onChange={handleSearchFieldChange}
              style={{
                width: 170,
                height: 49,
                borderRadius: "5px",
                border: "1px solid #d9d9d9",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                fontSize: "16px",
                //   textAlign:"center"
              }}
              dropdownStyle={{
                borderRadius: "5px",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Option value="tracking_number">POD Number</Option>
              <Option value="application_ids">Application ID</Option>
            </Select>
          </Form.Item>

          <Form.Item className="searchbarContainer">
            <Input
              placeholder="Search..."
              value={searchValue}
              onChange={handleSearchChange}
              prefix={
                <SearchOutlined
                  style={{ color: "#1890ff", marginRight: "10px" }}
                />
              }
              style={{
                height: 49,
                borderRadius: "5px",
                border: "1px solid #d9d9d9",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                fontSize: "16px",
              }}
              className="mySearchBar"
              disabled={enableSearch}
            />
          </Form.Item>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "78vw",
            textAlign: "end",
          }}
        >
          <animated.div
            style={{
              ...floatButtonProps,
              position: "fixed",
              top: "140px",
              right: "20px",
            }}
          ></animated.div>
        </div>
      </Form>

      <div style={{ margin: "0 30px" }}>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "70vh",
          }}
        >
          {/* <DotLottieReact
            src="https://lottie.host/262f30d7-3e17-42b5-9b46-4ff3a0f04dec/EwWWSsCFV6.lottie"
            loop
            autoplay
            style={{ height: "400px" }}
          /> */}

           <div className="spinner-container">
                            <HashLoader color="#f57171" />
                          </div>
        </div>
      ) : (
        <>
          {viewTable ? (
            <MyStyledTable
              columns={myColumns}
              dataSource={myFormattedData}
              rowKey="tracking_number"
              pagination={{
                pageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ["5", "10", "20", "50"],
              }}
              style={{ marginTop: "26px" }}
              onRow={(record) => ({
                onClick: () => handleTableRowClick(record),
              })}
            />
          ) : (
            <div>
            
                 <CardContainer>
          {filteredData.map((data, index) => (
            <card
              style={{
                position: "relative",
                marginBottom: "40px",
                cursor: "pointer"
              }}
              onClick={() => handleCardClick(data)}
            >
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    width: "150px",
                    height: "60px",
                    border: "2px solid black",
                    backgroundColor: "transparent",
                    // position: 'absolute',
                    top: "10px",
                    left: "0px",
                    // borderRadius: '5px',
                    borderRadius: "10px 10px 0 0",
                  }}
                >
                  <CargoArea data={data} />
                </div>
                <Window data={data} />
                <div style={{ alignSelf: "flex-end" }}>
                  <EngineHeadLight data={data} />
                </div>
              </div>
              <div style={{ position: "relative" }}>
                <BottomFrameReturning data={data} />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    position: "absolute",
                    top: "0",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      left: "180px",
                      top: "2.5px",
                    }}
                  >
                    <Circle />
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      left: "35px",
                      top: "2.5px",
                    }}
                  >
                    <Circle />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      {" "}
                      <LeftTyre />
                    </div>
                    <div>
                      <RightTyre />
                    </div>
                  </div>
                </div>
              </div>
            </card>
          ))}
        </CardContainer>
        
            </div>
          )}
        </>
      )}
    </div>

      <ReturningToBranchInTransitDrawer
        visible={modalVisible}
        onClose={handleCloseModal}
        data={selectedCardData}
        all_app_id={appIDData}
        app_id={cleanAppIDData}
        onStatusChange={handleStatusChange}
        onMissingDocs={handleMissingDocs}
        chatData={chatData}
      />
      <ReturningToBranchReceivedDrawer
        returningToBranchReceivedDrawer={returningToBranchReceivedDrawer}
        onClose={handleCloseModal}
        data={selectedCardData}
        all_app_id={appIDData}
        app_id={cleanAppIDData}
        onStatusChange={handleStatusChange}
        onMissingDocs={handleMissingDocs}
        selectedIndex={selectedIndex}
        jwtBearer={jwtBearer}
        fetchData={fetchData}
        chatData={chatData}
      />
    </>
  );
};

export default BranchHoToBranch;
