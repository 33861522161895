import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { BASE_URL } from '../apiConfig';  // Assuming you have a proper BASE_URL

export const postBankVerification = createAsyncThunk(
  'postBankVerification',
  async ({ jwtBearer, requestBody }, { rejectWithValue }) => {
    const options = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + jwtBearer,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    };

    try {
      const response = await fetch(`${BASE_URL}hr/verify/bank/`, options);
      const responseData = await response.json();

      if (!response.ok) {
        return rejectWithValue(responseData);  // Reject with response error
      }

      return responseData;  // Return response data if successful
    } catch (error) {
      return rejectWithValue({ errorMessage: 'Failed to verify bank account' });
    }
  }
);
export const postVerifyIfsc = createAsyncThunk(
  'postVerifyIfsc',
  async ({ jwtBearer, requestBody }, { rejectWithValue }) => {
    const options = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + jwtBearer,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    };

    try {
      const response = await fetch(`${BASE_URL}hr/verify/ifsc/`, options);
      const responseData = await response.json();

      if (!response.ok) {
        return rejectWithValue(responseData);  // Reject with response error
      }

      return responseData;  // Return response data if successful
    } catch (error) {
      return rejectWithValue({ errorMessage: 'Failed to verify bank account' });
    }
  }
);
export const getBankSummary = createAsyncThunk(
  'getBankSummary',
  async ({ jwtBearer }, { rejectWithValue }) => {
    const options = {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + jwtBearer,
        'Content-Type': 'application/json',
      },
    };

    try {
      const response = await fetch(`${BASE_URL}hr/get/summary`, options);
      const responseData = await response.json();

      if (!response.ok) {
        return rejectWithValue(responseData);
      }

      return responseData;
    } catch (error) {
      return rejectWithValue({ errorMessage: 'Failed to fetch bank verification summary' });
    }
  }
);

const initialState = {
  bankSummary: [],
  loading: false,
  error: null,
};

const bankVerificationSlice = createSlice({
  name: 'bankVerification',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBankSummary.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getBankSummary.fulfilled, (state, action) => {
        state.loading = false;
        state.bankSummary = action.payload;
        console.log("state",state.bankSummary)
      })
      .addCase(getBankSummary.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.errorMessage || 'Failed to load bank summary';
      });
  },
});

export default bankVerificationSlice.reducer;