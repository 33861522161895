import React from 'react';
import { motion } from "framer-motion";

function EngineHeadLight({ data }) {

    // console.log("head light:",data)

    const headLightGradient = (e) => {
        switch (e) {
            // verification pending
            case "4":
                return "linear-gradient(to right, white, white)";
            // verified
            case "5":
                return "linear-gradient(to right, white, white)";
            // missing/damaged
            case "8":
                return "linear-gradient(to right, rgba(255, 0, 0, 0.8), rgba(255, 0, 0, 0.1))";
            default:
                return "linear-gradient(to right, white, white)";
        }
    };

    return (
        <div>
            <div style={{ display: "flex" }}>
                <div
                    style={{
                        border: "solid 2px",
                        height: "20px",
                        width: "20px",
                        borderRadius: "0 10px 0 0",
                        borderLeft: "white",
                    }}
                ></div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "start",
                        gap: "0",
                        position: "relative",
                        top: "11px",
                        left: "-3px",
                    }}
                >
                    <div
                        style={{
                            width: "10px",
                            height: "5px",
                            background: "white",
                            transform: "perspective(60px) rotateY(-70deg)",
                            alignSelf: "start",
                            border: "solid 2px black",
                        }}
                    ></div>
                    <motion.span
                        style={{
                            width: "60px",
                            height: "10px",
                            background: "white",
                            transform: "perspective(60px) rotateY(-70deg)",
                            alignSelf: "self-start",
                            position: "relative",
                            left: "-27px",
                            top: "-2px",
                            background: headLightGradient(data.verification),
                        }}
                        className="headLight"
                        animate={
                            data.verification === "8"
                                ? { opacity: [1, 0, 1] } 
                                : {} 
                        }
                        transition={{
                            duration: 0.5,
                            repeat: Infinity,
                            repeatType: "reverse",
                        }}
                    ></motion.span>
                </div>
            </div>
        </div>
    );
}

export default EngineHeadLight;
