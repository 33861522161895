import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
import { useAuth } from "../../../context/AuthContext";
import { fetchBranchMIS } from "../../../redux/slice/getMISSlice";
import { fetchOpsMIS } from "../../../redux/slice/getOpsMISSlice";
import { uploadOpsMIS } from "../../../redux/slice/uploadOpsMISSlice";
import { updateBranchFeedback } from "../../../redux/slice/updateBranchFeedback";
import "../../../../assets/styles/buttons.css";
import {
  Spin,
  Table,
  Card,
  Modal,
  Button,
  Input,
  Tabs,
  Upload,
  message,
  Tooltip,
  Radio,
  Alert,
  Select,
  Divider,
  Form,
  DatePicker
} from "antd";
import {
  UploadOutlined,
  LoadingOutlined,
  PlusOutlined,
  FileExcelOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { CSVLink } from "react-csv";
import * as XLSX from "xlsx";
import moment from 'moment';

const { TextArea } = Input;

const MISComp = ({ reportType, extraProp }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [selectedFile, setSelectedFile] = useState(null);
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [uploadAlert, setUploadAlert] = useState("");
  const [lastUpdatedDate, setLastUpdatedDate] = useState(null);
  const [lastUpdatedTime, setLastUpdatedTime] = useState(null);
  const [fileList, setFileList] = useState([]); // To store the uploaded file
  const [userUploadType, setUserUploadType] = useState(null);
  const [branchFieldError, setBranchFieldError] = useState(false);
  const [uploadFailedError, setUploadFailedError] = useState(false);
  const [invalidFormatError, setInvalidFormatError] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [branchFeedbackValue, setBranchFeedbackValue] = useState('')
  const [otherRemark, setOtherRemark] = useState('');
  const [feedbackModalVisible, setFeedbackModalVisible] = useState(false)
  const [selectedAppID, setSelectedAppID] = useState('')
  const [followUpDate, setFollowUpDate] = useState('');

  const { authState, onLogout } = useAuth();

  var token_decoded = authState.token;
  var jwtBearer = authState.token;
  var jwt_payload = "";
  var jwt_payload_group = [];
  var jwt_payload_expiry = [];
  var jwt_user_id = "";
  var jwt_user_name = "";
  var jwt_user = '';

  if (jwtBearer) {
    // console.log("Token decoded");
    jwt_payload = jwt_decode(jwtBearer);
    // jwt_payload_group = jwt_payload.group_id;
    jwt_payload_group = extraProp?.group_id;
    jwt_payload_expiry = jwt_payload.exp;
    jwt_user_id = jwt_payload.user_id;
    // jwt_user_name = jwt_payload.username;
    jwt_user_name = extraProp?.username;
    jwt_user = jwt_user_id + ' - ' + jwt_user_name
    // console.log("JWT Group Reports - ", jwt_payload_group);
  }

  // Session Timeout
  const TOKEN_KEY = "my-jwt";
  var currentTime = Math.floor(Date.now() / 1000);

  // Compare current time with token expiry time
  if (jwt_payload_expiry.length != 0) {
    if (currentTime > jwt_payload_expiry) {
      console.log("In token expired condition");
      // Token has expired, perform logout
      AsyncStorage.removeItem(TOKEN_KEY);
      onLogout();
      location.reload();
    }
  }
  // Session Timeout

  // console.log("JWT Bearer Branch MIS - ", jwtBearer);

  useEffect(() => {
    dispatch(fetchOpsMIS({ type: reportType, jwtBearer })).then(() => {
      setLoading(false); // Set loading state to false when the data is fetched
    });
  }, []);
  // useEffect(() => {
  //   dispatch(fetchBranchMIS({ duration: 'weekly', jwtBearer })).then(() => {
  //     setLoading(false) // Set loading state to false when the data is fetched
  //   })
  // }, [])

  const state = useSelector((state) => state);
  // console.log("MIS State - ", state);

  const tokenExpired = state.opsMIS.data?.detail || "";
  // const TOKEN_KEY = "my-jwt";
  if (tokenExpired == "Signature has expired.") {
    console.log("In token expired condition");
    AsyncStorage.removeItem(TOKEN_KEY);
    onLogout;
    location.reload();
  }

  const allFields = state.opsMIS.data?.message || [];
  const updated_date = state.opsMIS.data?.updated_date || [];
  const updated_time = state.opsMIS.data?.updated_time || [];

  // console.log("Branch MIS All Fields - ", allFields);

  useEffect(() => {
    setDataSource(allFields);
    setLastUpdatedDate(updated_date);
    setLastUpdatedTime(updated_time);
  }, [allFields]);

  const allKeys = allFields.reduce((keys, record) => {
    return keys.concat(Object.keys(record));
  }, []);

  const uniqueKeys = [...new Set(allKeys)];

  // const columns = uniqueKeys.map((key) => ({
  //   title: <strong>{key.toUpperCase()}</strong>,
  //   dataIndex: key,
  //   key: key,
  //   width: "30%",
  //   align: "left",
  //   filters: [...new Set(allFields.map((record) => record[key]))].map(
  //     (value) => ({
  //       text: value,
  //       value: value,
  //     })
  //   ),
  //   onFilter: (value, record) => record[key] === value,
  //   filterSearch: true,
  // }));

  const columns = [
    ...uniqueKeys.map((key) => ({
      title: <strong>{key.toUpperCase()}</strong>,
      dataIndex: key,
      key: key,
      width: "30%",
      align: "left",
      filters: [...new Set(allFields.map((record) => record[key]))].map((value) => ({
        text: value,
        value: value,
      })),
      onFilter: (value, record) => record[key] === value,
      filterSearch: true,
    })),
  ];

  // Check if the ADD FEEDBACK column should be visible
  if (jwt_payload_group === "3") {
    columns.push({
      title: <strong>ADD FEEDBACK</strong>,
      key: 'addFeedback',
      width: "15%",
      align: "center",
      render: (_, record) => {
        if (record.STATUS === 'Ready For Disbursement') {
          const feedbackText = record.BRANCH_FEEDBACK ? 'Update Feedback' : 'Add Feedback';
          return (
            <Button
              className="regular-button"
              type="primary"
              onClick={() => handleAddFeedback(record)}
            >
              {feedbackText}
            </Button>
          );
        }
        return null;
      },
    });
  }



  const handleAddFeedback = (e) => {
    // console.log('Selected Record - ', e.APP_ID)
    // setSelectedAppID(e.APP_ID)
    setSelectedAppID(e.APP_ID.toString());
    setFeedbackModalVisible(true)
  }

  // const handleSubmitBankFeedback = async () => {
  //   const requestBody = {
  //     user: jwt_user,
  //     application_id: selectedAppID,
  //     branch_feedback: branchFeedbackValue
  //   }

  //   const response = await dispatch(
  //     updateBranchFeedback({
  //       jwtBearer: authState.token,
  //       requestBody
  //     })
  //   );

  //   if (response.payload.code == 200) {
  //     // After successful file processing, await the dispatch and then execute the rest of the code
  //     await dispatch(fetchOpsMIS({ type: reportType, jwtBearer }));
  //     setFeedbackModalVisible(false)
  //     setBranchFeedbackValue(null)
  //     message.success('Feedback submitted successfully')
  //   }
  //   else {
  //     message.error('Erro submitting feedback')
  //   }
  // }


  // console.log("Branch MIS Datasource - ", dataSource);

  const handleSubmitBankFeedback = async () => {
    const feedbackValue = branchFeedbackValue === 'other' ? otherRemark : branchFeedbackValue;
    // Format the follow_up_date using moment if it is selected
  const formattedFollowUpDate = followUpDate ? moment(followUpDate).format('DD-MM-YYYY') : null;
console.log("followUpDate",followUpDate)
  const final_follow_up_date= branchFeedbackValue === 'In follow-up with the customer' ? formattedFollowUpDate : ""
  
    // const requestBody = {
    //   user: jwt_user,
    //   application_id: selectedAppID.toString(),
    //   branch_feedback: feedbackValue ,
    //   follow_up_date:final_follow_up_date
    // };
    const requestBody = {
      user: jwt_user,
      application_id: selectedAppID,
      branch_feedback: feedbackValue ,
      follow_up_date:final_follow_up_date
    };
  
    console.log("requestBody",requestBody)
    try {
      // Dispatch the updateBranchFeedback action
      const response = await dispatch(
        updateBranchFeedback({
          jwtBearer: authState.token,
          requestBody
        })
      );
  
      if (response.payload.code === 200) {
        // After successful feedback submission, dispatch the fetchOpsMIS action
        await dispatch(fetchOpsMIS({ type: reportType, jwtBearer }));
  
        // Reset the feedback form and close the modal
        setFeedbackModalVisible(false);
        setBranchFeedbackValue(null);
        setOtherRemark('');  // Clear the remark input field if "Other" was selected
        message.success('Feedback submitted successfully');
      } else {
        message.error('Error submitting feedback');
      }
    } catch (error) {
      message.error('An error occurred while submitting feedback');
    }
  };
  
  const allowedExtensions = ["xlsx"];
  const handleFileChange = (info) => {
    // console.log("In handle file change function");
    // console.log("info", info);
    const fileExtension = info.file.name.split(".").pop().toLowerCase();
    if (!allowedExtensions.includes(fileExtension)) {
      //   message.error(
      //     `Invalid file format. Allowed formats: ${allowedExtensions.join(', ')}`
      //   )
      setInvalidFormatError(true);
      setFileList([]);
      return;
    }
    setFileList(info.fileList);
  };

  const handleUpload = async () => {
    setButtonLoading(true);
    if (fileList.length > 0) {
      const file = fileList[0].originFileObj;
      const reader = new FileReader();

      reader.onload = async (e) => {
        try {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: "array" });

          // Assuming the first sheet is the one you want to convert to JSON
          let sheetNum = 0;
          if (reportType == "DAILY STATUS") {
            sheetNum = 1;
          }

          const sheet = workbook.Sheets[workbook.SheetNames[sheetNum]];
          // const excelData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
          const excelData = XLSX.utils.sheet_to_json(sheet, { header: 1, defval: "" });

          // Extract the headers (first sub-array)
          const headers = excelData[0];
          const headersUpper = headers.map((element) => {
            return element.toUpperCase();
          });
          console.log('in modify cond type - ', reportType)

          if (reportType === "DISB MIS") {
            console.log('in modify cond disb')
            // Convert Excel serial date numbers to actual dates in the Disbursal Date column
            const disbursalDateIndex = headersUpper.indexOf("DISBURSAL DATE");
            console.log('in modify cond - ', disbursalDateIndex)
            if (disbursalDateIndex !== -1) {
              console.log('In modify cond')
              excelData.forEach((row, rowIndex) => {
                if (rowIndex > 0) { // Skip headers
                  const disbursalDateValue = row[disbursalDateIndex];
                  if (typeof disbursalDateValue === "number") {
                    // Convert serial number to date
                    row[disbursalDateIndex] = XLSX.SSF.format("dd-mm-yyyy", disbursalDateValue);
                  }
                }
              });
            }
          }

          // Check for and insert BRANCH_FEEDBACK column if reportType is "DAILY STATUS"
          if (reportType === "DAILY STATUS") {
            if (!headersUpper.includes("BRANCH_FEEDBACK")) {
              headersUpper.push("BRANCH_FEEDBACK");
            }
          }

           // Check for and insert FOLLOW_UP_DATE column if reportType is "DAILY STATUS"
        if (reportType === "DAILY STATUS") {
          if (!headersUpper.includes("FOLLOW_UP_DATE")) {
            headersUpper.push("FOLLOW_UP_DATE");
          }
        }

          if (headersUpper.includes("BRANCH")) {
            // Create an array of objects using headers as keys and data as values
            const formattedData = excelData.slice(1).map((row) => {
              const obj = {};
              headersUpper.forEach((header, index) => {
                obj[header] = row[index];
              });
              // Add empty value for BRANCH_FEEDBACK if not already present
              if (reportType === "DAILY STATUS" && !obj["BRANCH_FEEDBACK"]) {
                obj["BRANCH_FEEDBACK"] = "";
              }

               // Add empty value for FOLLOW_UP_DATE if not already present
            if (reportType === "DAILY STATUS" && !obj["FOLLOW_UP_DATE"]) {
              obj["FOLLOW_UP_DATE"] = ""; // or you can set a default date here
            }
              return obj;
            });

            // File size
            const jsonString = JSON.stringify(formattedData);

            // Calculate the size of the JSON string in bytes
            const fileSizeInBytes = new Blob([jsonString]).size;

            // Convert the size to kilobytes (KB) and megabytes (MB)
            const fileSizeInKB = fileSizeInBytes / 1024;
            // const fileSizeInMB = fileSizeInKB / 1024
            const fileSizeInMB = Math.floor(fileSizeInKB / 1024);
            // console.log("Size number", fileSizeInMB);

            // Log the file size
            // console.log(
            //   `File size: ${fileSizeInBytes} bytes (${fileSizeInKB} KB, ${fileSizeInMB} MB)`
            // );

            const chunkNumber = parseInt(fileSizeInMB + 1);
            // File size

            const chunkSize = Math.ceil(formattedData.length / chunkNumber);
            const chunks = [];
            for (let i = 0; i < formattedData.length; i += chunkSize) {
              chunks.push(formattedData.slice(i, i + chunkSize));
            }

            // Call uploadOpsMIS API for each chunk with different uploadType
            for (let i = 0; i < chunks.length; i++) {
              const uploadType = i === 0 ? "Fresh" : "Append"; // Set uploadType based on chunk index
              const response = await dispatch(
                uploadOpsMIS({
                  jwt_token: authState.token,
                  type: reportType,
                  uploadType: uploadType, // Pass the uploadType
                  userUploadType: userUploadType,
                  requestBody: chunks[i],
                })
              );

              // console.log(`File upload response (Chunk ${i + 1}):`, response);
            }

            // message.success('File uploaded successfully')
            setBranchFieldError(false);
            setUploadFailedError(false);
            setInvalidFormatError(false);
            setUploadSuccess(true);

            // After successful file processing, await the dispatch and then execute the rest of the code
            await dispatch(fetchOpsMIS({ type: reportType, jwtBearer }));
            setLoading(false);
            setButtonLoading(false);
            setFileList([]); // Clear the uploaded file list
          } else {
            // message.error('File is missing Branch field')
            setBranchFieldError(true);
            setFileList([]);
            setButtonLoading(false);
          }
        } catch (error) {
          console.error("Error:", error);
          //   message.error('File upload failed')
          setUploadFailedError(true);
          setFileList([]);
          setButtonLoading(false);
        }
      };

      reader.readAsArrayBuffer(file);
    }
    setUserUploadType(null);
  };

  const paginationOptions = {
    showSizeChanger: true,
    pageSizeOptions: ["10", "20", "50", "100"],
    defaultPageSize: 10, // Initial page size
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
  };

  const customRequest = ({ file, onSuccess }) => {
    // Prevent the default behavior by calling onSuccess immediately
    onSuccess("ok");
  };

  const onUploadTypeChange = (e: RadioChangeEvent) => {
    // console.log("Upload Value", e.target.value);
    setUserUploadType(e.target.value);
  };

  const handleModalClose=()=>{
    console.log("in handle close")
    setFeedbackModalVisible(false)
    setFollowUpDate("");
    setBranchFeedbackValue("")
  }

  return (
    <>
      <center>
        <div>
          {jwt_payload_group == "0" ||
            jwt_payload_group == "1" ||
            jwt_payload_group == "2" ||
            jwt_payload_group == "3" ||
            jwt_payload_group == "4" || jwt_payload_group == "13" ? null : (
            <Card
              style={{
                width: "60%",
                marginTop: "20px",
                boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)", // Add the box-shadow property
                borderRadius: "5px", // Add rounded corners for a more aesthetic look
              }}
              bordered={true}
            >
              <center>
                <div>
                  <p>
                    <strong>
                      <FileExcelOutlined /> UPLOAD {reportType} REPORT
                    </strong>
                  </p>
                  <Upload
                    customRequest={customRequest}
                    name="avatar" // Set the name attribute to "avatar"
                    fileList={fileList}
                    listType="picture-card" // Set the listType to "picture-card"
                    onChange={handleFileChange}
                    maxCount={1}
                  >
                    {fileList.length === 0 && (
                      <div>
                        <UploadOutlined />
                        <div style={{ marginTop: 8 }}>Select File</div>
                      </div>
                    )}
                  </Upload>
                  <div>
                    <p>
                      <strong>Select upload type</strong>
                      <Tooltip
                        title={
                          <span>
                            Fresh -
                            <br />
                            Selecting fresh as upload type will delete the
                            previous data and show the new data.
                            <br /> <br />
                            Append -
                            <br />
                            Selecting append as upload type will add the new
                            data along with the old data.
                          </span>
                        }
                      >
                        <InfoCircleOutlined
                          type="question-circle"
                          style={{
                            marginLeft: "5px",
                            cursor: "pointer",
                            color: "dodgerblue",
                            fontSize: "16px",
                          }}
                        />
                      </Tooltip>
                    </p>
                    <Radio.Group
                      onChange={onUploadTypeChange}
                      value={userUploadType}
                    >
                      <Radio value={"Fresh"}>Fresh</Radio>
                      <Radio value={"Append"}>Append</Radio>
                    </Radio.Group>
                  </div>
                  <Button
                    className="regular-button"
                    style={{
                      color: "white",
                      padding: "10px 20px",
                      borderRadius: "5px",
                      display: "inline-flex",
                      alignItems: "center",
                      fontWeight: "bold",
                      marginTop: "20px",
                    }}
                    onClick={handleUpload}
                    loading={buttonLoading}
                    disabled={fileList.length === 0 || userUploadType === null}
                  >
                    Upload
                  </Button>
                  {branchFieldError === false ? null : (
                    <Alert
                      style={{
                        marginTop: "20px",
                        textAlign: "justify",
                        width: "50%",
                      }}
                      message="Error"
                      description="Branch field is missing from the excel sheet."
                      type="error"
                      showIcon
                    />
                  )}
                  {uploadFailedError === false ? null : (
                    <Alert
                      style={{
                        marginTop: "20px",
                        textAlign: "justify",
                        width: "50%",
                      }}
                      message="Error"
                      description="File Upload Failed."
                      type="error"
                      showIcon
                    />
                  )}
                  {invalidFormatError == false ? null : (
                    <Alert
                      style={{
                        marginTop: "20px",
                        textAlign: "justify",
                        width: "50%",
                      }}
                      message="Error"
                      description="Invalid file format. Allowed formats: xlsx"
                      type="error"
                      showIcon
                    />
                  )}
                  {uploadSuccess == false ? null : (
                    <Alert
                      style={{
                        marginTop: "20px",
                        textAlign: "justify",
                        width: "50%",
                      }}
                      message="Success"
                      description="File Uploaded Successfully"
                      type="success"
                      showIcon
                    />
                  )}
                  <div
                    style={{
                      textAlign: "justify",
                    }}
                  >
                    <p>
                      <strong>Instructions to be followed -</strong>
                    </p>
                    <p>1. The extension of the file should be 'xlsx'.</p>
                    <p>
                      2. Name of one of the column of the excel file should
                      'Branch' and it should have data in either of the below
                      format - '10001-Jobner' or '10001'.
                    </p>
                    <p>
                      3. The excel sheet should not be having any formula in any
                      of the cells and cells should only have values in it.
                    </p>
                  </div>

                  {uploadAlert && (
                    <div style={{ marginTop: "10px" }}>{uploadAlert}</div>
                  )}
                </div>
              </center>
            </Card>
          )}
          <Card
            layout="horizontal"
            bordered={true}
            className="criclebox tablespace mb-24"
            title={
              <>
                {reportType} TRACKER
                {/* Weekly Branch MIS */}
                {lastUpdatedDate && lastUpdatedTime && (
                  <span style={{ marginLeft: "10px", fontSize: "14px" }}>
                    <br />
                    <strong>DATE</strong> - {lastUpdatedDate} &nbsp;
                    <strong>TIME</strong> - {lastUpdatedTime} (24 HRS)
                  </span>
                )}
              </>
            }
            centered
            style={{ width: "100%", marginTop: "20px" }}
            extra={
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "12px",
                }}
              >
                {/* {jwt_payload_group == '2' || jwt_payload_group == '3' ? null : ( */}
                {/* {jwt_payload_group == '3' &&
                (reportType == 'UTR' ||
                  reportType == 'DAILY STATUS') ? null : ( */}
                <CSVLink
                  className="regular-button"
                  style={{
                    color: "white",
                    padding: "10px 20px",
                    borderRadius: "5px",
                    textDecoration: "none",
                    fontWeight: "bold",
                  }}
                  data={dataSource}
                  onClick={() => { }}
                >
                  Download Report
                </CSVLink>
                {/* )} */}
              </div>
            }
          >
            <div className="table-responsive">
              {loading ? (
                <div className="spinner-container">
                  <Spin size="large" />
                </div>
              ) : (
                <Table
                  columns={columns}
                  dataSource={dataSource}
                  pagination={paginationOptions}
                  bordered
                  rowClassName={(record, index) =>
                    index % 2 === 0 ? "table-row-light" : "table-row-dark"
                  }
                />
              )}
            </div>
          </Card>
          <Modal
            style={{ height: '600px' }} // Set the height here
            width={800}
            visible={feedbackModalVisible}
            title={<p style={{ fontSize: '20px', fontWeight: '700', color: 'grey' }}>Branch Feedback</p>}
            footer={null}
            onCancel={handleModalClose}
          >
            <Divider style={{ borderColor: 'lightgrey' }} />
            <Form style={{ marginLeft: '10px', marginRight: '20px' }} form={form} layout="vertical">
              <Form.Item label={<strong style={{ fontSize: '20px', fontWeight: '700', color: 'grey' }}>Select Feedback</strong>} >
                <Select
                  value={branchFeedbackValue}
                  onChange={(value) => setBranchFeedbackValue(value)}
                  placeholder={<p style={{ fontSize: '18px', fontWeight: '600', borderRadius: '10px' }}>Select reason</p>}
                >
                  <Select.Option value="In follow-up with the customer"><p style={{ fontSize: '18px', fontWeight: '600', color: 'black', borderRadius: '10px' }}>In follow-up with the customer</p></Select.Option>
                  <Select.Option value="Pending due to banking"><p style={{ fontSize: '18px', fontWeight: '600', color: 'black', borderRadius: '10px' }}>Pending due to banking</p></Select.Option>
                  <Select.Option value="Pending due to a security check"><p style={{ fontSize: '18px', fontWeight: '600', color: 'black', borderRadius: '10px' }}>Pending due to a security check</p></Select.Option>
                  <Select.Option value="Follow-up Applicant/Co-Applicant"><p style={{ fontSize: '18px', fontWeight: '600', color: 'black', borderRadius: '10px' }}>Follow-up Applicant/Co-Applicant</p></Select.Option>
                  <Select.Option value="Not interested or rejected"><p style={{ fontSize: '18px', fontWeight: '600', color: 'black', borderRadius: '10px' }}>Not interested or rejected</p></Select.Option>
                  <Select.Option value="Disbursed"><p style={{ fontSize: '18px', fontWeight: '600', color: 'black', borderRadius: '10px' }}>Disbursed</p></Select.Option>
                  <Select.Option value="other"><p style={{ fontSize: '18px', fontWeight: '600', color: 'black', borderRadius: '10px' }}>Other</p></Select.Option>
                </Select>
              </Form.Item>
           
    

             {/* Conditionally render the TextArea if "Other" is selected */}
      {branchFeedbackValue === 'other' && (
        <Form.Item>
          <TextArea
            rows={4}
            value={otherRemark}
            onChange={(e) => setOtherRemark(e.target.value)}
            placeholder="Enter your feedback"
          />
        </Form.Item>
      )}

{branchFeedbackValue === 'In follow-up with the customer' && (
         <Form.Item 
         label={<strong style={{ fontSize: '20px', fontWeight: '700', color: 'grey' }}>Follow-up Date</strong>} 
         name="followUpDate">
         <DatePicker
           value={followUpDate}
           onChange={(date) => setFollowUpDate(date?.toString())}
           style={{ width: '100%' }}
           placeholder="Select a date"
          //  disabledDate={(current) => current && current < moment().endOf('day')}
           disabledDate={(current) => current && current < moment().startOf('day')}
         />
       </Form.Item>
       
        )}

{/* {(branchFeedbackValue !== 'other' || (branchFeedbackValue === 'other' && otherRemark)) && (
      <Button
        className="regular-button"
        type="primary"
        onClick={handleSubmitBankFeedback}
        htmlType="submit"
      >
        Submit Feedback
      </Button>
    )} */}

{(branchFeedbackValue !== 'other' || (branchFeedbackValue === 'other' && otherRemark)) && (
    branchFeedbackValue !== 'In follow-up with the customer' || followUpDate ? (
      <Button
        className="regular-button"
        type="primary"
        onClick={handleSubmitBankFeedback}
        htmlType="submit"
      >
        Submit Feedback
      </Button>
    ) : null
  )}
    </Form>

    
            {/* {bankVerificationSuccess && (
              <Alert
                style={{ marginTop: '20px' }}
                message="Success"
                description="Your bank account has been verified successfully."
                type="success"
                showIcon
              />
            )}
            {bankVerificationError && (
              <Alert
                style={{ marginTop: '20px' }}
                message="Bank verification failed. Please find the reason below -"
                description={bankVerificationErrorMsg}
                type="warning"
                showIcon
              />
            )}
            {bankVerificationAPIFailure && (
              <Alert
                style={{ marginTop: '20px' }}
                message="Error"
                description="Bank verification API failure."
                type="error"
                showIcon
              />
            )} */}
          </Modal>
        </div>
      </center>
    </>
  );
};

export default MISComp;
