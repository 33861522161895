import React, { useState, useEffect, useMemo } from "react";
import {
  Drawer,
  Typography,
  List,
  Empty,
  Row,
  Col,
  Card,
  Button,
  Modal,
  notification,
  Space,
  Popover
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { fetchPDDMissingDocs } from "../../../../../redux/slice/getPDDMissingDocs";
import { useAuth } from "../../../../../context/AuthContext";
import {
  CheckCircleFilled,
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  ArrowDownOutlined,
  ArrowUpOutlined,
  InfoCircleOutlined
} from "@ant-design/icons";
import {
  MyDetailedStyledTable,
  MyStyledTable,
  StyledTable,
} from "../../../style/styleComp";
import { deleteNotReceivedId } from "../../../../../redux/slice/pddAddMissingDocs";
import { fetchPDD } from "../../../../../redux/slice/getPDDData";
const { Text, Title } = Typography;

const BranchMissDamDrawer = ({ visible, onClose, data, statusDetails }) => {
  if (!data) {
    return null;
  }

  // console.log("data: ", data);
  const dispatch = useDispatch();
  const { authState } = useAuth();
  const [loading, setLoading] = useState(false);
  const [missingPDDDocs, setMissingPDDDocs] = useState({});
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const jwtBearer = authState.token;

  const fetchData = async () => {
    setLoading(true);
    try {
      const requestBody = {
        app_ids: data.application_ids,
      };
      const missingResponse = await dispatch(
        fetchPDDMissingDocs({ jwtBearer, requestBody })
      );
      setMissingPDDDocs(missingResponse.payload?.data || {});
      await dispatch(fetchPDD({ jwtBearer, movement: "Branch" }));
    } catch (error) {
      console.error("Error fetching missing docs:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    fetchPDDData();
  }, [dispatch, chatData]);

  const fetchPDDData = async () => {
    // console.log("fetchPDDData api triggered");
    setLoading(true);
    await dispatch(fetchPDD({ jwtBearer, movement: "Branch" }));
  };

  const chatData = useSelector((state) => state.fetchPDD?.data?.message || []);
  // console.log("chatData", chatData);
  const trackingNumber = data.tracking_number;
  // Filter chatData based POD
  const dataSource = chatData.filter(
    (item) => item.tracking_number === trackingNumber
  );

  const filteredDataSource = dataSource.filter(
    (record) => record.app_id_status === "2"
  );
  const getStatusText = (statusCode) => {
    switch (statusCode) {
      case "1":
        return "In-Transit";
      case "2":
        return "In-Query";
      case "3":
        return "Received";
      default:
        return "";
    }
  };

  const verificationColumns = [
    {
      title: "Verification",
      key: "verificationStatus",
      render: (text, record) => {
        const status = statusDetails.find(
          (item) => item.app_id === record.application_id
        );
        const myVerification = status?.verification;

        if (myVerification === "5") {
          return (
            <CheckCircleFilled style={{ color: "green", fontSize: "28px" }} />
          );
        } else if (myVerification === "4") {
          return (
            <CheckCircleOutlined style={{ color: "grey", fontSize: "28px" }} />
          );
        } else if (myVerification === "8") {
          return (
            <ExclamationCircleOutlined
              style={{ color: "red", fontSize: "28px" }}
            />
          );
        } else {
          return (
            <ExclamationCircleOutlined
              style={{ color: "orange", fontSize: "28px" }}
            />
          );
        }
      },
    },
  ];

  const columns = [
    {
      title: "Application Id",
      dataIndex: "application_id",
      key: "application_id",
    },

    {
      title: "NAME",
      key: "applicant_name",
      render: (text, record) => record.applicant_details?.applicant_name,
    },
    {
      title: "OXYZO ID",
      key: "partner_loan_id",
      render: (text, record) => record.applicant_details?.partner_loan_id,
    },
    {
      title: "PRODUCT",
      key: "product",
      render: (text, record) => record.applicant_details?.product,
    },
  ];

  const showModal = (record) => {
    setSelectedRecord(record);
    setIsModalVisible(true);
  };



  const handleOk = async () => {
    if (selectedRecord) {
      try {
        const requestBody = {
          application_id: selectedRecord.application_id,
        };
        console.log("requestBody", requestBody);
        const response = await dispatch(
          deleteNotReceivedId({
            requestBody,
            jwtBearer,
          })
        );

        console.log("response", response);

        if (response?.type === "deleteNotReceivedId/fulfilled") {
          notification.success({
            message: "Success",
            description: `Application ID ${selectedRecord.application_id} deleted successfully.`,
          });
          fetchPDDData();
        } else {
          notification.error({
            message: "Error",
            description: `Failed to delete Application ID ${selectedRecord.application_id}.`,
          });
        }
      } catch (error) {
        console.error("Error deleting application ID:", error);

        notification.error({
          message: "Error",
          description: `An unexpected error occurred while deleting Application ID ${selectedRecord.application_id}.`,
        });
      }
    }
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const acknowledgement = {
    title: "Acknowledgement",
  };

  const verificationColumn = {
    title: "VERIFIED",
    key: "verification",
    dataIndex: "verification",
    render: (text, record) => {
      // const status = statusDetails.find(
      //   (item) => item.app_id === record.application_id
      // );
      // console.log("status",status)

      // const verification = status?.verification;
      const verification = record.verification;
      // console.log("verification", verification);

      if (verification === "5") {
        return (
          <CheckCircleFilled style={{ color: "green", fontSize: "28px" }} />
        );
      } else if (verification === "4") {
        return (
          <CheckCircleOutlined style={{ color: "grey", fontSize: "28px" }} />
        );
      } else if (verification === "8") {
        return (
          <ExclamationCircleOutlined
            style={{ color: "red", fontSize: "28px" }}
          />
        );
      } else {
        return (
          <ExclamationCircleOutlined
            style={{ color: "orange", fontSize: "28px" }}
          />
        );
      }
    },
  };

  const notReceivedColumns = [
    {
      title: "Application Id",
      dataIndex: "application_id",
      key: "application_id",
    },

    {
      title: "NAME",
      key: "applicant_name",
      render: (text, record) =>
        record.applicant_details?.applicant_name || "N/A",
    },
    {
      title: "OXYZO ID",
      key: "partner_loan_id",
      render: (text, record) =>
        record.applicant_details?.partner_loan_id || "N/A",
    },
    {
      title: "PRODUCT",
      key: "product",
      render: (text, record) => record.applicant_details?.product || "N/A",
    },
  ];

  const getVerificationText = (statusCode) => {
    switch (statusCode) {
      case "4":
        return "Pending at HO";
      case "5":
        return "Verified";
      case "8":
        return "Missing/Damaged Documents";
      default:
        return "";
    }
  };

  columns.unshift(verificationColumn);
  notReceivedColumns.unshift({
    title: acknowledgement.title,
    key: "acknowledgement",
    render: (_, record) => (
      <Button onClick={() => showModal(record)}>Acknowledge</Button>
    ),
  });

  // const renderExpandedRow = (record) => {
  //   const docData = record.doc_details
  //   console.log("docData", docData);
  //   const sections = [
  //     {
  //       title: "Verified Documents",
  //       items: docData.verified_docs || [],
  //       bgColor: "green",
  //     },
  //     {
  //       title: "Not Applicable Documents",
  //       items: docData.not_applicable_docs || [],
  //       bgColor: "green",
  //     },
  //     {
  //       title: "Missing Documents",
  //       items: docData.missing_documents || [],
  //       bgColor: "red",
  //     },
  //     {
  //       title: "Damaged Documents",
  //       items: docData.damaged_docs || [],
  //       bgColor: "red",
  //     },
  //   ];

  //   if (docData.other && docData.other.length > 0) {
  //     sections.push({
  //       title: "Other",
  //       items: docData.other.map((doc) => `${doc.document}: ${doc.Remark}`),
  //       bgColor: "red",
  //     });
  //   }

  //   // return (
  //   //     <Row gutter={16}>
  //   //         {sections.map((section) => (
  //   //             <Col span={12} key={section.title}>
  //   //                 <Card
  //   //                     title={
  //   //                         <span
  //   //                             style={{
  //   //                                 backgroundColor: section.bgColor,
  //   //                                 color: 'white',
  //   //                                 padding: '5px 10px',
  //   //                                 borderRadius: '5px',
  //   //                                 display: 'block',
  //   //                                 position: 'sticky',
  //   //                                 top: 0,
  //   //                                 zIndex: 1,
  //   //                             }}
  //   //                         >
  //   //                             {section.title}
  //   //                         </span>
  //   //                     }
  //   //                     bordered={false}
  //   //                     style={{
  //   //                         height: '400px',
  //   //                         overflowY: 'auto',
  //   //                         paddingTop: '30px',
  //   //                     }}
  //   //                 >
  //   //                     {section.items.length === 0 ? (
  //   //                         <Empty description="No data available" image={Empty.PRESENTED_IMAGE_SIMPLE} />
  //   //                     ) : (
  //   //                         <List
  //   //                             size="small"
  //   //                             dataSource={section.items}
  //   //                             renderItem={(item) => <List.Item>{item}</List.Item>}
  //   //                         />
  //   //                     )}
  //   //                 </Card>
  //   //             </Col>
  //   //         ))}
  //   //     </Row>
  //   // );

  //   return (
  //     <Row gutter={16}>
  //       {sections.map((section) => {
  //         // Only render the section if it has items
  //         if (section.items.length > 0) {
  //           return (
  //             <Col span={12} key={section.title}>
  //               <Card
  //                 title={
  //                   <span
  //                     style={{
  //                       backgroundColor: section.bgColor,
  //                       color: "white",
  //                       padding: "5px 10px",
  //                       borderRadius: "5px",
  //                       display: "block",
  //                       position: "sticky",
  //                       top: 0,
  //                       zIndex: 1,
  //                     }}
  //                   >
  //                     {section.title}
  //                   </span>
  //                 }
  //                 bordered={false}
  //                 style={{
  //                   height: "400px",
  //                   overflowY: "auto",
  //                   paddingTop: "30px",
  //                 }}
  //               >
  //                 <List
  //                   size="small"
  //                   dataSource={section.items}
  //                   renderItem={(item) => <List.Item>{item}</List.Item>}
  //                 />
  //               </Card>
  //             </Col>
  //           );
  //         }
  //         return null; // Don't render the section if it has no items
  //       })}
  //     </Row>
  //   );
  // };

  const renderExpandedRow = (record) => {
    const docData = record.doc_details;
    console.log("docData", docData);
  
    // Creating a Set for quick lookup of verified documents
    const verifiedDocsSet = new Set(docData.verified_docs || []);
  
    const sections = [
      {
        title: "Verified Documents",
        items: docData.verified_docs || [],
        bgColor: "green",
      },
      {
        title: "Not Applicable Documents",
        items: docData.not_applicable_docs || [],
        bgColor: "green",
      },
      {
        title: "Missing Documents",
        items: docData.missing_documents || [],
        bgColor: "red",
      },
      {
        title: "Damaged Documents",
        items: docData.damaged_docs || [],
        bgColor: "red",
      },
    ];
  
    // Filtering out verified documents from the 'Other' section
    if (docData.other && docData.other.length > 0) {
      const filteredOtherDocs = docData.other
        .filter(doc => !verifiedDocsSet.has(doc.document)) 
        .map(doc => `${doc.document}: ${doc.Remark}`);
  
      if (filteredOtherDocs.length > 0) {
        sections.push({
          title: "Other",
          items: filteredOtherDocs,
          bgColor: "red",
        });
      }
    }
  
    return (
      <Row gutter={16}>
        {sections.map((section) => {
          // Only render the section if it has items
          if (section.items.length > 0) {
            return (
              <Col span={12} key={section.title}>
                <Card
                  title={
                    <span
                      style={{
                        backgroundColor: section.bgColor,
                        color: "white",
                        padding: "5px 10px",
                        borderRadius: "5px",
                        display: "block",
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                      }}
                    >
                      {section.title}
                    </span>
                  }
                  bordered={false}
                  style={{
                    height: "400px",
                    overflowY: "auto",
                    paddingTop: "30px",
                  }}
                >
                  <List
                    size="small"
                    dataSource={section.items}
                    renderItem={(item) => <List.Item>{item}</List.Item>}
                  />
                </Card>
              </Col>
            );
          }
          return null; 
        })}
      </Row>
    );
  };
  
  const missDamOtherData=dataSource.filter(
    (record) => record.app_id_status !== "2"
  )

  return (
    <>
      <Drawer
         title={
          <div style={{ display: "flex", alignItems: "center" }}>
            <Popover
              content={`This courier has ${missDamOtherData.length} number of Application ID (s).`}
              // title="Verification"
              trigger="hover"
            >
              <InfoCircleOutlined
                style={{
                  marginRight: "8px",
                  color: "#1890ff",
                  fontSize: "28px",
                }}
              />
            </Popover>
  
            <Title
              level={4}
              style={{
                marginBottom: "16px",
                color: "rgba(0, 0, 0, 0.7)",
                fontSize: "20px",
                fontFamily: "Poppins",
                marginLeft: "5px",
              }}
            >
              Courier details
            </Title>
          </div>
        }
        visible={visible}
        onClose={onClose}
        width={1200}
          >
           <div style={{ padding: "0 20px" }}>
                   <Space
                     style={{
                       display: "flex",
                       marginBottom: "8px",
                       padding: "0 8px",
                       borderRadius: "4px",
                       backgroundColor: "#ffffff",
                       boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                       fontSize: "20px",
                       fontFamily: "Poppins",
                       display: "flex",
                       justifyContent: "space-between",
                     }}
                   >
                     <strong style={{ color: "#555" }}>Status:</strong>
                     <Text
                       style={{
                         fontSize: "18px",
                         fontFamily: "Poppins",
                         display: "flex",
                         justifyContent: "space-between",
                       }}
                     >
                       {getStatusText(data?.status)}
                     </Text>
                   </Space>
           
                   <Space
                     style={{
                       display: "flex",
                       marginBottom: "8px",
                       padding: "8px",
                       borderRadius: "4px",
                       backgroundColor: "#ffffff",
                       boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                       fontSize: "20px",
                       fontFamily: "Poppins",
                       display: "flex",
                       justifyContent: "space-between",
                     }}
                   >
                     <strong style={{ color: "#555" }}>POD Number:</strong>
                     <Text
                       style={{
                         fontSize: "18px",
                         fontFamily: "Poppins",
                         display: "flex",
                         justifyContent: "space-between",
                         fontWeight: "800",
                       }}
                     >
                       {data?.tracking_number}
                     </Text>
                   </Space>
           
                   <Space
                     style={{
                       display: "flex",
                       marginBottom: "8px",
                       padding: "8px",
                       borderRadius: "4px",
                       backgroundColor: "#ffffff",
                       boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                       fontSize: "20px",
                       fontFamily: "Poppins",
                       display: "flex",
                       justifyContent: "space-between",
                     }}
                   >
                     <strong style={{ color: "#555" }}>Verification:</strong>
                     <Text
                       style={{
                         fontSize: "18px",
                         fontFamily: "Poppins",
                         display: "flex",
                         justifyContent: "space-between",
                         backgroundColor: "orange",
                         padding: "3px 8px",
                         color: "white",
                         borderRadius: "6px",
                       }}
                     >
                       {getVerificationText(data?.verification)}
                     </Text>
                   </Space>
           
                   <Space
                     style={{
                       display: "flex",
                       marginBottom: "8px",
                       padding: "8px",
                       borderRadius: "4px",
                       backgroundColor: "#ffffff",
                       boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                       fontSize: "20px",
                       fontFamily: "Poppins",
                       display: "flex",
                       justifyContent: "space-between",
                     }}
                   >
                     <strong style={{ color: "#555" }}>Branch:</strong>
                     <Text
                       style={{
                         fontSize: "18px",
                         fontFamily: "Poppins",
                         display: "flex",
                         justifyContent: "space-between",
                       }}
                     >
                       {data?.branch}
                     </Text>
                   </Space>
                   <Space
                     style={{
                       display: "flex",
                       marginBottom: "8px",
                       padding: "8px",
                       borderRadius: "4px",
                       backgroundColor: "#ffffff",
                       boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                       fontSize: "20px",
                       fontFamily: "Poppins",
                       display: "flex",
                       justifyContent: "space-between",
                     }}
                   >
                     <strong style={{ color: "#555" }}>Dispatched by :</strong>
                     <Text
                       style={{
                         fontSize: "18px",
                         fontFamily: "Poppins",
                         display: "flex",
                         justifyContent: "space-between",
                       }}
                     >
                       {data?.courier_dispatcher_name}
                     </Text>
                   </Space>
                 </div>
       
        <MyStyledTable
          columns={columns}
          dataSource={dataSource.filter(
            (record) => record.app_id_status !== "2"
          )}
          rowKey="application_id"
          expandable={{
            expandedRowRender: renderExpandedRow,
            rowExpandable: (record) => !!missingPDDDocs[record.application_id],
            expandIcon: ({ expanded, onExpand, record }) => (
              <div
                onClick={(e) => onExpand(record, e)}
                style={{
                  color: expanded ? "#1890ff" : "#8c8c8c",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  fontSize: "16px",
                  fontWeight: "bold",
                  transition: "color 0.3s ease",
                }}
              >
                {expanded ? (
                  <ArrowUpOutlined style={{ marginRight: 8 }} />
                ) : (
                  <ArrowDownOutlined style={{ marginRight: 8 }} />
                )}
                {expanded ? "Collapse" : "Expand"}
              </div>
            ),
          }}
          pagination={false}
        />

        {filteredDataSource.length > 0 && (
          <>
            <h2 style={{ margin: "30px 0" }}>
              Application Id(s) not received by Head Office
            </h2>
            <MyStyledTable
              columns={notReceivedColumns}
              dataSource={filteredDataSource}
              pagination={false}
              rowKey="application_id"
            />
          </>
        )}
      </Drawer>

      <Modal
        title="Acknowledge Application"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>
          Are you sure you want to acknowledge that application ID{" "}
          {selectedRecord?.application_id} was not sent to head office?
        </p>
      </Modal>
    </>
  );
};

export default BranchMissDamDrawer;
