import React, { useState } from 'react';
import { Modal } from 'antd';
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';
import BranchToHO from '../components/HeadOffice/ComingFromBranch/branchToHO';
import HoToVendor from '../components/HeadOffice/LeavingToWarehouse/hoToVendor';
import ReturningHoToBranch from '../components/HeadOffice/LeavingToBranch/ReturningHoToBranch';
import HoInTransitDrawer from '../components/HeadOffice/ComingFromBranch/HoInTransitDrawer';
import { useAuth } from "../../../context/AuthContext";
import Checkbox from '@mui/material/Checkbox';

const HoPDD = (props) => {
    const { authState } = useAuth();
    const jwtBearer = authState.token;

    // State for checkboxes, defaulting "From Branch" to true
    const [checkboxState, setCheckboxState] = useState({
        branch: true,
        warehouse: false,
        sent: false,
    });

    const handleCheckboxChange = (e) => {
        const { name } = e.target;

        // Ensure only one checkbox is checked at a time
        setCheckboxState({
            branch: name === 'branch',
            warehouse: name === 'warehouse',
            sent: name === 'sent',
        });
    };

    const renderComponent = () => {
        const { branch, warehouse, sent } = checkboxState;

        if (warehouse) {
            return <HoToVendor extraProp={props.userDetails} />;
        } else if (branch) {
            return sent ? <ReturningHoToBranch jwtBearer={jwtBearer} extraProp={props.userDetails} /> : <BranchToHO jwtBearer={jwtBearer} extraProp={props.userDetails} />;
        } else if (sent) {
            return <ReturningHoToBranch jwtBearer={jwtBearer} extraProp={props.userDetails} />;
        }
        return null;
    };

    const [modalVisible, setModalVisible] = useState(false);
    const [selectedCardData, setSelectedCardData] = useState(null);
    const handleCloseModal = () => {
        setModalVisible(false);
        setSelectedCardData(null);
    };

    return (
        <>
            <div style={{ display: "flex", margin: "0 30px" }}>
                {/* <p style={{ fontSize: "20px" }}>Filters: </p> */}

                <Checkbox
                    size='large'
                    checkedIcon={<SquareRoundedIcon style={{ color: 'dodgerBlue' }} />}
                    name="branch"
                    checked={checkboxState.branch}
                    onChange={handleCheckboxChange}
                />
                <p style={{ fontSize: "20px", fontWeight: 700 }}>From Branch</p>

              

                <Checkbox
                    size='large'
                    checkedIcon={<SquareRoundedIcon style={{ color: 'dodgerBlue' }} />}
                    name="sent"
                    checked={checkboxState.sent}
                    onChange={handleCheckboxChange}
                />
                <p style={{ fontSize: "20px", fontWeight: 700 }}>To Branch</p>


                <Checkbox
                    size='large'
                    checkedIcon={<SquareRoundedIcon style={{ color: 'dodgerBlue' }} />}
                    name="warehouse"
                    checked={checkboxState.warehouse}
                    onChange={handleCheckboxChange}
                />
                <p style={{ fontSize: "20px", fontWeight: 700 }}>Warehouse</p>
            </div>

            {/* Render corresponding component based on selected filters */}
            {renderComponent()}

            {/* Modal for details */}
            <Modal
                visible={modalVisible}
                onCancel={handleCloseModal}
                footer={null}
                width={800}
            >
                <HoInTransitDrawer
                    visible={modalVisible}
                    onClose={handleCloseModal}
                    data={selectedCardData}
                />
            </Modal>
        </>
    );
};

export default HoPDD;
