import styled from 'styled-components';
import React from 'react';
import { Tabs } from 'antd';
import BankVerification from '../components/BankVerification';
import TransactionHistory from '../components/TransactionHistory';
import { useAuth } from "../../../context/AuthContext";
import { useDispatch, useSelector } from 'react-redux';

const customTabPaneStyles = {
  backgroundColor: '#f9f9f9',
  borderRadius: '8px', 
};

// Styled component for the tabs
const StyledTabs = styled(Tabs)`
  .ant-tabs-tab {
    font-size: 18px !important; 
    font-weight: 500;
  }
`;

const Bank = (props) => {
  const { authState } = useAuth();
  const dispatch = useDispatch();
  const jwtBearer = authState.token;

  return (
    <div style={{ marginLeft: '5px', marginRight: '5px' }}>
      <StyledTabs defaultActiveKey="1">
        <Tabs.TabPane tab="Bank Verification" key="1" style={customTabPaneStyles}>
          <BankVerification />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Summary" key="2" style={customTabPaneStyles}>
          <TransactionHistory jwtBearer={jwtBearer} />
        </Tabs.TabPane>
      </StyledTabs>
    </div>
  );
};

export default Bank;
