import React from 'react'

function Window({ data }) {
    return (
        <div style={{ alignSelf: "flex-end" }}>
                            <div
                              style={{
                                height: "40px",
                                width: "46px",
                                borderRadius: "0 20px 0 0",
                                border: "2px solid",
                                borderLeft: "white",
                                display: "flex",
                                justifyContent: "space-around",
                                alignItems: "center",
                                flexDirection: "column",
                              }}
                            >
                              <div
                                style={{
                                  border: "solid black 2px",
                                  height: "15px",
                                  width: "30px",
                                  marginTop: "2px",
                                  borderRadius: "0 12px 0 0",
                                }}
                              ></div>
                              <span
                                style={{
                                  fontSize: "10px",
                                  whiteSpace: "nowrap",
                                  fontWeight: "700",
                                }}
                              >
                                {/* {data.vendor} */}
                              </span>
                            </div>
                          </div>
    )
}

export default Window
