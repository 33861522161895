import React, { useState, useEffect, useRef } from "react";
import {
  Modal,
  Button,
  Input,
  Typography,
  Space,
  Popconfirm,
  Select,
  Drawer,
  notification,
  Popover,
} from "antd";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { deleteAppId, updateInTransit } from "../../../../../redux/slice/pddUpdateIntransitEntry";
import moduleName from '../../'
import axios from "axios";
import {
  PlusOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { MyDetailedStyledTable,MyDetailedStyledTempTable } from "../../../style/styleComp";
import { fetchPDD } from "../../../../../redux/slice/getPDDData";
import { PiRecord } from "react-icons/pi";
const { Text, Title } = Typography;
const { Option } = Select;
import {
  fetchPDDAppID,
  fetchNotAddedAppID,
} from "../../../../../redux/slice/getPDDAppID";
import {updateBranchTrkAppEntry} from '../../../../../redux/slice/pddUpdateBranchTrkApp'

const BranchInTransitDrawer = ({
  visible,
  onClose,
  data,
  app_id,
  onSave,
  jwtBearer,
}) => {

  if (!data) {
    return null;
  }
  // console.log("data=", data);
  // console.log("app_id=",app_id);
  const [isEditingTracking, setIsEditingTracking] = useState(false);
  const [editedTrackingNumber, setEditedTrackingNumber] = useState("");
  const [myAppIdArr, setMyAppIdArr] = useState([]);
  const [dataSourceAppIdArr, setDataSourceAppIdArr] = useState([]);
  const [selectedAppId, setSelectedAppId] = useState(null);
  const [selectedAppIds, setSelectedAppIds] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [isSelectDisabled, setIsSelectDisabled] = useState(false);
  const [isCourierSaved, setIsCourierSaved] = useState(false);
  // const [availableAppIDs, setAvailableAppIDs] = useState([]);
   const [loadingSave, setLoadingSave] = useState(false);

  const tableRef = useRef(null);

  const dispatch = useDispatch();
  // console.log("data prop", data);

  useEffect(() => {
    if (data) {
      // console.log("Final list of formatted App IDs triggered");
      // console.log("Data prop has changed:", data);
      setEditedTrackingNumber(data.tracking_number || "");
      // console.log("Initial array -  ", data.application_ids);
      setMyAppIdArr(data.application_ids || []);
    }
  }, [data]);

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(fetchNotAddedAppID({ jwtBearer }));
    };

    
    fetchData();
  }, [data]);



  const getStatusText = (statusCode) => {
    switch (statusCode) {
      case "1":
        return "In-Transit";
      case "2":
        return "In-Query";
      case "3":
        return "Received";
      default:
        return "";
    }
  };

  const getVerificationText = (statusCode) => {
    switch (statusCode) {
      case "4":
        return "Pending at HO";
      case "5":
        return "Verified";
      case "8":
        return "Missing/Damaged Documents";
      default:
        return "";
    }
  };
  



  const notAddedIds = useSelector((state) => state.pddDataSlice.notAddedData?.not_added_ids);

// console.log("notAdded",notAddedIds)

const availableAppIDs = app_id.filter(app =>
  notAddedIds && Array.isArray(notAddedIds) && 
  notAddedIds.includes(app.APP_ID) && 
  data && data.branch && app.BR_CD === data.branch
);

  const handleEditTrackingClick = () => {
    setIsEditingTracking(true);
  };

  const handleSaveTrackingClick = async () => {
    const requestBody = {
      branch: data.branch,
      tracking_number: data.tracking_number,
      field: "tracking_number",
      value: editedTrackingNumber,
    };

    console.log("req", requestBody);

    try {
      const resultAction = await dispatch(
        updateBranchTrkAppEntry({ jwtBearer, requestBody })
      );

      if (updateBranchTrkAppEntry.rejected.match(resultAction)) {
        throw new Error(
          resultAction.error.message || "Failed to update POD number"
        );
      }

      notification.success({
        message: "Success",
        description: "POD number updated successfully!",
        position: "bottomRight",
      });

      setIsEditingTracking(false);
      await dispatch(fetchPDD({ jwtBearer, movement: "Branch" }));
    } catch (error) {
      console.error(error);
      notification.error({
        message: "Error",
        description:
          error.message || "Failed to update POD number and fetch data.",
        placement: "bottomRight",
      });
    }
  };

  const formatAppIds = (appIds) => {
    return appIds.map((appId) => {
      if (appId.includes(" - ")) {
        // If the application ID is already in the correct format, return it as is
        return appId;
      }

      const selectedApp = app_id.find((item) => item.APP_ID === appId);
      if (selectedApp) {
        const { APPLICANT_NAME, PARTNER_LOAN_ID, PRODUCT } = selectedApp;
        // Return the application ID in the desired format
        return `${appId} - ${APPLICANT_NAME} - ${PARTNER_LOAN_ID} - ${PRODUCT}`;
      }

      return appId; // Default case (shouldn't happen)
    });
  };

  const handleCancelTrackingClick = () => {
    setEditedTrackingNumber(data?.tracking_number);
    setIsEditingTracking(false);
  };

  const handleTrackingInputChange = (e) => {
    setEditedTrackingNumber(e.target.value);
  };

  // Handle Application ID selection
  const handleSelectAppId = (value) => {
    setSelectedAppId(value);
  };

  const handleDeleteTempAppId = (key) => {
    const updatedAppIds = selectedAppIds.filter((appId) => appId !== key);
    setSelectedAppIds(updatedAppIds);
  };


  const handleDeleteAppId = async (index,tracking_number) => {
    try {
        const appIdToDelete = myAppIdArr[index];
        // console.log("appIdToDelete", appIdToDelete);

        // Remove the appId from the array
        const updatedAppIds = myAppIdArr.filter((_, i) => i !== index);
        setMyAppIdArr(updatedAppIds);

        const payload = { 
          application_id: appIdToDelete,
          tracking_number: tracking_number
      }; 
        console.log("Payload being sent:", payload);

        const response = await dispatch(deleteAppId({ jwtBearer, requestBody: payload }));

        console.log("response",response)

   
        // Handle the response from the action
        if (response.meta.requestStatus === 'fulfilled') {
            // Success
            notification.success({
              message: "Success",
              description: "Application ID deleted Successfully",
              placement: "bottomRight",
            });
            await dispatch(fetchPDD({ jwtBearer, movement: "Branch" }));
    
    
        } else {
            // If the request fails, check for error details
            console.error("Error deleting appId:", response.error.message || 'Unknown error');
        }

    } catch (error) {
        console.error("Error deleting appId:", error);
    }
};


  const handleAddAppId = () => {
    if (selectedAppId && selectedAppId.length > 0) {
      const selectedApp = app_id.find((item) => item.APP_ID === selectedAppId);
  
      if (selectedApp) {
        const formattedAppId = `${selectedApp.APP_ID} - ${selectedApp.APPLICANT_NAME} - ${selectedApp.PARTNER_LOAN_ID} - ${selectedApp.PRODUCT}`;
        setSelectedAppIds((prevArray) => [...prevArray, formattedAppId]);
      }

      // console.log("selectedAppId: " + selectedAppIds)
  
      // Immediately scroll into view after adding the app ID
      if (tableRef.current) {
        tableRef.current.scrollIntoView({ behavior: "smooth" });
      }
  
      setSelectedAppId(null); 
    }
  };
  



  const handleSaveAppIdsClick = async () => {
    // const formattedSelectedAppIds = formatAppIds(selectedAppIds);
    const formattedSelectedAppIds = selectedAppIds;
    const formattedMyAppIdArr = formatAppIds(myAppIdArr);
  
    const allFormattedAppIds = [
      ...formattedMyAppIdArr,
      ...formattedSelectedAppIds,
    ];

    // console.log("allFormattedAppIds",allFormattedAppIds)
  
    const appIdsOnly = allFormattedAppIds.map((item) => item.split(" - ")[0]);

    // const selectedAppIdsOnly=formattedSelectedAppIds.map((item)=>item.split(" - ")[0]);
  
    // Prepare the request body
    const requestBody = {
      application_id: formattedSelectedAppIds,
      tracking_number: data.tracking_number,
    };

    console.log("requestBody",requestBody)
  
    setLoadingSave(true);
  
    try {
      await dispatch(updateInTransit({ jwtBearer, requestBody }));
      await dispatch(fetchPDD({ jwtBearer, movement: "Branch" }));
  
      setTimeout(() => {
        setMyAppIdArr(appIdsOnly);
        setSelectedAppIds([]);
  
        notification.success({
          message: "Success",
          description: "Application ID(s) Added Successfully",
          placement: "bottomRight",
        });
      }, 0);  
  
    } catch (error) {
      console.error("Error updating in-transit:", error);
      notification.error({
        message: "Error",
        description: "Failed to update application IDs",
        placement: "bottomRight",
      });
    } finally {
      setLoadingSave(false);
    }
  };
  

  // console.log("myAppIdArr", myAppIdArr);

  const handleDrawerClose = () => {
    if (selectedAppIds.length > 0 || isEditingTracking) {
      // console.log("in changes", isEditingTracking, selectedAppIds.length);
      setOpenDialog(true);
    } else {
      // console.log("no change");

      onClose();
      setOpenDialog(false);
   
    }
  };

  const handleSaveAndClose = () => {
    handleSaveAppIdsClick();
    // console.log("isEditingTracking --", isEditingTracking);
    {
      isEditingTracking &&
        selectedAppIds.length > 0 &&
        handleSaveTrackingClick();
    }
    onClose();
    setOpenDialog(false);
  };

  const handleCancelAndClose = () => {
    setOpenDialog(false);
    onClose();
  };

  // console.log("myAppIdArr", myAppIdArr);
  // console.log("selectedAppIds", selectedAppIds);
  // console.log("app_id", app_id);

  return (
    <Drawer
      title={
        <div style={{ display: "flex", alignItems: "center" }}>
          <Popover
            content={`This courier has ${myAppIdArr.length} number of Application ID (s).`}
            title="In-Transit"
            trigger="hover"
          >
            <InfoCircleOutlined
              style={{
                marginRight: "8px",
                color: "#1890ff",
                fontSize: "28px",
              }}
            />
          </Popover>

          <Title
            level={4}
            style={{
              marginBottom: "16px",
              color: "rgba(0, 0, 0, 0.7)",
              fontSize: "20px",
              fontFamily: "Poppins",
              marginLeft: "5px",
            }}
          >
            Courier details
          </Title>
        </div>
      }
      visible={visible}
      onClose={handleDrawerClose}
      width={700}
    >
      <div style={{ padding: "0 20px" }}>
        <Space
          style={{
            display: "flex",
            marginBottom: "8px",
            padding: "0 8px",
            borderRadius: "4px",
            backgroundColor: "#ffffff",
            boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
            fontSize: "20px",
            fontFamily: "Poppins",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <strong style={{ color: "#555" }}>Status:</strong>
          <Text
            style={{
              fontSize: "18px",
              fontFamily: "Poppins",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {getStatusText(data?.status)}
          </Text>
        </Space>

        <Space
          style={{
            display: "flex",
            marginBottom: "8px",
            padding: "8px",
            borderRadius: "4px",
            backgroundColor: "#ffffff",
            boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
            fontSize: "20px",
            fontFamily: "Poppins",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <strong style={{ color: "#555" }}>POD Number:</strong>
          <Text
            style={{
              fontSize: "18px",
              fontFamily: "Poppins",
              display: "flex",
              justifyContent: "space-between",
              fontWeight: "800",
            }}
          >
            {data?.tracking_number}
          </Text>
        </Space>

        <Space
          style={{
            display: "flex",
            marginBottom: "8px",
            padding: "8px",
            borderRadius: "4px",
            backgroundColor: "#ffffff",
            boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
            fontSize: "20px",
            fontFamily: "Poppins",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <strong style={{ color: "#555" }}>Verification:</strong>
          <Text
            style={{
              fontSize: "18px",
              fontFamily: "Poppins",
              display: "flex",
              justifyContent: "space-between",
              backgroundColor: "orange",
              padding: "3px 8px",
              color: "white",
              borderRadius: "6px",
            }}
          >
            {getVerificationText(data?.verification)}
          </Text>
        </Space>

        <Space
          style={{
            display: "flex",
            marginBottom: "8px",
            padding: "8px",
            borderRadius: "4px",
            backgroundColor: "#ffffff",
            boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
            fontSize: "20px",
            fontFamily: "Poppins",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <strong style={{ color: "#555" }}>Branch:</strong>
          <Text
            style={{
              fontSize: "18px",
              fontFamily: "Poppins",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {data?.branch}
          </Text>
        </Space>
        <Space
          style={{
            display: "flex",
            marginBottom: "8px",
            padding: "8px",
            borderRadius: "4px",
            backgroundColor: "#ffffff",
            boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
            fontSize: "20px",
            fontFamily: "Poppins",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <strong style={{ color: "#555" }}>Dispatched by :</strong>
          <Text
            style={{
              fontSize: "18px",
              fontFamily: "Poppins",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {data?.courier_dispatcher_name}
          </Text>
        </Space>
      </div>

      {/* <hr style={{ fontWeight: "700", fontSize: "20px" }} /> */}

      {/* Tracking Number Section */}
      <div
        style={{
          marginTop: "16px",
          marginBottom: "30px",
          fontSize: "20px",
          display: "flex",
          justifyContent: "space-around",
          width: "100%",
        }}
      >
        <h4>POD Number:</h4>
        <Space style={{ marginTop: "8px", fontWeight: "800" }}>
          <Input
            value={editedTrackingNumber}
            onChange={handleTrackingInputChange}
            disabled={!isEditingTracking}
            style={{ flex: 1, backgroundColor: "#F4F6FF", color: "black" }}
          />
          {!isEditingTracking ? (
            <Button
              type="primary"
              onClick={handleEditTrackingClick}
              style={{ border: "2px solid #1E90FF" }}
            >
              Update POD Number
            </Button>
          ) : (
            <>
              <Button onClick={handleSaveTrackingClick} type="primary">
                Save
              </Button>
              <Button onClick={handleCancelTrackingClick}>Cancel</Button>
            </>
          )}
        </Space>
      </div>

      {/* Existing App IDs Table */}
      <MyDetailedStyledTable
        bordered
        dataSource={myAppIdArr.map((appId, index) => ({
          key: index,
          appId,
        }))}
        pagination={myAppIdArr.length > 5 ? { pageSize: 5 } : false}
        columns={[
          {
            title: "Application id",
            dataIndex: "appId",
            render: (text) => {
              const getDisplayValue = (appId) => {
                const item = app_id.find((item) => item.APP_ID === appId);
                return item
                  ? `${item.APP_ID} - ${item.APPLICANT_NAME} - ${item.PARTNER_LOAN_ID}`
                  : "";
              };
              return getDisplayValue(text);
            },
          },
          {
            title: "Delete",
            render: (text, record) => (
              <Space>
                <Popconfirm
                  placement="leftTop"
                  title={` Delete ${record.appId} ?`}
                  onConfirm={() => handleDeleteAppId(record.key,editedTrackingNumber)}
                  okText="Yes"
                  cancelText="No"
                  style={{ height: "100px", width: "100px" }}
                >
                  <DeleteOutlined style={{ color: "red" }} />
                </Popconfirm>
              </Space>
            ),
          },
        ]}
      />

      {/* Add new application id section */}
      <div
        style={{
          padding: "16px 0",
          backgroundColor: "#f9f9f9",
          borderRadius: "8px",
          boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
          marginTop: "30px",
          overflowX: "hidden",
          margin: "20px 25px",
        }}
      >
        <h2 style={{ marginLeft: "5px", margin: "15px 30px" }}>
          Add {selectedAppIds.length > 0 && selectedAppIds.length} New
          Application ID(s)
        </h2>
        <Space style={{ marginTop: "8px", width: "100%", margin: "15px 30px",marginLeft:"5px" }}>
          <Select
            value={selectedAppId}
            onChange={handleSelectAppId}
            placeholder="Select Application ID"
            style={{
              flex: 1,
              minWidth: "300px",
              width: "100%",
              height: "40px",
            }}
          >
        {availableAppIDs
        .filter((app) => !selectedAppIds.includes(
          `${app.APP_ID} - ${app.APPLICANT_NAME} - ${app.PARTNER_LOAN_ID} - ${app.PRODUCT}`
        ))
        .map((app) => (
          <Option key={app.APP_ID} value={app.APP_ID}>
            {`${app.APP_ID} - ${app.APPLICANT_NAME} - ${app.PRODUCT}`}
          </Option>
        ))}
          </Select>

          {selectedAppId ? (
            <Button
              onClick={handleAddAppId}
              type="dashed"
              icon={<PlusOutlined />}
              style={{ border: "2px solid dodgerblue" }}
            >
              Add
            </Button>
          ) : (
            <Button
              // onClick={handleAddAppId}
              type="primary"
              icon={<PlusOutlined />}
              disabled={!selectedAppId}
              // style={{border:"2px solid dodgerblue"}}
            >
              Add
            </Button>
          )}

          {selectedAppIds.length > 0 && (
            <Button
              onClick={handleSaveAppIdsClick}
              type="primary"
              style={{ marginRight: "8px" }}
              loading={loadingSave}
            >
              Save Changes
            </Button>
          )}
        </Space>

        {/* newly added app id */}
        {selectedAppIds.length > 0 && (
          <MyDetailedStyledTempTable
            bordered
            // style={{marginRight:"50px"}}
            dataSource={selectedAppIds.map((appId, index) => ({
              key: appId,
              appId: appId,
            }))}
            pagination={selectedAppIds.length > 5 ? { pageSize: 5 } : false}
            columns={[
              {
                dataIndex: "appId",
                render: (text) => text || "",
              },
              {
                render: (text, record) => (
                  <Space>
                    <DeleteOutlined
                      onClick={() => handleDeleteTempAppId(record.key)}
                      style={{ color: "red" }}
                    />
                  </Space>
                ),
              },
            ]}
            showHeader={false} //
          />
        )}
      </div>

      {/* Confirmation Dialog */}
      <Modal
        visible={openDialog}
        title="Unsaved Changes"
        onOk={handleSaveAndClose}
        onCancel={handleCancelAndClose}
        cancelText="Don't Save"
        okText="Save Changes"
        centered
      >
        <Text>Do you want to save any changes?</Text>
      </Modal>
    </Drawer>
  );
};

export default BranchInTransitDrawer;
