import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";
import truckAnimation from "../../../../../../assets/loaderTruck.json";
import moment from "moment";
import Lottie from "lottie-react";
import { updateBrchTrkAppEntry } from "../../../../../redux/slice/pddUpdateBranchTrkApp";
import {
  Typography,
  Tabs,
  Button,
  Input,
  Tag,
  Card,
  Form,
  Space,
  Divider,
  Progress,
  Row,
  Col,
  Radio,
  Select,
  Steps,
  Spin,
  FloatButton,
  Slider,
  InputNumber,
  Collapse,
  Drawer,
  Alert,
  notification,
} from "antd";
import styled from "styled-components";
import {
  SearchOutlined,
  PlusOutlined,
  DeleteOutlined,
  CodeSandboxOutlined,
  DropboxOutlined,
} from "@ant-design/icons";
import jwt_decode from "jwt-decode";
import {
  StyledModal,
  StyledCard,
  StyledRow,
  StyledCol,
  StyledTable,
  RefreshButton,
  StyledButton,
  CardContainer,
  MyStyledTable,
} from "../../../style/styleComp";
import { useAuth } from "../../../../../context/AuthContext";
import { fetchPDDHoToBranch } from "../../../../../redux/slice/getPDDHoToBranchData";
import {
  fetchNotAddedAppIdReturn,
  fetchPDDAppID,
} from "../../../../../redux/slice/getPDDAppID";
import { fetchPDDAppIDRecalled } from "../../../../../redux/slice/getPDDAppIDRecalled";
import { HashLoader } from "react-spinners";
import { addHoToBranchEntry } from "../../../../../redux/slice/pddAddHoToBranchEntry ";
import { updateHoToBranchEntry } from "../../../../../redux/slice/pddUpdateHoToBranchEntry";
import { fetchBranch } from "../../../../../redux/slice/getBranchData";
import { addMissingDocs } from "../../../redux/slice/pddAddMissingDocs";
import ReturningInTransitDrawer from "./ReturningInTransitDrawer";
import ReturningReceivedDrawer from "./ReturningReceivedDrawer";
import { RiRefreshLine } from "react-icons/ri";
import { useSpring, animated } from "react-spring";
import "../../../style/customStyles.css";
import { PiSquaresFour } from "react-icons/pi";
import { IoListOutline } from "react-icons/io5";
import CargoArea from "../../../style/truck/CargoArea";
import Window from "../../../style/truck/Window";
import EngineHeadLight from "../../../style/truck/EngineHeadLight";
import BottomFrameReturning from "../../../style/truck/BottomFrameReturning";
import { LeftTyre } from "../../../style/truck/LeftTyre";
import { RightTyre } from "../../../style/truck/RightTyre";
import { Circle } from "../../../style/truck/Circle";
import { fetchPDDMaster } from "../../../../../redux/slice/getPDDMaster";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";

const { Title, Text } = Typography;

const { TabPane } = Tabs;

const { Panel } = Collapse;

const { Option } = Select;
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

const ReturningHoToBranch = ({ extraProp }) => {
  const [receivedModalVisible, setReceivedModalVisible] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [activeTabKey, setActiveTabKey] = useState("allTables");
  const [tabList, setTabList] = useState([
    { key: "allTables", title: "Branch PDD" },
  ]);
  const [openBox, setOpenBox] = useState(true);
  const [showAddBranchEntryModalVisible, setShowAddBranchEntryModalVisible] =
    useState(false);
  const [selectedAppIDs, setSelectedAppIDs] = useState([]);
  const [searchField, setSearchField] = useState("Select");
  const [searchValue, setSearchValue] = useState("");
  const [enableSearch, setEnableSearch] = useState(true);
  const [modalVisible, setDetailModalVisible] = useState(false);
  const [queryModalVisible, setQueryModalVisible] = useState(false);
  const [selectedCardData, setSelectedCardData] = useState([]);
  const [isAnimated, setIsAnimated] = useState(false);
  const [tableData, setTableData] = useState([]);
  // const [brchData, setBrchData] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState("");

  const [selectedButton, setSelectedButton] = useState("truckIcon");

  const [viewTable, setViewTable] = useState(false);
  const [filteredData, setFilteredData] = useState(tableData);

  // not added app ids
  const [availableAppIDs, setAvailableAppIDs] = useState([]);
  const [filteredAppIDs, setFilteredAppIDs] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [customVendor, setCustomVendor] = useState("");
  const [isOtherSelected, setIsOtherSelected] = useState(false);

  const { authState } = useAuth();

  var token_decoded = authState.token;
  var jwtBearer = authState.token;
  var jwt_payload = "";
  var user_branch = "";
  if (jwtBearer) {
    jwt_payload = jwt_decode(jwtBearer);
    user_branch = extraProp.branch_id;
  }

  const [branches, setBranches] = useState([]);

  useEffect(() => {
    // Parse the user_branch string into an array of branch IDs
    const parsedBranches = user_branch.replace(/[()]/g, "").split(",");
    setBranches(parsedBranches);
  }, [user_branch]);

  const fetchData = () => {
    // console.log("Fetching chat data and app ID...");
    setLoading(true);

    // Dispatch both actions in parallel
    Promise.all([
      dispatch(fetchPDDHoToBranch({ jwtBearer, movement: "ho" })),
      dispatch(fetchPDDAppIDRecalled({ jwtBearer })),
      dispatch(fetchBranch({ jwtBearer })),
      dispatch(fetchPDDMaster({ jwtBearer })),
    ])
      .then(() => {
        // console.log("Chat data and app ID fetched successfully.");
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching chat data or app ID:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    console.log("filteredAppIDs has been updated:", filteredAppIDs);
  }, [filteredAppIDs]);

  // Initial fetch on component mount
  useEffect(() => {
    fetchData();
  }, [dispatch]);

  // Handle refresh button click
  const handleRefresh = () => {
    setIsAnimated(true);
    setLoading(true);
    // console.log("refresing");
    setTimeout(() => {
      setLoading(false);
      setIsAnimated(false);
    }, 1000);
    fetchData();
  };

  const addBranchNewEntry = () => {
    setShowAddBranchEntryModalVisible(true);
  };

  var chatData = useSelector(
    (state) => state.fetchPDDHoToBranch?.data?.message || []
  );

  // const  brchData=chatData
  // console.log("chatData:", chatData);
  const appIDData = useSelector(
    (state) => state.fetchPDDAppIDRecalled?.data?.message || []
  );
  const allBranches = useSelector(
    (state) => state.fetchBranch?.data?.message.btrim || []
  );

  const masterData = useSelector(
    (state) => state.fetchPDDMaster?.data?.message || []
  );
  // console.log("masterData", masterData);
  const courierVendorData = masterData.find(
    (item) => item.field === "courier_vendor"
  );

  useEffect(() => {
    if (courierVendorData) {
      const vendorOptions = courierVendorData.value.map((vendor) => ({
        value: vendor,
        label: vendor,
      }));
      setVendors(vendorOptions);
    } else {
      setVendors([]);
    }
  }, [courierVendorData, setVendors]);

  // console.log("courierVendorData", courierVendorData);

  // console.log("All Branches - ", appIDData);

  const cleanAppIDData = appIDData;
  // console.log("App ID data - ", appIDData);

  // console.log("chat data - ", chatData);

  // const handleRowClick = async (record) => {
  //     console.log("Selected record - ", record);
  // };

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);

    if (searchField !== "Select" && e.target.value) {
      console.log("tableDataTableData", tableData);
      const filtered = tableData.filter((data) => {
        if (searchField === "tracking_number") {
          return data.tracking_number
            ?.toLowerCase()
            .includes(e.target.value.toLowerCase());
        }
        if (searchField === "application_ids") {
          return data.application_ids.some((id) =>
            id.toLowerCase().includes(e.target.value.toLowerCase())
          );
        }
        return false;
      });
      setFilteredData(filtered);
    } else {
      setFilteredData(tableData);
    }
  };

  const handleSearchFieldChange = (value) => {
    setSearchField(value);
    setEnableSearch(false);
  };

  useEffect(() => {
    if (chatData && chatData.length > 0) {
      const groupedData = {};

      chatData.forEach((item) => {
        const {
          trk_no,
          app_id,
          status,
          verification,
          applicant_details,
          updated_at,
          created_at,
          vendor,
          branch,
          cal_file,
        } = item;

        // Initialize the group if it doesn't exist
        if (!groupedData[trk_no]) {
          groupedData[trk_no] = {
            status: item.status,
            branch: item.branch,
            product: item.product,
            tracking_number: trk_no,
            vendor: item.vendor,
            verification: item.verification,
            created_at: item.created_at,
            updated_at: item.updated_at,
            application_ids: [],
            applicant_details: {},
            statuses: [],
            verifications: [],
            application_id_statuses: [],
            cal_file: item.cal_file,
            cal_files: [],
          };
        }

        // Add application ID to the array (only if it's unique)
        if (app_id && !groupedData[trk_no].application_ids.includes(app_id)) {
          groupedData[trk_no].application_ids.push(app_id);
        }

        // Consolidate applicant details
        if (applicant_details) {
          // Ensure applicant_details is in the correct format (object or string)
          const details =
            typeof applicant_details === "string"
              ? JSON.parse(applicant_details)
              : applicant_details;
          groupedData[trk_no].applicant_details[app_id] = details;
        }

        if (status) {
          groupedData[trk_no].statuses.push({ app_id, status });
        }

        if (verification) {
          groupedData[trk_no].verifications.push({ app_id, verification });
        }
        if (cal_file === null) {
          groupedData[trk_no].cal_files.push({ app_id, cal_file: "" });
        } else {
          groupedData[trk_no].cal_files.push({ app_id, cal_file });
        }
      });

      Object.keys(groupedData).forEach((tracking_number) => {
        const verifications = groupedData[tracking_number].verifications;
        let finalVerification = "4";

        if (verifications.some((v) => v.verification === "8")) {
          finalVerification = "8";
        } else if (verifications.every((v) => v.verification === "5")) {
          finalVerification = "5";
        }

        // Set the final verification status
        groupedData[tracking_number].verification = finalVerification;
      });

      // Convert grouped object to an array
      const formattedData = Object.values(groupedData);

      // Set state with the formatted data
      setTableData(formattedData);
      setFilteredData(formattedData);
    }
  }, [chatData]);

  // console.log("filteredData", filteredData);
  // Convert jwt_group string to an array of branch IDs
  const jwtGroupArray = user_branch.slice(1, -1).split(",");

  // chatData = chatData.reverse();

  const handleCloseModal = () => {
    setShowAddBranchEntryModalVisible(false);
    setDetailModalVisible(false);
    setQueryModalVisible(false);
    setSelectedCardData(null);
  };

  const onFinish = async (values) => {
    const finalVendor = isOtherSelected ? customVendor : values.vendor;
    const requestBody = {
      tracking_no: values.trackingNumber,
      application_id: selectedAppIDs,
      vendor: finalVendor,
    };
    // console.log("requestBody", requestBody);

    try {
      // console.log(values, selectedAppIDs);

      // Start loading state
      setLoading(true);

      // Perform the dispatch for adding the branch entry
      const res = await dispatch(
        addHoToBranchEntry({ jwtBearer, requestBody })
      );
      console.log("Response:", res);

      // Fetch the updated data after the first dispatch is successful
      await dispatch(fetchPDDHoToBranch({ jwtBearer, movement: "ho" }));

      // Hide the modal and reset selected application IDs
      setShowAddBranchEntryModalVisible(false);
      setSelectedAppIDs([]);

      // console.log("res.payload.code", res.payload.code);

      if (res.payload && res.payload.code == "400") {
        // Handle duplicate entry error
        console.log("Branch entry failed:", res.payload.message);

        notification.error({
          message: "Duplicate Entry",
          description:
            "An entry with this POD number already exists. Please use a unique POD number.",
          placement: "bottomRight",
          duration: 5,
        });

        form.setFields([
          {
            name: "trackingNumber",
            errors: ["This POD number already exists."],
          },
        ]);

        setShowAddBranchEntryModalVisible(true);
      } else if (res.payload.code && res.payload.code == "100") {
        notification.success({
          message: "Success",
          description: "Courier has been sent to branch successfully.",
          placement: "bottomRight",
          duration: 5,
        });

        onReset();
      } else {
        console.log("Unexpected response code:", res.payload.code);
        notification.error({
          message: "Error",
          description: "An unexpected error occurred. Please try again later.",
          placement: "bottomRight",
          duration: 5,
        });
      }
    } catch (error) {
      console.error("Error updating reschedule date:", error);
      notification.error({
        message: "Error",
        description:
          "An error occurred while processing your request. Please try again later.",
        placement: "bottomRight",
        duration: 5,
      });
    } finally {
      setLoading(false);
    }
  };

  const onReset = () => {
    form.resetFields();
    setCustomVendor("");
  };
  const onFill = () => {
    form.setFieldsValue({
      note: "Hello world!",
      gender: "male",
    });
  };

  const trackingNumberFilters = [
    ...new Set(filteredData.map((record) => record.tracking_number)),
  ].map((value) => ({
    text: value,
    value: value,
  }));

  const handleSelectChange2 = (value) => {
    if (!selectedAppIDs.includes(value)) {
      setSelectedAppIDs([...selectedAppIDs, value]);
    }
  };

  const handleRemoveAppID = (appID) => {
    setSelectedAppIDs(selectedAppIDs.filter((id) => id !== appID));
  };

  const handleSaveModal = (updatedData) => {
    // console.log("Save click - ", updatedData);
    const requestBody = {
      branch: updatedData.updatedData.branch,
      tracking_number: updatedData.updatedData.tracking_number,
      field: updatedData.updatedField,
      value: updatedData.newValue,
    };

    dispatch(updateBrchTrkAppEntry({ jwtBearer, requestBody }))
      .then(() => {
        dispatch(fetchPDD({ jwtBearer, movement: "HO" }));
      })
      .catch((error) => {
        console.error("Error updating reschedule date:", error);
      });
  };

  // console.log("cleanAppId", cleanAppIDData);

  const handleBranchChange = async (value) => {
    try {
      // Fetch not added app IDs (for now, manually set for testing)
      const notAddedResponse = await dispatch(
        fetchNotAddedAppIdReturn({ jwtBearer })
      );
      const availableAppIDs = notAddedResponse.payload.not_added_ids;

      // Optionally, set available app IDs manually for testing
      setAvailableAppIDs(availableAppIDs);
      // console.log("availableAppIds", availableAppIDs);

      // Update selected branch
      setSelectedBranch(value);
      // console.log("selected branch", value);

      // Normalize the branch value (remove spaces) for comparison
      const normalizedBranchValue = value.replace(/\s+/g, "").toLowerCase();

      // Find the branch object based on the selected value (normalized)
      const selectedBranchData = cleanAppIDData.find(
        (branchData) =>
          branchData.branch.replace(/\s+/g, "").toLowerCase() ===
          normalizedBranchValue
      );

      if (selectedBranchData) {
        // Filter the app IDs based on available app IDs (not added IDs)
        const filteredApps = selectedBranchData.application_ids
          .filter((appData) => availableAppIDs.includes(appData.application_id))
          .map((appData) => {
            return `${appData.application_id} - ${appData.applicant_name} - ${appData.product}`;
          });

        // console.log("availableAppIDs: ", availableAppIDs);
        // console.log("filteredApps after filtering:", filteredApps);

        // Set the filtered apps
        setFilteredAppIDs(filteredApps);
        // console.log("filteredAppIds after setting state:", filteredApps);
      } else {
        // If no matching branch found, reset the filtered apps
        setFilteredAppIDs([]);
        console.log("No apps found for the selected branch.");
      }

      // Reset selected app IDs
      setSelectedAppIDs([]);
    } catch (error) {
      console.error("Error during operation:", error);
    }
  };

  const floatButtonProps = useSpring({
    to: { transform: "translateY(0px)", opacity: 1 },
    from: { transform: "translateY(100px)", opacity: 0 },
    config: { tension: 200, friction: 10 },
  });

  const boxShadowFunc = (e) => {
    console.log("Color func - ", e);
    switch (e) {
      case "In-Transit":
        return "0px 4px 8px rgba(245, 144, 39, 0.3)";
      case "In-Query":
        return "0px 4px 8px rgba(255, 0, 0, 0.3)";
      case "Received":
        return "0px 4px 8px rgba(33, 206, 66, 0.8)";
      default:
        return "0px 4px 8px rgba(0, 0, 0, 0.1)";
    }
  };

  // console.log("chatData", chatData);

  const statusCodeToText = (status) => {
    switch (status) {
      case "1":
        return "In-Transit";
      case "2":
        return "In-Query";
      case "3":
        return "Received";
      default:
        return "Unknown Status";
    }
  };

  const bottomFrameVerification = (e) => {
    // console.log("verification status - ", e);
    switch (e) {
      case "4":
        return "orange";
      case "5":
        return "green";
      case "8":
        return "red";
      default:
        return "0px 4px 8px rgba(0, 0, 0, 0.1)";
    }
  };
  const headLightGradient = (e) => {
    // console.log("Color func - ", e);
    switch (e) {
      // verification pending
      case "4":
        return "linear-gradient(to right, white,white)";
      // verified
      case "5":
        return "linear-gradient(to right, white,white)";
      // missing/damaged
      case "8":
        return "linear-gradient(to right, rgba(255, 0, 0, 0.8) , rgba(255, 0, 0, 0.1))";
      default:
        return "linear-gradient(to right, white,white)";
    }
  };

  const handleInQueryCheckboxChange = (e) => {
    setIsInQueryChecked(e.target.checked);
  };

  function TruckDoor() {
    return (
      <div
        style={{
          height: "40px",
          width: "46px",
          borderRadius: "0 20px 0 0",
          border: "2px solid",
          borderLeft: "white",
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            border: "solid black 2px",
            height: "15px",
            width: "30px",
            marginTop: "2px",
            borderRadius: "0 12px 0 0",
          }}
        ></div>
        <span style={{ fontSize: "10px", whiteSpace: "nowrap" }}>
          Blue dart
        </span>
      </div>
    );
  }

  function TruckEngine() {
    return (
      <>
        <div style={{ display: "flex" }}>
          <div
            style={{
              border: "solid 2px",
              height: "20px",
              width: "20px",
              borderRadius: "0 10px 0 0",
              borderLeft: "white",
            }}
          ></div>
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              gap: "0",
              position: "relative",
              top: "11px",
              left: "-3px",
            }}
          >
            <div
              style={{
                width: "10px",
                height: "5px",
                background: "white",
                transform: "perspective(60px) rotateY(-70deg)",
                // margin: "50px",
                alignSelf: "start",
                //   backgroundColor: "yellow",
                border: "solid 2px black",
              }}
            ></div>
            <motion.span
              style={{
                // width: "60px",
                height: "10px",
                background: "white",
                //   border: "solid 1px black",
                transform: "perspective(60px) rotateY(-70deg)",
                // margin: "50px",
                alignSelf: "self-start",
                position: "relative",
                left: "-27px",
                top: "-2px",
                background: headLightGradient(items[0]?.status),
                //   "linear-gradient(to right, rgba(255, 0, 0, 0.8) , rgba(255, 0, 0, 0.1))",
              }}
              className="headLight"
              transition={{
                duration: 0.5,
                repeat: Infinity,
                repeatType: "reverse",
              }}
            ></motion.span>
          </div>
        </div>
      </>
    );
  }

  const handleCardClick = async (data) => {
    setSelectedCardData(data); // Set the entire array as selected data
    // console.log("Card click - ", data);

    // Check the status of the first item to decide which modal to open
    data.status === "1"
      ? setDetailModalVisible(true)
      : setQueryModalVisible(true);
  };

  const verificationCodeToText = (verification) => {
    switch (verification) {
      case "4":
        return "Pending at HO";
      case "5":
        return "Verified";
      case "8":
        return "Missing/Damaged Documents";
      default:
        return "Unknown Verification";
    }
  };
  const verificationCodeForTableToText = (verification) => {
    // console.log("verification status for table", verification);
    switch (verification) {
      case "4":
        return "Verification Pending At HO";
      case "5":
        return "Verified";
      case "8":
        return "Missing/Damaged Documents";
      default:
        return "Unknown Verification";
    }
  };

  const myColumns = [
    {
      title: "POD Number",
      dataIndex: "tracking_number",
      key: "tracking_number",
    },
    {
      title: "Verification",
      dataIndex: "verification",
      key: "verification",
      render: (verification) => verificationCodeToText(verification),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => statusCodeToText(status),
    },
    // { title: "Vendor", dataIndex: "vendor", key: "vendor" },
    { title: "Branch", dataIndex: "branch", key: "branch" },
    { title: "Created At", dataIndex: "created_at", key: "created_at" },
  ];

  const myFormattedData = filteredData.map((item) => ({
    tracking_number: item.tracking_number,
    verification: item.verification,
    // verification: verificationCodeForTableToText(item.verification),
    status: item.status,
    vendor: item.vendor,
    branch: item.branch,
    created_at: moment(item.created_at).format("DD-MM-YYYY"),
    // fullData: item,
  }));

  // console.log("myFormattedData", myFormattedData);

  const handleSelectChange = (value) => {
    if (value === "other") {
      setIsOtherSelected(true);
    } else {
      setIsOtherSelected(false);
    }
  };

  const handleCustomVendorChange = (e) => {
    setCustomVendor(e.target.value);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "0 10px",
        }}
      >
        {/* <Form layout="inline">
          <div
            className="selectAndsearch"
            style={{ display: "flex", margin: "0 30px" }}
          >
            <Form.Item style={{ marginBottom: "20px" }}>
              <Select
                value={searchField}
                onChange={handleSearchFieldChange}
                style={{
                  width: 170,
                  height: 49,
                  borderRadius: "5px",
                  border: "1px solid #d9d9d9",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                  fontSize: "16px",
                  //   textAlign:"center"
                }}
                dropdownStyle={{
                  borderRadius: "5px",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                }}
              >
                <Option value="tracking_number">POD Number</Option>
                <Option value="application_ids">Application ID</Option>
              </Select>
            </Form.Item>

            <Form.Item className="searchbarContainer">
              <Input
                placeholder="Search..."
                value={searchValue}
                onChange={handleSearchChange}
                prefix={
                  <SearchOutlined
                    style={{ color: "#1890ff", marginRight: "10px" }}
                  />
                }
                style={{
                  height: 49,
                  borderRadius: "5px",
                  border: "1px solid #d9d9d9",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                  fontSize: "16px",
                }}
                className="mySearchBar"
                disabled={enableSearch}
              />
            </Form.Item>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "78vw",
              textAlign: "end",
            }}
          >
            <animated.div
              style={{
                ...floatButtonProps,
                position: "fixed",
                top: "140px",
                right: "20px",
              }}
            ></animated.div>
          </div>

          <animated.div
            style={{
              ...floatButtonProps,
              position: "fixed",
              bottom: "20px",
              right: "20px",
            }}
          >
            <Button
              type="primary"
              shape="circle"
              icon={
                openBox ? (
                  <CodeSandboxOutlined
                    style={{ fontSize: "36px", marginLeft: "3px" }}
                  />
                ) : (
                  <DropboxOutlined
                    style={{ fontSize: "36px", marginLeft: "3px" }}
                  />
                )
              }
              size="large"
              onClick={addBranchNewEntry}
              // onClick={openBoxDrawer}
              onMouseEnter={() => setOpenBox(false)}
              onMouseLeave={() => setOpenBox(true)}
              style={{
                width: "70px", // Increased width
                height: "70px", // Increased height
                fontSize: "24px", // Increased font size
                backgroundColor: "red",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
              }}
            />
          </animated.div>
        </Form> */}

        <Form layout="inline">
          <div
            className="selectAndsearch"
            style={{ display: "flex", margin: "0 30px" }}
          >
            {/* Search Field Select */}
            <Form.Item style={{ marginBottom: "20px" }}>
              <Select
                value={searchField}
                onChange={handleSearchFieldChange}
                style={{
                  width: 170,
                  height: 49,
                  borderRadius: "5px",
                  border: "1px solid #d9d9d9",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                  fontSize: "16px",
                  //   textAlign:"center"
                }}
                dropdownStyle={{
                  borderRadius: "5px",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                }}
              >
                <Option value="tracking_number">POD Number</Option>
                <Option value="application_ids">Application ID</Option>
              </Select>
            </Form.Item>

            {/* Search Input */}
            <Form.Item
              name="searchValue"
              // rules={[{ required: true, message: "Please enter a search term" }]}
            >
              <Input
                placeholder="Search..."
                value={searchValue}
                onChange={handleSearchChange}
                prefix={
                  <SearchOutlined
                    style={{ color: "#1890ff", marginRight: "10px" }}
                  />
                }
                style={{
                  height: 49,
                  borderRadius: "5px",
                  border: "1px solid #d9d9d9",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                  fontSize: "16px",
                }}
                className="mySearchBar"
                disabled={enableSearch}
              />
            </Form.Item>
          </div>

          {/* Button to trigger action */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "78vw",
              textAlign: "end",
            }}
          >
            <animated.div
              style={{
                ...floatButtonProps,
                position: "fixed",
                top: "140px",
                right: "20px",
              }}
            />
          </div>

          {/* Floating button */}
          <animated.div
            style={{
              ...floatButtonProps,
              position: "fixed",
              bottom: "20px",
              right: "20px",
            }}
          >
            <Button
              type="primary"
              shape="circle"
              icon={
                openBox ? (
                  <CodeSandboxOutlined
                    style={{ fontSize: "36px", marginLeft: "3px" }}
                  />
                ) : (
                  <DropboxOutlined
                    style={{ fontSize: "36px", marginLeft: "3px" }}
                  />
                )
              }
              size="large"
              onClick={addBranchNewEntry}
              onMouseEnter={() => setOpenBox(false)}
              onMouseLeave={() => setOpenBox(true)}
              style={{
                width: "70px", // Increased width
                height: "70px", // Increased height
                fontSize: "24px", // Increased font size
                backgroundColor: "red",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
              }}
            />
          </animated.div>
        </Form>

        {/* right container */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "40px",
            marginBottom: "20px",
            justifyContent: "center",
            marginRight: "50px",
            position: "relative",
            top: "-50px",
          }}
        >
          {/* car and table */}

          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              gap: "5px",
              borderRadius: "10px",
              // padding: "5px",
              // boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
            }}
          >
            <button
              onClick={() => {
                setViewTable(false);
                setSelectedButton("truckIcon");
              }}
              style={{
                background:
                  selectedButton === "truckIcon" ? "#f0f0f0" : "transparent",
                border: "none",
                fontSize: "28px",
                cursor: "pointer",
                // padding: "5px 10px",
                paddingTop: "10px",
                paddingBottom: "5px",
                paddingLeft: "7px",
                paddingRight: "7px",
                borderRadius: "8px",
                transition: "background-color 0.3s ease, transform 0.2s ease",
                boxShadow:
                  selectedButton === "truckIcon"
                    ? "0px 4px 10px rgba(0, 0, 0, 0.2)"
                    : "none",
              }}
              onMouseEnter={(e) => (e.target.style.transform = "scale(1.1)")}
              onMouseLeave={(e) => (e.target.style.transform = "scale(1)")}
            >
              <PiSquaresFour />
            </button>
            <button
              onClick={() => {
                setViewTable(true);
                setSelectedButton("table");
              }}
              style={{
                background:
                  selectedButton === "table" ? "#f0f0f0" : "transparent",
                border: "none",
                fontSize: "28px",
                cursor: "pointer",
                paddingTop: "10px",
                paddingBottom: "5px",
                paddingLeft: "7px",
                paddingRight: "7px",
                borderRadius: "8px",
                transition: "background-color 0.3s ease, transform 0.2s ease",
                boxShadow:
                  selectedButton === "table"
                    ? "0px 4px 10px rgba(0, 0, 0, 0.2)"
                    : "none",
              }}
              onMouseEnter={(e) => (e.target.style.transform = "scale(1.1)")}
              onMouseLeave={(e) => (e.target.style.transform = "scale(1)")}
            >
              <IoListOutline />
            </button>
          </div>

          {/* refresh icon */}
          <div>
            <Button
              type="primary"
              shape="circle"
              size="large"
              onClick={handleRefresh}
              style={{
                width: "40px",
                height: "40px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
                backgroundColor: "white",
                position: "relative",
                padding: 0,
                borderRadius: "50%",
              }}
            >
              {loading ? (
                <span
                  className={loading ? "spin" : ""}
                  style={{
                    fontSize: "16px",
                    fontWeight: "900",
                    color: "blue",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                    transformOrigin: "50% 50%",
                  }}
                >
                  <RiRefreshLine
                    style={{
                      fontSize: "40px",
                      color: "blue",
                      marginLeft: "4px",
                    }}
                    className="spin"
                  />
                </span>
              ) : (
                <RiRefreshLine
                  style={{
                    fontSize: "40px",
                    color: "blue",
                    marginLeft: "4px",
                  }}
                />
              )}
            </Button>
          </div>
        </div>
      </div>

      {loading ? (
        <div
          style={{
            height: "400px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          {/* <Lottie
            animationData={truckAnimation}
            loop={true}
            style={{ width: "400px", height: "400px" }}
          /> */}
        <div className="spinner-container">
                   <HashLoader color="#36d7b7" />
                 </div>
        </div>
      ) : (
        <>
          {viewTable ? (
            <MyStyledTable
              columns={myColumns}
              dataSource={myFormattedData}
              rowKey="tracking_number"
              pagination={{
                pageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ["5", "10", "20", "50"],
              }}
              onRow={(record) => ({
                onClick: () => handleTableRowClick(record),
              })}
              style={{ margin: "40px" }}
            />
          ) : (
            <CardContainer>
              {filteredData.map((data, index) => (
                <card
                  style={{
                    position: "relative",
                    marginBottom: "40px",
                    cursor: "pointer",
                    marginLeft: "40px",
                  }}
                  onClick={() => handleCardClick(data)}
                >
                  <div style={{ display: "flex" }}>
                    <div
                      style={{
                        width: "150px",
                        height: "60px",
                        border: "2px solid black",
                        backgroundColor: "transparent",
                        // position: 'absolute',
                        top: "10px",
                        left: "0px",
                        // borderRadius: '5px',
                        borderRadius: "10px 10px 0 0",
                      }}
                    >
                      <CargoArea data={data} />
                    </div>
                    <Window data={data} />
                    <div style={{ alignSelf: "flex-end" }}>
                      <EngineHeadLight data={data} />
                    </div>
                  </div>
                  <div style={{ position: "relative" }}>
                    <BottomFrameReturning data={data} />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        position: "absolute",
                        top: "0",
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          left: "180px",
                          top: "2.5px",
                        }}
                      >
                        <Circle />
                      </div>
                      <div
                        style={{
                          position: "absolute",
                          left: "35px",
                          top: "2.5px",
                        }}
                      >
                        <Circle />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          {" "}
                          <LeftTyre />
                        </div>
                        <div>
                          <RightTyre />
                        </div>
                      </div>
                    </div>
                  </div>
                </card>
              ))}
            </CardContainer>
          )}
        </>
      )}

      <Drawer
        open={showAddBranchEntryModalVisible}
        onClose={handleCloseModal}
        width={600}
        //   style={{ borderRadius: "15px" }}
        //   title={"Return Courier"}
      >
        <Form
          layout="vertical"
          style={{ padding: "0px 40px", fontFamily: "Arial, sans-serif" }}
          form={form}
          onFinish={onFinish}
        >
          {/* Vendor Selection */}
          <Form.Item
            name="vendor"
            label={<strong className="customerSubHead">Select Vendor</strong>}
            rules={[{ required: true, message: "Please select a vendor" }]}
          >
            <Select
              placeholder="Select a Vendor"
              style={{ width: "100%", height: "50px" }}
              onChange={handleSelectChange}
              value={isOtherSelected ? "other" : undefined}
            >
              {/* Replace with dynamic vendor list */}
              {vendors.map((vendor) => (
                <Select.Option key={vendor.value} value={vendor.value}>
                  {vendor.label}
                </Select.Option>
              ))}

              {/* Add the "Other" option */}
              <Select.Option value="other">Other</Select.Option>
            </Select>

            {/* Conditionally show input field when "Other" is selected */}
            {isOtherSelected && (
              <Input
                style={{ width: "100%", height: "50px", marginTop: 30 }}
                name="vendor"
                value={customVendor}
                onChange={handleCustomVendorChange}
                placeholder="Enter vendor name"
              />
            )}
          </Form.Item>

          {/* Branch Selection */}
          <Form.Item
            name="branch"
            label={<strong className="customerSubHead">Select Branch</strong>}
            rules={[
              {
                required: true,
                message: "Please select a branch",
              },
            ]}
          >
            <Select
              showSearch
              style={{
                width: "100%",
                height: "50px",
                borderRadius: "8px",
                fontWeight: "500",
              }}
              placeholder="Select a Branch"
              onChange={handleBranchChange}
            >
              {Object.values(allBranches).map((branch) => (
                <Option key={branch} value={branch}>
                  {branch}
                </Option>
              ))}
            </Select>
          </Form.Item>

          {/* POD Number */}
          <Form.Item
            name="trackingNumber"
            label={
              <strong className="customerSubHead">Enter POD Number</strong>
            }
            rules={[
              {
                required: true,
                message: "Please enter POD number",
              },
            ]}
          >
            <Input
              style={{
                fontSize: "16px",
                fontWeight: "600",
                color: "black",
                borderRadius: "8px",
                padding: "12px",
                border: "1px solid #d9d9d9",
              }}
              placeholder="Enter POD Number"
            />
          </Form.Item>

          {/* Application ID Selection */}
          <Form.Item
            name="appIDs"
            label={
              <strong
                style={{ fontSize: "18px", color: "grey", fontWeight: "500" }}
              >
                Select Application ID:
              </strong>
            }
          >
            <Select
              showSearch
              style={{
                fontSize: "16px",
                fontWeight: "600",
                color: "black",
                borderRadius: "8px",
                height: "50px",
                border: "1px solid #d9d9d9",
              }}
              placeholder="Select an Application ID"
              onChange={handleSelectChange2}
              filterOption={(input, option) =>
                option?.label?.toLowerCase().includes(input.toLowerCase())
              }
              options={filteredAppIDs
                .filter((app) => !selectedAppIDs.includes(app))
                .map((app) => ({
                  value: app, // Use app as the value
                  label: app, // Display application ID as label
                }))}
            />
          </Form.Item>

          {/* Display Selected Application IDs */}
          <div style={{ marginTop: "20px" }}>
            {selectedAppIDs.map((appID) => (
              <Tag
                key={appID}
                closable
                onClose={() => handleRemoveAppID(appID)}
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  color: "#000",
                  borderRadius: "8px",
                  margin: "5px",
                  padding: "10px 10px",
                  backgroundColor: "#e6f7ff",
                  border: "1px solid #91d5ff",
                }}
              >
                {appID}
              </Tag>
            ))}
          </div>

          {/* Submit Button */}
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{
                width: "100%",
                height: "45px",
                fontSize: "16px",
                fontWeight: "600",
                borderRadius: "8px",
                backgroundColor: "#1890ff",
                borderColor: "#1890ff",
                color: "#fff",
                marginTop: "20px",
              }}
              loading={loading}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
      <ReturningInTransitDrawer
        visible={modalVisible}
        onClose={handleCloseModal}
        data={selectedCardData}
        app_id={cleanAppIDData}
        onSave={handleSaveModal} // Function to save the updated POD number
        jwtBearer={jwtBearer}
      />
      <ReturningReceivedDrawer
        visible={queryModalVisible}
        onClose={handleCloseModal}
        data={selectedCardData}
        brchData={chatData}
      />
    </>
  );
};

export default ReturningHoToBranch;
