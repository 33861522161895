import React from 'react';

function BottomFrame({ data }) {
    if (!data || data.length === 0) {
        return null;
    }

    // console.log("bottom frame data", data);

    const calculateVerification = () => {
        if (!Array.isArray(data.verifications)) {
            console.error("Invalid verifications data:", data.verifications);
            return "4"; 
        }

        const validVerifications = data.verifications
            .filter(ver => {
                if (!Array.isArray(data.application_id_statuses)) {
                    console.error("Invalid application_id_statuses data:", data.application_id_statuses);
                    return false;
                }

                const appId = ver.application_id;
                const appIdStatus = data.application_id_statuses.find(
                    status => status.application_id === appId
                )?.app_id_status;

                return appIdStatus !== "2"; 
            })
            .map(ver => ver.verification); 

        if (validVerifications.includes("8")) {
            return "8";
        }

        if (validVerifications.every(ver => ver === "5")) {
            return "5";
        }

        return "4";
    };

    const bottomFrameVerification = (verification) => {
        switch (verification) {
            case "4":
                return "orange";
            case "5":
                return "green";
            case "8":
                return "red";
            default:
                return "0px 4px 8px rgba(0, 0, 0, 0.1)";
        }
    };

    const overallVerification = calculateVerification();

    return (
        <div>
            <div
                style={{
                    height: "13px",
                    width: "220px",
                    border: "2px solid",
                    borderTopColor: bottomFrameVerification(overallVerification),
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: bottomFrameVerification(overallVerification),
                }}
            >
                <span style={{ color: "white" }}>
                    {data.status && (
                        <b>
                            <p
                                style={{
                                    textAlign: "left",
                                    width: "100%",
                                    paddingLeft: "10px",
                                    fontSize: "12px",
                                    fontWeight: "800",
                                    fontFamily: "Poppins",
                                    letterSpacing: "3px",
                                    marginLeft: "17px",
                                }}
                            >
                                {Array.isArray(data.status)
                                    ? data.status.join(", ")
                                    : data.status === "1"
                                        ? " IN-TRANSIT"
                                        : data.status === "3"
                                            ? " RECEIVED"
                                            : data.status}
                            </p>
                        </b>
                    )}
                </span>
            </div>
        </div>
    );
}

export default BottomFrame;
