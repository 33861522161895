import React, { useState } from "react";
import { Drawer, Table, Typography, Modal, Button, Space, notification } from "antd";
import { MyStyledTable } from "../../../style/styleComp";
import { InboxOutlined, InfoCircleOutlined } from "@ant-design/icons";

const { Title, Text } = Typography;

const ReturningReceivedDrawer = ({ visible, onClose, data, brchData }) => {
  if (!data || !brchData) {
    return null;
  }

  // console.log("data", data);
  // console.log("brchData", brchData);

  const [calFile, setCalFile] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [currentAppId, setCurrentAppId] = useState(null);

  const trackingNumber = data.tracking_number;

// Step 2: Filter `brchData` based on the `trk_no` value
const filteredBrchData = brchData.filter(item => item.trk_no === trackingNumber);

  // Handle the "View" button click to open the modal
  const showModal = () => {
    setModalVisible(true);
  };

  // Close the modal
  const closeModal = () => {
    setModalVisible(false);
  };

  // Get status text based on status code
  const getStatusText = (statusCode) => {
    switch (statusCode) {
      case "1":
        return "In-Transit";
      case "2":
        return "In-Query";
      case "3":
        return "Received";
      default:
        return "";
    }
  };

  // Get verification text based on the verification status code
  const getVerificationText = (statusCode) => {
    switch (statusCode) {
      case "4":
        return "Pending at HO";
      case "5":
        return "Verified";
      case "8":
        return "Missing/Damaged Documents";
      default:
        return "";
    }
  };

  // Handle the "View" button in the table column
  const columns = [
    {
      title: "Application Name",
      dataIndex: "app_name",
      key: "app_name",
    },
    {
      title: "Application Id",
      dataIndex: "app_id",
      key: "app_id",
    },
    {
      title: "Partner Loan ID",
      dataIndex: "partner_loan_id",
      key: "partner_loan_id",
    },
    {
      title: "Product",
      dataIndex: "product",
      key: "product",
    },
    {
      title: "CAL",
      key: "cal_file",
      render: (_, record) => {
        const { app_id, cal_file } = record;

        // Handle opening the modal to view the CAL file
        const handleViewClick = () => {
          setCalFile(cal_file);
          setCurrentAppId(app_id);
          showModal();
        };

        return (
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            {cal_file ? (
              <Button onClick={handleViewClick} type="link">
                View
              </Button>
            ) : null}
          </div>
        );
      },
    },
  ];

  const handleClose = () => {
    onClose();
  };

  return (
    <Drawer
      visible={visible}
      onClose={handleClose}
      footer={null}
      width={900}
      title={
        <div style={{ display: "flex", alignItems: "center" }}>
          <InfoCircleOutlined style={{ marginRight: "8px", color: "#1890ff", fontSize: "28px" }} />
          <Title
            level={4}
            style={{
              marginBottom: "16px",
              color: "rgba(0, 0, 0, 0.7)",
              fontSize: "20px",
              fontFamily: "Poppins",
              marginLeft: "5px",
            }}
          >
            Courier details
          </Title>
        </div>
      }
    >
      <div style={{ padding: "0 20px" }}>
        <Space
          style={{
            display: "flex",
            marginBottom: "8px",
            padding: "0 8px",
            borderRadius: "4px",
            backgroundColor: "#ffffff",
            boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
            fontSize: "20px",
            fontFamily: "Poppins",
            display: "flex",
            justifyContent: "space-between",
            height: "50px",
          }}
        >
          <strong style={{ color: "#555" }}>Status :</strong>
          <Text
            style={{
              fontSize: "20px",
              fontFamily: "Poppins",
              backgroundColor: "green",
              padding: "0px 8px",
              color: "white",
              borderRadius: "6px",
            }}
          >
            {getStatusText(data?.status)}
          </Text>
        </Space>

        <Space
          style={{
            display: "flex",
            marginBottom: "8px",
            padding: "8px",
            borderRadius: "4px",
            backgroundColor: "#ffffff",
            boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
            fontSize: "20px",
            fontFamily: "Poppins",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <strong style={{ color: "#555" }}>POD Number :</strong>
          <Text
            style={{
              fontSize: "18px",
              fontFamily: "Poppins",
              fontWeight: "800",
            }}
          >
            {data?.tracking_number}
          </Text>
        </Space>

        <Space
          style={{
            display: "flex",
            marginBottom: "8px",
            padding: "8px",
            borderRadius: "4px",
            backgroundColor: "#ffffff",
            boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
            fontSize: "20px",
            fontFamily: "Poppins",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <strong style={{ color: "#555" }}>Verification:</strong>
          <Text
            style={{
              fontSize: "18px",
              fontFamily: "Poppins",
              backgroundColor:
                data.verification === "5"
                  ? "green"
                  : data.verification === "4"
                  ? "orange"
                  : data.verification === "8"
                  ? "red"
                  : "",
              padding: "3px 8px",
              color: "white",
              borderRadius: "6px",
            }}
          >
            {getVerificationText(data?.verification)}
          </Text>
        </Space>

        <Space
          style={{
            display: "flex",
            marginBottom: "18px",
            padding: "8px",
            borderRadius: "4px",
            backgroundColor: "#ffffff",
            boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
            fontSize: "20px",
            fontFamily: "Poppins",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <strong style={{ color: "#555" }}>Branch :</strong>
          <Text style={{ fontSize: "18px", fontFamily: "Poppins" }}>
            {data?.branch}
          </Text>
        </Space>
      </div>

      <MyStyledTable
        columns={columns}
        dataSource={filteredBrchData}
        rowKey="app_id"
        pagination={false}
      />

      {/* Modal to View CAL File */}
      <Modal
        title="Customer Acknowledgement Letter"
        visible={modalVisible}
        onCancel={closeModal}
        footer={null}
        width="1200px"
        style={{ height: "100vh"}}
      
      >
        {calFile ? (
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
            {calFile.match(/\.(jpeg|jpg|png)$/i) ? (
              <img
                src={calFile}
                alt="CAL"
                style={{
                  width: "100%",
                  maxHeight: "80vh",
                  objectFit: "contain",
                }}
              />
            ) : calFile.match(/\.pdf$/i) ? (
              <iframe
                src={calFile}
                style={{ width: "100%", height: "100vh", border: "none" }}
                title="CAL File"
              />
            ) : (
              <div>Unsupported file type</div>
            )}
          </div>
        ) : (
          <div>No file available</div>
        )}
      </Modal>
    </Drawer>
  );
};

export default ReturningReceivedDrawer;
