import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { PiSquaresFour } from "react-icons/ai";
import { PiSquaresFour } from "react-icons/pi";
import { FaTable } from "react-icons/fa";
// import { IoListOutline } from "react-icons/fa";
import { IoListOutline } from "react-icons/io5";
// import TableView from "../components/TableView";
import { MyStyledTable } from "../style/styleComp";
import moment from "moment";
import "../style/customStyles.css";
import {
  Typography,
  Tabs,
  Button,
  Input,
  Tag,
  Card,
  Form,
  Space,
  Divider,
  Progress,
  Row,
  Col,
  Radio,
  Modal,
  Select,
  Steps,
  Spin,
  FloatButton,
  Slider,
  InputNumber,
  Collapse,
  Drawer,
  Alert,
  notification,
  message,
} from "antd";
import styled from "styled-components";
import {
  CloseCircleOutlined,
  SearchOutlined,
  PlusOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
  DropboxOutlined,
  CodeSandboxOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import jwt_decode from "jwt-decode";
import {
  StyledModal,
  StyledCard,
  StyledRow,
  StyledCol,
  StyledTable,
  StyledButton,
  CardContainer,
} from "../style/styleComp";
import { useAuth } from "../../../context/AuthContext";
import { fetchPDD } from "../../../redux/slice/getPDDData";
import {
  fetchPDDAppID,
  fetchNotAddedAppID,
} from "../../../redux/slice/getPDDAppID";
import { HashLoader } from "react-spinners";
import { addBranchEntry } from "../../../redux/slice/pddAddBranchEntry";
import { updateBranchTrkAppEntry } from "../../../redux/slice/pddUpdateBranchTrkApp";
import { addMissingDocs } from "../../../redux/slice/pddAddMissingDocs";
import BranchInTransitDrawer from "../components/Branch/Leaving/BranchInTransitDrawer";
import BranchMissDamDrawer from "../components/Branch/Leaving/BranchMissDamDrawer";
import BranchHoToBranch from "../components/Branch/Returning/ReturningHoToBranch";
import { useSpring, animated } from "react-spring";
import "../style/customStyles.css";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import Checkbox from "@mui/material/Checkbox";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import SquareRoundedIcon from "@mui/icons-material/SquareRounded";
import { RiRefreshLine } from "react-icons/ri";
import { availableAppId } from "../../../redux/slice/pddCheckAvailableApplication";
import BranchReceivedDrawer from "../components/Branch/Leaving/BranchReceivedDrawer";
import truckAnimation from "../../../../assets/loaderTruck.json";
import Lottie from "lottie-react";
import { motion } from "framer-motion";
import { fetchPDDMaster } from "../../../redux/slice/getPDDMaster";
import { LeftTyre } from "../style/truck/LeftTyre";
import { RightTyre } from "../style/truck/RightTyre";
import { Circle } from "../style/truck/Circle";
import CargoArea from "../style/truck/CargoArea";
import Window from "../style/truck/Window";
import EngineHeadLight from "../style/truck/EngineHeadLight";
import BottomFrame from "../style/truck/BottomFrame";
import CheckBoxAppId from "../components/Branch/CheckBoxAppId";
import { fetchPDDHoToBranch } from "../../../redux/slice/getPDDHoToBranchData";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";
const { Title, Text } = Typography;

const { Panel } = Collapse;

const { Option } = Select;

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

const BranchPDD = (props) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [showAddBranchEntryModalVisible, setShowAddBranchEntryModalVisible] =
    useState(false);
  const [searchField, setSearchField] = useState("Select");
  const [enableSearch, setEnableSearch] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [filteredData, setFilteredData] = useState(tableData);
  const [modalVisible, setDetailModalVisible] = useState(false);
  const [queryModalVisible, setQueryModalVisible] = useState(false);
  const [receivedModalVisible, setReceivedModalVisible] = useState(false);
  const [missingModalVisible, setMissingModalVisible] = useState(false);
  const [selectedCardData, setSelectedCardData] = useState(null);
  const [isFormValid, setIsFormValid] = useState(false);
  const [customVendor, setCustomVendor] = useState("");
  const [isOtherSelected, setIsOtherSelected] = useState(false);
  const { authState } = useAuth();
  const [openBox, setOpenBox] = useState(true);
  // const [loading, setLoading] = useState(false);
  const [isAnimated, setIsAnimated] = useState(false);
  const [isChecked, setIsChecked] = useState(false); 
  const [isInQueryChecked, setIsInQueryChecked] = useState(false);
  const [availableAppIDs, setAvailableAppIDs] = useState([]);
  const [formattedBranches, setFormattedBranches] = useState([]);
  const [viewTable, setViewTable] = useState(false);
  const [selectedButton, setSelectedButton] = useState("truckIcon");
  const [isBranchSelected, setIsBranchSelected] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [filteredAppIDs, setFilteredAppIDs] = useState([]);
  const [selectedAppIDs, setSelectedAppIDs] = useState([]);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [vendors, setVendors] = useState([]);
  const [isRefreshing, setIsRefreshing] = useState(false)

  var token_decoded = authState.token;
  var jwtBearer = authState.token;
  var jwt_payload = "";
  var jwt_payload_group = [];
  var user_id = "";
  var user_role = "";
  var user_name = "";
  var user_branch = "";
  if (jwtBearer) {
    jwt_payload = jwt_decode(jwtBearer);
    user_name = props.userDetails?.username;
    user_id = jwt_payload.user_id;
    user_role = props.userDetails?.role;
    jwt_payload_group = props.userDetails?.group_id;
    user_branch = props.userDetails?.branch_id;
  }

  const [branches, setBranches] = useState([]);

  const chatData = useSelector((state) => state.fetchPDD?.data?.message || []);

  const appIDData = useSelector(
    (state) => state.fetchPDDAppID?.data?.message || []
  );
  const notAddedIds = useSelector(
    (state) => state.pddDataSlice.notAddedData?.not_added_ids
  );

  // console.log("my notAddedIds", notAddedIds);

  const masterData = useSelector(
    (state) => state.fetchPDDMaster?.data?.message || []
  );

  const courierVendorData = masterData.find(
    (item) => item.field === "courier_vendor"
  );

  const statusData = useSelector((state) => state.fetchPDD?.data?.status || []);

  const countData = useSelector((state) => state.fetchPDD?.data?.count || []);

  useEffect(() => {
    // console.log("Use effect 1");
    // Parse the user_branch string into an array of branch IDs
    const parsedBranches = user_branch.replace(/[()]/g, "").split(",");
    setBranches(parsedBranches);
  }, [user_branch]);

  useEffect(() => {
    // console.log("courierVendorData", courierVendorData); 
    if (courierVendorData && courierVendorData.value) {
      const vendorOptions = courierVendorData.value.map((vendor) => ({
        value: vendor,
        label: vendor,
      }));
      setVendors(vendorOptions);
    } else {
      setVendors([]); // Fallback to empty
    }
  }, [courierVendorData]);

  // console.log("vendors", vendors);



  useEffect(() => {
    // console.log("Use effect 2");
    fetchData();
  }, [dispatch]);

  const fetchData = () => {
    // console.log("Fetching chat data and app ID...");
    setLoading(true);

    // Dispatch both actions in parallel
    Promise.all([
      dispatch(fetchPDD({ jwtBearer, movement: "Branch" })),
      dispatch(fetchPDDAppID({ jwtBearer })),
      dispatch(fetchPDDMaster({ jwtBearer })),
      // dispatch(fetchNotAddedAppID({ jwtBearer })),
    ])
      .then(() => {
        // console.log("Chat data and app ID fetched successfully.");
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching chat data or app ID:", error);
        setLoading(false);
      });
  };
  const fetchHoToBranchData = () => {
    setLoading(true);
    Promise.all([
      dispatch(fetchPDDHoToBranch({ jwtBearer, movement: "branch" })),
      dispatch(fetchPDDAppID({ jwtBearer })),
    ])
      .then(() => setLoading(false))
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  };

  const handleRefresh = () => {
    setIsAnimated(true);
    setLoading(true);
  
    // Check the value of isChecked and call the respective function
    if (isChecked) {
      // If isChecked is true, fetch HoToBranch data
      fetchHoToBranchData();
    } else {
      // If isChecked is false, fetch regular PDD and AppID data
      fetchData();
    }
  };
  

  // console.log("available app id to add", availableAppIDs);

  const addBranchNewEntry = async () => {
    setShowAddBranchEntryModalVisible(true);

    // console.log("Add entry data - ", cleanAppIDData);

    // Extract the branch codes from cleanAppIDData
    const branchCodes = Array.from(
      new Set(cleanAppIDData.map((item) => item.BR_CD))
    );

    // Extract app IDs and structure data by branch
    const transformedData = branchCodes.map((branchCode) => {
      return {
        branch_code: branchCode,
        application_id: cleanAppIDData
          .filter((item) => item.BR_CD === branchCode)
          .map((item) => ({
            APP_ID: item.APP_ID,
            BR_CD: item.BR_CD,
            PRODUCT: item.PRODUCT,
            DISBURSAL_DATE: item.DISBURSAL_DATE,
          })),
      };
    });

    // console.log("Transformed Data:", transformedData);

    // Prepare request body
    const requestBody = {
      application_id: transformedData,
    };

    // console.log("Request Body filter:", requestBody);

    // Call the dispatch method with the new request body
    const userResponse = await dispatch(
      availableAppId({ jwtBearer, requestBody })
    );

    // console.log("User Response:", userResponse);
    setAvailableAppIDs(userResponse.payload?.available_app_ids);
    // console.log("availableAppIds:", availableAppIDs);
  };

  // console.log("status data", statusData);
  // console.log("Count data", countData);

  // console.log("All PDD App ID - ", appIDData);

  const branchArray = user_branch.replace(/[()]/g, "").split(",");

  // Filter appIDData to include only entries where BR_CD matches jwt_branch
  const cleanAppIDData = appIDData.filter((app) =>
    branchArray.includes(app.BR_CD.split(" ")[0])
  );
  // console.log("App ID data - ", cleanAppIDData, branches);

  // console.log("chat data - ", chatData);

  // Format branches into the desired format directly
  const formattedBranchesTemp = branches
    .map((branchCode) => {
      const match = cleanAppIDData.find((app) =>
        app.BR_CD.startsWith(branchCode)
      );
      return match ? match.BR_CD : null;
    })
    .filter(Boolean); 



  useEffect(() => {
    if (chatData && chatData.length > 0) {
      const groupedData = {};

      chatData.forEach((item) => {
        const {
          tracking_number,
          application_id,
          applicant_details,
          status,
          verification,
          app_id_status,
        } = item;

        // Initialize the group if it doesn't exist
        if (!groupedData[tracking_number]) {
          groupedData[tracking_number] = {
            status: item.status,
            branch: item.branch,
            tracking_number: tracking_number,
            vendor: item.vendor,
            verification: item.verification,
            created_at: item.created_at,
            updated_at: item.updated_at,
            verified_at: item.verified_at,
            courier_dispatcher_name: item.courier_dispatcher_name,
            courier_dispatcher_id: item.courier_dispatcher_id,
            application_ids: [],
            applicant_details: {},
            app_id_status: item.app_id_status,
            statuses: [],
            verifications: [],
            application_id_statuses: [],
          };
        }

        // Add application ID to the array (only if it's unique)
        if (
          application_id &&
          !groupedData[tracking_number].application_ids.includes(application_id)
        ) {
          groupedData[tracking_number].application_ids.push(application_id);
        }

        // Consolidate applicant details
        if (applicant_details) {
          const details =
            typeof applicant_details === "string"
              ? JSON.parse(applicant_details)
              : applicant_details;
          groupedData[tracking_number].applicant_details[application_id] =
            details;
        }

        // Add the status for the specific application ID (check for existence)
        if (status) {
          groupedData[tracking_number].statuses.push({
            application_id,
            status,
          });
        }

        // Add the verification for the specific application ID (check for existence)
        if (verification) {
          groupedData[tracking_number].verifications.push({
            application_id,
            verification,
          });
        }

        // Add the app_id_status for the specific application ID (check for existence)
        if (app_id_status) {
          groupedData[tracking_number].application_id_statuses.push({
            application_id,
            app_id_status,
          });
        }
      });

      // After grouping, determine the final verification status
      Object.keys(groupedData).forEach((tracking_number) => {
        const verifications = groupedData[tracking_number].verifications;
        let finalVerification = "4"; // Default value

        // Check if any verification is 8
        if (verifications.some((v) => v.verification === "8")) {
          finalVerification = "8";
        } else if (verifications.every((v) => v.verification === "5")) {
          finalVerification = "5";
        }

        // Set the final verification status
        groupedData[tracking_number].verification = finalVerification;
      });

      // Convert grouped object to an array
      const formattedData = Object.values(groupedData);

      // Set state with the formatted data
      setTableData(formattedData);
      setFilteredData(formattedData);
    }
  }, [chatData]);

  // console.log("filteredData", filteredData);
  const handleRowClick = async (record) => {
    // console.log("Selected record - ", record);
  };

  const getColumns = (data) => {
    if (!data || data.length === 0) return [];

    // Extract keys from the first object in the data array
    const keys = Object.keys(data[0]);

    // Map over the keys to create the columns dynamically
    return keys.map((key) => ({
      title: (
        <strong style={{ fontSize: "18px" }}>
          {key.replace(/_/g, " ").toUpperCase()}{" "}
          {/* Replace underscores with spaces and uppercase the title */}
        </strong>
      ),
      dataIndex: key,
      key: key,
      render: (text) => (
        <span className="poppins-font" style={{ fontSize: "18px" }}>
          {Array.isArray(text) ? text.join(", ") : text}{" "}
          {/* Handle array values like application_ids */}
        </span>
      ),
    }));
  };

  const columns = getColumns(chatData);

  // console.log("tableData: ",tableData)
  // console.log("filteredData: ",filteredData)

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);

    if (searchField !== "Select" && e.target.value) {
      // console.log("tableDataTableData",tableData)
      const filtered = tableData.filter((data) => {
        if (searchField === "tracking_number") {
          return data.tracking_number
            ?.toLowerCase()
            .includes(e.target.value.toLowerCase());
        }
        if (searchField === "application_ids") {
          return data.application_ids.some((id) =>
            id.toLowerCase().includes(e.target.value.toLowerCase())
          );
        }
        return false;
      });
      setFilteredData(filtered);
    } else {
      setFilteredData(tableData); // Reset to original data if no search criteria
    }
  };

  const handleSearchFieldChange = (value) => {
    setSearchField(value);
    setEnableSearch(false); // Enable search input when a field is selected
  };

  const jwtGroupArray = user_branch.slice(1, -1).split(",");

  const handleCloseModal = () => {
    setShowAddBranchEntryModalVisible(false);
    setDetailModalVisible(false);
    setQueryModalVisible(false);
    setSelectedCardData(null);
    setQueryModalVisible(false);
    setMissingModalVisible(false);
  };

  // console.log("selected application id", selectedAppIDs);

  const onFinish = async (values) => {
    const finalVendor = isOtherSelected ? customVendor : values.vendor;

    const requestBody = {
      vendor: finalVendor,
      branch: values.branch,
      tracking_number: values.trackingNumber,
      application_id: selectedAppIDs,
    };
    try {
      setLoading(true);

      const res = await dispatch(addBranchEntry({ jwtBearer, requestBody }));
      console.log("Response:", res);

      await dispatch(fetchPDD({ jwtBearer, movement: "Branch" }));

      setShowAddBranchEntryModalVisible(false);
      setSelectedAppIDs([]);

      if (res.type === "addBranchEntry/rejected") {
        console.log("Branch entry failed:", res.error.message);


        notification.error({
          message: "Duplicate Entry",
          description:
            "An entry with this POD Number already exists. Please use a unique POD Number.",
          placement: "bottomRight",
          duration: 5,
        });


        form.setFields([
          {
            name: "trackingNumber",
            errors: ["This POD Number already exists."],
          },
        ]);
        setShowAddBranchEntryModalVisible(true);
      } else if (res.type === "addBranchEntry/fulfilled") {

        notification.success({
          message: "Success",
          description: "Courier has been sent to Head Office successfully.",
          placement: "bottomRight",
          duration: 5,
        });

        onReset();
      }
    } catch (error) {
      console.error("Error updating reschedule date:", error);
    } finally {
      setLoading(false);
    }
  };

  const onReset = () => {
    form.resetFields();
    setIsOtherSelected(false);
    setCustomVendor("");
  };
  const openNotification = (appID) => {
    notification.success({
      message: "Application ID Selected",
      description: `You have selected: ${appID}`,
      placement: "bottomRight",
    });
  };

  const numberOfSelectedAppIDs = selectedAppIDs.length;

  const handleCardClick = (data) => {
    // console.log("Card data - ", data);
    setSelectedCardData(data);
    console.log("data.status", data);

    // data.status === '1' ? setDetailModalVisible(true) : setQueryModalVisible(true);

    const trackingNo = data.tracking_number;

    // console.log("card click status data - ", data.status, data.verification);

    if (data.status === "1") {
      setQueryModalVisible(false);
      setMissingModalVisible(false);
      setReceivedModalVisible(false);
      setDetailModalVisible(true);
    } else if (data.status === "3" || data.verification == "8") {
      setDetailModalVisible(false);
      setQueryModalVisible(false);
      setReceivedModalVisible(false);
      setMissingModalVisible(true);
    } 
    // else if (data.status === "3") {
    //   setDetailModalVisible(false);
    //   setQueryModalVisible(false);
    //   setMissingModalVisible(false);
    //   setReceivedModalVisible(true);
    // } 
    else {
      setDetailModalVisible(false);
      setQueryModalVisible(false);
      setMissingModalVisible(false);
      setReceivedModalVisible(false);
    }
  };

  const handleTableRowClick = (row) => {
    // console.log("Full data row - ", row.fullData);
    setSelectedCardData(row.fullData);

    const trackingNo = row.fullData.tracking_number;
    // console.log("POD Number --", trackingNo);

    if (row.fullData.status === "1") {
      setDetailModalVisible(true);
    } else if (
      row.fullData.status === "3" &&
      row.fullData.verification === "8"
    ) {
      setMissingModalVisible(true);
    } else if (row.fullData.status === "3") {
      setReceivedModalVisible(true);
    } else {
      setDetailModalVisible(false);
      setQueryModalVisible(false);
    }
  };

  const handleSaveModal = (updatedData) => {
    // console.log("Save click - ", updatedData);
    const requestBody = {
      branch: updatedData.updatedData.branch,
      tracking_number: updatedData.updatedData.tracking_number,
      field: updatedData.updatedField,
      value: updatedData.newValue,
    };

    dispatch(updateBranchTrkAppEntry({ jwtBearer, requestBody }))
      .then(() => {
        dispatch(fetchPDD({ jwtBearer, movement: "Branch" }));
      })
      .catch((error) => {
        console.error("Error updating reschedule date:", error);
      });
  };

  const handleValuesChange = () => {
    form
      .validateFields()
      .then(() => setIsSubmitDisabled(false))
      .catch(() => setIsSubmitDisabled(true));
  };
  const floatButtonProps = useSpring({
    to: { transform: "translateY(0px)", opacity: 1 },
    from: { transform: "translateY(100px)", opacity: 0 },
    config: { tension: 200, friction: 10 },
  });

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
    setIsRefreshing(true)
  };

  const handleInQueryCheckboxChange = (e) => {
    setIsInQueryChecked(e.target.checked);
  };

  // console.log("cleanAppIDData", cleanAppIDData);

  const handleBranchChange = async (value) => {
    try {
      setIsBranchSelected(!!value);
      // Fetch not added app IDs
      const notAddedResponse = await dispatch(
        fetchNotAddedAppID({ jwtBearer })
      );
      const availableAppIDs = notAddedResponse.payload.not_added_ids;
      // console.log("Not Added Data fetched successfully:", availableAppIDs);

      // Set available app IDs in state
      setAvailableAppIDs(availableAppIDs);

      // Update selected branch
      setSelectedBranch(value);

      // Filter the app IDs based on selected branch after available app IDs are set
      const filteredApps = cleanAppIDData.filter(
        (app) => app.BR_CD === value && availableAppIDs.includes(app.APP_ID)
      );

      // Set filtered app IDs in state
      setFilteredAppIDs(filteredApps);
      // console.log("Filtered App IDs:", filteredApps); // Log the filtered results

      // Reset selected app IDs
      setSelectedAppIDs([]);
    } catch (error) {
      console.error("Error during operation:", error);
    }
  };

  const handleSelect = (value) => {
    setSelectedAppIDs((prevSelected) => [...prevSelected, value]);
  };

  // console.log("selectedAppIds", selectedAppIDs);

  const handleRemoveAppID = (appID) => {
    const newSelectedAppIDs = selectedAppIDs.filter((id) => id !== appID);
    setSelectedAppIDs(newSelectedAppIDs);
    form.setFieldsValue({ appIDs: undefined });
  };

  // Logic for status and verification codes
  const statusCodeToText = (status) => {
    switch (status) {
      case "1":
        return "In-Transit";
      case "2":
        return "In-Query";
      case "3":
        return "Received";
      default:
        return "Unknown Status";
    }
  };

  const verificationCodeToText = (verification) => {
    switch (verification) {
      case "4":
        return "Pending at HO";
      case "5":
        return "Verified";
      case "8":
        return "Missing/Damaged Documents";
      default:
        return "Unknown Verification";
    }
  };
  const verificationCodeForTableToText = (verification) => {
    // console.log("verification status for table", verification);
    switch (verification) {
      case "4":
        return "Verification Pending At HO";
      case "5":
        return "Verified";
      case "8":
        return "Missing/Damaged Documents";
      default:
        return "Unknown Verification";
    }
  };

  const trackingNumberFilters = [
    ...new Set(filteredData.map((record) => record.tracking_number)),
  ].map((value) => ({
    text: value,
    value: value,
  }));

  console.log("filteredData", filteredData);

  const myColumns = [
    {
      title: "POD Number",
      dataIndex: "tracking_number",
      key: "tracking_number",
      filters: trackingNumberFilters,
      onFilter: (value, record) => record.tracking_number === value,
      filterSearch: true,
    },
    {
      title: "Verification",
      dataIndex: "verification",
      key: "verification",
      render: (verification) => verificationCodeToText(verification),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => statusCodeToText(status),
    },
    { title: "Vendor", dataIndex: "vendor", key: "vendor" },
    { title: "Branch", dataIndex: "branch", key: "branch" },
    { title: "Created At", dataIndex: "created_at", key: "created_at" },
  ];

  // for table
  const myFormattedData = filteredData.map((item) => ({
    tracking_number: item.tracking_number,
    verification: item.verification,
    // verification: verificationCodeForTableToText(item.verification),
    status: item.status,
    vendor: item.vendor,
    branch: item.branch,
    created_at: moment(item.created_at).format("DD-MM-YYYY"),
    fullData: item,
  }));

  // console.log("selected app id", selectedAppIDs);

  const handleSelectChange = (value) => {
    if (value === "other") {
      setIsOtherSelected(true); 
    } else {
      setIsOtherSelected(false);
    }
  };

  const handleCustomVendorChange = (e) => {
    setCustomVendor(e.target.value);
  };
  return (
    <>
      <div style={{ padding: "2px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "0 17px",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            {/* <p style={{ fontSize: "20px", marginRight: "8px" }}>Filters: </p> */}
            <Checkbox
              size="large"
              {...label}
              checkedIcon={
                <SquareRoundedIcon style={{ color: "dodgerBlue" }} />
              }
              onChange={handleCheckboxChange}
            />
            <p style={{ fontSize: "20px", fontWeight: 700, marginLeft: "5px" }}>
              Show Returning
            </p>
          </div>

          {/* right container */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "40px",
              marginTop: "5px",
              justifyContent: "center",
            }}
          >
            {/* car and table */}

            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                gap: "5px",
                borderRadius: "10px",
                // padding: "5px",
                // boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
              }}
            >
              <button
                onClick={() => {
                  setViewTable(false);
                  setSelectedButton("truckIcon");
                }}
                style={{
                  background:
                    selectedButton === "truckIcon" ? "#f0f0f0" : "transparent",
                  border: "none",
                  fontSize: "28px",
                  cursor: "pointer",
                  // padding: "5px 10px",
                  paddingTop: "10px",
                  paddingBottom: "5px",
                  paddingLeft: "7px",
                  paddingRight: "7px",
                  borderRadius: "8px",
                  transition: "background-color 0.3s ease, transform 0.2s ease",
                  boxShadow:
                    selectedButton === "truckIcon"
                      ? "0px 4px 10px rgba(0, 0, 0, 0.2)"
                      : "none",
                }}
                onMouseEnter={(e) => (e.target.style.transform = "scale(1.1)")}
                onMouseLeave={(e) => (e.target.style.transform = "scale(1)")}
              >
                <PiSquaresFour />
              </button>
              <button
                onClick={() => {
                  setViewTable(true);
                  setSelectedButton("table");
                }}
                style={{
                  background:
                    selectedButton === "table" ? "#f0f0f0" : "transparent",
                  border: "none",
                  fontSize: "28px",
                  cursor: "pointer",
                  paddingTop: "10px",
                  paddingBottom: "5px",
                  paddingLeft: "7px",
                  paddingRight: "7px",
                  borderRadius: "8px",
                  transition: "background-color 0.3s ease, transform 0.2s ease",
                  boxShadow:
                    selectedButton === "table"
                      ? "0px 4px 10px rgba(0, 0, 0, 0.2)"
                      : "none",
                }}
                onMouseEnter={(e) => (e.target.style.transform = "scale(1.1)")}
                onMouseLeave={(e) => (e.target.style.transform = "scale(1)")}
              >
                <IoListOutline />
              </button>
            </div>

            {/* refresh icon */}
            <div>
              <Button
                type="primary"
                shape="circle"
                size="large"
                onClick={handleRefresh}
                style={{
                  width: "40px",
                  height: "40px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
                  backgroundColor: "white",
                  position: "relative",
                  padding: 0,
                  borderRadius: "50%",
                }}
              >
                {loading ? (
                  <span
                    className={loading ? "spin" : ""}
                    style={{
                      fontSize: "16px",
                      fontWeight: "900",
                      color: "blue",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      height: "100%",
                      transformOrigin: "50% 50%",
                    }}
                  >
                    <RiRefreshLine
                      style={{
                        fontSize: "40px",
                        color: "blue",
                        marginLeft: "4px",
                      }}
                      className="spin"
                    />
                  </span>
                ) : (
                  <RiRefreshLine
                    style={{
                      fontSize: "40px",
                      color: "blue",
                      marginLeft: "4px",
                    }}
                  />
                )}
              </Button>
            </div>
          </div>
        </div>

        {isChecked ? (
          <BranchHoToBranch
            jwtBearer={jwtBearer}
            extraProp={props.userDetails}
            viewTable={viewTable} 
            selectedButton={selectedButton}
            isRefreshing={isRefreshing}
            loading={loading} 
            setLoading={setLoading}
          />
        ) : (
          <>
            <Form layout="inline">
              <div
                className="selectAndsearch"
                style={{ display: "flex", margin: "0 30px" }}
              >
                <Form.Item style={{ marginBottom: "20px" }}>
                  <Select
                    value={searchField}
                    onChange={handleSearchFieldChange}
                    style={{
                      width: 170,
                      height: 49,
                      borderRadius: "5px",
                      border: "1px solid #d9d9d9",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                      fontSize: "16px",
                      //   textAlign:"center"
                    }}
                    dropdownStyle={{
                      borderRadius: "5px",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <Option value="tracking_number">POD Number</Option>
                    <Option value="application_ids">Application ID</Option>
                  </Select>
                </Form.Item>

                <Form.Item className="searchbarContainer">
                  <Input
                    placeholder="Search..."
                    value={searchValue}
                    onChange={handleSearchChange}
                    prefix={
                      <SearchOutlined
                        style={{ color: "#1890ff", marginRight: "10px" }}
                      />
                    }
                    style={{
                      height: 49,
                      borderRadius: "5px",
                      border: "1px solid #d9d9d9",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                      fontSize: "16px",
                    }}
                    className="mySearchBar"
                    disabled={enableSearch}
                  />
                </Form.Item>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "78vw",
                  textAlign: "end",
                }}
              >
                <animated.div
                  style={{
                    ...floatButtonProps,
                    position: "fixed",
                    top: "140px",
                    right: "20px",
                  }}
                ></animated.div>
              </div>

              <animated.div
                style={{
                  ...floatButtonProps,
                  position: "fixed",
                  bottom: "20px",
                  right: "20px",
                }}
              >
                <Button
                  type="primary"
                  shape="circle"
                  icon={
                    openBox ? (
                      <CodeSandboxOutlined
                        style={{ fontSize: "36px", marginLeft: "3px" }}
                      />
                    ) : (
                      <DropboxOutlined
                        style={{ fontSize: "36px", marginLeft: "3px" }}
                      />
                    )
                  }
                  size="large"
                  onClick={addBranchNewEntry}
                  // onClick={openBoxDrawer}
                  onMouseEnter={() => setOpenBox(false)}
                  onMouseLeave={() => setOpenBox(true)}
                  style={{
                    width: "70px", // Increased width
                    height: "70px", // Increased height
                    fontSize: "24px", // Increased font size
                    backgroundColor: "red",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
                  }}
                />
              </animated.div>
            </Form>

            {loading ? (
              <div
                style={{
                  height: "400px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                {/* <Lottie
                  animationData={truckAnimation}
                  loop={true}
                  style={{ width: "400px", height: "400px" }}
                /> */}
                 <div className="spinner-container">
                                  <HashLoader color="#f57171" />
                                </div>
              
              </div>
            ) : (
              <div style={{ margin: "0 30px" }}>
                {viewTable ? (
                  <MyStyledTable
                    columns={myColumns}
                    dataSource={myFormattedData}
                    rowKey="tracking_number"
                    pagination={{
                      pageSize: 10,
                      showSizeChanger: true,
                      pageSizeOptions: ["5", "10", "20", "50"],
                    }}
                    style={{ marginTop: "26px" }}
                    onRow={(record) => ({
                      onClick: () => handleTableRowClick(record),
                    })}
                  />
                ) : (
                  <CardContainer>
                    {filteredData.map((data, index) => (
                      <card
                        style={{
                          position: "relative",
                          marginBottom: "40px",
                          cursor: "pointer",
                        }}
                        onClick={() => handleCardClick(data)}
                      >
                        <div style={{ display: "flex" }}>
                          <div
                            style={{
                              width: "150px",
                              height: "60px",
                              border: "2px solid black",
                              backgroundColor: "transparent",
                              // position: 'absolute',
                              top: "10px",
                              left: "0px",
                              // borderRadius: '5px',
                              borderRadius: "10px 10px 0 0",
                            }}
                          >
                            <CargoArea data={data} />
                          </div>
                          <Window data={data} />
                          <div style={{ alignSelf: "flex-end" }}>
                            <EngineHeadLight data={data} />
                          </div>
                        </div>
                        <div style={{ position: "relative" }}>
                          <BottomFrame data={data} />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              position: "absolute",
                              top: "0",
                            }}
                          >
                            <div
                              style={{
                                position: "absolute",
                                left: "180px",
                                top: "2.5px",
                              }}
                            >
                              <Circle />
                            </div>
                            <div
                              style={{
                                position: "absolute",
                                left: "35px",
                                top: "2.5px",
                              }}
                            >
                              <Circle />
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <div>
                                {" "}
                                <LeftTyre />
                              </div>
                              <div>
                                <RightTyre />
                              </div>
                            </div>
                          </div>
                        </div>
                      </card>
                    ))}
                  </CardContainer>
                )}
              </div>
            )}
          </>
        )}

        <Drawer
          placement="right"
          closable={true}
          onClose={handleCloseModal}
          visible={showAddBranchEntryModalVisible}
          width={650}
          title={<span style={{ fontSize: "20px" }}>Add Courier</span>}
          onValuesChange={handleValuesChange}
        >
          <Form
            layout="vertical"
            form={form}
            onFinish={onFinish}
            style={{ padding: "0px 30px" }}
          >
            <Form.Item
              name="vendor"
              label={<strong className="customerSubHead">Select Vendor</strong>}
              rules={[{ required: true, message: "Please select a vendor" }]}
            >
              <Select
                placeholder="Select a Vendor"
                style={{ width: "100%", height: "50px" }}
                onChange={handleSelectChange}
                value={isOtherSelected ? "other" : undefined} 
              >
                {/* Replace with dynamic vendor list */}
                {vendors.map((vendor) => (
                  <Select.Option key={vendor.value} value={vendor.value}>
                    {vendor.label}
                  </Select.Option>
                ))}

                {/* Add the "Other" option */}
                <Select.Option value="other">Other</Select.Option>
              </Select>

              {/* Conditionally show input field when "Other" is selected */}
              {isOtherSelected && (
                <Input
                  style={{ width: "100%", height: "50px", marginTop: 30 }}
                  name="vendor"
                  value={customVendor}
                  onChange={handleCustomVendorChange}
                  placeholder="Enter vendor name"
                />
              )}
            </Form.Item>

            {/* Branch Selection */}
            <Form.Item
              name="branch"
              label={<strong className="customerSubHead">Select Branch</strong>}
              rules={[
                {
                  required: true,
                  message: "Please select a branch",
                },
              ]}
            >
              <Select
                showSearch
                style={{
                  fontSize: "18px",
                  fontWeight: "600",
                  color: "black",
                  borderRadius: "10px",
                  height: "50px",
                }}
                placeholder="Select a Branch"
                onChange={handleBranchChange}
              >
                {formattedBranchesTemp.map((branch) => (
                  <Select.Option key={branch} value={branch}>
                    {branch}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            {/* Courier POD No */}
            <Form.Item
              name="trackingNumber"
              label={
                <strong className="customerSubHead">Courier POD No.</strong>
              }
              rules={[
                {
                  required: true,
                  message: "Please enter courier POD No.",
                },
              ]}
            >
              <Input
                style={{
                  fontSize: "18px",
                  fontWeight: "600",
                  color: "black",
                  borderRadius: "10px",
                  marginRight: "8px",
                  flex: 1,

                  height: "50px",
                }}
                placeholder="Enter courier POD No."
              />
            </Form.Item>

            {/* Application ID Selection */}
            <Form.Item
              name="appIDs"
              label={
                <p
                  style={{ fontSize: "18px", color: "grey", fontWeight: "500" }}
                >
                  <strong>Select Application ID:</strong>
                </p>
              }
              rules={[
                {
                  required: true,
                  message: "Please select at least 1 application ID",
                },
              ]}
            >
              <Select
                showSearch
                disabled={!isBranchSelected}
                style={{
                  fontSize: "18px",
                  fontWeight: "600",
                  color: "black",
                  borderRadius: "10px",
                  height: "55px",
                }}
                placeholder="Select an Application ID"
                onChange={handleSelect}
                filterOption={(input, option) =>
                  option?.label?.toLowerCase().includes(input.toLowerCase())
                }
                options={filteredAppIDs
                  .filter(
                    (app) =>
                      !selectedAppIDs.includes(
                        `${app.APP_ID} - ${app.APPLICANT_NAME} - ${app.PARTNER_LOAN_ID} - ${app.PRODUCT}`
                      )
                  ) // Filter out selected app IDs
                  .map((app) => {
                    const labelValue = `${app.APP_ID} - ${app.APPLICANT_NAME} - ${app.PARTNER_LOAN_ID} - ${app.PRODUCT}`;
                    const SelectValue = `${app.APP_ID} - ${app.APPLICANT_NAME} - ${app.PARTNER_LOAN_ID} - ${app.PRODUCT}`;
                    return {
                      value: SelectValue,
                      label: labelValue,
                    };
                  })}
              />
            </Form.Item>

            {/* <CheckBoxAppId
              cleanAppIDData={cleanAppIDData}
              availableAppIDs={availableAppIDs}
              selectedAppIDs={selectedAppIDs}
              filteredAppIDs={filteredAppIDs}
              handleSelect={handleSelect}
            /> */}

            <div
              style={{
                marginTop: "20px",
                display: "flex",
                flexWrap: "wrap",
                marginBottom: "20px",
              }}
            >
              {selectedAppIDs.map((appID) => (
                <Tag
                  key={appID}
                  closable
                  onClose={() => handleRemoveAppID(appID)}
                  closeIcon={
                    <CloseCircleOutlined
                      style={{ color: "#ff4d4f", fontSize: "16px" }}
                    />
                  }
                  style={{
                    fontSize: "16px",
                    fontWeight: "500",
                    color: "#000",
                    borderRadius: "8px",
                    margin: "5px",
                    padding: "10px 10px",
                    backgroundColor: "#e6f7ff",
                    border: "1px solid #91d5ff",
                  }}
                >
                  {appID}
                </Tag>
              ))}
            </div>

            {/* Submit Button */}
            <Form.Item>
              <Button
                loading={loading}
                type="primary"
                htmlType="submit"
                style={{
                  width: "100%",
                  height: "45px",
                  fontSize: "16px",
                  fontWeight: "600",
                  borderRadius: "8px",
                  backgroundColor: "#1890ff",
                  borderColor: "#1890ff",
                  color: "#fff",
                }}
                disabled={!isSubmitDisabled}
              >
                {loading
                  ? "Submitting..."
                  : `Submit${
                      selectedAppIDs.length > 0
                        ? ` (${selectedAppIDs.length})`
                        : ""
                    }`}
              </Button>
            </Form.Item>
          </Form>
        </Drawer>

        {/* when status is 1 (In-Transit) and verification is 4(verfication pending)*/}
        <BranchInTransitDrawer
          visible={modalVisible}
          onClose={handleCloseModal}
          data={selectedCardData}
          app_id={cleanAppIDData}
          onSave={handleSaveModal}
          jwtBearer={jwtBearer}
        />

        {/* when status is 3 (Received) and verification is 5 (verified) */}
        <BranchReceivedDrawer
          visible={receivedModalVisible}
          onClose={handleCloseModal}
          data={selectedCardData}
          all_app_id={appIDData}
          app_id={cleanAppIDData}
          statusDetails={statusData}
          jwtBearer={jwtBearer}
        />

        {/* when status is 3 (Received) and verification is 8 (Missing/Damaged Documents) */}
        <BranchMissDamDrawer
          visible={missingModalVisible}
          onClose={handleCloseModal}
          data={selectedCardData}
          all_app_id={appIDData}
          statusDetails={statusData}
        />
      </div>
    </>
  );
};

export default BranchPDD;
