import React, { useState } from "react";
import {
  Modal,
  Button,
  Input,
  Typography,
  Space,
  Popconfirm,
  Select,
  Drawer,
  notification,
  Popover,
  Upload,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  MyDetailedStyledTable,
  MyStyledTable,
  StyledTable,
} from "../../../style/styleComp";
import {
  PlusOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
  SaveOutlined,
  UploadOutlined,
  InboxOutlined,
} from "@ant-design/icons";
import { uploadNocCalFile } from "../../../../../redux/slice/uploadNocCal";
import { fetchPDDHoToBranch } from "../../../../../redux/slice/getPDDHoToBranchData";

const { Text, Title } = Typography;
const { Dragger } = Upload;
const ReturningToBranchInTransitDrawer = ({
  returningToBranchReceivedDrawer,
  onClose,
  data,
  onStatusChange,
  selectedIndex,
  fetchData,
  jwtBearer,
  chatData,
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [currentAppId, setCurrentAppId] = useState("");
  const [visible, setVisible] = useState(false);
  const [calFile, setCalFile] = useState(null);
  const dispatch = useDispatch();

  // console.log("Callback data modal received- ", data);

  const filteredData = chatData?.filter(
    (item) => item.trk_no === data?.tracking_number
  );

  // const handleFinalMarkReceived = () => {
  //   console.log("mark as received - ", data.application_ids);
  //   if (onStatusChange) {
  //     const callbackData = {
  //       app_id: data.flatMap((item) => item.app_id),
  //       status: "Received",
  //     };
  //     console.log("Callback data - ", callbackData);
  //     onStatusChange(callbackData);
  //   }
  // };

  const handleFinalMarkReceived = () => {
    // console.log("mark as received - ", data.application_ids);

    // Ensure that `data.application_ids` is an array
    if (onStatusChange && Array.isArray(data.application_ids)) {
      const callbackData = {
        app_id: data.application_ids,
        status: "Received",
      };

      // console.log("Callback data - ", callbackData);

      onStatusChange(callbackData);
    } else {
      console.error(
        "Invalid application_ids or onStatusChange callback not provided"
      );
    }
  };

  const showDrawer = () => setVisible(true);
  const closeDrawer = () => setVisible(false);

  const { uploading, fileUrl, error } = useSelector(
    (state) => state.uploadNocCal
  );



  const showModal = () => {
    setVisible(true);
  };

  // Function to close Modal
  const closeModal = () => {
    setVisible(false);
  };
  // Columns for the table
  const columns = [
    {
      title: "Name",
      dataIndex: "app_name",
      key: "app_name",
      render: (value) => value,
    },
    {
      title: "App ID",
      dataIndex: "app_id",
      key: "app_id",
      render: (value) => value,
    },

    // {
    //   title: "Created At",
    //   dataIndex: "created_at",
    //   key: "created_at",
    //   render: (value) => new Date(value).toLocaleString(),
    // },
    {
      title: "Partner Loan ID",
      dataIndex: "partner_loan_id",
      key: "partner_loan_id",
      render: (value) => value,
    },
    {
      title: "Product",
      dataIndex: "product",
      key: "product",
      render: (value) => value,
    },

    // {
    //   title: "Updated At",
    //   dataIndex: "updated_at",
    //   key: "updated_at",
    //   render: (value) => new Date(value).toLocaleString(),
    // },
    // {

    {
      title: "CAL",
      key: "cal_file",
      render: (_, record) => {
        const { app_id, status, cal_file } = record;
        // console.log("record: ", record);

        // Handle opening the drawer
        const handleDrawerOpen = () => {
          setCalFile(cal_file);
          setCurrentAppId(app_id);
          showDrawer();
        };

        return (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {cal_file ? (
                <Button
                  onClick={() => {
                    setCalFile(cal_file);
                    setCurrentAppId(app_id);
                    showModal(); // Open Modal
                  }}
                  type="link"
                >
                  View
                </Button>
              ) : (
                <Button
                  type="link"
                  onClick={() => {
                    setCalFile(cal_file);
                    setCurrentAppId(app_id);
                    showModal();
                  }}
                >
                  Upload
                </Button>
              )}
            </div>
            <Modal
              title="Customer Acknowledgement Letter"
              visible={visible}
              onCancel={closeModal}
              footer={null}
              width={calFile ? "1200px" : "500px"}
              style={{ height: "100vh", overflow: "hidden" }}
              // bodyStyle={{
              //   maxHeight: "80vh",
              //   overflowY: "auto",
              // }}
            >
              {calFile ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    height: "100%",
                  }}
                >
                  {calFile.match(/\.(jpeg|jpg|png)$/i) ? (
                    <img
                      src={calFile}
                      alt="CAL"
                      style={{
                        width: "100%",
                        maxHeight: "80vh",
                        objectFit: "contain",
                      }}
                    />
                  ) : calFile.match(/\.pdf$/i) ? (
                    <iframe
                      src={calFile}
                      style={{ width: "100%", height: "100vh", border: "none" }}
                      title="CAL File"
                    />
                  ) : (
                    <div>Unsupported file type</div>
                  )}
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "stretch",
                    gap: "10px",
                  }}
                >
                  {/* The file upload section */}
                  <div style={{ width: "100%" }}>
                    <Dragger
                     key={selectedFile ? selectedFile.name : ''}
                      showUploadList={true}
                      beforeUpload={(file) => {
                        const isValid =
                          /^(image\/(jpeg|png|jpg|gif|bmp|webp|tiff))|application\/pdf$/.test(
                            file.type
                          );
                        if (!isValid) {
                          notification.error({
                            message: "Error",
                            description:
                              "You can only upload images or PDF files.",
                            placement: "bottomRight",
                          });
                          return false; 
                        }

                        setSelectedFile(file); 
                        setCurrentAppId(currentAppId);
                        return false;
                      }}
                      accept="image/*,application/pdf"
                      multiple={false}
                      style={{ height: "180px", fontSize: "18px" }}
                    >
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">
                        Click or drag file to this area to upload
                      </p>
                    </Dragger>
                  </div>

                  <Button
                    type="primary"
                    onClick={() => handleFileUpload(currentAppId)}
                    loading={uploading[currentAppId]}
                    style={{ marginTop: "10px", width: "100%" }}
                    disabled={!selectedFile}
                  >
                    {uploading[currentAppId] ? "Uploading..." : "Upload"}
                  </Button>
                </div>
              )}
            </Modal>
          </>
        );
      },
    },
  ];

  // Helper functions for displaying status and verification text
  const getStatusText = (statusCode) => {
    switch (statusCode) {
      case "1":
        return "In-Transit";
      case "2":
        return "In-Query";
      case "3":
        return "Received";
      default:
        return "";
    }
  };

  const getVerificationText = (statusCode) => {
    switch (statusCode) {
      case "4":
        return "Pending at HO";
      case "5":
        return "Verified";
      case "8":
        return "Missing/Damaged Documents";
      default:
        return "";
    }
  };

  // Handling the drawer close event
  const handleDrawerClose = () => {
    if (openDialog) {
      setOpenDialog(true);
      // console.log("open dialog", openDialog);
    } else {
      onClose();
    }
  };

  // Save and close the modal
  const handleSaveAndClose = () => {
    handleFinalMarkReceived();
    setOpenDialog(false);
  };

  const handleFileChange = (info) => {
    if (info.file.status === "done") {
      notification.success({
        message: "Success",
        description: `${info.file.name} file uploaded successfully`,
        placement: "bottomRight",
      });
    } else if (info.file.status === "error") {
      notification.error({
        message: "Error",
        description: `${info.file.name} file upload failed.`,
        placement: "bottomRight",
      });
    }
  };
  const handleFileUpload = async (app_id) => {
    if (!selectedFile || !jwtBearer) {
      notification.error({
        message: "Error!",
        description: "Please select a file before uploading",
        placement: "bottomRight",
      });

      return;
    }

    const reqBody = {
      applicationId: app_id,
      fileType: "cal",
    };

    console.log("reqBody", reqBody);

    dispatch(uploadNocCalFile({ file: selectedFile, ...reqBody, jwtBearer }))
      .then(() => {
        notification.success({
          message: "Success!",
          description: `${selectedFile.name} uploaded successfully`,
          placement: "bottomRight",
        });
        // setCalFile(URL.createObjectURL(selectedFile));
        // closeDrawer();
        // dispatch(fetchPDDHoToBranch({ jwtBearer, movement: "branch" }));
        setCalFile(URL.createObjectURL(selectedFile));
        closeDrawer();
        setSelectedFile(null); // Clear the selected file here
        dispatch(fetchPDDHoToBranch({ jwtBearer, movement: "branch" }));
      })
      .catch((error) => {
        console.error("Error uploading file:", error);
        notification.Error({
          message: "Success!",
          description: `${selectedFile.name} file upload failed.`,
          placement: "bottomRight",
        });
      });
  };

  return (
    <Drawer
      title={
        <div style={{ display: "flex", alignItems: "center" }}>
          <Popover title="In-Transit" trigger="hover">
            <InfoCircleOutlined
              style={{
                marginRight: "8px",
                color: "#1890ff",
                fontSize: "28px",
              }}
            />
          </Popover>

          <Title
            level={4}
            style={{
              marginBottom: "16px",
              color: "rgba(0, 0, 0, 0.7)",
              fontSize: "20px",
              fontFamily: "Poppins",
              marginLeft: "5px",
            }}
          >
            Courier details
          </Title>
        </div>
      }
      visible={returningToBranchReceivedDrawer}
      onClose={handleDrawerClose}
      width={850}
    >
      <div style={{ padding: "0 20px" }}>
        {/* Status Section */}
        <Space
          style={{
            display: "flex",
            marginBottom: "8px",
            padding: "0 8px",
            borderRadius: "4px",
            backgroundColor: "#ffffff",
            boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
            fontSize: "20px",
            fontFamily: "Poppins",
            justifyContent: "space-between",
            height: "50px",
          }}
        >
          <strong style={{ color: "#555" }}>Status :</strong>
          <Text
            style={{
              fontSize: "20px",
              fontFamily: "Poppins",
              backgroundColor: "green",
              padding: "0px 8px",
              color: "white",
              borderRadius: "6px",
            }}
          >
            {getStatusText(data?.status)} {/* Access status correctly */}
          </Text>
        </Space>

        {/* POD Number */}
        <Space
          style={{
            display: "flex",
            marginBottom: "8px",
            padding: "8px",
            borderRadius: "4px",
            backgroundColor: "#ffffff",
            boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
            fontSize: "20px",
            fontFamily: "Poppins",
            justifyContent: "space-between",
          }}
        >
          <strong style={{ color: "#555" }}>POD Number :</strong>
          <Text
            style={{
              fontSize: "18px",
              fontFamily: "Poppins",
              fontWeight: "800",
            }}
          >
            {data?.tracking_number}
          </Text>
        </Space>
        {/* POD Number */}
        <Space
          style={{
            display: "flex",
            marginBottom: "8px",
            padding: "8px",
            borderRadius: "4px",
            backgroundColor: "#ffffff",
            boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
            fontSize: "20px",
            fontFamily: "Poppins",
            justifyContent: "space-between",
          }}
        >
          <strong style={{ color: "#555" }}>Branch:</strong>
          <Text
            style={{
              fontSize: "18px",
              fontFamily: "Poppins",
              fontWeight: "800",
            }}
          >
            {data?.branch}
          </Text>
        </Space>

        {/* Verification Status */}
        {/* <Space
          style={{
            display: "flex",
            marginBottom: "8px",
            padding: "8px",
            borderRadius: "4px",
            backgroundColor: "#ffffff",
            boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
            fontSize: "20px",
            fontFamily: "Poppins",
            justifyContent: "space-between",
          }}
        >
          <strong style={{ color: "#555" }}>Verification Status :</strong>
          <Text
            style={{
              fontSize: "18px",
              fontFamily: "Poppins",
              fontWeight: "800",
            }}
          >
            {getVerificationText(data?.verification)}
          </Text>
        </Space> */}
      </div>

      <MyStyledTable
        columns={columns}
        style={{ marginTop: "40px", marginLeft: "20px", marginRight: "20px" }}
        rowKey={(record) => record.app_id}
        dataSource={filteredData}
        // expandable={{
        //   expandedRowRender: (record) =>
        //     record.doc_details ? renderDocDetails(record.doc_details) : null,
        //   rowExpandable: (record) => !!record.doc_details,
        // }}
        pagination={{ pageSize: 5 }}
      />

      {/* Modal to confirm marking as received */}
      <Modal
        title="Mark as Received"
        visible={openDialog}
        onOk={handleSaveAndClose}
        onCancel={() => setOpenDialog(false)}
      >
        <p>Are you sure you want to mark this as received?</p>
      </Modal>
    </Drawer>
  );
};

export default ReturningToBranchInTransitDrawer;
