import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  Modal,
  Typography,
  List,
  Checkbox,
  Button,
  Popconfirm,
  Drawer,
  Space,
  notification,
  Popover,
} from "antd";
import { debounce } from "lodash";
import { fetchPDDMaster } from "../../../../../redux/slice/getPDDMaster";
import { fetchPDD } from "../../../../../redux/slice/getPDDData";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../../../../context/AuthContext";
import { MyStyledTable, ReceivedStyledTable } from "../../../style/styleComp";
import {
  CheckCircleFilled,
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { deleteNotReceivedId } from "../../../../../redux/slice/pddAddMissingDocs";
const { Title, Text } = Typography;

const BranchReceivedDrawer = ({
  visible,
  onClose,
  data,
  all_app_id,
  statusDetails,
  jwtBearer,
}) => {
  // console.log("statusDetails", statusDetails);
  // console.log("data", data);
  if (!data || !all_app_id) {
    return null;
  }

  const dispatch = useDispatch();
  const { authState } = useAuth();

  const [loading, setLoading] = useState(true);
  const [appIdStatus, setAppIdStatus] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const chatData = useSelector((state) => state.fetchPDD?.data?.message || []);
  // console.log("chatData", chatData);

  const masterData = useSelector(
    (state) => state.fetchPDDMaster?.data?.message || []
  );
  // console.log("Master data - ", masterData);

  useEffect(() => {
    setAppIdStatus(data.app_id_status);
  }, [data]);
  var jwtBearer = authState.token;

  // Fetch master data on component mount
  const fetchData = () => {
    // console.log("fetching data detail modal");
    setLoading(true);
    dispatch(fetchPDDMaster({ jwtBearer }))
      .then(() => setLoading(false))
      .catch((error) => {
        console.error("Error fetching PDD master data:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
    fetchPDDData();
  }, [dispatch]);
  // }, [dispatch,chatData]);

  const fetchPDDData = async () => {
    // console.log("fetchPDDData api triggered");
    setLoading(true);
    await dispatch(fetchPDD({ jwtBearer, movement: "Branch" }));
  };

  // Memoized checklists based on masterData
  const checklistPLUSEC = useMemo(() => {
    const usecData = masterData.find((item) => item.field === "usec_docs");
    return usecData ? usecData.value : [];
  }, [masterData]);

  const checklistPLSEC = useMemo(() => {
    const secData = masterData.find((item) => item.field === "sec_docs");
    return secData ? secData.value : [];
  }, [masterData]);

  const matchedAppIDs = useMemo(() => {
    return all_app_id.filter((app) =>
      data.application_ids.includes(app.APP_ID)
    );
  }, [all_app_id, data.application_ids]);

  // console.log("Matched App ID - ", matchedAppIDs);

  const [checkedItems, setCheckedItems] = useState({});
  const [missingDocs, setMissingDocs] = useState({});
  const [allCheckedState, setAllChecked] = useState(false);

  useEffect(() => {
    const initialCheckedItems = {};
    const initialMissingDocs = {};

    matchedAppIDs.forEach((app) => {
      const checklist =
        app.PRODUCT === "PL-USEC" ? checklistPLUSEC : checklistPLSEC;
      initialCheckedItems[app.APP_ID] = {};

      checklist.forEach((item) => {
        initialCheckedItems[app.APP_ID][item] = true;
      });

      const missingDocsForApp =
        data.missing_docs && Array.isArray(data.missing_docs)
          ? data.missing_docs.find((doc) => doc.application_id === app.APP_ID)
          : null;

      if (missingDocsForApp) {
        initialMissingDocs[app.APP_ID] = missingDocsForApp.missing_documents;
        missingDocsForApp.missing_documents.forEach((missingItem) => {
          initialCheckedItems[app.APP_ID][missingItem] = false; // Uncheck missing docs
        });
      }
    });

    setCheckedItems((prev) => ({ ...prev, ...initialCheckedItems }));
    setMissingDocs((prev) => ({ ...prev, ...initialMissingDocs }));
  }, [data.missing_docs, matchedAppIDs, checklistPLUSEC, checklistPLSEC]);

  const getChecklist = useCallback(
    (product) => {
      return product === "PL-USEC" ? checklistPLUSEC : checklistPLSEC;
    },
    [checklistPLUSEC, checklistPLSEC]
  );

  const handleCheckboxChange = useCallback(
    debounce((appId, item, checked) => {
      setCheckedItems((prevState) => {
        const updatedState = {
          ...prevState,
          [appId]: {
            ...prevState[appId],
            [item]: checked,
          },
        };

        setMissingDocs((prevMissingDocs) => {
          const updatedMissingDocs = { ...prevMissingDocs };

          if (!checked) {
            if (!updatedMissingDocs[appId]) {
              updatedMissingDocs[appId] = [];
            }

            if (!updatedMissingDocs[appId].includes(item)) {
              updatedMissingDocs[appId] = [...updatedMissingDocs[appId], item];
            }
          } else {
            if (updatedMissingDocs[appId]) {
              updatedMissingDocs[appId] = updatedMissingDocs[appId].filter(
                (doc) => doc !== item
              );
              if (updatedMissingDocs[appId].length === 0) {
                delete updatedMissingDocs[appId];
              }
            }
          }

          return updatedMissingDocs;
        });

        return updatedState;
      });
    }, 300),
    []
  );

  useEffect(() => {
    const allChecked = matchedAppIDs.every((app) => {
      const checklist = getChecklist(app.PRODUCT);
      return checklist.every(
        (item) => checkedItems[app.APP_ID]?.[item] !== false
      );
    });

    setAllChecked(allChecked);
  }, [checkedItems, matchedAppIDs, getChecklist]);

  const handleSubmitMissingDocs = () => {
    const missingDocsJSON = Object.keys(missingDocs).map((appId) => ({
      application_id: appId,
      missing_documents: missingDocs[appId],
    }));

    const callbackData = {
      tracking_number: data.tracking_number,
      branch: data.branch,
      docs: missingDocsJSON,
    };

    if (onMissingDocs) {
      onMissingDocs(callbackData);
    }
  };

  // Define the custom "Verification Status" column
  const verificationColumn = {
    title: "VERIFIED",
    key: "verification",
    dataIndex: "verification",
    render: (text, record) => {
      // const status = statusDetails.find(
      //   (item) => item.app_id === record.application_id
      // );
      // console.log("status",status)

      // const verification = status?.verification;
      const verification = record.verification;
      // console.log("verification", verification);

      if (verification === "5") {
        return (
          <CheckCircleFilled style={{ color: "green", fontSize: "28px" }} />
        );
      } else if (verification === "4") {
        return (
          <CheckCircleOutlined style={{ color: "grey", fontSize: "28px" }} />
        );
      } else if (verification === "8") {
        return (
          <ExclamationCircleOutlined
            style={{ color: "red", fontSize: "28px" }}
          />
        );
      } else {
        return (
          <ExclamationCircleOutlined
            style={{ color: "orange", fontSize: "28px" }}
          />
        );
      }
    },
  };

  const columns = [
    {
      title: "Application Id",
      dataIndex: "application_id",
      key: "application_id",
    },

    {
      title: "NAME",
      key: "applicant_name",
      render: (text, record) =>
        record.applicant_details?.applicant_name || "N/A",
    },
    {
      title: "OXYZO ID",
      key: "partner_loan_id",
      render: (text, record) =>
        record.applicant_details?.partner_loan_id || "N/A",
    },
    {
      title: "PRODUCT",
      key: "product",
      render: (text, record) => record.applicant_details?.product || "N/A",
    },
  ];

  const showModal = (record) => {
    setSelectedRecord(record);
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    if (selectedRecord) {
      try {
        const requestBody = {
          application_id: selectedRecord.application_id,
        };
        console.log("requestBody", requestBody);
        const response = await dispatch(
          deleteNotReceivedId({
            requestBody,
            jwtBearer,
          })
        );

        console.log("response", response);

        if (response?.type === "deleteNotReceivedId/fulfilled") {
          notification.success({
            message: "Success",
            description: `Application ID ${selectedRecord.application_id} deleted successfully.`,
            placement: "bottomRight",
          });
          fetchPDDData();
        } else {
          notification.error({
            message: "Error",
            description: `Failed to delete Application ID ${selectedRecord.application_id}.`,
            placement: "bottomRight",
          });
        }
      } catch (error) {
        console.error("Error deleting application ID:", error);

        notification.error({
          message: "Error",
          description: `An unexpected error occurred while deleting Application ID ${selectedRecord.application_id}.`,
          placement: "bottomRight",
        });
      }
    }
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const acknowledgement = {
    title: "Acknowledgement",
  };

  const notReceivedColumns = [
    {
      title: "Application Id",
      dataIndex: "application_id",
      key: "application_id",
    },

    {
      title: "NAME",
      key: "applicant_name",
      render: (text, record) =>
        record.applicant_details?.applicant_name || "N/A",
    },
    {
      title: "OXYZO ID",
      key: "partner_loan_id",
      render: (text, record) =>
        record.applicant_details?.partner_loan_id || "N/A",
    },
    {
      title: "PRODUCT",
      key: "product",
      render: (text, record) => record.applicant_details?.product || "N/A",
    },
  ];

  const trackingNumber = data.tracking_number;

  // Filter chatData based on the tracking number
  const dataSource = chatData.filter(
    (item) => item.tracking_number === trackingNumber
  );
  // console.log("dataSource", dataSource);

  const filteredDataSource = dataSource.filter(
    (record) => record.app_id_status === "2"
  );

  const getStatusText = (statusCode) => {
    switch (statusCode) {
      case "1":
        return "In-Transit";
      case "2":
        return "In-Query";
      case "3":
        return "Received";
      default:
        return "";
    }
  };
  const getVerificationText = (statusCode) => {
    switch (statusCode) {
      case "4":
        return "Pending at HO";
      case "5":
        return "Verified";
      case "8":
        return "Missing/Damaged Documents";
      default:
        return "";
    }
  };

  columns.unshift(verificationColumn);
  notReceivedColumns.unshift({
    title: acknowledgement.title,
    key: "acknowledgement",
    render: (_, record) => (
      <Button onClick={() => showModal(record)}>Acknowledge</Button>
    ),
  });

  const ReceivedRecords = dataSource.filter(
    (record) => record.app_id_status !== "2"
  );
  
  return (
    <>
      <Drawer
        visible={visible}
        onClose={onClose}
        footer={null}
        width={900}
        title={
          <div style={{ display: "flex", alignItems: "center" }}>
            <Popover
              content={`This courier has ${ReceivedRecords.length} number of Application ID (s).`}
              title="Received"
              trigger="hover"
            >
              <InfoCircleOutlined
                style={{
                  marginRight: "8px",
                  color: "#1890ff",
                  fontSize: "28px",
                }}
              />
            </Popover>
            <Title
              level={4}
              style={{
                marginBottom: "16px",
                color: "rgba(0, 0, 0, 0.7)",
                fontSize: "20px",
                fontFamily: "Poppins",
                marginLeft: "5px",
              }}
            >
              Courier details
            </Title>
          </div>
        }
      >
        <div style={{ padding: "0 20px" }}>
          <Space
            style={{
              display: "flex",
              marginBottom: "8px",
              padding: "0 8px",
              borderRadius: "4px",
              backgroundColor: "#ffffff",
              boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
              fontSize: "20px",
              fontFamily: "Poppins",
              display: "flex",
              justifyContent: "space-between",
              height: "50px",
            }}
          >
            <strong style={{ color: "#555" }}>Status :</strong>

            <Text
              style={{
                fontSize: "20px",
                fontFamily: "Poppins",
                display: "flex",
                justifyContent: "space-between",
                backgroundColor: "green",
                padding: "0px 8px",
                color: "white",
                borderRadius: "6px",
              }}
            >
              {getStatusText(data.status)}
            </Text>
          </Space>

          <Space
            style={{
              display: "flex",
              marginBottom: "8px",
              padding: "8px",
              borderRadius: "4px",
              backgroundColor: "#ffffff",
              boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
              fontSize: "20px",
              fontFamily: "Poppins",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <strong style={{ color: "#555" }}>POD Number :</strong>
            <Text
              style={{
                fontSize: "18px",
                fontFamily: "Poppins",
                display: "flex",
                justifyContent: "space-between",
                fontWeight: "800",
              }}
            >
              {data.tracking_number}
            </Text>
          </Space>

          <Space
            style={{
              display: "flex",
              marginBottom: "8px",
              padding: "8px",
              borderRadius: "4px",
              backgroundColor: "#ffffff",
              boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
              fontSize: "20px",
              fontFamily: "Poppins",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <strong style={{ color: "#555" }}>Verification:</strong>
            <Text
              style={{
                fontSize: "18px",
                fontFamily: "Poppins",
                display: "flex",
                justifyContent: "space-between",
                // backgroundColor: { data.verification === "5" ? "green" ? data.verification === "4" ? "orange" ? data.verification === "8" ? "red" : "red" },
                backgroundColor:
                  data?.verification === "5"
                    ? "green"
                    : data?.verification === "4"
                    ? "orange"
                    : data?.verification === "8"
                    ? "red"
                    : "",

                padding: "3px 8px",
                color: "white",
                borderRadius: "6px",
              }}
            >
              {getVerificationText(data?.verification)}
            </Text>
          </Space>

          <Space
            style={{
              display: "flex",
              marginBottom: "8px",
              padding: "8px",
              borderRadius: "4px",
              backgroundColor: "#ffffff",
              boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
              fontSize: "20px",
              fontFamily: "Poppins",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <strong style={{ color: "#555" }}>Branch :</strong>
            <Text
              style={{
                fontSize: "18px",
                fontFamily: "Poppins",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {data.branch}
            </Text>
          </Space>

          <Space
            style={{
              display: "flex",
              marginBottom: "8px",
              padding: "8px",
              borderRadius: "4px",
              backgroundColor: "#ffffff",
              boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
              fontSize: "20px",
              fontFamily: "Poppins",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <strong style={{ color: "#555" }}>Dispatched by :</strong>
            <Text
              style={{
                fontSize: "18px",
                fontFamily: "Poppins",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {data?.courier_dispatcher_name}
            </Text>
          </Space>
        </div>

        <MyStyledTable
          columns={columns}
          dataSource={dataSource.filter(
            (record) => record.app_id_status !== "2"
          )}
          pagination={{
            pageSize: 6,
          }}
          style={{ marginTop: "30px" }}
        />

        {filteredDataSource.length > 0 && (
          <>
            <h2 style={{ margin: "30px 0" }}>
              Application Id(s) not received by Head Office
            </h2>
            <MyStyledTable
              columns={notReceivedColumns}
              dataSource={filteredDataSource}
              pagination={{
                pageSize: 6,
              }}
              rowKey="application_id"
            />
          </>
        )}
      </Drawer>
      <Modal
        title="Acknowledge Application"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>
          Are you sure you want to acknowledge that application ID{" "}
          {selectedRecord?.application_id} was not sent to head office?
        </p>
      </Modal>
    </>
  );
};
export default BranchReceivedDrawer;
