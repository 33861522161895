import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { BASE_URL } from '../apiConfig';

export const updateInTransit = createAsyncThunk(
    'updateInTransit',
    async ({ jwtBearer, requestBody }) => {
        const options = {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + jwtBearer,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
        };

        try {
            const response = await fetch(
                `${BASE_URL}pdd/update/appid/entry/intransit/`,
                options
            );

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to update branch entry');
            }

            return await response.json();
        } catch (error) {
            throw error;
        }
    }
);

export const deleteAppId = createAsyncThunk(
    'deleteAppId',
    async ({ jwtBearer, requestBody }) => {
        const { application_id, tracking_number } = requestBody;
        if (!application_id || !tracking_number) {
            throw new Error('application_id or tracking_number is missing');
        }

        const options = {
            method: 'DELETE',
            headers: {
                Authorization: 'Bearer ' + jwtBearer,
                'Content-Type': 'application/json',
            },
        };

        try {
            const response = await fetch(
                `${BASE_URL}pdd/delete/application/id/?application_id=${application_id}&tracking_number=${tracking_number}`,
                options
            );

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to delete application');
            }

            return await response.json();
        } catch (error) {
            throw error;
        }
    }
);


export const updateReturningInTransit = createAsyncThunk(
    'updateReturningInTransit',
    async ({ jwtBearer, requestBody }) => {
        const options = {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + jwtBearer,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
        };

        try {
            const response = await fetch(
                `${BASE_URL}pdd/update/appid/return/intransit/`,
                options
            );

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to update branch entry');
            }

            return await response.json();
        } catch (error) {
            throw error;
        }
    }
);


export const deleteReturnAppId = createAsyncThunk(
    'deleteReturnAppId',
    async ({ jwtBearer, requestBody }) => {
        const application_id = requestBody.application_id;
        if (!application_id) {
            throw new Error('application_id is missing');
        }

        const options = {
            method: 'DELETE',
            headers: {
                Authorization: 'Bearer ' + jwtBearer,
                'Content-Type': 'application/json',
            },
        };

        try {
            const response = await fetch(
                `${BASE_URL}pdd/delete/return/application/id/?application_id=${application_id}`,
                options
            );

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to delete application');
            }

            return await response.json();
        } catch (error) {
            throw error;
        }
    }
);



const updateInTransitSlice = createSlice({
    name: 'updateApplicationIntransit',
    initialState: {
        isLoading: false,
        data: null,
        isError: false,
        errorMessage: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(updateInTransit.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.errorMessage = null;
            })
            .addCase(updateInTransit.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload;
                state.isError = false;
                state.errorMessage = null;
            })
            .addCase(updateInTransit.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.errorMessage = action.error.message || 'An error occurred';
            })
            .addCase(deleteAppId.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.errorMessage = null;
            })
            .addCase(deleteAppId.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload;
                state.isError = false;
                state.errorMessage = null;
            })
            .addCase(deleteAppId.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.errorMessage = action.error.message || 'An error occurred';
            })
            .addCase(deleteReturnAppId.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.errorMessage = null;
            })
            .addCase(deleteReturnAppId.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload;
                state.isError = false;
                state.errorMessage = null;
            })
            .addCase(deleteReturnAppId.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.errorMessage = action.error.message || 'An error occurred';
            });
    },
});

export default updateInTransitSlice.reducer;
