import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Typography, Table, Tabs, Button, Input, Tag, Card, Form, Divider, Progress, Row, Col, Radio, Modal, Select, Steps, Spin, FloatButton, Slider, InputNumber, Collapse, Drawer, Alert } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, EditOutlined, CommentOutlined, LeftOutlined, RightOutlined, SaveOutlined, EyeOutlined, EyeInvisibleOutlined, NotificationOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import Highlighter from 'react-highlight-words';
import { SearchOutlined, ArrowRightOutlined } from '@ant-design/icons';
import moment from 'moment';
import jwt_decode from 'jwt-decode';
import { StyledModal, StyledCard, StyledRow, StyledCol, StyledButton, StyledInputNumber, StyledSlider } from "../style/styleComp";
import { useAuth } from "../../../context/AuthContext";
import { fetchChatData } from "../../../redux/slice/getChatData";
import { fetchBranch } from "../../../redux/slice/getBranchData";
import { fetchBureau } from '../../../redux/slice/fetchBureau';
import { fetchBankVerification } from '../../../redux/slice/fetchBankVerification';
import { fetchFieldMaster } from '../../../redux/slice/getFieldMasterData';
import { StaticDateTimePicker } from '@mui/x-date-pickers/StaticDateTimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { processLead } from "../../../redux/slice/processLead";
import { updateRescheduleDate } from "../../../redux/slice/rescheduleLead";
import { updateAvailabilityStatus } from '../../../redux/slice/availabilityStatus';
import { updateLeadData } from '../../../redux/slice/updateLeadData';
import { updateChatData } from "../../../redux/slice/updateChatData";
import profile_placeholder from "../../../../assets/profile_placeholder.png"
import { useSpring, animated } from 'react-spring';
import Notification from '../components/notification';
import { HashLoader } from 'react-spinners'
import '../style/customStyles.css'
import ChatWindow from '../components/chatWindow'
import LoanAmountSelector from '../components/amountSelector';
import "../style/userTab.css";

const { Text } = Typography;

const { TabPane } = Tabs;

const { Panel } = Collapse;

// const UserTab = ({ selectedUserData, selectedUserChatData, fieldMaster, branchData, nearestBranchData, onSendData, onSendDataReschedule, onSendDataAvailability }) => {
const UserTab = ({ userDetails, selectedUserData, selectedUserChatData, fieldMaster, branchData, chatImages, onSendData, onSendDataReschedule, onSendDataAvailability }) => {
    console.log('In usertab - ', userDetails)
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [activeTabKey, setActiveTabKey] = useState('allTables');
    const [internalActiveTabKey, setInternalActiveTabKey] = useState('1');
    const searchInput = React.useRef(null);
    const [imageUrl, setImageUrl] = useState(null);
    const [imageUrls, setImageUrls] = useState([]);
    const [selectedChatData, setSelectedChatData] = useState(null);
    // const [selectedUserData, setSelectedUserData] = useState([{ Verified_stages: {} }]);
    const [aadhaarNumber, setAadhaarNumber] = useState('');
    const [emailID, setEmailID] = useState(selectedUserData.chat_user_profile.email_id)
    const [panNumber, setPanNumber] = useState(selectedUserData.chat_user_profile.kyc.pan);
    // const [branchData, setBranchData] = useState({});
    const [custMobile, setCustMobile] = useState(selectedUserData.chat_user_profile.reg_phone);
    const [secondaryContact, setSecondaryContact] = useState(selectedUserData.chat_user_profile.sec_phone)
    const [custName, setCustName] = useState('');
    const [custIndustry, setCustIndustry] = useState(selectedUserData.business_det.bus_sub_category);
    const [selectedRefNum, setSelectedRefNum] = useState("");
    const [purposeOfLoan, setPurposeOfLoan] = useState(selectedUserData.req_loan_det.loan_purpose);
    // const [purposeOfLoan, setPurposeOfLoan] = useState(selectedUserData.req_loan_det.purpose);
    const [businessExperienceYears, setBusinessExperienceYears] = useState(selectedUserData.business_det.total_bus_exp);
    const [businessPresentExperienceYears, setBusinessPresentExperienceYears] = useState(selectedUserData.business_det.pre_bus_exp);
    const [custLoanAmt, setCustLoanAmt] = useState(selectedUserData.req_loan_det.amount);
    const [custFathersName, setCustFathersName] = useState(selectedUserData.chat_user_profile.father_name);
    const [custAffordableEMI, setCustAffordableEMI] = useState(selectedUserData.req_loan_det.emi);
    const [custEmploymentCat, setCustEmploymentCat] = useState(selectedUserData.chat_user_profile?.emp_cat_type);
    const [custSalaryMode, setCustSalaryMode] = useState(selectedUserData.chat_user_profile.salary_det?.salary_rec_type);
    const [custAnnualIncome, setCustAnnualIncome] = useState(selectedUserData.chat_user_profile.salary_det?.annual_inc_ran);
    const [custOtherIncSource, setCustOtherIncSource] = useState(selectedUserData.chat_user_profile.salary_det?.oth_src_inc_type);
    const [custOtherIncSourceIncome, setCustOtherIncSourceIncome] = useState(selectedUserData.chat_user_profile.salary_det?.oth_src_annual_ran);
    const [custMothersName, setCustMothersName] = useState(selectedUserData.chat_user_profile.mother_name);
    const [custGender, setCustGender] = useState(selectedUserData.chat_user_profile.gender);
    const [custMaritalStatus, setCustMaritalStatus] = useState(selectedUserData.chat_user_profile.marital_status);
    const [custSpouseName, setCustSpouseName] = useState(selectedUserData.chat_user_profile.spouse_name);
    const [custChildren, setCustChildren] = useState(selectedUserData.chat_user_profile.no_of_child);
    const [custGST, setCustGST] = useState("");
    const [custResidenceAddress, setCustResidenceAddress] = useState(selectedUserData.chat_user_profile.address.address_1);
    const [custWorkAddress, setCustWorkAddress] = useState(selectedUserData.business_det.address.address_1);
    const [custResidenceOwnership, setCustResidenceOwnership] = useState(selectedUserData.business_det.property_owner);
    // const [selectedUserData, setSelectedUserData] = useState([]);
    const [customerAvailability, setCustomerAvailability] = useState("")
    const [rescheduleDate, setRescheduleDate] = useState("");
    const [loanAmountValue, setLoanAmountValue] = useState("")
    const [isLoanAmountValid, setIsLoanAmountValid] = useState(false);
    const [isAffordableEMIValid, setIsAffordableEMIValid] = useState(false);
    const [isTenureValid, setIsTenureValid] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [affordableEMIErrorMessage, setAffordableEMIErrorMessage] = useState('');
    const [tenureErrorMessage, setTenureErrorMessage] = useState('');
    const [customerFeedbackValue, setCustomerFeedbackValue] = useState('');
    const [customerBureauFeedbackValue, setCustomerBureauFeedbackValue] = useState('');
    const [selectedDateTime, setSelectedDateTime] = useState(dayjs());
    const [showChatModal, setShowChatModal] = useState(false);
    const [editableMessageIndex, setEditableMessageIndex] = useState(-1);
    const [editableInputValue, setEditableInputValue] = useState("");
    const [showChat, setShowChat] = useState(false);
    const [imgIsModalVisible, setImgIsModalVisible] = useState(false);
    const [modalImageUrl, setModalImageUrl] = useState("");
    const [leadDecisionValue, setLeadDecisionValue] = useState("")
    const [branchToAssign, setBranchToAssign] = useState('')
    const [showBranchSelect, setShowBranchSelect] = useState(false)
    const [bMToAssign, setBMToAssign] = useState("")
    const [originalMessage, setOriginalMessage] = useState("");
    const [originalTimeStamp, setOriginalTimeStamp] = useState("")
    const [originalQuesID, setOriginalQuesID] = useState("")
    const [stringDateTime, setStringDateTime] = useState("")
    const [tableData, setTableData] = useState([]);
    const [affordableEMIValue, setAffordableEMIValue] = useState("")
    const [tenureValue, setTenureValue] = useState("")
    const [affordableEMIMin, setAffordableEMIMin] = useState(null)
    const [minLoanAmt, setMinLoanAmt] = useState(null)
    const [maxLoanAmt, setMaxLoanAmt] = useState(null)
    const [affordableEMIMax, setAffordableEMIMax] = useState(null)
    const [loanAmountMin, setLoanAmountMin] = useState(null)
    const [loanAmountMax, setLoanAmountMax] = useState(null)
    // const [fieldMaster, setFieldMaster] = useState([])
    const [reasonToReject, setReasonToReject] = useState('')
    const [recommendedProduct, setRecommendedProduct] = useState('')
    const [editAllowed, setEditAllowed] = useState(false)
    const [branchSelection, setBranchSelection] = useState('')
    // const [nearestBranchesArray, setNearestBranchesArray] = useState([])
    // const [nearestBranchesArray, setNearestBranchesArray] = useState(selectedUserData.nearest_branches ? selectedUserData.nearest_branches : [])

    const [showRescheduleCard, setShowRescheduleCard] = useState(false);
    const [showAvailableCard, setShowAvailableCard] = useState(false);
    const [showChatCard, setShowChatCard] = useState(false);
    const [showDocumentCard, setShowDocumentCard] = useState(false);

    const [showSecondTab, setShowSecondTab] = useState(false);
    const [allTablesKey, setAllTablesKey] = useState('allTables');

    const [showNotification, setShowNotification] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [loadingNotificationMessage, setLoadingNotificationMessage] = useState('')
    const [loadingNotification, setLoadingNotification] = useState(false)

    const [showCustChat, setShowCustChat] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [EditCustLoanAmt, setEditCustLoanAmt] = useState("");
    const [showUnsavedModal, setShowUnsavedModal] = useState(false)
    const [crifResponse, setCrifResponse] = useState('')
    const [custCRIFResponse, setCustCRIFResponse] = useState(selectedUserData.responses.crif)
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [drawerContent, setDrawerContent] = useState('');
    const [salarySource, setSalarySource] = useState(selectedUserData.chat_user_profile.salary_det?.salary_rec_type ? selectedUserData.chat_user_profile.salary_det?.salary_rec_type : '')
    const [emailModalVisible, setEmailModalVisible] = useState(false)
    const [aadhaarModalVisible, setAadhaarModalVisible] = useState(false)
    const [bankingModalVisible, setbankingModalVisible] = useState(false)
    const [custAccountNumber, setCustAccountNumber] = useState('')
    const [custEmailID, setCustEmailID] = useState('')
    const [custIFSC, setCustIFSCNumber] = useState('')
    const [accountErrorMessage, setAccountErrorMessage] = useState('')
    const [custAccountNumberValid, setCustAccountNumberValid] = useState(false)
    const [bankVerificationSuccess, setBankVerificationSuccess] = useState(false)
    const [bankVerificationError, setBankVerificationError] = useState(false)
    const [bankVerificationAPIFailure, setBankVerificationAPIFailure] = useState(false)
    const [bankVerificationErrorMsg, setBankVerificationErrorMsg] = useState('')
    const [trueKeys, setTrueKeys] = useState([]);
    const [falseKeys, setFalseKeys] = useState([]);
    const [tabRerenderKey, setTabRerenderKey] = useState(0); // New state to force re-render
    const [bureauLoading, setBureauLoading] = useState(false);
    const [custDOB, setCustDOB] = useState(null);
    function parseNearestBranches(branchesString) {
        // Remove the surrounding square brackets and split the string into individual branches
        const branchesArray = branchesString.slice(1, -1).split('),');

        return branchesArray.map(branch => {
            // Remove the "NearestBranches(" and trailing ")" and split by ","
            const branchDetails = branch.replace('NearestBranches(', '').replace(')', '').split(', ');

            // Create a branch object from the details
            const branchObj = {};
            branchDetails.forEach(detail => {
                const [key, value] = detail.split('=');
                branchObj[key.trim()] = value ? value.trim() : '';
            });

            return branchObj;
        });
    }

    const nearestBranchesArray = selectedUserData?.nearest_branches
        ? parseNearestBranches(selectedUserData.nearest_branches)
        : [];

    const selectedBranch = selectedUserData?.selected_branch ? selectedUserData?.selected_branch : ''

    // Convert reschedule_datetime string to Date object
    const parseRescheduleDatetime = (datetime) => moment(datetime, 'YYYY-MM-DD hh:mm A');

    // Update image URLs whenever selectedUserData changes
    useEffect(() => {
        // setCustCRIFResponse(selectedUserData.CRIF_response)
        // const urls = getImageUrl(selectedUserData.images);
        const urls = getImageUrl(chatImages[0].images);
        console.log('Images - ', urls)
        // Filter the imageUrls to find the image with the title "Passbook/Cheque"

        setImageUrls(urls);

        let loanAmtMin = 50000; // Default value
        let loanAmtMax = 1000000; // Default value

        if (custLoanAmt != 'ABOVE 5,00,000' && custLoanAmt != '' && custLoanAmt != '5,00,000 से ऊपर') {
            // Step 1: Remove the currency symbol
            // const loanRange = custAffordableEMI.replace(/₹/g, '');

            // Step 2: Split the string by the hyphen
            const loanParts = custLoanAmt.split(' - ');
            console.log('EMI parts - ', loanParts);

            // Step 3: Remove commas from the resulting strings
            const loanAmtMinStr = loanParts[0].replace(/,/g, '');
            const loanAmtMaxStr = loanParts[1].replace(/,/g, '');

            // Step 4: Convert the cleaned strings to integers
            loanAmtMin = parseInt(loanAmtMinStr, 10);
            loanAmtMax = parseInt(loanAmtMaxStr, 10);

            setMinLoanAmt(loanAmtMin)
            setMaxLoanAmt(loanAmtMax)
        }
        else if (custLoanAmt === 'ABOVE 5,00,000') {
            console.log('In above')
            setMinLoanAmt(500000)
            setMaxLoanAmt(1000000)
        }
        else {
            setMinLoanAmt(loanAmtMin)
            setMaxLoanAmt(loanAmtMax)
        }

        let emiMin = 1000; // Default value
        let emiMax = 100000; // Default value
        console.log('Hindi Text - ', custAffordableEMI)
        // if (custAffordableEMI != 'More than ₹20,000' || '₹20,000 से अधिक') {
        if (custAffordableEMI != 'MORE THAN ₹20,000' && custAffordableEMI != '' && custAffordableEMI != '₹20,000 से अधिक') {
            // const targetString = 'MORE THAN ₹20,000'.toLowerCase();
            // if (custAffordableEMI.toLowerCase() != targetString && custAffordableEMI != '') {
            // Step 1: Remove the currency symbol
            const emiRange = custAffordableEMI.replace(/₹/g, '');

            // Step 2: Split the string by the hyphen
            const emiParts = emiRange.split(' - ');
            console.log('EMI parts - ', emiParts);

            // Step 3: Remove commas from the resulting strings
            const emiMinStr = emiParts[0].replace(/,/g, '');
            const emiMaxStr = emiParts[1].replace(/,/g, '');

            // Step 4: Convert the cleaned strings to integers
            emiMin = parseInt(emiMinStr, 10);
            emiMax = parseInt(emiMaxStr, 10);
            setAffordableEMIMin(emiMin)
            setAffordableEMIMax(emiMax)
            console.log('emi parts converted - ', emiMinStr, emiMaxStr)
        }
        else if (custAffordableEMI === 'MORE THAN ₹20,000') {
            console.log('in above emi')
            setAffordableEMIMin(20000)
            setAffordableEMIMax(100000)
        }
        else {
            setAffordableEMIMin(emiMin)
            setAffordableEMIMax(emiMax)
        }
    }, [selectedUserData]);

    useEffect(() => {
        console.log('Appointment triggered')
        const interval = setInterval(() => {
            const now = moment();
            tableData.forEach((item) => {
                const rescheduleTime = parseRescheduleDatetime(item.reschedule_datetime);
                const timeDiff = rescheduleTime.diff(now, 'minutes');
                console.log('Appointment diff - ', timeDiff)

                if (timeDiff >= 0 && timeDiff <= 15) {
                    console.log('Appointment 15 - ', timeDiff)
                    setNotificationMessage(`Appointment for ${item.cust_name} is in less than ${timeDiff} minutes!`);
                    setShowNotification(true);
                }
                else if (timeDiff < 0) {
                    console.log('Appointment - ', timeDiff)
                    setNotificationMessage(`Appointment for ${item.cust_name} is in due over ${timeDiff} minutes!`);
                    setShowNotification(true);
                }
            });
        }, 1000 * 60 * 5); // Check every minute

        // Clean up interval on component unmount
        return () => clearInterval(interval);
    }, [tableData]);

    useEffect(() => {
        if (bankVerificationSuccess) {
            setShowSecondTab(true); // Show second tab when bank verification is successful
        }
    }, [bankVerificationSuccess]);

    const floatButtonProps = useSpring({
        to: { transform: 'translateY(0px)', opacity: 1 },
        from: { transform: 'translateY(100px)', opacity: 0 },
        config: { tension: 200, friction: 10 },
    });

    useEffect(() => {
        updateSteps();
    }, [selectedUserData]);

    const updateSteps = () => {
        // const verifiedStages = selectedUserData[0]?.Verified_stages || {};
        const verifiedStages = selectedUserData.chat_user_profile.verification || {};
        // const verifiedStages = selectedUserData.verification || {};

        let trueKeys = Object.keys(verifiedStages).filter(key => verifiedStages[key]);
        trueKeys = trueKeys.map(key => key.replace('_', ' '))
            .map(key => key.replace('Pan verified', 'PAN'))
            .map(key => key.replace('aadhaar verified', 'Aadhaar'))
            .map(key => key.replace('gst verified', 'GST'))
            .map(key => key.replace('bank verified', 'Bank'))
            .map(key => key.replace('email verified', 'Email'));

        let falseKeys = Object.keys(verifiedStages).filter(key => !verifiedStages[key]);
        falseKeys = falseKeys.map(key => key.replace('_', ' '))
            .map(key => key.replace('Pan verified', 'PAN'))
            .map(key => key.replace('aadhaar verified', 'Aadhaar'))
            .map(key => key.replace('gst verified', 'GST'))
            .map(key => key.replace('bank verified', 'Bank'))
            .map(key => key.replace('email verified', 'Email'));

        setTrueKeys(trueKeys);
        setFalseKeys(falseKeys);
    };

    const [editMode, setEditMode] = useState(null); // Track which field is being edited
    const [tempValue, setTempValue] = useState("");
    const [unsavedChanges, setUnsavedChanges] = useState(false);

    const onNotifClose = (e) => {
        setShowNotification(false)
    }

    const handleStepClick = (e) => {
        console.log('Step clicked - ', e)
        if (e == 'Email') {
            setEmailModalVisible(true)
        }
        else if (e == 'Aadhaar') {
            setAadhaarModalVisible(true)
        }
        else if (e == 'Bank') {
            setbankingModalVisible(true)
        }
    }

    const handleUnsavedModalCancel = () => {
        setUnsavedChanges(false)
    }


    // Listen for page refresh or navigation
    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (unsavedChanges) {
                event.preventDefault();
                event.returnValue = '';
                setShowUnsavedModal(true); // Show modal when attempting to leave the page
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [unsavedChanges]);

    const handleEditClick = (field) => {
        if (field === 'loanAmount') {
            setTempValue(custLoanAmt);
        } else if (field === 'affordableEMI') {
            setTempValue(custAffordableEMI);
        } else if (field === 'purposeOfLoan') {
            setTempValue(purposeOfLoan);
        } else if (field === 'industry') {
            setTempValue(custIndustry);
        } else if (field === 'totalExperience') {
            setTempValue(businessExperienceYears)
        } else if (field === 'presentExperience') {
            setTempValue(businessPresentExperienceYears)
        } else if (field === 'fathersName') {
            setTempValue(custFathersName)
        } else if (field === 'mothersName') {
            setTempValue(custMothersName)
        } else if (field === 'gender') {
            setTempValue(custGender)
        } else if (field === 'maritalStatus') {
            setTempValue(custMaritalStatus)
        } else if (field === 'spouseName') {
            setTempValue(custSpouseName)
        } else if (field === 'custChildren') {
            setTempValue(custChildren)
        } else if (field === 'gender') {
            setTempValue(custGender)
        } else if (field == 'secondaryContact') {
            setTempValue(secondaryContact)
        } else if (field == 'emailID') {
            setTempValue(emailID)
        } else if (field == 'custResidenceAddress') {
            setTempValue(custResidenceAddress)
        } else if (field == 'custWorkAddress') {
            setTempValue(custWorkAddress)
        } else if (field == 'businessPremiseOwnership') {
            setTempValue(custResidenceOwnership)
        } else if (field == 'employmentCat') {
            setTempValue(custEmploymentCat)
        } else if (field == 'salaryMode') {
            setTempValue(custSalaryMode)
        } else if (field == 'annualIncome') {
            setTempValue(custAnnualIncome)
        } else if (field == 'otherIncomeSource') {
            setTempValue(custOtherIncSource)
        } else if (field == 'otherIncomeSourceIncome') {
            setTempValue(custOtherIncSourceIncome)
        }
        setEditMode(field);
        setUnsavedChanges(true); // Indicate unsaved changes
    };

    const handleSaveClick = (field) => {
        const requestBody = {
            uuid: selectedUserData.uuid,
            field: field,
            value: tempValue,
            emp_id: user_id
        }

        dispatch(updateLeadData({ jwtBearer, requestBody }))
            .then(() => {
                // Dispatch fetchChatData after updateRescheduleDate is successful
                dispatch(fetchChatData({ jwtBearer, user_id: user_id, user_role: user_role, chat_type: 'Open' }));
            })
            .catch((error) => {
                console.error("Error updating reschedule date:", error);
            });

        if (field === 'loanAmount') {
            setCustLoanAmt(tempValue);

            // const emiRange = tempValue.replace(/₹/g, '');

            if (tempValue != 'ABOVE 5,00,000') {
                // Step 2: Split the string by the hyphen
                const loanParts = tempValue.split(' - ');
                console.log('EMI parts - ', loanParts);

                // Step 3: Remove commas from the resulting strings
                const loanAmtMinStr = loanParts[0].replace(/,/g, '');
                const loanAmtMaxStr = loanParts[1].replace(/,/g, '');

                let loanAmtMin = 50000; // Default value
                let loanAmtMax = 1000000; // Default value
                // Step 4: Convert the cleaned strings to integers
                loanAmtMin = parseInt(loanAmtMinStr, 10);
                loanAmtMax = parseInt(loanAmtMaxStr, 10);

                console.log('emi min max - ', loanAmtMin, loanAmtMax);
                setMinLoanAmt(loanAmtMin)
                setMaxLoanAmt(loanAmtMax)
            }
            else if (tempValue === 'ABOVE 5,00,000') {
                setMinLoanAmt(500000)
                setMaxLoanAmt(1000000)
            }
            else {
                setMinLoanAmt(50000)
                setMaxLoanAmt(1000000)
            }
        } else if (field === 'affordableEMI') {
            setCustAffordableEMI(tempValue);

            const emiRange = tempValue.replace(/₹/g, '');

            console.log('in above emi value - ', emiRange)

            if (emiRange != 'MORE THAN 20,000') {
                console.log('in above emi if')
                // Step 2: Split the string by the hyphen
                const emiParts = emiRange.split(' - ');
                console.log('EMI parts - ', emiParts);

                // Step 3: Remove commas from the resulting strings
                const emiMinStr = emiParts[0].replace(/,/g, '');
                const emiMaxStr = emiParts[1].replace(/,/g, '');

                let emiMin = 1000; // Default value
                let emiMax = 100000; // Default value
                // Step 4: Convert the cleaned strings to integers
                emiMin = parseInt(emiMinStr, 10);
                emiMax = parseInt(emiMaxStr, 10);

                console.log('emi min max - ', emiMin, emiMax);
                setAffordableEMIMin(emiMin)
                setAffordableEMIMax(emiMax)
            }
            else if (emiRange === 'MORE THAN 20,000') {
                console.log('in above emi')
                setAffordableEMIMin(20000)
                setAffordableEMIMax(100000)
            }
            else {
                console.log('in above emi else')
                let emiMin = 1000; // Default value
                let emiMax = 100000; // Default value
                setAffordableEMIMin(emiMin)
                setAffordableEMIMax(emiMax)
            }

        } else if (field === 'purposeOfLoan') {
            setPurposeOfLoan(tempValue);
        } else if (field === 'industry') {
            setCustIndustry(tempValue);
        } else if (field === 'totalExperience') {
            setBusinessExperienceYears(tempValue);
        } else if (field === 'presentExperience') {
            setBusinessPresentExperienceYears(tempValue);
        } else if (field === 'fathersName') {
            setCustFathersName(tempValue);
        } else if (field === 'mothersName') {
            setCustMothersName(tempValue);
        } else if (field === 'gender') {
            setCustGender(tempValue);
        } else if (field === 'maritalStatus') {
            setCustMaritalStatus(tempValue);
        } else if (field === 'spouseName') {
            setCustSpouseName(tempValue);
        } else if (field === 'custChildren') {
            setCustChildren(tempValue);
        } else if (field === 'secondaryContact') {
            setSecondaryContact(tempValue)
        } else if (field === 'emailID') {
            setEmailID(tempValue)
        } else if (field === 'custResidenceAddress') {
            setCustResidenceAddress(tempValue)
        } else if (field === 'custWorkAddress') {
            setCustWorkAddress(tempValue)
        } else if (field === 'businessPremiseOwnership') {
            setCustResidenceOwnership(tempValue)
        } else if (field === 'employmentCat') {
            setCustEmploymentCat(tempValue)
        } else if (field === 'salaryMode') {
            setCustSalaryMode(tempValue)
        } else if (field === 'annualIncome') {
            setCustAnnualIncome(tempValue)
        } else if (field === 'otherIncomeSource') {
            setCustOtherIncSource(tempValue)
        } else if (field === 'otherIncomeSourceIncome') {
            setCustOtherIncSourceIncome(tempValue)
        }
        setEditMode(null);
        setUnsavedChanges(false); // Reset unsaved changes state
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleCloseChat = () => {
        setChatWindowVisible(false)
    }

    const rescheduleCardProps = useSpring({
        opacity: showRescheduleCard ? 1 : 0,
        transform: showRescheduleCard ? 'translateY(0)' : 'translateY(100%)',
    });

    const availableCardProps = useSpring({
        opacity: showAvailableCard ? 1 : 0,
        transform: showAvailableCard ? 'translateY(0)' : 'translateY(100%)',
    });

    const chatCardProps = useSpring({
        opacity: showChatCard ? 1 : 0,
        transform: showChatCard ? 'translateX(0)' : 'translateX(100%)',
    });

    const documentCardProps = useSpring({
        opacity: showDocumentCard ? 1 : 0,
        // transform: showDocumentCard ? 'translateX(0)' : 'translateX(-100%)',
        transform: showDocumentCard ? 'translateY(0)' : 'translateY(-2%)',
    });


    useEffect(() => {
        setShowRescheduleCard(customerAvailability === 'Reschedule');
    }, [customerAvailability]);

    useEffect(() => {
        setShowAvailableCard(customerAvailability === 'Available' || customerAvailability === 'BranchLead' || customerAvailability === 'Drop' || customerAvailability === 'NotInterested');
    }, [customerAvailability]);

    useEffect(() => {
        setShowChatCard(isChatWindowVisible === true);
    }, [isChatWindowVisible]);

    useEffect(() => {
        setShowDocumentCard(showChat === false);
    }, [showChat]);

    const toggleChat = () => {
        setShowChat(!showChat);
    };

    const handleToggle = (event) => {
        setShowChat(event.target.value === 'business');
    };

    const { authState, onLogout } = useAuth();

    var token_decoded = authState.token;
    var jwtBearer = authState.token;
    var jwt_payload = "";
    var jwt_payload_group = [];
    var user_id = "";
    var user_role = ""
    var user_name = ""
    if (jwtBearer) {
        jwt_payload = jwt_decode(jwtBearer);
        user_name = userDetails.username;
        user_id = jwt_payload.user_id;
        user_role = userDetails.role;
        jwt_payload_group = userDetails.group_id;
    }

    // useEffect(() => {
    //     dispatch(fetchChatData({ user_id: user_id, user_role: user_role }))
    //         .then(() => {
    //             console.log('Chat data fetched successfully.');
    //             setLoading(false);
    //         })
    //         .catch((error) => {
    //             console.error("Error fetching chat data:", error);
    //             setLoading(false);
    //         });
    // }, [dispatch]);

    useEffect(() => {
        const fetchData = () => {
            console.log('Fetching chat data...');
            dispatch(fetchChatData({ jwtBearer, user_id, user_role, chat_type: 'Open' }))
                .then(() => {
                    console.log('Chat data fetched successfully.');
                    setLoading(false);
                })
                .catch((error) => {
                    console.error("Error fetching chat data:", error);
                    setLoading(false);
                });
        };

        // Initial fetch and set up interval for periodic fetching
        fetchData();
        // const intervalId = setInterval(fetchData, 10000); // 10000 ms = 10 seconds
        const intervalId = setInterval(fetchData, 1000 * 60 * 5); // 10000 ms = 10 seconds

        // Cleanup interval on component unmount or dependency change
        return () => {
            clearInterval(intervalId);
            console.log('Interval cleared.');
        };
    }, [dispatch]); // Dependencies


    const { data: chatData } = useSelector((state) => state.fetchChatData);

    console.log('Chat data - ', chatData)

    let ImageData = []
    if (chatData && chatData.length > 0) {
        ImageData = chatData[1]
        console.log('Image data - ', ImageData)
        console.log('user chat state - ', chatData[0]);
    } else {
        console.log("chatData is null or empty.");
    }

    let usernames = []
    let phone_number = []
    let cust_name = []
    let crif_response = []
    let Reference_number = []
    let requestedLoanAmounts = []
    let loanPurpose = []
    let businessExperience = []
    let businessPresentExperience = []
    let fathersName = []
    let affordableEMI = []
    let mothersName = []
    let gender = []
    let dob = []
    let maritalStatus = []
    let spouseName = []
    let childrenCust = []
    let residenceAddress = []
    let workAddress = []
    let residenceOwnership = []
    let reschedule_datetime = []
    let availability = []
    let last_action = []
    let aadhaar_number = []
    let pan_number = []
    let gstNumber = []
    let nearest_branches = []
    let industry = []

    let newData = []

    // Function to format date
    function formatDate(isoString) {
        console.log('Time to convert - ', isoString);
        if (!isoString) {
            return ''; // Return empty string if dateString is null or undefined
        }
        const date = new Date(isoString);

        // Check for invalid date
        if (isNaN(date.getTime())) {
            return ''; // Return empty string if date is invalid
        }

        // Extract individual date and time components
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const year = date.getFullYear();

        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12; // Convert 24-hour time to 12-hour time
        hours = String(hours).padStart(2, '0');

        // Construct the formatted date string
        return `${month}-${day}-${year}, ${hours}:${minutes} ${ampm}`;
    }

    console.log('Table data - ', tableData)

    const getImageUrl = (selectedUserData) => {
        console.log('In image url - ', selectedUserData);
        const imageUrls = [];

        // Check if selectedUserData is not empty and it contains the expected structure
        if (selectedUserData) {
            // Iterate over the keys of selectedUserData
            Object.keys(selectedUserData).forEach((key) => {
                if (selectedUserData[key]) {
                    const imageUrl = {
                        url: selectedUserData[key],
                        // Generate a human-readable title by replacing underscores with spaces and capitalizing words
                        title: key.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase())
                    };
                    imageUrls.push(imageUrl);
                }
            });
        }

        console.log('All urls - ', imageUrls);
        setImageUrls(imageUrls);

        // Return the array of image URLs
        return imageUrls;
    };


    const handleInternalTabChange = (key) => {
        if (unsavedChanges) {
            StyledModal.confirm({
                title: 'Unsaved Changes',
                content: 'You have unsaved changes. Are you sure you want to leave?',
                okText: 'Leave',
                cancelText: 'Stay',
                centered: true, // Centers the modal
                onOk() {
                    console.log('Tab change confirmed');
                    setInternalActiveTabKey(key);
                    setUnsavedChanges(false);
                },
                onCancel() {
                    console.log('Tab change canceled');
                },
                // Add custom styles
                // className: 'custom-modal',
            });
        } else {
            console.log('No unsaved changes, switching tab:', key);
            setInternalActiveTabKey(key);
        }
    };

    const handleTabEdit = (targetKey, action) => {
        if (action === 'remove') {
            setShowSecondTab(false);
            setActiveTabKey('allTables');
            setAllTablesKey('allTables');
        }
    };

    // Helper functions for rendering tags based on time difference
    const renderTimeDifferenceTag = (diffInMinutes) => {
        if (diffInMinutes < 15) {
            return createTag("green", "< 15 minutes");
        } else if (diffInMinutes < 30) {
            return createTag("green", "< 30 minutes");
        } else if (diffInMinutes < 60) {
            return createTag("orange", "30 mins to 1 hr");
        } else if (diffInMinutes < 1440) { // Less than 1 day
            return createTag("red", `${Math.floor(diffInMinutes / 60)} hr${Math.floor(diffInMinutes / 60) > 1 ? 's' : ''}`);
        } else if (diffInMinutes < 10080) { // Less than 1 week
            return createTag("blue", `${Math.floor(diffInMinutes / 1440)} day${Math.floor(diffInMinutes / 1440) > 1 ? 's' : ''}`);
        } else if (diffInMinutes < 43200) { // Less than 1 month
            return createTag("purple", `${Math.floor(diffInMinutes / 10080)} week${Math.floor(diffInMinutes / 10080) > 1 ? 's' : ''}`);
        } else {
            return createTag("black", `${Math.floor(diffInMinutes / 43200)} month${Math.floor(diffInMinutes / 43200) > 1 ? 's' : ''}`);
        }
    };

    // Create a Tag component
    const createTag = (color, text) => (
        <span className="poppins-font" style={{ fontSize: '18px' }}>
            <Tag color={color} style={{ fontSize: "16px", lineHeight: "32px", padding: "0 16px" }}>{text}</Tag>
        </span>
    );

    const renderNoActivityTag = () => (
        <span className="poppins-font" style={{ fontSize: '18px' }}>
            <Tag color="gray" style={{ fontSize: "16px", lineHeight: "32px", padding: "0 16px" }}>{"No activity"}</Tag>
        </span>
    );

    const [filteredInfo, setFilteredInfo] = useState({});

    // Helper function to get unique values for filtering
    const getUniqueValues = (dataIndex) => {
        return [...new Set(data.map(item => item[dataIndex]))].map(value => ({
            text: value,
            value,
        }));
    };

    // Function to handle column filter state
    const handleChange = (pagination, filters, sorter) => {
        setFilteredInfo(filters);
    };

    // Function to reset filters
    const clearFilters = () => {
        setFilteredInfo({});
    };

    // Define your column filter properties
    const getColumnFilterProps = (dataIndex) => ({
        filters: getUniqueValues(dataIndex),
        filteredValue: filteredInfo[dataIndex] || null,
        onFilter: (value, record) => record[dataIndex].toString().includes(value),
    });

    const [isChatWindowVisible, setChatWindowVisible] = useState(false);

    const toggleChatWindow = () => {
        setChatWindowVisible(!isChatWindowVisible);
    };


    const [currentIndex, setCurrentIndex] = useState(0);

    // Handle navigation to the next image
    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % imageUrls.length);
    };

    // Handle navigation to the previous image
    const handlePrevious = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? imageUrls.length - 1 : prevIndex - 1
        );
    };

    const handleIFSCChange = (e) => {
        setCustIFSCNumber(e.target.value)
    }

    const handleAccountNumberChange = (e) => {
        const value = e.target.value;

        // Ensure only numbers are entered
        if (/^\d*$/.test(value)) {
            setCustAccountNumber(value); // Store value as a string
            setCustAccountNumberValid(true);
            setAccountErrorMessage('');
        } else {
            setCustAccountNumber('');
            setCustAccountNumberValid(false);
            setAccountErrorMessage('Please enter a valid number.');
        }
    };

    const handleEmailIDChange = (e) => {
        const value = e.target.value;

        // Ensure only numbers are entered
        if (/^\d*$/.test(value)) {
            setCustAccountNumber(value); // Store value as a string
            setCustAccountNumberValid(true);
            setAccountErrorMessage('');
        } else {
            setCustAccountNumber('');
            setCustAccountNumberValid(false);
            setAccountErrorMessage('Please enter a valid number.');
        }
    };

    const handleAffordableEMIChange = value => {
        // setAffordableEMIValue(value);
        // Add your validation logic here to set isAffordableEMIValid and affordableEMIErrorMessage
        if (value >= affordableEMIMin && value <= affordableEMIMax) {
            setIsAffordableEMIValid(true);
            setAffordableEMIValue(value);
        } else {
            setIsAffordableEMIValid(false);
        }
    };

    const handleLoanAmountChange = value => {
        // console.log('Loan Amount - ', value)
        // setLoanAmountValue(value);
        // Add your validation logic here to set isAffordableEMIValid and affordableEMIErrorMessage
        if (value >= minLoanAmt && value <= maxLoanAmt) {
            setIsLoanAmountValid(true);
            setLoanAmountValue(value);
        } else {
            setIsLoanAmountValid(false);
        }
    };

    const handleTenureChange = value => {
        setTenureValue(value);
        // Add your validation logic here to set isAffordableEMIValid and affordableEMIErrorMessage
    };

    const handleCustomerFeedbackChange = (e) => {
        setCustomerFeedbackValue(e.target.value);

        // const requestBody = {
        //     feedback: customerFeedbackValue
        // };

        // dispatch(processLead({ jwt_token: 'Sample', username: custMobile, name: custName, requestBody }))
        //     .then(() => {
        //         // Show success notification
        //         setLoadingNotification(false);
        //         setNotificationMessage('Lead processed successfully!');
        //         setShowNotification(true);

        //         // // Hide notification after 3 seconds
        //         setTimeout(() => {
        //             setShowNotification(false);
        //         }, 3000);

        //         // Dispatch fetchChatData after updateRescheduleDate is successful
        //         dispatch(fetchChatData({ user_id: user_id, user_role: user_role }));
        //     })
        //     .catch((error) => {
        //         console.error("Error updating reschedule date:", error);
        //     });
    };

    const handleCustomerBureauFeedbackChange = (e) => {
        setCustomerBureauFeedbackValue(e.target.value);
    };

    const handleDateTimeChange = (newValue) => {
        console.log("Selected Date and Time:", newValue.format('YYYY-MM-DD HH:mm A'));
        setSelectedDateTime(newValue);
        setStringDateTime(newValue.format('YYYY-MM-DD HH:mm A'))
    };

    const handleUpdateRescheduleDate = () => {
        const requestBody = {
            uuid: selectedUserData.uuid,
            rescheduleDate: stringDateTime,
        };

        dispatch(updateRescheduleDate({ jwtBearer, requestBody }))
            .then(() => {
                // Dispatch fetchChatData after updateRescheduleDate is successful
                dispatch(fetchChatData({ jwtBearer, user_id: user_id, user_role: user_role, chat_type: 'Open' }));
            })
            .catch((error) => {
                console.error("Error updating reschedule date:", error);
            });

        // Close the details tab and switch back to allTables
        setShowSecondTab(false);
        setActiveTabKey('allTables');
        const callbackData = {
            Reference_number: selectedUserData.req_loan_det.ref_no,
            Customer_name: selectedUserData.chat_user_profile.full_name
        }

        onSendDataReschedule(callbackData);
    };

    const handleNotAvailable = () => {
        const requestBody = {
            uuid: selectedUserData.uuid,
            availability: customerAvailability,
            feedback: customerFeedbackValue
        }

        dispatch(updateAvailabilityStatus({ jwtBearer, requestBody }))
            .then(() => {
                // Dispatch fetchChatData after updateRescheduleDate is successful
                dispatch(fetchChatData({ jwtBearer, user_id: user_id, user_role: user_role, chat_type: 'Open' }));
            })
            .catch((error) => {
                console.error("Error updating availability status:", error);
            });

        // Close the details tab and switch back to allTables
        setShowSecondTab(false);
        setActiveTabKey('allTables');

        const callbackData = {
            Reference_number: selectedUserData.req_loan_det.ref_no,
            Customer_name: selectedUserData.chat_user_profile.full_name
        }

        onSendDataAvailability(callbackData);
    }

    // const totalSteps = [...trueKeys, ...falseKeys];
    const totalSteps = [...trueKeys, ...falseKeys].filter(key => key !== 'pri phone' && key !== 'reg phone');


    const handleEditMessage = (index, message) => {
        console.log('Message to be edited - ', index)
        setEditableInputValue(message.questionOrAnswer);
        setOriginalMessage(message.questionOrAnswer);
        setOriginalTimeStamp(message.timestamp);
        setOriginalQuesID(message.questionId);
        setEditableMessageIndex(index);
    };

    // Function to handle input change
    const handleInputChange = (e) => {
        setEditableInputValue(e.target.value);
    };

    const handleCancelEdit = () => {
        console.log('In cancel resp func')
        setEditableInputValue(originalMessage);
        setEditableMessageIndex(-1);
    };

    const handleAvailabilityChange = (e) => {
        console.log('Availability Changed - ', e)
        if (e == 'Available') {
            setEditAllowed(true)
        }
        else {
            setEditAllowed(false)
        }
        setCustomerAvailability(e)
        if (e == 'Reschedule') {
            setCustomerFeedbackValue('')
            setLoanAmountValue('')
            setBranchToAssign('')
            setLeadDecisionValue('')
        }
    }

    useEffect(() => {
        if (branchToAssign) {
            setBMToAssign(branchData[branchToAssign]?.bmEmpCode || '');
        }
    }, [branchToAssign, branchData]);

    const handleProcessLead = () => {

        const requestBody = {
            Reference_number: selectedUserData.req_loan_det.ref_no,
            username: selectedUserData.chat_user_profile.reg_phone,
            name: selectedUserData.chat_user_profile.full_name,
            feedback: customerFeedbackValue,
            bureauFeedback: customerBureauFeedbackValue,
            lead_decision: leadDecisionValue,
            branch: branchToAssign,
            BM: bMToAssign,
            telecaller_loan_amount: loanAmountValue.toString(),
            affordableEMI: affordableEMIValue.toString(),
            tenure: tenureValue.toString(),
            product: recommendedProduct,
            salarySource: salarySource,
            rejectReason: reasonToReject,
            crifResponse: custCRIFResponse,
            dob: custDOB,
            user_data: selectedUserChatData.chat,
            converted_by: user_id
        };

        console.log('Request to be sent - ', requestBody)

        setLoadingNotificationMessage('Processing Lead...')
        setLoadingNotification(true);

        dispatch(processLead({ jwtBearer, uuid: selectedUserData.uuid, username: custMobile, name: custName, requestBody }))
            .then((response) => {
                console.log('Process Lead Response - ', response)
                // Show success notification
                setLoadingNotification(false);
                setNotificationMessage('Lead processed successfully!');
                setShowNotification(true);

                // // Hide notification after 3 seconds
                setTimeout(() => {
                    setShowNotification(false);
                }, 3000);

                // Dispatch fetchChatData after updateRescheduleDate is successful
                dispatch(fetchChatData({ jwtBearer, user_id: user_id, user_role: user_role, chat_type: 'Open' }));
            })
            .catch((error) => {
                console.error("Error updating reschedule date:", error);
            });

        // Close the details tab and switch back to allTables
        setShowSecondTab(false);
        setActiveTabKey('allTables');
        const callbackData = {
            Reference_number: selectedUserData.req_loan_det.ref_no,
            Customer_name: selectedUserData.chat_user_profile.full_name
        }

        onSendData(callbackData);

    };

    const handleConfirmEdit = () => {
        console.log('In update resp func')
        console.log('Ques ID - ', originalQuesID)
        console.log('Time - ', originalTimeStamp)
        console.log('New message - ', editableInputValue)
        // Update the message content in your data structure
        // You may need to dispatch an action to update the state in Redux
        setEditableMessageIndex(-1);

        const requestBody = {
            username: custMobile,
            timestamp: originalTimeStamp,
            response: editableInputValue,
        };

        dispatch(updateChatData({ jwtBearer, requestBody }))

            .then(() => {
                // After updating the data, manually update the selectedUserData state with the updated data
                const updatedUserData = selectedUserData.map((user) => ({
                    ...user,
                    data: user.data.map((message) => ({
                        ...message,
                        questionOrAnswer: message.timestamp === originalTimeStamp ? editableInputValue : message.questionOrAnswer,
                    })),
                }));
                // dispatch(fetchChatData())
                dispatch(fetchChatData({ jwtBearer, user_id: user_id, user_role: user_role, chat_type: 'Open' }));
                setSelectedUserData(updatedUserData);
            })
            .catch((error) => {
                console.error("Error updating chat data:", error);
            });
    };

    const DocumentsCard = ({ onOpenChat, imageUrls }) => {

        const handleImgCancel = () => {
            setImgIsModalVisible(false);
            setModalImageUrl("");
        };

        const showImgModal = (index) => {
            setCurrentIndex(index); // Update the current index
            setImgIsModalVisible(true); // Show the modal
        };

        return (
            <>
                <animated.div style={documentCardProps}>
                    <div style={{ padding: "0px", margin: "0px" }}>
                        <p className="documentsPara">
                            <strong>Documents (Total - {imageUrls.length})</strong>
                        </p>
                        <Divider style={{ borderColor: "lightgrey" }} />

                        <div style={{ maxHeight: "40vh", overflowY: "auto", marginRight: "-25px", scrollbarColor: "#aaa white", scrollbarWidth: "thin" }}>
                            {imageUrls.map((image, index) => (
                                <div
                                    key={index}
                                    style={{
                                        display: "inline-flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        margin: "10px",
                                        textAlign: "center",
                                    }}
                                >
                                    <div
                                        style={{
                                            width: "150px",
                                            height: "150px",
                                            overflow: "hidden",
                                        }}
                                    >
                                        <img
                                            src={image.url}
                                            alt={image.title}
                                            style={{
                                                width: "100%",
                                                height: "100%",
                                                objectFit: "cover",
                                                cursor: "pointer",
                                                borderRadius: "10px",
                                            }}
                                            // onClick={() => showImgModal(image.url)}
                                            onClick={() => showImgModal(index)} // Pass the index to the function
                                        />
                                    </div>
                                    <p style={{ margin: "5px 0", fontSize: "20px" }}>
                                        <strong>{image.title}</strong>
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>
                </animated.div>
                {imageUrls.length > 0 && (
                    <Modal
                        visible={imgIsModalVisible}
                        centered
                        onCancel={handleImgCancel}
                        footer={null}
                        width={800} // Adjust the width as needed
                        style={{ borderRadius: '10px', textAlign: 'center' }}
                    >
                        {imageUrls[currentIndex] && (
                            <div style={{ position: 'relative', paddingTop: '20px' }}>
                                <img
                                    src={imageUrls[currentIndex].url}
                                    alt={imageUrls[currentIndex].title}
                                    style={{ width: "100%", height: "auto", marginBottom: '10px', borderRadius: '10px' }}
                                />
                                <Button
                                    type="primary"
                                    shape="circle"
                                    icon={<LeftOutlined />}
                                    onClick={handlePrevious}
                                    style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '10px',
                                        transform: 'translateY(-50%)',
                                        zIndex: 1,
                                        backgroundColor: '#1890ff', // Custom background color
                                        borderColor: '#1890ff', // Custom border color
                                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Shadow for depth
                                        fontSize: '18px', // Larger icon
                                        width: '50px', // Larger button size
                                        height: '50px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        transition: 'background-color 0.3s ease', // Smooth transition effect
                                    }}
                                    onMouseEnter={(e) => {
                                        e.currentTarget.style.backgroundColor = '#40a9ff';
                                        e.currentTarget.style.borderColor = '#40a9ff';
                                    }}
                                    onMouseLeave={(e) => {
                                        e.currentTarget.style.backgroundColor = '#1890ff';
                                        e.currentTarget.style.borderColor = '#1890ff';
                                    }}
                                />
                                <Button
                                    type="primary"
                                    shape="circle"
                                    icon={<RightOutlined />}
                                    onClick={handleNext}
                                    style={{
                                        position: 'absolute',
                                        top: '50%',
                                        right: '10px',
                                        transform: 'translateY(-50%)',
                                        zIndex: 1,
                                        backgroundColor: '#1890ff', // Custom background color
                                        borderColor: '#1890ff', // Custom border color
                                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Shadow for depth
                                        fontSize: '18px', // Larger icon
                                        width: '50px', // Larger button size
                                        height: '50px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        transition: 'background-color 0.3s ease', // Smooth transition effect
                                    }}
                                    onMouseEnter={(e) => {
                                        e.currentTarget.style.backgroundColor = '#40a9ff';
                                        e.currentTarget.style.borderColor = '#40a9ff';
                                    }}
                                    onMouseLeave={(e) => {
                                        e.currentTarget.style.backgroundColor = '#1890ff';
                                        e.currentTarget.style.borderColor = '#1890ff';
                                    }}
                                />

                            </div>
                        )}
                    </Modal>
                )}
            </>
        )
    }

    const handleLoanInputChange = (value) => {
        if (value >= minLoanAmt && value <= maxLoanAmt) {
            setLoanAmountValue(value);
            onLoanAmountChange(value);
        }
    };

    const handleInitiateBankVerification = async () => {

        const custUUID = selectedUserData.uuid
        const bankVerificationResponse = await dispatch(fetchBankVerification({ jwtBearer, custMobile, custUUID, custAccountNumber, custIFSC, custName }));
        console.log('Bank verification response - ', bankVerificationResponse.payload)

        if (bankVerificationResponse.payload.success) {
            if (bankVerificationResponse.payload.data.verified) {
                setBankVerificationSuccess(true)
                dispatch(fetchChatData({ jwtBearer, user_id: user_id, user_role: user_role, chat_type: 'Open' }));
                setTabRerenderKey(prevKey => prevKey + 1); // Change state to force re-render
            }
            else {
                setBankVerificationError(true)
                setBankVerificationErrorMsg(bankVerificationResponse.payload.data.bankResponse)
                dispatch(fetchChatData({ jwtBearer, user_id: user_id, user_role: user_role, chat_type: 'Open' }));
                setTabRerenderKey(prevKey => prevKey + 1); // Change state to force re-render
            }
        }
        else {
            setBankVerificationAPIFailure(true)
        }
    }

    const handleFetchBureau = async () => {

        setBureauLoading(true);

        const requestBody = {
            uuid: selectedUserData.uuid,
            username: custMobile,
            loan_amt: loanAmountValue,
            name: selectedUserData.chat_user_profile.full_name,
            pan_card: panNumber,
            dob: selectedUserData.chat_user_profile.date_of_birth,
            number: custMobile,
            fathers_name: custFathersName,
            mothers_name: custMothersName,
            husband_name: custSpouseName,
            wife_name: custSpouseName,
            residence_address: custResidenceAddress,
            city: "string",
            state: "string",
            pincode: "string"
        };
        try {
            if (custCRIFResponse) {
                console.log('In CRIF existing')
                // Get the content inside the <CONTENT> tag
                const content = custCRIFResponse.match(/<CONTENT>([\s\S]*?)<\/CONTENT>/)[1];

                // Set content in state and open the drawer
                setDrawerContent(content);
                setDrawerVisible(true);

            }
            else {
                console.log('In CRIF new')
                const bureauResponse = await dispatch(fetchBureau({ jwtBearer, requestBody }));
                setCrifResponse(bureauResponse.payload)
                setCustCRIFResponse(bureauResponse.payload)
                console.log('Bureau response - ', bureauResponse.payload)

                // Get the content inside the <CONTENT> tag
                const content = bureauResponse.payload.match(/<CONTENT>([\s\S]*?)<\/CONTENT>/)[1];

                // Set content in state and open the drawer
                setDrawerContent(content);
                setDrawerVisible(true);

                // Create a new HTML document
                // const newTab = window.open();

                // Write the content to the new document
                // newTab.document.open();
                // newTab.document.write(content);
                // newTab.document.close();
            }
        } catch (error) {
            console.error('Error fetching bureau data:', error);
        } finally {
            setBureauLoading(false);  // Stop loading
        }
    }

    const [showFullAadhaar, setShowFullAadhaar] = useState(false);

    const toggleShowAadhaar = () => {
        setShowFullAadhaar(!showFullAadhaar);
    };

    const maskedAadhaar = aadhaarNumber.slice(0, -4) + 'XXXX';

    // const passbookChequeImage = imageUrls.find(image => image.title === "Passbook/Cheque");
    // const passbookChequeImage = selectedUserData.images.cheque;
    const passbookChequeImage = chatImages[0].images.cheque;

    // const handleDOBChange = (e) => {
    //     // Convert the selected value to a dayjs object and format it as DD-MM-YYYY
    //     const formattedDate = dayjs(e).format('DD-MM-YYYY');

    //     // Perform any additional logic here
    //     console.log('Selected DOB - ', formattedDate);

    //     // Set the date in state (store the dayjs object, not the formatted string)
    //     setCustDOB(dayjs(formattedDate));
    // };

    const handleDOBChange = (newValue) => {

        if (newValue) {
            console.log("custDOB changed", dayjs(newValue).format("YYYY-MM-DD"))
            setCustDOB(dayjs(newValue).format("YYYY-MM-DD"));
        } else {
            setCustDOB(null);
        }
    };

    return (
        <>
            {selectedUserData && (
                <>
                    <animated.div style={documentCardProps}>
                        <StyledRow>
                            <StyledCol style={{ overflowY: "hidden" }} span={8}>
                                {/* {(selectedUserData.images.photo || profile_placeholder) && ( */}
                                {(chatImages[0].images.photo || profile_placeholder) && (
                                    <div>
                                        <Row>
                                            <Col>
                                                <img
                                                    // src={selectedUserData.images.photo ? selectedUserData.images.photo : profile_placeholder}
                                                    src={chatImages[0].images.photo ? chatImages[0].images.photo : profile_placeholder}
                                                    alt="Profile"
                                                    // style={{
                                                    //     borderRadius: "50%",
                                                    //     width: "180px", // Adjust the size as needed
                                                    //     height: "180px",
                                                    //     objectFit: "cover", // Maintain aspect ratio
                                                    // }}
                                                    className="myProfile"
                                                />
                                            </Col>
                                            <Col style={{ marginLeft: '20px' }}>
                                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '2px', marginTop: '20px' }}>
                                                    <p className="fullName">
                                                        <strong>{selectedUserData.chat_user_profile.full_name}</strong>
                                                    </p>
                                                    <p className="regNumberA">
                                                        <span style={{ fontSize: '14px', fontStyle: 'italic', color: 'grey', fontWeight: '700', lineHeight: '1' }}>Mob - </span>
                                                        {selectedUserData.chat_user_profile.reg_phone}
                                                    </p>
                                                    <p className="refNumberA">
                                                        <span style={{ fontSize: '14px', fontStyle: 'italic', color: 'grey', fontWeight: '700', lineHeight: '1' }}>Ref No. - </span>
                                                        {selectedUserData.req_loan_det.ref_no}
                                                    </p>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                )}
                                <p style={{ fontSize: '18px', fontStyle: 'italic', color: 'grey', fontWeight: '500', marginTop: '20px' }}>Verification Status</p>
                                <div className="steps-container">
                                    <Steps direction="horizontal" size='default' labelPlacement='vertical' current={trueKeys.length - 1}>
                                        {totalSteps.map((key, index) => (
                                            <Steps.Step
                                                // onClick={() => handleStepClick(key)}
                                                key={index}
                                                title={<strong>{key}</strong>}
                                                status={trueKeys.includes(key) ? "finish" : "error"}
                                                style={falseKeys.includes(key) ? { cursor: 'pointer' } : {}}
                                            />
                                        ))}
                                    </Steps>
                                </div>
                                <animated.div style={documentCardProps}>
                                    <Card
                                        style={{
                                            boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
                                            // margin: "20px 5px 5px 5px",
                                            marginRight: "-24px",

                                            overflowX: "hidden",
                                            borderRadius: "10px",
                                            display: "flex",
                                            flexDirection: "column",
                                        }}
                                        className="customerCard"
                                    >
                                        {/* <div style={{ display: 'flex', alignItems: 'center', fontSize: '25px', color: 'grey' }}> */}
                                        <div className="customerDiv">
                                            <strong style={{ marginRight: '20px' }}>Customer Profile</strong>
                                            {/* {loanAmountValue && !custCRIFResponse && (
                                                <StyledButton
                                                    type="primary"
                                                    onClick={handleFetchBureau}
                                                    htmlType="submit"
                                                    icon={<ArrowRightOutlined />}
                                                    style={{ marginLeft: 'auto' }}
                                                    loading={bureauLoading}  // Use the loading state
                                                >
                                                    {loading ? 'Loading...' : 'Initiate Bureau'}
                                                </StyledButton>
                                            )}
                                            {custCRIFResponse && (
                                                <StyledButton
                                                    type="primary"
                                                    onClick={handleFetchBureau}
                                                    htmlType="submit"
                                                    icon={<ArrowRightOutlined />}
                                                    style={{ marginLeft: 'auto' }}
                                                >
                                                    Show Bureau
                                                </StyledButton>
                                            )} */}
                                        </div>
                                        <Tabs activeKey={internalActiveTabKey} onChange={handleInternalTabChange}>
                                            <TabPane tab={<strong className="customerHeads">Basic Details</strong>} key="1">
                                                <div style={{ height: 'calc(68vh - 200px)', overflowY: 'auto', paddingRight: '10px', scrollbarColor: "#aaa white", scrollbarWidth: "thin" }}>
                                                    <Form style={{ marginLeft: '10px', marginRight: '20px' }} form={form} layout="vertical">
                                                        <Form.Item
                                                            label={
                                                                <strong className="customerSubHead">
                                                                    Father's Name
                                                                </strong>
                                                            }
                                                        >
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                {editMode === 'fathersName' ? (
                                                                    <Input
                                                                        style={{
                                                                            fontSize: '18px',
                                                                            fontWeight: '600',
                                                                            color: 'black',
                                                                            borderRadius: '10px',
                                                                            marginRight: '8px',
                                                                            flex: 1,
                                                                        }}
                                                                        value={tempValue}
                                                                        onChange={(e) => setTempValue(e.target.value)}
                                                                    />
                                                                ) : (
                                                                    <div
                                                                        style={{
                                                                            fontSize: '18px',
                                                                            fontWeight: '600',
                                                                            color: 'black',
                                                                            borderRadius: '10px',
                                                                            marginRight: '8px',
                                                                            flex: 1,
                                                                            lineHeight: '32px', // Ensure it aligns with Input height
                                                                            border: '1px solid #d9d9d9',
                                                                            padding: '4px 11px',
                                                                            backgroundColor: '#f5f5f5', // Match the style of antd Input when disabled
                                                                        }}
                                                                    >
                                                                        {custFathersName}
                                                                    </div>
                                                                )}
                                                                {editAllowed && (
                                                                    <>
                                                                        {editMode === 'fathersName' ? (
                                                                            <Button
                                                                                type="primary"
                                                                                icon={<SaveOutlined />}
                                                                                onClick={() => handleSaveClick('fathersName')}
                                                                            />
                                                                        ) : (
                                                                            <Button
                                                                                type="default"
                                                                                icon={<EditOutlined />}
                                                                                onClick={() => handleEditClick('fathersName')}
                                                                            />
                                                                        )}
                                                                    </>
                                                                )}
                                                            </div>
                                                        </Form.Item>
                                                        <Form.Item
                                                            label={
                                                                <strong className="customerSubHead">
                                                                    Mother's Name
                                                                </strong>
                                                            }
                                                        >
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                {editMode === 'mothersName' ? (
                                                                    <Input
                                                                        style={{
                                                                            fontSize: '18px',
                                                                            fontWeight: '600',
                                                                            color: 'black',
                                                                            borderRadius: '10px',
                                                                            marginRight: '8px',
                                                                            flex: 1,
                                                                        }}
                                                                        value={tempValue}
                                                                        onChange={(e) => setTempValue(e.target.value)}
                                                                    />
                                                                ) : (
                                                                    <div
                                                                        style={{
                                                                            fontSize: '18px',
                                                                            fontWeight: '600',
                                                                            color: 'black',
                                                                            borderRadius: '10px',
                                                                            marginRight: '8px',
                                                                            flex: 1,
                                                                            lineHeight: '32px', // Ensure it aligns with Input height
                                                                            border: '1px solid #d9d9d9',
                                                                            padding: '4px 11px',
                                                                            backgroundColor: '#f5f5f5', // Match the style of antd Input when disabled
                                                                        }}
                                                                    >
                                                                        {custMothersName}
                                                                    </div>
                                                                )}
                                                                {editAllowed && (
                                                                    <>
                                                                        {editMode === 'mothersName' ? (
                                                                            <Button
                                                                                type="primary"
                                                                                icon={<SaveOutlined />}
                                                                                onClick={() => handleSaveClick('mothersName')}
                                                                            />
                                                                        ) : (
                                                                            <Button
                                                                                type="default"
                                                                                icon={<EditOutlined />}
                                                                                onClick={() => handleEditClick('mothersName')}
                                                                            />
                                                                        )}
                                                                    </>
                                                                )}
                                                            </div>
                                                        </Form.Item>
                                                        <Form.Item label={<strong className="customerSubHead">Gender</strong>}>
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                {editMode === 'gender' ? (
                                                                    <Select
                                                                        style={{ fontSize: '18px', fontWeight: '600', color: 'black', borderRadius: '10px', marginRight: '8px', flex: 1 }}
                                                                        value={tempValue}
                                                                        onChange={(value) => setTempValue(value)}
                                                                    >
                                                                        {fieldMaster[0].gender.map((amount, index) => (
                                                                            <Option key={index} value={amount}>{amount}</Option>
                                                                        ))}
                                                                    </Select>
                                                                ) : (
                                                                    <div
                                                                        style={{
                                                                            fontSize: '18px',
                                                                            fontWeight: '600',
                                                                            color: 'black',
                                                                            borderRadius: '10px',
                                                                            marginRight: '8px',
                                                                            flex: 1,
                                                                            lineHeight: '32px', // Ensure it aligns with Select height
                                                                            border: '1px solid #d9d9d9',
                                                                            padding: '4px 11px',
                                                                            backgroundColor: '#f5f5f5', // Match the style of antd Select when disabled
                                                                        }}
                                                                    >
                                                                        {custGender}
                                                                    </div>
                                                                )}
                                                                {editAllowed && (
                                                                    <>
                                                                        {editMode === 'gender' ? (
                                                                            <Button
                                                                                type="primary"
                                                                                icon={<SaveOutlined />}
                                                                                onClick={() => handleSaveClick('gender')}
                                                                            />
                                                                        ) : (
                                                                            <Button
                                                                                type="default"
                                                                                icon={<EditOutlined />}
                                                                                onClick={() => handleEditClick('gender')}
                                                                            />
                                                                        )}
                                                                    </>
                                                                )}
                                                            </div>
                                                        </Form.Item>
                                                        <Form.Item label={<strong className="customerSubHead">DOB</strong>}>
                                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                <DemoItem>
                                                                    {/* <DatePicker
                                                                        label="Select DOB"
                                                                        value={custDOB}
                                                                        onChange={handleDOBChange}
                                                                        maxDate={dayjs().subtract(18, 'years')}  // Disallow dates within the last 18 years
                                                                        minDate={dayjs().subtract(100, 'years')} // Optional: Disallow dates older than 100 years, adjust as needed
                                                                    /> */}
                                                                    <DatePicker
                                                                        label="Select DOB"
                                                                        value={custDOB ? dayjs(custDOB) : null}
                                                                        onChange={handleDOBChange}
                                                                        maxDate={dayjs().subtract(18, "years")} // Disallow dates within the last 18 years
                                                                        minDate={dayjs().subtract(100, "years")} // Optional: Disallow dates older than 100 years, adjust as needed
                                                                    />
                                                                </DemoItem>
                                                            </LocalizationProvider>
                                                        </Form.Item>

                                                        <Form.Item label={<strong className="customerSubHead">Marital Status</strong>}>
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                {editMode === 'maritalStatus' ? (
                                                                    <Select
                                                                        style={{ fontSize: '18px', fontWeight: '600', color: 'black', borderRadius: '10px', marginRight: '8px', flex: 1 }}
                                                                        value={tempValue}
                                                                        onChange={(value) => setTempValue(value)}
                                                                    >
                                                                        {fieldMaster[0].marital_status.map((amount, index) => (
                                                                            <Option key={index} value={amount}>{amount}</Option>
                                                                        ))}
                                                                    </Select>
                                                                ) : (
                                                                    <div
                                                                        style={{
                                                                            fontSize: '18px',
                                                                            fontWeight: '600',
                                                                            color: 'black',
                                                                            borderRadius: '10px',
                                                                            marginRight: '8px',
                                                                            flex: 1,
                                                                            lineHeight: '32px', // Ensure it aligns with Select height
                                                                            border: '1px solid #d9d9d9',
                                                                            padding: '4px 11px',
                                                                            backgroundColor: '#f5f5f5', // Match the style of antd Select when disabled
                                                                        }}
                                                                    >
                                                                        {custMaritalStatus}
                                                                    </div>
                                                                )}
                                                                {editAllowed && (
                                                                    <>
                                                                        {editMode === 'maritalStatus' ? (
                                                                            <Button
                                                                                type="primary"
                                                                                icon={<SaveOutlined />}
                                                                                onClick={() => handleSaveClick('maritalStatus')}
                                                                            />
                                                                        ) : (
                                                                            <Button
                                                                                type="default"
                                                                                icon={<EditOutlined />}
                                                                                onClick={() => handleEditClick('maritalStatus')}
                                                                            />
                                                                        )}
                                                                    </>
                                                                )}
                                                            </div>
                                                        </Form.Item>
                                                        <Form.Item
                                                            label={
                                                                <strong className="customerSubHead">
                                                                    Spouse Name
                                                                </strong>
                                                            }
                                                        >
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                {editMode === 'spouseName' ? (
                                                                    <Input
                                                                        style={{
                                                                            fontSize: '18px',
                                                                            fontWeight: '600',
                                                                            color: 'black',
                                                                            borderRadius: '10px',
                                                                            marginRight: '8px',
                                                                            flex: 1,
                                                                        }}
                                                                        value={tempValue}
                                                                        onChange={(e) => setTempValue(e.target.value)}
                                                                    />
                                                                ) : (
                                                                    <div
                                                                        style={{
                                                                            fontSize: '18px',
                                                                            fontWeight: '600',
                                                                            color: 'black',
                                                                            borderRadius: '10px',
                                                                            marginRight: '8px',
                                                                            flex: 1,
                                                                            lineHeight: '32px', // Ensure it aligns with Input height
                                                                            border: '1px solid #d9d9d9',
                                                                            padding: '4px 11px',
                                                                            backgroundColor: '#f5f5f5', // Match the style of antd Input when disabled
                                                                        }}
                                                                    >
                                                                        {custSpouseName}
                                                                    </div>
                                                                )}
                                                                {editAllowed && (
                                                                    <>
                                                                        {editMode === 'spouseName' ? (
                                                                            <Button
                                                                                type="primary"
                                                                                icon={<SaveOutlined />}
                                                                                onClick={() => handleSaveClick('spouseName')}
                                                                            />
                                                                        ) : (
                                                                            <Button
                                                                                type="default"
                                                                                icon={<EditOutlined />}
                                                                                onClick={() => handleEditClick('spouseName')}
                                                                            />
                                                                        )}
                                                                    </>
                                                                )}
                                                            </div>
                                                        </Form.Item>
                                                        <Form.Item label={<strong className="customerSubHead">No. of Children</strong>}>
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                {editMode === 'custChildren' ? (
                                                                    <Select
                                                                        style={{ fontSize: '18px', fontWeight: '600', color: 'black', borderRadius: '10px', marginRight: '8px', flex: 1 }}
                                                                        value={tempValue}
                                                                        onChange={(value) => setTempValue(value)}
                                                                    >
                                                                        {fieldMaster[0].children_count.map((amount, index) => (
                                                                            <Option key={index} value={amount}>{amount}</Option>
                                                                        ))}
                                                                    </Select>
                                                                ) : (
                                                                    <div
                                                                        style={{
                                                                            fontSize: '18px',
                                                                            fontWeight: '600',
                                                                            color: 'black',
                                                                            borderRadius: '10px',
                                                                            marginRight: '8px',
                                                                            flex: 1,
                                                                            lineHeight: '32px', // Ensure it aligns with Select height
                                                                            border: '1px solid #d9d9d9',
                                                                            padding: '4px 11px',
                                                                            backgroundColor: '#f5f5f5', // Match the style of antd Select when disabled
                                                                        }}
                                                                    >
                                                                        {custChildren}
                                                                    </div>
                                                                )}
                                                                {editAllowed && (
                                                                    <>
                                                                        {editMode === 'custChildren' ? (
                                                                            <Button
                                                                                type="primary"
                                                                                icon={<SaveOutlined />}
                                                                                onClick={() => handleSaveClick('custChildren')}
                                                                            />
                                                                        ) : (
                                                                            <Button
                                                                                type="default"
                                                                                icon={<EditOutlined />}
                                                                                onClick={() => handleEditClick('custChildren')}
                                                                            />
                                                                        )}
                                                                    </>
                                                                )}
                                                            </div>
                                                        </Form.Item>
                                                    </Form>
                                                </div>
                                            </TabPane>
                                            <TabPane tab={<strong style={{ fontSize: '20px' }}>Loan Information</strong>} key="2">
                                                <div style={{ height: 'calc(68vh - 200px)', overflowY: 'auto', paddingRight: '10px', scrollbarColor: "#aaa white", scrollbarWidth: "thin" }}>
                                                    <Form style={{ marginLeft: '10px', marginRight: '20px' }} form={form} layout="vertical">
                                                        <Form.Item label={<strong className="customerSubHead">Required Loan Amount</strong>}>
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                {editMode === 'loanAmount' ? (
                                                                    <Select
                                                                        style={{ fontSize: '18px', fontWeight: '600', color: 'black', borderRadius: '10px', marginRight: '8px', flex: 1 }}
                                                                        value={tempValue}
                                                                        onChange={(value) => setTempValue(value)}
                                                                    >
                                                                        {fieldMaster[0].loan_amount.map((amount, index) => (
                                                                            <Option key={index} value={amount}>{amount}</Option>
                                                                        ))}
                                                                    </Select>
                                                                ) : (
                                                                    <div
                                                                        style={{
                                                                            fontSize: '18px',
                                                                            fontWeight: '600',
                                                                            color: 'black',
                                                                            borderRadius: '10px',
                                                                            marginRight: '8px',
                                                                            flex: 1,
                                                                            lineHeight: '32px', // Ensure it aligns with Select height
                                                                            border: '1px solid #d9d9d9',
                                                                            padding: '4px 11px',
                                                                            backgroundColor: '#f5f5f5', // Match the style of antd Select when disabled
                                                                        }}
                                                                    >
                                                                        {custLoanAmt}
                                                                    </div>
                                                                )}
                                                                {editAllowed && (
                                                                    <>
                                                                        {editMode === 'loanAmount' ? (
                                                                            <Button
                                                                                type="primary"
                                                                                icon={<SaveOutlined />}
                                                                                onClick={() => handleSaveClick('loanAmount')}
                                                                            />
                                                                        ) : (
                                                                            <Button
                                                                                type="default"
                                                                                icon={<EditOutlined />}
                                                                                onClick={() => handleEditClick('loanAmount')}
                                                                            />
                                                                        )}
                                                                    </>
                                                                )}
                                                            </div>
                                                        </Form.Item>

                                                        <Form.Item label={<strong className="customerSubHead">Affordable EMI</strong>}>
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                {editMode === 'affordableEMI' ? (
                                                                    <Select
                                                                        style={{ fontSize: '18px', fontWeight: '600', color: 'black', borderRadius: '10px', marginRight: '8px', flex: 1 }}
                                                                        value={tempValue}
                                                                        onChange={(value) => setTempValue(value)}
                                                                    >
                                                                        {fieldMaster[0].affordable_emi.map((amount, index) => (
                                                                            <Option key={index} value={amount}>{amount}</Option>
                                                                        ))}
                                                                    </Select>
                                                                ) : (
                                                                    <div
                                                                        style={{
                                                                            fontSize: '18px',
                                                                            fontWeight: '600',
                                                                            color: 'black',
                                                                            borderRadius: '10px',
                                                                            marginRight: '8px',
                                                                            flex: 1,
                                                                            lineHeight: '32px', // Ensure it aligns with Select height
                                                                            border: '1px solid #d9d9d9',
                                                                            padding: '4px 11px',
                                                                            backgroundColor: '#f5f5f5', // Match the style of antd Select when disabled
                                                                        }}
                                                                    >
                                                                        {custAffordableEMI}
                                                                    </div>
                                                                )}
                                                                {editAllowed && (
                                                                    <>
                                                                        {editMode === 'affordableEMI' ? (
                                                                            <Button
                                                                                type="primary"
                                                                                icon={<SaveOutlined />}
                                                                                onClick={() => handleSaveClick('affordableEMI')}
                                                                            />
                                                                        ) : (
                                                                            <Button
                                                                                type="default"
                                                                                icon={<EditOutlined />}
                                                                                onClick={() => handleEditClick('affordableEMI')}
                                                                            />
                                                                        )}
                                                                    </>
                                                                )}
                                                            </div>
                                                        </Form.Item>
                                                        <Form.Item label={<strong className="customerSubHead">Employment Category</strong>}>
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                {editMode === 'employmentCat' ? (
                                                                    <Select
                                                                        style={{ fontSize: '18px', fontWeight: '600', color: 'black', borderRadius: '10px', marginRight: '8px', flex: 1 }}
                                                                        value={tempValue}
                                                                        onChange={(value) => setTempValue(value)}
                                                                    >
                                                                        {fieldMaster[0].employment_category.map((amount, index) => (
                                                                            <Option key={index} value={amount}>{amount}</Option>
                                                                        ))}
                                                                    </Select>
                                                                ) : (
                                                                    <div
                                                                        style={{
                                                                            fontSize: '18px',
                                                                            fontWeight: '600',
                                                                            color: 'black',
                                                                            borderRadius: '10px',
                                                                            marginRight: '8px',
                                                                            flex: 1,
                                                                            lineHeight: '32px', // Ensure it aligns with Select height
                                                                            border: '1px solid #d9d9d9',
                                                                            padding: '4px 11px',
                                                                            backgroundColor: '#f5f5f5', // Match the style of antd Select when disabled
                                                                        }}
                                                                    >
                                                                        {custEmploymentCat}
                                                                    </div>
                                                                )}
                                                                {editAllowed && (
                                                                    <>
                                                                        {editMode === 'employmentCat' ? (
                                                                            <Button
                                                                                type="primary"
                                                                                icon={<SaveOutlined />}
                                                                                onClick={() => handleSaveClick('employmentCat')}
                                                                            />
                                                                        ) : (
                                                                            <Button
                                                                                type="default"
                                                                                icon={<EditOutlined />}
                                                                                onClick={() => handleEditClick('employmentCat')}
                                                                            />
                                                                        )}
                                                                    </>
                                                                )}
                                                            </div>
                                                        </Form.Item>
                                                        <Form.Item label={<strong className="customerSubHead">Salary Mode</strong>}>
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                {editMode === 'salaryMode' ? (
                                                                    <Select
                                                                        style={{ fontSize: '18px', fontWeight: '600', color: 'black', borderRadius: '10px', marginRight: '8px', flex: 1 }}
                                                                        value={tempValue}
                                                                        onChange={(value) => setTempValue(value)}
                                                                    >
                                                                        {fieldMaster[0].salary_mode.map((amount, index) => (
                                                                            <Option key={index} value={amount}>{amount}</Option>
                                                                        ))}
                                                                    </Select>
                                                                ) : (
                                                                    <div
                                                                        style={{
                                                                            fontSize: '18px',
                                                                            fontWeight: '600',
                                                                            color: 'black',
                                                                            borderRadius: '10px',
                                                                            marginRight: '8px',
                                                                            flex: 1,
                                                                            lineHeight: '32px', // Ensure it aligns with Select height
                                                                            border: '1px solid #d9d9d9',
                                                                            padding: '4px 11px',
                                                                            backgroundColor: '#f5f5f5', // Match the style of antd Select when disabled
                                                                        }}
                                                                    >
                                                                        {custSalaryMode}
                                                                    </div>
                                                                )}
                                                                {editAllowed && (
                                                                    <>
                                                                        {editMode === 'salaryMode' ? (
                                                                            <Button
                                                                                type="primary"
                                                                                icon={<SaveOutlined />}
                                                                                onClick={() => handleSaveClick('salaryMode')}
                                                                            />
                                                                        ) : (
                                                                            <Button
                                                                                type="default"
                                                                                icon={<EditOutlined />}
                                                                                onClick={() => handleEditClick('salaryMode')}
                                                                            />
                                                                        )}
                                                                    </>
                                                                )}
                                                            </div>
                                                        </Form.Item>
                                                        <Form.Item label={<strong className="customerSubHead">Annual Income</strong>}>
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                {editMode === 'annualIncome' ? (
                                                                    <Select
                                                                        style={{ fontSize: '18px', fontWeight: '600', color: 'black', borderRadius: '10px', marginRight: '8px', flex: 1 }}
                                                                        value={tempValue}
                                                                        onChange={(value) => setTempValue(value)}
                                                                    >
                                                                        {fieldMaster[0].annual_inc_range.map((amount, index) => (
                                                                            <Option key={index} value={amount}>{amount}</Option>
                                                                        ))}
                                                                    </Select>
                                                                ) : (
                                                                    <div
                                                                        style={{
                                                                            fontSize: '18px',
                                                                            fontWeight: '600',
                                                                            color: 'black',
                                                                            borderRadius: '10px',
                                                                            marginRight: '8px',
                                                                            flex: 1,
                                                                            lineHeight: '32px', // Ensure it aligns with Select height
                                                                            border: '1px solid #d9d9d9',
                                                                            padding: '4px 11px',
                                                                            backgroundColor: '#f5f5f5', // Match the style of antd Select when disabled
                                                                        }}
                                                                    >
                                                                        {custAnnualIncome}
                                                                    </div>
                                                                )}
                                                                {editAllowed && (
                                                                    <>
                                                                        {editMode === 'annualIncome' ? (
                                                                            <Button
                                                                                type="primary"
                                                                                icon={<SaveOutlined />}
                                                                                onClick={() => handleSaveClick('annualIncome')}
                                                                            />
                                                                        ) : (
                                                                            <Button
                                                                                type="default"
                                                                                icon={<EditOutlined />}
                                                                                onClick={() => handleEditClick('annualIncome')}
                                                                            />
                                                                        )}
                                                                    </>
                                                                )}
                                                            </div>
                                                        </Form.Item>
                                                        <Form.Item label={<strong className="customerSubHead">Other Income Source</strong>}>
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                {editMode === 'otherIncomeSource' ? (
                                                                    <Select
                                                                        style={{ fontSize: '18px', fontWeight: '600', color: 'black', borderRadius: '10px', marginRight: '8px', flex: 1 }}
                                                                        value={tempValue}
                                                                        onChange={(value) => setTempValue(value)}
                                                                    >
                                                                        {fieldMaster[0].other_income_source.map((amount, index) => (
                                                                            <Option key={index} value={amount}>{amount}</Option>
                                                                        ))}
                                                                    </Select>
                                                                ) : (
                                                                    <div
                                                                        style={{
                                                                            fontSize: '18px',
                                                                            fontWeight: '600',
                                                                            color: 'black',
                                                                            borderRadius: '10px',
                                                                            marginRight: '8px',
                                                                            flex: 1,
                                                                            lineHeight: '32px', // Ensure it aligns with Select height
                                                                            border: '1px solid #d9d9d9',
                                                                            padding: '4px 11px',
                                                                            backgroundColor: '#f5f5f5', // Match the style of antd Select when disabled
                                                                        }}
                                                                    >
                                                                        {custOtherIncSource}
                                                                    </div>
                                                                )}
                                                                {editAllowed && (
                                                                    <>
                                                                        {editMode === 'otherIncomeSource' ? (
                                                                            <Button
                                                                                type="primary"
                                                                                icon={<SaveOutlined />}
                                                                                onClick={() => handleSaveClick('otherIncomeSource')}
                                                                            />
                                                                        ) : (
                                                                            <Button
                                                                                type="default"
                                                                                icon={<EditOutlined />}
                                                                                onClick={() => handleEditClick('otherIncomeSource')}
                                                                            />
                                                                        )}
                                                                    </>
                                                                )}
                                                            </div>
                                                        </Form.Item>
                                                        <Form.Item label={<strong className="customerSubHead">Income From Other Source</strong>}>
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                {editMode === 'otherIncomeSourceIncome' ? (
                                                                    <Select
                                                                        style={{ fontSize: '18px', fontWeight: '600', color: 'black', borderRadius: '10px', marginRight: '8px', flex: 1 }}
                                                                        value={tempValue}
                                                                        onChange={(value) => setTempValue(value)}
                                                                    >
                                                                        {fieldMaster[0].annual_inc_range_other.map((amount, index) => (
                                                                            <Option key={index} value={amount}>{amount}</Option>
                                                                        ))}
                                                                    </Select>
                                                                ) : (
                                                                    <div
                                                                        style={{
                                                                            fontSize: '18px',
                                                                            fontWeight: '600',
                                                                            color: 'black',
                                                                            borderRadius: '10px',
                                                                            marginRight: '8px',
                                                                            flex: 1,
                                                                            lineHeight: '32px', // Ensure it aligns with Select height
                                                                            border: '1px solid #d9d9d9',
                                                                            padding: '4px 11px',
                                                                            backgroundColor: '#f5f5f5', // Match the style of antd Select when disabled
                                                                        }}
                                                                    >
                                                                        {custOtherIncSourceIncome}
                                                                    </div>
                                                                )}
                                                                {editAllowed && (
                                                                    <>
                                                                        {editMode === 'otherIncomeSourceIncome' ? (
                                                                            <Button
                                                                                type="primary"
                                                                                icon={<SaveOutlined />}
                                                                                onClick={() => handleSaveClick('otherIncomeSourceIncome')}
                                                                            />
                                                                        ) : (
                                                                            <Button
                                                                                type="default"
                                                                                icon={<EditOutlined />}
                                                                                onClick={() => handleEditClick('otherIncomeSourceIncome')}
                                                                            />
                                                                        )}
                                                                    </>
                                                                )}
                                                            </div>
                                                        </Form.Item>
                                                        <Form.Item label={<strong className="customerSubHead">Purpose of Loan</strong>}>
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                {editMode === 'purposeOfLoan' ? (
                                                                    <Select
                                                                        style={{ fontSize: '18px', fontWeight: '600', color: 'black', borderRadius: '10px', marginRight: '8px', flex: 1 }}
                                                                        value={tempValue}
                                                                        onChange={(value) => setTempValue(value)}
                                                                    >
                                                                        {fieldMaster[0].purpose_of_loan.map((amount, index) => (
                                                                            <Option key={index} value={amount}>{amount}</Option>
                                                                        ))}
                                                                    </Select>
                                                                ) : (
                                                                    <div
                                                                        style={{
                                                                            fontSize: '18px',
                                                                            fontWeight: '600',
                                                                            color: 'black',
                                                                            borderRadius: '10px',
                                                                            marginRight: '8px',
                                                                            flex: 1,
                                                                            lineHeight: '32px', // Ensure it aligns with Select height
                                                                            border: '1px solid #d9d9d9',
                                                                            padding: '4px 11px',
                                                                            backgroundColor: '#f5f5f5', // Match the style of antd Select when disabled
                                                                        }}
                                                                    >
                                                                        {purposeOfLoan}
                                                                    </div>
                                                                )}
                                                                {editAllowed && (
                                                                    <>
                                                                        {editMode === 'purposeOfLoan' ? (
                                                                            <Button
                                                                                type="primary"
                                                                                icon={<SaveOutlined />}
                                                                                onClick={() => handleSaveClick('purposeOfLoan')}
                                                                            />
                                                                        ) : (
                                                                            <Button
                                                                                type="default"
                                                                                icon={<EditOutlined />}
                                                                                onClick={() => handleEditClick('purposeOfLoan')}
                                                                            />
                                                                        )}
                                                                    </>
                                                                )}
                                                            </div>
                                                        </Form.Item>
                                                        <Form.Item label={<strong className="customerSubHead">Industry</strong>}>
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                {editMode === 'industry' ? (
                                                                    <Select
                                                                        style={{ fontSize: '18px', fontWeight: '600', color: 'black', borderRadius: '10px', marginRight: '8px', flex: 1 }}
                                                                        value={tempValue}
                                                                        onChange={(value) => setTempValue(value)}
                                                                    >
                                                                        {fieldMaster[0].industry.map((amount, index) => (
                                                                            <Option key={index} value={amount}>{amount}</Option>
                                                                        ))}
                                                                    </Select>
                                                                ) : (
                                                                    <div
                                                                        style={{
                                                                            fontSize: '18px',
                                                                            fontWeight: '600',
                                                                            color: 'black',
                                                                            borderRadius: '10px',
                                                                            marginRight: '8px',
                                                                            flex: 1,
                                                                            lineHeight: '32px', // Ensure it aligns with Select height
                                                                            border: '1px solid #d9d9d9',
                                                                            padding: '4px 11px',
                                                                            backgroundColor: '#f5f5f5', // Match the style of antd Select when disabled
                                                                        }}
                                                                    >
                                                                        {custIndustry}
                                                                    </div>
                                                                )}
                                                                {editAllowed && (
                                                                    <>
                                                                        {editMode === 'industry' ? (
                                                                            <Button
                                                                                type="primary"
                                                                                icon={<SaveOutlined />}
                                                                                onClick={() => handleSaveClick('industry')}
                                                                            />
                                                                        ) : (
                                                                            <Button
                                                                                type="default"
                                                                                icon={<EditOutlined />}
                                                                                onClick={() => handleEditClick('industry')}
                                                                            />
                                                                        )}
                                                                    </>
                                                                )}
                                                            </div>
                                                        </Form.Item>
                                                        <Form.Item label={<strong className="customerSubHead">Total Business Experience</strong>}>
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                {editMode === 'totalExperience' ? (
                                                                    <Select
                                                                        style={{ fontSize: '18px', fontWeight: '600', color: 'black', borderRadius: '10px', marginRight: '8px', flex: 1 }}
                                                                        value={tempValue}
                                                                        onChange={(value) => setTempValue(value)}
                                                                    >
                                                                        {fieldMaster[0].total_experience.map((amount, index) => (
                                                                            <Option key={index} value={amount}>{amount}</Option>
                                                                        ))}
                                                                    </Select>
                                                                ) : (
                                                                    <div
                                                                        style={{
                                                                            fontSize: '18px',
                                                                            fontWeight: '600',
                                                                            color: 'black',
                                                                            borderRadius: '10px',
                                                                            marginRight: '8px',
                                                                            flex: 1,
                                                                            lineHeight: '32px', // Ensure it aligns with Select height
                                                                            border: '1px solid #d9d9d9',
                                                                            padding: '4px 11px',
                                                                            backgroundColor: '#f5f5f5', // Match the style of antd Select when disabled
                                                                        }}
                                                                    >
                                                                        {businessExperienceYears}
                                                                    </div>
                                                                )}
                                                                {editAllowed && (
                                                                    <>
                                                                        {editMode === 'totalExperience' ? (
                                                                            <Button
                                                                                type="primary"
                                                                                icon={<SaveOutlined />}
                                                                                onClick={() => handleSaveClick('totalExperience')}
                                                                            />
                                                                        ) : (
                                                                            <Button
                                                                                type="default"
                                                                                icon={<EditOutlined />}
                                                                                onClick={() => handleEditClick('totalExperience')}
                                                                            />
                                                                        )}
                                                                    </>
                                                                )}
                                                            </div>
                                                        </Form.Item>
                                                        <Form.Item label={<strong className="customerSubHead">Present Business Experience(Current Address)</strong>}>
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                {editMode === 'presentExperience' ? (
                                                                    <Select
                                                                        style={{ fontSize: '18px', fontWeight: '600', color: 'black', borderRadius: '10px', marginRight: '8px', flex: 1 }}
                                                                        value={tempValue}
                                                                        onChange={(value) => setTempValue(value)}
                                                                    >
                                                                        {fieldMaster[0].present_experience.map((amount, index) => (
                                                                            <Option key={index} value={amount}>{amount}</Option>
                                                                        ))}
                                                                    </Select>
                                                                ) : (
                                                                    <div
                                                                        style={{
                                                                            fontSize: '18px',
                                                                            fontWeight: '600',
                                                                            color: 'black',
                                                                            borderRadius: '10px',
                                                                            marginRight: '8px',
                                                                            flex: 1,
                                                                            lineHeight: '32px', // Ensure it aligns with Select height
                                                                            border: '1px solid #d9d9d9',
                                                                            padding: '4px 11px',
                                                                            backgroundColor: '#f5f5f5', // Match the style of antd Select when disabled
                                                                        }}
                                                                    >
                                                                        {businessPresentExperienceYears}
                                                                    </div>
                                                                )}
                                                                {editAllowed && (
                                                                    <>
                                                                        {editMode === 'presentExperience' ? (
                                                                            <Button
                                                                                type="primary"
                                                                                icon={<SaveOutlined />}
                                                                                onClick={() => handleSaveClick('presentExperience')}
                                                                            />
                                                                        ) : (
                                                                            <Button
                                                                                type="default"
                                                                                icon={<EditOutlined />}
                                                                                onClick={() => handleEditClick('presentExperience')}
                                                                            />
                                                                        )}
                                                                    </>
                                                                )}
                                                            </div>
                                                        </Form.Item>
                                                    </Form>
                                                </div>
                                            </TabPane>
                                            <TabPane tab={<strong style={{ fontSize: '20px' }}>Contact & KYC</strong>} key="3">
                                                <div style={{ height: 'calc(68vh - 200px)', overflowY: 'auto', paddingRight: '10px', scrollbarColor: "#aaa white", scrollbarWidth: "thin" }}>
                                                    <Form style={{ marginLeft: '10px', marginRight: '20px' }} form={form} layout="vertical">
                                                        <Form.Item label={<strong className="customerSubHead">Customer Contact</strong>}>
                                                            <Input style={{ fontSize: '18px', fontWeight: '600', color: 'black', borderRadius: '10px' }} value={custMobile} disabled />
                                                        </Form.Item>
                                                        {/* <Form.Item label={<strong style={{ fontSize: '20px', fontWeight: '700', color: 'grey' }}>Secondary Contact Number</strong>}>
                                                            <Input style={{ fontSize: '18px', fontWeight: '600', color: 'black', borderRadius: '10px' }} value={secondaryContact} disabled />
                                                        </Form.Item> */}
                                                        <Form.Item
                                                            label={
                                                                <strong className="customerSubHead">
                                                                    Secondary Contact Number
                                                                </strong>
                                                            }
                                                        >
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                {editMode === 'secondaryContact' ? (
                                                                    <Input
                                                                        style={{
                                                                            fontSize: '18px',
                                                                            fontWeight: '600',
                                                                            color: 'black',
                                                                            borderRadius: '10px',
                                                                            marginRight: '8px',
                                                                            flex: 1,
                                                                        }}
                                                                        value={tempValue}
                                                                        onChange={(e) => setTempValue(e.target.value)}
                                                                    />
                                                                ) : (
                                                                    <div
                                                                        style={{
                                                                            fontSize: '18px',
                                                                            fontWeight: '600',
                                                                            color: 'black',
                                                                            borderRadius: '10px',
                                                                            marginRight: '8px',
                                                                            flex: 1,
                                                                            lineHeight: '32px', // Ensure it aligns with Input height
                                                                            border: '1px solid #d9d9d9',
                                                                            padding: '4px 11px',
                                                                            backgroundColor: '#f5f5f5', // Match the style of antd Input when disabled
                                                                        }}
                                                                    >
                                                                        {secondaryContact}
                                                                    </div>
                                                                )}
                                                                {editAllowed && (
                                                                    <>
                                                                        {editMode === 'secondaryContact' ? (
                                                                            <Button
                                                                                type="primary"
                                                                                icon={<SaveOutlined />}
                                                                                onClick={() => handleSaveClick('secondaryContact')}
                                                                            />
                                                                        ) : (
                                                                            <Button
                                                                                type="default"
                                                                                icon={<EditOutlined />}
                                                                                onClick={() => handleEditClick('secondaryContact')}
                                                                            />
                                                                        )}
                                                                    </>
                                                                )}
                                                            </div>
                                                        </Form.Item>
                                                        <Form.Item label={<strong className="customerSubHead">PAN</strong>}>
                                                            <Input style={{ fontSize: '18px', fontWeight: '600', color: 'black', borderRadius: '10px' }} value={panNumber} disabled />
                                                        </Form.Item>
                                                        <Form.Item label={<strong className="customerSubHead">Aadhaar Card</strong>}>
                                                            <Input
                                                                style={{ fontSize: '18px', fontWeight: '600', color: 'maroon', borderRadius: '10px' }}
                                                                value={showFullAadhaar ? aadhaarNumber : maskedAadhaar}
                                                                disabled
                                                                suffix={
                                                                    <Button
                                                                        type="text"
                                                                        icon={showFullAadhaar ? <EyeInvisibleOutlined /> : <EyeOutlined />}
                                                                        onClick={toggleShowAadhaar}
                                                                    />
                                                                }
                                                            />
                                                        </Form.Item>
                                                        {/* <Form.Item label={<strong style={{ fontSize: '20px', fontWeight: '700', color: 'grey' }}>Email ID</strong>}>
                                                            <Input style={{ fontSize: '18px', fontWeight: '600', color: 'black', borderRadius: '10px' }} value={emailID} disabled />
                                                        </Form.Item> */}
                                                        <Form.Item
                                                            label={
                                                                <strong className="customerSubHead">
                                                                    Email ID
                                                                </strong>
                                                            }
                                                        >
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                {editMode === 'emailID' ? (
                                                                    <Input
                                                                        style={{
                                                                            fontSize: '18px',
                                                                            fontWeight: '600',
                                                                            color: 'black',
                                                                            borderRadius: '10px',
                                                                            marginRight: '8px',
                                                                            flex: 1,
                                                                        }}
                                                                        value={tempValue}
                                                                        onChange={(e) => setTempValue(e.target.value)}
                                                                    />
                                                                ) : (
                                                                    <div
                                                                        style={{
                                                                            fontSize: '18px',
                                                                            fontWeight: '600',
                                                                            color: 'black',
                                                                            borderRadius: '10px',
                                                                            marginRight: '8px',
                                                                            flex: 1,
                                                                            lineHeight: '32px', // Ensure it aligns with Input height
                                                                            border: '1px solid #d9d9d9',
                                                                            padding: '4px 11px',
                                                                            backgroundColor: '#f5f5f5', // Match the style of antd Input when disabled
                                                                        }}
                                                                    >
                                                                        {emailID}
                                                                    </div>
                                                                )}
                                                                {editAllowed && (
                                                                    <>
                                                                        {editMode === 'emailID' ? (
                                                                            <Button
                                                                                type="primary"
                                                                                icon={<SaveOutlined />}
                                                                                onClick={() => handleSaveClick('emailID')}
                                                                            />
                                                                        ) : (
                                                                            <Button
                                                                                type="default"
                                                                                icon={<EditOutlined />}
                                                                                onClick={() => handleEditClick('emailID')}
                                                                            />
                                                                        )}
                                                                    </>
                                                                )}
                                                            </div>
                                                        </Form.Item>
                                                    </Form>
                                                </div>
                                            </TabPane>
                                            <TabPane tab={<strong style={{ fontSize: '20px' }}>Address Details</strong>} key="4">
                                                <div style={{ height: 'calc(68vh - 200px)', overflowY: 'auto', paddingRight: '10px', scrollbarColor: "#aaa white", scrollbarWidth: "thin" }}>
                                                    <Form style={{ marginLeft: '10px', marginRight: '20px' }} form={form} layout="vertical">
                                                        {/* <Form.Item label={<strong style={{ fontSize: '20px', fontWeight: '700', color: 'grey' }}>Residence Ownership</strong>}>
                                                            <Input style={{ fontSize: '18px', fontWeight: '600', color: 'black', borderRadius: '10px' }} value={custResidenceOwnership} disabled />
                                                        </Form.Item> */}
                                                        <Form.Item label={<strong className="customerSubHead">Business Premise Ownership</strong>}>
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                {editMode === 'businessPremiseOwnership' ? (
                                                                    <Select
                                                                        style={{ fontSize: '18px', fontWeight: '600', color: 'black', borderRadius: '10px', marginRight: '8px', flex: 1 }}
                                                                        value={tempValue}
                                                                        onChange={(value) => setTempValue(value)}
                                                                    >
                                                                        {fieldMaster[0].property_ownership.map((amount, index) => (
                                                                            <Option key={index} value={amount}>{amount}</Option>
                                                                        ))}
                                                                    </Select>
                                                                ) : (
                                                                    <div
                                                                        style={{
                                                                            fontSize: '18px',
                                                                            fontWeight: '600',
                                                                            color: 'black',
                                                                            borderRadius: '10px',
                                                                            marginRight: '8px',
                                                                            flex: 1,
                                                                            lineHeight: '32px', // Ensure it aligns with Select height
                                                                            border: '1px solid #d9d9d9',
                                                                            padding: '4px 11px',
                                                                            backgroundColor: '#f5f5f5', // Match the style of antd Select when disabled
                                                                        }}
                                                                    >
                                                                        {custResidenceOwnership}
                                                                    </div>
                                                                )}
                                                                {editAllowed && (
                                                                    <>
                                                                        {editMode === 'businessPremiseOwnership' ? (
                                                                            <Button
                                                                                type="primary"
                                                                                icon={<SaveOutlined />}
                                                                                onClick={() => handleSaveClick('businessPremiseOwnership')}
                                                                            />
                                                                        ) : (
                                                                            <Button
                                                                                type="default"
                                                                                icon={<EditOutlined />}
                                                                                onClick={() => handleEditClick('businessPremiseOwnership')}
                                                                            />
                                                                        )}
                                                                    </>
                                                                )}
                                                            </div>
                                                        </Form.Item>
                                                        {/* <Form.Item label={<strong style={{ fontSize: '20px', fontWeight: '700', color: 'grey' }}>Residence Address</strong>}>
                                                            <Input.TextArea style={{ fontSize: '18px', fontWeight: '600', color: 'black', borderRadius: '10px' }} value={custResidenceAddress} disabled />
                                                        </Form.Item> */}
                                                        <Form.Item
                                                            label={
                                                                <strong className="customerSubHead">
                                                                    Residence Address
                                                                </strong>
                                                            }
                                                        >
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                {editMode === 'custResidenceAddress' ? (
                                                                    <Input
                                                                        style={{
                                                                            fontSize: '18px',
                                                                            fontWeight: '600',
                                                                            color: 'black',
                                                                            borderRadius: '10px',
                                                                            marginRight: '8px',
                                                                            flex: 1,
                                                                        }}
                                                                        value={tempValue}
                                                                        onChange={(e) => setTempValue(e.target.value)}
                                                                    />
                                                                ) : (
                                                                    <div
                                                                        style={{
                                                                            fontSize: '18px',
                                                                            fontWeight: '600',
                                                                            color: 'black',
                                                                            borderRadius: '10px',
                                                                            marginRight: '8px',
                                                                            flex: 1,
                                                                            lineHeight: '32px', // Ensure it aligns with Input height
                                                                            border: '1px solid #d9d9d9',
                                                                            padding: '4px 11px',
                                                                            backgroundColor: '#f5f5f5', // Match the style of antd Input when disabled
                                                                        }}
                                                                    >
                                                                        {custResidenceAddress}
                                                                    </div>
                                                                )}
                                                                {editAllowed && (
                                                                    <>
                                                                        {editMode === 'custResidenceAddress' ? (
                                                                            <Button
                                                                                type="primary"
                                                                                icon={<SaveOutlined />}
                                                                                onClick={() => handleSaveClick('custResidenceAddress')}
                                                                            />
                                                                        ) : (
                                                                            <Button
                                                                                type="default"
                                                                                icon={<EditOutlined />}
                                                                                onClick={() => handleEditClick('custResidenceAddress')}
                                                                            />
                                                                        )}
                                                                    </>
                                                                )}
                                                            </div>
                                                        </Form.Item>
                                                        {/* <Form.Item label={<strong style={{ fontSize: '20px', fontWeight: '700', color: 'grey' }}>Work Address</strong>}>
                                                            <Input.TextArea style={{ fontSize: '18px', fontWeight: '600', color: 'black', borderRadius: '10px' }} value={custWorkAddress} disabled />
                                                        </Form.Item> */}
                                                        <Form.Item
                                                            label={
                                                                <strong className="customerSubHead">
                                                                    Work Address
                                                                </strong>
                                                            }
                                                        >
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                {editMode === 'custWorkAddress' ? (
                                                                    <Input
                                                                        style={{
                                                                            fontSize: '18px',
                                                                            fontWeight: '600',
                                                                            color: 'black',
                                                                            borderRadius: '10px',
                                                                            marginRight: '8px',
                                                                            flex: 1,
                                                                        }}
                                                                        value={tempValue}
                                                                        onChange={(e) => setTempValue(e.target.value)}
                                                                    />
                                                                ) : (
                                                                    <div
                                                                        style={{
                                                                            fontSize: '18px',
                                                                            fontWeight: '600',
                                                                            color: 'black',
                                                                            borderRadius: '10px',
                                                                            marginRight: '8px',
                                                                            flex: 1,
                                                                            lineHeight: '32px', // Ensure it aligns with Input height
                                                                            border: '1px solid #d9d9d9',
                                                                            padding: '4px 11px',
                                                                            backgroundColor: '#f5f5f5', // Match the style of antd Input when disabled
                                                                        }}
                                                                    >
                                                                        {custWorkAddress}
                                                                    </div>
                                                                )}
                                                                {editAllowed && (
                                                                    <>
                                                                        {editMode === 'custWorkAddress' ? (
                                                                            <Button
                                                                                type="primary"
                                                                                icon={<SaveOutlined />}
                                                                                onClick={() => handleSaveClick('custWorkAddress')}
                                                                            />
                                                                        ) : (
                                                                            <Button
                                                                                type="default"
                                                                                icon={<EditOutlined />}
                                                                                onClick={() => handleEditClick('custWorkAddress')}
                                                                            />
                                                                        )}
                                                                    </>
                                                                )}
                                                            </div>
                                                        </Form.Item>
                                                    </Form>
                                                </div>
                                            </TabPane>
                                        </Tabs>
                                    </Card>
                                </animated.div>
                            </StyledCol>
                            <StyledCol style={{ overflowY: "hidden" }} span={8}>
                                <animated.div style={documentCardProps}>
                                    <Card style={{ boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)", marginRight: '5px', marginLeft: '5px', marginBottom: '10px', maxHeight: '40vh', overflowY: 'auto', borderRadius: '10px', scrollbarColor: "#aaa white", scrollbarWidth: "thin" }}>
                                        <Form
                                            className="customerForm"
                                            form={form}
                                            layout="vertical"
                                        >
                                            <p className="dispoPara"><strong>Customer Disposition</strong></p>
                                            <Divider style={{ borderColor: 'lightgrey' }} />
                                            <Form.Item>
                                                <Radio.Group
                                                    id="customerAvailability"
                                                    onChange={(e) => {
                                                        handleAvailabilityChange(e.target.value);
                                                    }}
                                                >
                                                    <Radio style={{ fontSize: '18px', color: 'grey', fontWeight: '500' }} value="Available">Available</Radio>
                                                    <Radio style={{ fontSize: '18px', color: 'grey', fontWeight: '500' }} value="Reschedule">Reschedule Call</Radio>
                                                    <Radio style={{ fontSize: '18px', color: 'grey', fontWeight: '500' }} value="Unavailable">Not Available</Radio>
                                                    <Radio style={{ fontSize: '18px', color: 'grey', fontWeight: '500' }} value="NotInterested">Not Interested</Radio>
                                                    <Radio style={{ fontSize: '18px', color: 'grey', fontWeight: '500' }} value="Drop">Drop Lead</Radio>
                                                    <Radio style={{ fontSize: '18px', color: 'grey', fontWeight: '500' }} value="BranchLead">Branch Lead</Radio>
                                                </Radio.Group>
                                            </Form.Item>
                                        </Form>
                                        {(customerAvailability === 'Unavailable' || customerAvailability === 'Drop' || customerAvailability === 'BranchLead' || customerAvailability === 'NotInterested') && (
                                            <StyledButton
                                                type="primary"
                                                onClick={handleNotAvailable}
                                                htmlType="submit"
                                                icon={<ArrowRightOutlined />}
                                            >
                                                Proceed
                                            </StyledButton>
                                        )}
                                    </Card>
                                </animated.div>
                                {customerAvailability == 'Available' && (
                                    <animated.div style={availableCardProps}>
                                        <Card
                                            style={{
                                                boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
                                                marginRight: "5px",
                                                marginLeft: "5px",
                                                marginBottom: "5px",

                                                overflowY: "auto",
                                                scrollbarColor: "#aaa white", scrollbarWidth: "thin",
                                                marginTop: "20px",
                                            }}
                                            className="resolveCard"
                                        >
                                            <Form style={{ marginLeft: '10px', marginRight: '20px', marginTop: '10px', marginBottom: '10px' }} form={form} layout="vertical">
                                                <p className="resolvePara"><strong>Resolve</strong></p>
                                                <Divider style={{ borderColor: 'lightgrey' }} />
                                                {customerAvailability === 'Available' && (
                                                    <>
                                                        <Collapse defaultActiveKey={[]} style={{ borderRadius: '10px', overflow: 'hidden' }}>
                                                            <Panel
                                                                header={<strong className="quesDrop">Questions</strong>}
                                                                key="1"
                                                                style={{ borderRadius: '10px', overflow: 'hidden' }}
                                                            >
                                                                <ul style={{ padding: 0, listStyle: 'none' }}>
                                                                    <li className="quesList">
                                                                        <label className="quesLabel">
                                                                            <input type="checkbox" name="question1" value="option1" /> Good morning/ Namaste, Sir/Maam
                                                                        </label>
                                                                    </li>
                                                                    <li className="quesList">
                                                                        <label className="quesLabel">
                                                                            <input type="checkbox" name="question1" value="option1" /> Introduction - Mai {user_name} Baat krra hun Zfirst Technologies se
                                                                        </label>
                                                                    </li>
                                                                    <li className="quesList">
                                                                        <label className="quesLabel">
                                                                            <input type="checkbox" name="question1" value="option1" /> Meri baat {selectedUserData.chat_user_profile.full_name} se ho rahi hai
                                                                        </label>
                                                                    </li>
                                                                    <li className="quesList">
                                                                        <label className="quesLabel">
                                                                            <input type="checkbox" name="question1" value="option1" /> Confirm Gender and address (pincode, city, district, state)
                                                                        </label>
                                                                    </li>
                                                                    <li className="quesList">
                                                                        <label className="quesLabel">
                                                                            <input type="checkbox" name="question1" value="option1" /> Sir aap kya kaam krte hai ?
                                                                        </label>
                                                                    </li>
                                                                    <li className="quesList">
                                                                        <label className="quesLabel">
                                                                            <input type="checkbox" name="question1" value="option1" /> If salaried (Salary cash aati hai ya bank)?
                                                                        </label>
                                                                    </li>
                                                                    <li className="quesList">
                                                                        <label className="quesLabel">
                                                                            <input type="checkbox" name="question2" value="option1" /> What is the loan amount required by you?
                                                                        </label>
                                                                    </li>
                                                                    <li className="quesList">
                                                                        <label className="quesLabel">
                                                                            <input type="checkbox" name="question3" value="option1" /> What is the tenure of loan repayment that you would want?
                                                                        </label>
                                                                    </li>
                                                                    <li className="quesList">
                                                                        <label className="quesLabel">
                                                                            <input type="checkbox" name="question4" value="option1" /> Are there any other queries?
                                                                        </label>
                                                                    </li>
                                                                    <li className="quesList">
                                                                        <label className="quesLabel">
                                                                            <input type="checkbox" name="question5" value="option1" /> Would you like to request a callback?
                                                                        </label>
                                                                    </li>
                                                                </ul>
                                                            </Panel>
                                                        </Collapse>
                                                        < Form.Item label={<p className="enterPara"><strong>Enter the actual loan amount:</strong></p>}
                                                            validateStatus={isLoanAmountValid ? 'success' : 'error'}
                                                            help={errorMessage}>
                                                            {/* <div> */}
                                                            <LoanAmountSelector
                                                                minLoanAmt={minLoanAmt}
                                                                maxLoanAmt={maxLoanAmt}
                                                                onLoanAmountChange={handleLoanAmountChange}
                                                            />
                                                            {/* </div> */}
                                                        </Form.Item>

                                                    </>
                                                )}
                                                {loanAmountValue && !errorMessage && isLoanAmountValid && (
                                                    < Form.Item label={<p style={{ fontSize: '20px', color: 'grey', fontWeight: '500' }}><strong>Enter the EMI that the customer is comfortable with:</strong></p>}
                                                        validateStatus={isAffordableEMIValid ? 'success' : 'error'}
                                                        help={affordableEMIErrorMessage}>
                                                        <div>
                                                            <LoanAmountSelector
                                                                minLoanAmt={affordableEMIMin}
                                                                maxLoanAmt={affordableEMIMax}
                                                                onLoanAmountChange={handleAffordableEMIChange}
                                                            />
                                                        </div>
                                                    </Form.Item>
                                                )}
                                                {affordableEMIValue && !affordableEMIErrorMessage && isAffordableEMIValid && (
                                                    < Form.Item label={<p style={{ fontSize: '20px', color: 'grey', fontWeight: '500' }}><strong>Enter the tenure that the customer is comfortable with:</strong></p>}
                                                        validateStatus={isTenureValid ? 'success' : 'error'}
                                                        help={tenureErrorMessage}>
                                                        <Row>
                                                            <Col span={20}>
                                                                {/* <StyledSlider */}
                                                                <Slider
                                                                    min={6}
                                                                    max={84}
                                                                    step={6}  // Set the slider step to 6
                                                                    marks={{
                                                                        6: '6',
                                                                        84: '84'
                                                                    }}
                                                                    value={tenureValue}
                                                                    onChange={handleTenureChange}
                                                                    style={{ fontSize: '18px', fontWeight: '600', color: 'black', borderRadius: '10px' }}
                                                                />
                                                            </Col>
                                                            <Col span={4}>
                                                                <StyledInputNumber
                                                                    min={6}
                                                                    max={84}
                                                                    step={6}  // Set the slider step to 6
                                                                    style={{
                                                                        margin: '0 16px',
                                                                        color: 'black'
                                                                    }}
                                                                    value={tenureValue}
                                                                    onChange={handleTenureChange}
                                                                    disabled='true'
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </Form.Item>
                                                )}
                                                {tenureValue && !tenureErrorMessage && (
                                                    <Form.Item label={<p style={{ fontSize: '20px', color: 'grey', fontWeight: '500' }}><strong>Select Recommended Product:</strong></p>}>
                                                        <Radio.Group
                                                            onChange={(e) => setRecommendedProduct(e.target.value)}
                                                            value={recommendedProduct}
                                                            style={{ marginBottom: 20 }}
                                                        >
                                                            <Radio style={{ fontSize: '18px', color: 'grey', fontWeight: '500' }} value="PL-SEC">Secured</Radio>
                                                            <Radio style={{ fontSize: '18px', color: 'grey', fontWeight: '500' }} value="PL-USEC">Unsecured</Radio>
                                                        </Radio.Group>
                                                    </Form.Item>
                                                )}
                                                {/* {recommendedProduct && custEmploymentCat.toLowerCase() == 'salaried' && (
                                                    <Form.Item label={<p style={{ fontSize: '20px', color: 'grey', fontWeight: '500' }}><strong>Salary mode:</strong></p>}>
                                                        <Radio.Group
                                                            onChange={(e) => setSalarySource(e.target.value)}
                                                            value={salarySource}
                                                            style={{ marginBottom: 20 }}
                                                        >
                                                            <Radio style={{ fontSize: '18px', color: 'grey', fontWeight: '500' }} value="Cash">Cash</Radio>
                                                            <Radio style={{ fontSize: '18px', color: 'grey', fontWeight: '500' }} value="Cheque">Cheque</Radio>
                                                            <Radio style={{ fontSize: '18px', color: 'grey', fontWeight: '500' }} value="Bank">Bank</Radio>
                                                        </Radio.Group>
                                                    </Form.Item>
                                                )} */}
                                                {recommendedProduct && !tenureErrorMessage && (
                                                    <Form.Item label={<p style={{ fontSize: '20px', color: 'grey', fontWeight: '500' }}><strong>Assign or drop lead:</strong></p>}>
                                                        <Radio.Group
                                                            id="leadDecision"
                                                            onChange={(e) => {
                                                                setLeadDecisionValue(e.target.value);

                                                                if (e.target.value === "Assign") {
                                                                    setShowBranchSelect(true);
                                                                }
                                                                else if (e.target.value === "Drop") {
                                                                    setBranchSelection(null)
                                                                    setBranchToAssign('')
                                                                }
                                                            }}
                                                        >
                                                            <Radio style={{ fontSize: '18px', color: 'grey', fontWeight: '500' }} value="Assign">Assign to branch</Radio>
                                                            <Radio style={{ fontSize: '18px', color: 'grey', fontWeight: '500' }} value="Drop">Drop Lead</Radio>
                                                        </Radio.Group>
                                                    </Form.Item>
                                                )}

                                                {leadDecisionValue === "Drop" && (
                                                    <Form.Item label={<p style={{ fontSize: '20px', color: 'grey', fontWeight: '500' }}><strong>Select Reason to Drop Lead:</strong></p>}>
                                                        <Select
                                                            style={{ fontSize: '18px', fontWeight: '600', color: 'black', borderRadius: '10px' }}
                                                            placeholder={<p style={{ fontSize: '18px', fontWeight: '600', borderRadius: '10px' }}>Select reason</p>}
                                                            onChange={(value) => setReasonToReject(value)}
                                                        >
                                                            {fieldMaster[0].reject_reason.map((reason, index) => (
                                                                <Option key={index} value={reason}><p style={{ fontSize: '18px', fontWeight: '600', color: 'black', borderRadius: '10px' }}>{reason}</p></Option>
                                                            ))}
                                                        </Select>
                                                        {/* )} */}
                                                    </Form.Item>
                                                )}

                                                {/* {customerAvailability === 'Available' && leadDecisionValue === 'Assign' && loanAmountValue && isLoanAmountValid && ( */}
                                                {customerAvailability === 'Available' && leadDecisionValue === 'Assign' && loanAmountValue && (
                                                    <Form.Item label={<p style={{ fontSize: '20px', color: 'grey', fontWeight: '500' }}><strong>Branch Selection:</strong></p>}>
                                                        <Radio.Group
                                                            id="branchSelect"
                                                            onChange={(e) => {
                                                                setBranchSelection(e.target.value);
                                                            }}
                                                        >
                                                            {/* {(nearestBranchData.length > 0 && */}
                                                            {(nearestBranchesArray.length > 0 &&
                                                                <Radio style={{ fontSize: '18px', color: 'grey', fontWeight: '500' }} value="Nearest">Check Nearest Branches</Radio>
                                                            )}
                                                            <Radio style={{ fontSize: '18px', color: 'grey', fontWeight: '500' }} value="All">See All Branches</Radio>
                                                        </Radio.Group>
                                                    </Form.Item>
                                                )}

                                                {/* {customerAvailability === 'Available' && leadDecisionValue === 'Assign' && loanAmountValue && branchSelection === 'Nearest' && (
                                                    <Form.Item
                                                        label={
                                                            <p style={{ fontSize: '18px', color: 'grey', fontWeight: '500' }}>
                                                                <strong>Select Nearest Branch:</strong>
                                                            </p>
                                                        }
                                                    >
                                                        <Select
                                                            showSearch
                                                            style={{
                                                                fontSize: '18px',
                                                                fontWeight: '600',
                                                                color: 'black',
                                                                borderRadius: '10px'
                                                            }}
                                                            placeholder={
                                                                <p
                                                                    style={{
                                                                        fontSize: '18px',
                                                                        fontWeight: '600',
                                                                        borderRadius: '10px'
                                                                    }}
                                                                >
                                                                    Select a branch
                                                                </p>
                                                            }
                                                            optionFilterProp="children"
                                                            onChange={(value) => setBranchToAssign(value)}
                                                            filterOption={(input, option) =>
                                                                option?.props?.children?.props?.children
                                                                    ?.toLowerCase()
                                                                    .indexOf(input.toLowerCase()) >= 0
                                                            }
                                                        >
                                                            {nearestBranchData.map((branch) => (
                                                                <Select.Option key={branch.BRANCH_ID + '-' + branch.BRANCH_NAME} value={branch.BRANCH_ID + '-' + branch.BRANCH_NAME}>
                                                                    <p
                                                                        style={{
                                                                            fontSize: '18px',
                                                                            fontWeight: '600',
                                                                            color: 'black',
                                                                            borderRadius: '10px'
                                                                        }}
                                                                    >
                                                                        {branch.BRANCH_ID + '-' + branch.BRANCH_NAME + ' (' + branch.distance + ' KM) '}
                                                                    </p>
                                                                </Select.Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                )} */}
                                                {customerAvailability === 'Available' && leadDecisionValue === 'Assign' && loanAmountValue && branchSelection === 'Nearest' && (
                                                    <>
                                                        {selectedBranch != '' && (
                                                            < Form.Item label={<p style={{ fontSize: '18px', color: 'grey', fontWeight: '500' }}><strong>Branch selected by customer:</strong></p>}>
                                                                <Input style={{ fontSize: '18px', fontWeight: '600', color: 'black', borderRadius: '10px' }} value={selectedBranch} disabled />
                                                            </Form.Item>
                                                        )}
                                                        <Form.Item
                                                            label={
                                                                <p style={{ fontSize: '18px', color: 'grey', fontWeight: '500' }}>
                                                                    <strong>Select Nearest Branch:</strong>
                                                                </p>
                                                            }
                                                        >
                                                            <Select
                                                                showSearch
                                                                style={{
                                                                    fontSize: '18px',
                                                                    fontWeight: '600',
                                                                    color: 'black',
                                                                    borderRadius: '10px'
                                                                }}
                                                                placeholder={
                                                                    <p
                                                                        style={{
                                                                            fontSize: '18px',
                                                                            fontWeight: '600',
                                                                            borderRadius: '10px'
                                                                        }}
                                                                    >
                                                                        Select a branch
                                                                    </p>
                                                                }
                                                                optionFilterProp="children"
                                                                onChange={(value) => setBranchToAssign(value)}
                                                                filterOption={(input, option) =>
                                                                    option?.props?.children?.props?.children
                                                                        ?.toLowerCase()
                                                                        .indexOf(input.toLowerCase()) >= 0
                                                                }
                                                            >
                                                                {nearestBranchesArray.map((branch) => (
                                                                    <Select.Option key={branch.BRANCH_ID + '-' + branch.BRANCH_NAME} value={branch.BRANCH_ID + '-' + branch.BRANCH_NAME}>
                                                                        <p
                                                                            style={{
                                                                                fontSize: '18px',
                                                                                fontWeight: '600',
                                                                                color: 'black',
                                                                                borderRadius: '10px'
                                                                            }}
                                                                        >
                                                                            {branch.BRANCH_ID + '-' + branch.BRANCH_NAME + ' (' + branch.distance + ' KM) '}
                                                                        </p>
                                                                    </Select.Option>
                                                                ))}
                                                            </Select>
                                                        </Form.Item>
                                                    </>
                                                )}

                                                {customerAvailability === 'Available' && leadDecisionValue === 'Assign' && loanAmountValue && branchSelection === 'All' && (
                                                    <Form.Item label={<p style={{ fontSize: '18px', color: 'grey', fontWeight: '500' }}><strong>Select branch:</strong></p>}>
                                                        <Select
                                                            showSearch
                                                            style={{ fontSize: '18px', fontWeight: '600', color: 'black', borderRadius: '10px' }}
                                                            placeholder={<p style={{ fontSize: '18px', fontWeight: '600', borderRadius: '10px' }}>Select a branch</p>}
                                                            optionFilterProp="children"
                                                            onChange={(value) => setBranchToAssign(value)}
                                                            filterOption={(input, option) =>
                                                                option?.props?.children?.props?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }
                                                        >
                                                            {Object.keys(branchData).map((branchKey) => (
                                                                <Select.Option key={branchKey} value={branchKey}>
                                                                    <p style={{ fontSize: '18px', fontWeight: '600', color: 'black', borderRadius: '10px' }}>{branchKey}</p>
                                                                </Select.Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                )}

                                                {customerAvailability === 'Available' && branchToAssign && leadDecisionValue === "Assign" && (
                                                    <div>
                                                        < Form.Item label={<p style={{ fontSize: '18px', color: 'grey', fontWeight: '500' }}><strong>Branch head employee code:</strong></p>}>
                                                            <Input style={{ fontSize: '18px', fontWeight: '600', color: 'black', borderRadius: '10px' }} value={branchData[branchToAssign]?.bmEmpCode || ''} disabled />
                                                        </Form.Item>
                                                        < Form.Item label={<p style={{ fontSize: '18px', color: 'grey', fontWeight: '500' }}><strong>Branch head name:</strong></p>}>
                                                            <Input style={{ fontSize: '18px', fontWeight: '600', color: 'black', borderRadius: '10px' }} value={branchData[branchToAssign]?.bmName || ''} disabled />
                                                        </Form.Item>
                                                    </div>
                                                )}

                                                <div style={{ display: "flex", justifyContent: "right" }}>
                                                    {/* {customerAvailability === 'Available' && customerFeedbackValue && customerBureauFeedbackValue && leadDecisionValue && ( */}
                                                    {customerAvailability === 'Available' && customerFeedbackValue && leadDecisionValue && (branchToAssign || reasonToReject) && custDOB && (
                                                        <StyledButton
                                                            type="primary"
                                                            onClick={handleProcessLead}
                                                            htmlType="submit"
                                                            icon={<ArrowRightOutlined />}
                                                            style={{ marginRight: "8px" }}
                                                        >
                                                            Process Lead
                                                        </StyledButton>
                                                    )}
                                                </div>

                                            </Form>
                                        </Card>
                                    </animated.div>
                                )}
                                {customerAvailability == 'Reschedule' && (
                                    <>
                                        <animated.div style={rescheduleCardProps}>
                                            <Card
                                                style={{
                                                    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
                                                    marginRight: '5px',
                                                    marginLeft: '5px',
                                                    height: '68vh',
                                                    overflowY: 'auto',
                                                    scrollbarColor: "#aaa white", scrollbarWidth: "thin",
                                                    marginTop: '125px',
                                                    alignItems: ''
                                                }}
                                            >
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoContainer
                                                        components={[
                                                            'StaticDateTimePicker',
                                                        ]}
                                                    >
                                                        <DemoItem label={<p style={{ fontSize: '25px', color: 'grey' }}><strong>Reschedule Callback</strong></p>}>
                                                            <Divider style={{ borderColor: 'lightgrey' }} />
                                                            <StaticDateTimePicker
                                                                orientation="landscape"
                                                                disablePast
                                                                value={selectedDateTime}
                                                                onChange={handleDateTimeChange}
                                                                onAccept={handleUpdateRescheduleDate}
                                                            />
                                                        </DemoItem>
                                                    </DemoContainer>
                                                </LocalizationProvider>
                                            </Card>
                                        </animated.div>
                                    </>
                                )}
                            </StyledCol>
                            <StyledCol style={{ overflowY: "hidden" }} span={8}>
                                <Card style={{ boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)", marginRight: '5px', marginLeft: '5px', marginBottom: '5px', height: '65vh', borderRadius: '10px' }}>
                                    <div>
                                        {showChat ? (
                                            <ChatCard
                                                onOpenChat={() => setShowChat(true)}
                                                selectedUserData={selectedUserData}
                                                onCloseChat={() => setShowChat(false)}
                                                imageUrls={imageUrls}
                                            />
                                        ) : (
                                            <DocumentsCard onOpenChat={() => setShowChat(true)} imageUrls={imageUrls} />
                                        )}
                                    </div>
                                </Card>
                                {/* {customerAvailability === 'Available' && ( */}
                                {/* {(customerAvailability === 'Available' || customerAvailability === 'Drop' || customerAvailability === 'BranchLead' || customerAvailability === 'NotInterested') && ( */}
                                < animated.div style={availableCardProps}>
                                    <Card
                                        style={{
                                            boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
                                            marginRight: "5px",
                                            marginLeft: "5px",
                                            marginBottom: "5px",

                                            borderRadius: "10px",
                                            overflowY: "auto",
                                            scrollbarColor: "#aaa white", scrollbarWidth: "thin",
                                            position: 'relative', // Ensure feedback card is relatively positioned
                                        }}
                                        className="feedbackCard"
                                    >
                                        <Form form={form} layout="vertical">
                                            < Form.Item label={<p style={{ fontSize: '20px', color: 'grey', fontWeight: '500', }}><strong>Feedback/Notes:</strong></p>}>
                                                <Input.TextArea
                                                    className='feedbackTextArea'
                                                    value={customerFeedbackValue}
                                                    rows={4}
                                              
                                                    onChange={handleCustomerFeedbackChange}
                                                />
                                            </Form.Item>

                                            {/* < Form.Item label={<p style={{ fontSize: '20px', color: 'grey', fontWeight: '500' }}><strong>Bureau Feedback/Notes:</strong></p>}>
                                                    <Input.TextArea
                                                        value={customerBureauFeedbackValue}
                                                        style={{ height: "100px", color: "#404040" }}
                                                        onChange={handleCustomerBureauFeedbackChange}
                                                    />
                                                </Form.Item> */}
                                        </Form>
                                    </Card>
                                </animated.div>
                                {/* )} */}
                                <animated.div style={{ ...floatButtonProps, position: 'fixed', bottom: '90px', right: '20px' }}>
                                    <Button
                                        type="primary"
                                        shape="circle"
                                        icon={<CommentOutlined style={{ fontSize: '36px' }} />}
                                        size="large"
                                        onClick={toggleChatWindow}
                                        style={{
                                            width: '70px', // Increased width
                                            height: '70px', // Increased height
                                            fontSize: '24px', // Increased font size
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
                                        }}
                                    />

                                </animated.div>
                                {isChatWindowVisible ? (
                                    // <Card
                                    //     style={{
                                    //         position: 'fixed',
                                    //         bottom: '20px',
                                    //         right: '20px',
                                    //         width: '500px', // Adjust the width as needed
                                    //         height: '1000px', // Adjust the height as needed
                                    //         borderRadius: '10px',
                                    //         boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                                    //         backgroundColor: '#f0f2f5', // Background color
                                    //         display: 'flex',
                                    //         flexDirection: 'column',
                                    //     }}
                                    // >
                                    //     <div style={{ position: 'relative', paddingBottom: '20px', borderBottom: '1px solid #d9d9d9' }}>
                                    <div className="chatCardWrapper">
                                        <Card
                                            className="chatCard"
                                        >
                                            <div
                                                style={{
                                                    position: "relative",
                                                    paddingBottom: "20px",
                                                    borderBottom: "1px solid #d9d9d9",
                                                }}
                                            >
                                                <h3 style={{ margin: 0 }}>User Chat</h3>
                                                <button
                                                    style={{
                                                        position: 'absolute',
                                                        top: '10px',
                                                        right: '10px',
                                                        backgroundColor: 'transparent',
                                                        border: 'none',
                                                        fontSize: '16px',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={handleCloseChat}
                                                >
                                                    &#10006; {/* Unicode character for "x" */}
                                                </button>
                                            </div>
                                            <div style={{ overflowY: 'scroll', flex: 1, padding: '10px', width: '500px', height: '900px', scrollbarColor: "#aaa white", scrollbarWidth: "thin" }}>
                                                {selectedUserChatData.chat.map((message, index) => (
                                                    <div style={{ marginLeft: '20px', marginTop: '10px' }} key={index}>
                                                        {/* {user.data.map((message, index) => ( */}
                                                        <div
                                                            key={index}
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                alignItems: message.type === '-1' ? 'flex-end' : 'flex-start',
                                                                marginBottom: 10,
                                                            }}
                                                        >
                                                            <div style={{ marginBottom: 5 }}>
                                                                {editableMessageIndex === index ? (
                                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                        <Input
                                                                            style={{ flex: 1, marginRight: 5 }}
                                                                            value={editableInputValue}
                                                                            onChange={handleInputChange}
                                                                            onBlur={() => setEditableMessageIndex(-1)}
                                                                            onPressEnter={handleConfirmEdit}
                                                                        />
                                                                        <Button
                                                                            type="primary"
                                                                            icon={<CheckCircleOutlined />}
                                                                            style={{ marginRight: 5 }}
                                                                            onClick={handleConfirmEdit}
                                                                        />
                                                                        <Button
                                                                            type="default"
                                                                            icon={<CloseCircleOutlined />}
                                                                            onClick={handleCancelEdit}
                                                                        />
                                                                    </div>
                                                                ) : (
                                                                    <div
                                                                        style={{
                                                                            backgroundColor: message.type === '-1' ? '#5AB2FF' : '#ffffff',
                                                                            padding: '10px 15px',
                                                                            borderRadius: '15px',
                                                                            color: message.type === '-1' ? '#ffffff' : '#000000',
                                                                            maxWidth: '80%',
                                                                            wordWrap: 'break-word',
                                                                            textAlign: 'left',
                                                                            marginLeft: '20px',
                                                                            marginRight: '20px',
                                                                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Box shadow for message bubbles
                                                                            fontSize: '18px', // Adjust font size as needed
                                                                            fontFamily: 'Baloo, sans-serif', // Change font family
                                                                        }}
                                                                    >
                                                                        {message.user_ans}
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div
                                                                style={{
                                                                    fontSize: 12,
                                                                    color: '#888888',
                                                                    textAlign: 'right',
                                                                    marginLeft: '20px',
                                                                    marginRight: '20px',
                                                                }}
                                                            >
                                                                {moment(parseInt(message.timestamp)).format('hh:mm A')}
                                                            </div>
                                                        </div>
                                                        {/* ))} */}
                                                    </div>
                                                ))}
                                            </div>
                                        </Card>
                                    </div>
                                ) : null}
                            </StyledCol>
                        </StyledRow>
                    </animated.div>
                    <Modal
                        visible={bankingModalVisible}
                        title={<p style={{ fontSize: '20px', fontWeight: '700', color: 'grey' }}>Verify Banking Details</p>}
                        footer={null}

                        // loading={loading}
                        // open={open}
                        onCancel={() => setbankingModalVisible(false)}
                        width={1200} // Adjust the width as needed
                        style={{ borderRadius: '10px', textAlign: 'center' }}
                        centered
                    >
                        <Divider style={{ borderColor: 'lightgrey' }} />
                        <Row>
                            <Col span={12}>
                                <Form style={{ marginLeft: '10px', marginRight: '20px' }} form={form} layout="vertical">
                                    <Form.Item label={<strong className="customerSubHead">Account Number</strong>} help={accountErrorMessage}>
                                        {/* <Input style={{ fontSize: '18px', fontWeight: '600', color: 'black', borderRadius: '10px' }} value={custAccountNumber} onChange={(e) => setCustAccountNumber(e.target.value)} /> */}
                                        <Input style={{ fontSize: '18px', fontWeight: '600', color: 'black', borderRadius: '10px' }} value={custAccountNumber} onChange={handleAccountNumberChange} />
                                    </Form.Item>
                                    <Form.Item label={<strong className="customerSubHead">IFSC Code</strong>}>
                                        {/* <Input style={{ fontSize: '18px', fontWeight: '600', color: 'black', borderRadius: '10px' }} value={custIFSC} onChange={(e) => setCustIFSC(e.target.value)} /> */}
                                        <Input style={{ fontSize: '18px', fontWeight: '600', color: 'black', borderRadius: '10px' }} value={custIFSC} onChange={handleIFSCChange} />
                                    </Form.Item>
                                    {custAccountNumber && custIFSC && (
                                        <Button
                                            type="primary"
                                            onClick={handleInitiateBankVerification}
                                            htmlType="submit"

                                        >
                                            Initiate Bank Verification
                                        </Button>
                                    )}
                                </Form>
                            </Col>
                            <Col span={12}>
                                {passbookChequeImage ? (
                                    <img
                                        src={passbookChequeImage}
                                        alt={passbookChequeImage}
                                        style={{ width: '100%', height: 'auto', marginBottom: '10px', borderRadius: '10px' }}
                                    />
                                ) : (
                                    <p style={{ textAlign: 'center', color: 'grey', fontSize: '16px' }}>Passbook/Cheque image not available.</p>
                                )}
                            </Col>
                        </Row>
                        {bankVerificationSuccess && (
                            <Alert
                                style={{ marginTop: '20px' }}
                                message="Success"
                                description="Your bank account has been verified successfully."
                                type="success"
                                showIcon
                            />
                        )}
                        {bankVerificationError && (
                            <Alert
                                style={{ marginTop: '20px' }}
                                message="Bank verification failed. Please find the reason below -"
                                description={bankVerificationErrorMsg}
                                type="warning"
                                showIcon
                            />
                        )}
                        {bankVerificationAPIFailure && (
                            <Alert
                                style={{ marginTop: '20px' }}
                                message="Error"
                                description="Bank verification API failure."
                                type="error"
                                showIcon
                            />
                        )}
                    </Modal>
                    <Drawer
                        style={{ transition: 'left 0.3s ease', }}
                        title={<p style={{ fontSize: '25px', color: 'grey' }}>CRIF Bureau</p>}
                        placement="left"
                        closable={true}
                        onClose={() => setDrawerVisible(false)}
                        open={drawerVisible}
                        // // height={1200} // Adjust height as needed
                        // height='100%' // Adjust height as needed
                        // // width={1400}
                        width='100vw'
                        // style={{ margin: "auto" }}
                        bodyStyle={{ padding: 24 }}
                    >
                        <div
                            style={{
                                width: '100%',


                            }}
                            dangerouslySetInnerHTML={{ __html: drawerContent }}
                        />
                    </Drawer>
                </>
            )

            }
        </>
    );
};

export default UserTab;
