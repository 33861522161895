import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
    Modal,
    Typography,
    List,
    Select,
    Button,
    Popconfirm,
    Popover,
    Drawer,
    Space,
    Checkbox,
    Input,
    Col,
    notification,
    message,
    Row
} from "antd";
import "../../../style/styleComp"
import { fetchPDDMaster } from "../../../../../redux/slice/getPDDMaster";
import { fetchPDD } from "../../../../../redux/slice/getPDDData";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../../../../context/AuthContext";
import { MyStyledTable, StyledTable } from "../../../style/styleComp";
import {

    CheckCircleFilled,
    ExclamationCircleOutlined,
    InfoCircleOutlined

} from "@ant-design/icons";

import { RxCrossCircled } from "react-icons/rx";

// import { IoMdArrowDropupCircle } from "react-icons/io";
import { IoIosArrowDropup } from "react-icons/io";

// import { IoMdArrowDropdownCircle } from "react-icons/io";
import { IoIosArrowDropdown } from "react-icons/io";
import { markAppIdRecieve, postStampNo, addAdditionalDocuments } from '../../../../../redux/slice/pddAddMissingDocs'
import truckAnimation from "../../../lotties/loaderTruck.json";
import Lottie from "lottie-react";
const { Text, Title, View } = Typography;
const { Option } = Select;
const { TextArea } = Input;

const AfterReceivedHo = ({
    visible,
    onClose,
    data,
    all_app_id,
    missing_docs,
    onMissingDocs,
    statusDetails,
    myLoading
}) => {
    if (!data || !all_app_id) {
        return null;
    }

    // console.log('data=', data)
    // console.log('all_app_id', all_app_id)
    // console.log("missingDocs", missing_docs)

    const dispatch = useDispatch();
    const { authState } = useAuth();
    const [loading, setLoading] = useState(true);
    const [selectedValues, setSelectedValues] = useState({});
    const [selectedRows, setSelectedRows] = useState({}); // State to track selected rows
    const [isModified, setIsModified] = useState(false); // Tracks modification state
    const [openDialog, setOpenDialog] = useState(false);
    const [appStatus, setAppStatus] = useState({});
    const [receivedAppIds, setReceivedAppIds] = useState([]);
    const [notReceivedAppIds, setNotReceivedAppIds] = useState([]);
    const [applicationsData, setApplicationsData] = useState([]);
    const [appIdStatus, setAppIdStatus] = useState('');
    const [stampNos, setStampNos] = useState({});
    const [isInputVisible, setIsInputVisible] = useState(false);
    const [documentName, setDocumentName] = useState("");
    const [stampNoChanged, setStampNoChanged] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    const [currentRecord, setCurrentRecord] = useState(null);


    const jwtBearer = authState.token;

    const chatData = useSelector((state) => state.fetchPDD?.data?.message || []);
    // console.log("chatData", chatData)

    const masterData = useSelector(
        (state) => state.fetchPDDMaster?.data?.message || []
    );



    useEffect(() => {

        const initialAppIds = data.application_ids;
        // console.log("initialAppIds: ", initialAppIds)
        setReceivedAppIds(initialAppIds);
        setNotReceivedAppIds([]);

        // Prepare applicant details and application data
        const applicants = initialAppIds.map((appId) => ({
            key: appId,
            APP_ID: appId,
            ...data.applicant_details[appId],
        }));

        setApplicationsData(applicants);

        const initialAppStatus = initialAppIds.reduce((acc, appId) => {
            acc[appId] = 'Yes';
            return acc;
        }, {});
        setAppStatus(initialAppStatus);
        setAppIdStatus(data.app_id_status);
    }, [data]);


    const fetchData = () => {
        setLoading(true);
        dispatch(fetchPDDMaster({ jwtBearer }))
            .then(() => setLoading(false))
            .catch((error) => {
                console.error("Error fetching PDD master data:", error);
                setLoading(false);
            });
    };
    const fetchPDDData = async () => {
        await dispatch(fetchPDD({ jwtBearer, movement: "HO" }));

    };

    useEffect(() => {
        fetchData();
        fetchPDDData()
    }, [dispatch]);


    const checklistPLUSEC = useMemo(() => {
        const usecData = masterData.find((item) => item.field === "usec_docs");
        return usecData ? usecData.value : [];
    }, [masterData]);

    const checklistPLSEC = useMemo(() => {
        const secData = masterData.find((item) => item.field === "sec_docs");
        return secData ? secData.value : [];
    }, [masterData]);

    const matchedAppIDs = useMemo(() => {
        return all_app_id.filter((app) =>
            data.application_ids.includes(app.APP_ID)
        );
    }, [all_app_id, data.application_ids]);

    // console.log("matchedAppIds", matchedAppIDs)

    useEffect(() => {
        const initialSelectedValues = {};
        const initialSelectedRows = {};

        matchedAppIDs.forEach((app) => {
            const checklist =
                app.PRODUCT === "PL-USEC" ? checklistPLUSEC : checklistPLSEC;
            initialSelectedValues[app.APP_ID] = {};

            // console.log("checkList", checklist)


            checklist.forEach((item) => {
                // console.log("missing_docs", missing_docs)
                const missingDocsForApp = missing_docs[app.APP_ID] || {};
                // console.log("missingDocsForApp", missing_docs)
                if (missingDocsForApp.verified_docs?.includes(item)) {
                    initialSelectedValues[app.APP_ID][item] = "Verified";
                } else if (missingDocsForApp.missing_documents?.includes(item)) {
                    initialSelectedValues[app.APP_ID][item] = "Missing";
                } else if (missingDocsForApp.not_applicable_docs?.includes(item)) {
                    initialSelectedValues[app.APP_ID][item] = "N/A";
                } else if (missingDocsForApp.damaged_docs?.includes(item)) {
                    initialSelectedValues[app.APP_ID][item] = "Damaged";

                } else if (missingDocsForApp.other && missingDocsForApp.other.some(doc => doc.document === item)) {
                    // console.log("in other");
                    initialSelectedValues[app.APP_ID][item] = "Other";
                } else {
                    initialSelectedValues[app.APP_ID][item] = "Verified";
                }
            });

            // Initialize all rows as unselected (gray tick)
            initialSelectedRows[app.APP_ID] = false;
        });

        // console.log("initialSelectedValues", initialSelectedValues)
        // console.log("initialSelectedRows", initialSelectedRows)


        setSelectedValues((prev) => ({ ...prev, ...initialSelectedValues }));

        // console.log("selectedValues", selectedValues);
        setSelectedRows(initialSelectedRows);
    }, [matchedAppIDs, checklistPLUSEC, checklistPLSEC, missing_docs]);



    const getChecklist = useCallback(
        (product) => {
            return product === "PL-USEC" ? checklistPLUSEC : checklistPLSEC;
        },
        [checklistPLUSEC, checklistPLSEC]
    );

    // console.log('Checklist - ', checklistPLUSEC, checklistPLSEC)

    // const handleDropdownChange = (appId, item, value) => {
    //     // console.log("appId: " + appId)
    //     // console.log("item: " + item)
    //     // console.log("value: " + value)
    //     setSelectedValues((prevState) => {
    //         const updatedValues = {
    //             ...prevState,
    //             [appId]: {
    //                 ...prevState[appId],
    //                 [item]: value,
    //             },
    //         };

    //         // console.log('Updated Value - ', updatedValues)

    //         // If any document other than "Declaration for damaged docs" is marked as "Damaged"
    //         if (value === "Damaged" && item !== "Declaration for damaged docs") {
    //             // Automatically set "Declaration for damaged docs" to "Missing" if not already missing
    //             updatedValues[appId]["Declaration for damaged docs"] = "Missing";
    //         }
    //         // if (value === "Other" && item !== "Declaration for damaged docs") {
    //         //     // Automatically set "Declaration for damaged docs" to "Missing" if not already missing
    //         //     updatedValues[appId]["Declaration for damaged docs"] = "Verified";
    //         // }
    //         // if (value === "N/A" && item !== "Declaration for damaged docs") {
    //         //     // Automatically set "Declaration for damaged docs" to "Missing" if not already missing
    //         //     updatedValues[appId]["Declaration for damaged docs"] = "Verified";
    //         // }

    //         // if (value === "Verified" && item !== "Declaration for damaged docs") {
    //         //     // Automatically set "Declaration for damaged docs" to "Missing" if not already missing
    //         //     updatedValues[appId]["Declaration for damaged docs"] = "Verified";
    //         // }

    //         // if (value === "Other" && !updatedValues[appId][`${item}_other`]) {
    //         //     updatedValues[appId][`${item}_other`] = "";  
    //         // }

    //         return updatedValues;
    //     });
    //     setIsModified(true);
    // };

    const handleDropdownChange = (appId, item, value) => {
        setSelectedValues((prevState) => {
            const updatedValues = {
                ...prevState,
                [appId]: {
                    ...prevState[appId],
                    [item]: value,
                },
            };
            // Automatically set "Declaration for damaged docs" to "Missing" if necessary
            if (value === "Damaged" && item !== "Declaration for damaged docs") {
                updatedValues[appId]["Declaration for damaged docs"] = "Missing";
            }
            return updatedValues;
        });
        setIsModified(true);
    };


    const handleOtherInputChange = (appId, item, value) => {
        setSelectedValues((prevState) => {
            const updatedValues = {
                ...prevState,
                [appId]: {
                    ...prevState[appId],
                    [`${item}_other`]: value,
                },
            };

            return updatedValues;
        });
    };

    const handleRowSelect = (record, appId) => {
        setSelectedRows((prevState) => {
            const isSelected = !prevState[appId];

            if (isSelected) {
                // Update all missing documents to "Verified"
                setSelectedValues((prevValues) => {
                    const updatedValues = { ...prevValues };
                    const appValues = updatedValues[appId];

                    Object.keys(appValues).forEach((doc) => {
                        if (appValues[doc] === "Missing") {
                            appValues[doc] = "Verified";
                        }
                    });

                    return updatedValues;
                });
            }

            setSelectedValues(prevState => ({
                ...prevState,
                [record.application_id]: {
                    ...prevState[record.application_id],
                    [documentName]: "Verified", // Set the corresponding document's dropdown to "Verified"
                },
            }));

            return {
                ...prevState,
                [appId]: isSelected, // Toggle selection
            };
        });
        setIsModified(true);
    };




    useEffect(() => {
        // Check if any rows are selected or if any value in selectedValues is modified
        const anySelected = Object.values(selectedRows).some((isSelected) => isSelected);
        const anyModified = Object.keys(selectedValues).some((appId) => {
            const values = selectedValues[appId];
            return Object.values(values || {}).some((value) => value !== "Verified");
        });

        // Set `isModified` based on selections or modifications
        // setIsModified(anySelected || anyModified);
    }, [selectedValues, selectedRows]);

    const handleSubmitMissingDocs = () => {
        console.log("selectedValues", selectedValues);
        setLoading(true);

        const missingDocsJSON = Object.keys(selectedValues)
            .map((appId) => {
                if (!appId || !selectedValues[appId]) return null;

                // Only include rows with changes other than 'Verified'
                if (!selectedRows[appId]) {
                    const hasNonVerified = Object.values(selectedValues[appId]).some(
                        (value) => value !== "Verified"
                    );
                    if (!hasNonVerified) return null;
                }

                const missingDocsForApp = {
                    application_id: appId,
                    missing_documents: [],
                    not_applicable_docs: [],
                    damaged_docs: [],
                    verified_docs: [],
                    other_docs: [],
                };

                // Process each document entry and its status
                Object.keys(selectedValues[appId]).forEach((doc) => {
                    const selectedValue = selectedValues[appId][doc];
                    if (selectedValue === "Verified") {
                        missingDocsForApp.verified_docs.push(doc);
                    } else if (selectedValue === "N/A") {
                        missingDocsForApp.not_applicable_docs.push(doc);
                    } else if (selectedValue === "Damaged") {
                        missingDocsForApp.damaged_docs.push(doc);
                    } else if (selectedValue === "Missing") {
                        missingDocsForApp.missing_documents.push(doc);
                    } else if (selectedValue === "Other") {
                        const otherValue = selectedValues[appId][`${doc}_other`];
                        if (otherValue) {
                            missingDocsForApp.other_docs.push({ document: doc, Remark: otherValue });
                        }
                    }
                });

                // Include only if there are changes in any category
                if (
                    missingDocsForApp.verified_docs.length > 0 ||
                    missingDocsForApp.missing_documents.length > 0 ||
                    missingDocsForApp.not_applicable_docs.length > 0 ||
                    missingDocsForApp.damaged_docs.length > 0 ||
                    missingDocsForApp.other_docs.length > 0
                ) {
                    return missingDocsForApp;
                }
                return null;
            })
            .filter(Boolean);

        const requestBody = {
            tracking_number: data.tracking_number,
            branch: data.branch,
            docs: missingDocsJSON,
        };

        console.log("requestBody", requestBody);

        // Execute the callback with the updated data
        if (onMissingDocs) {
            onMissingDocs(requestBody);
        }

        setLoading(false);
    };

    // console.log("selected values", selectedValues);



    const handleSelectChange = (appId, value) => {
        // Update the app status for the selected appId
        setAppStatus((prevState) => ({
            ...prevState,
            [appId]: value,
        }));

        // Update the relevant lists based on the selection
        if (value === 'Yes') {
            // If marked "Yes", move the appId to received (if not already)
            if (!receivedAppIds.includes(appId)) {
                setReceivedAppIds((prev) => [...prev, appId]);
            }
            // Remove from not received
            setNotReceivedAppIds((prev) => prev.filter((id) => id !== appId));
        } else {
            // If marked "No", move the appId to not received
            setNotReceivedAppIds((prev) => [...prev, appId]);
            // Remove from received
            setReceivedAppIds((prev) => prev.filter((id) => id !== appId));
        }
    };

    const [stampNo, setStampNo] = useState("");

    const handleStampNoSubmit = async (application_id, tracking_number) => {

        const stampNo = stampNos[application_id];

        // Validate the stamp number (alphanumeric check)
        const pattern = /^[a-zA-Z0-9]+$/;
        if (!stampNo) {
            notification.warning({
                message: 'Warning',
                description: 'Please enter a stamp number before submitting.',
                placement: 'bottomRight',
            });
            return;
        }

        if (!pattern.test(stampNo)) {
            notification.error({
                message: 'Error',
                description: 'Stamp number must be alphanumeric!',
                placement: 'bottomRight',
            });
            return;
        }

        if (stampNo) {
            const requestBody = {
                application_id,
                stamp_no: stampNo,
                tracking_number: tracking_number
            };




            console.log("Request Body:", requestBody);

            try {
                // Make the API call to post or update the stamp_no
                const response = await dispatch(postStampNo({ requestBody, jwtBearer }));

                // console.log("Response:", response);

                if (response?.type === 'postStampNo/fulfilled') {
                    notification.success({
                        message: 'Success',
                        description: response.payload.message,
                        placement: 'bottomRight'
                    });

                    setDataSource(prevData =>
                        prevData.map(record =>
                            record.application_id === application_id
                                ? { ...record, stamp_no: stampNo, tracking_number: tracking_number }
                                : record
                        )
                    )

                    setStampNoChanged(false);
                    // fetchPDDData()

                } else {
                    // console.error('Failed to update stamp number:', response);
                    notification.error({
                        message: 'Error',
                        description: 'Failed to update stamp number. Please try again.',
                        placement: 'bottomRight'
                    });
                }
            } catch (error) {
                // console.error('Error updating stamp number:', error);
                notification.error({
                    message: 'Error',
                    description: 'An error occurred while updating the stamp number. Please check your network connection and try again.',
                    placement: 'bottomRight'
                });
            }
        } else {
            // console.warn('Stamp number is required');
            notification.warning({
                message: 'Warning',
                description: 'Please enter a stamp number before submitting.',
                placement: 'bottomRight'
            });
        }
    };


    const handleStampNoChange = (applicationId, value) => {
        setStampNoChanged(true);
        setStampNos(prevState => ({
            ...prevState,
            [applicationId]: value,
        }));
    };



    // Function to handle the API call
    const handleMarkAsReceive = async () => {
        try {
            const requestBody = {
                received_ids: receivedAppIds,
                not_received_ids: notReceivedAppIds,
                type: 'forward'
            };

            // console.log("requestBody", requestBody);

            const response = await dispatch(markAppIdRecieve({ jwtBearer, requestBody }));
            console.log("Response:", response);

            if (response?.payload?.message) {
                notification.success({
                    message: 'Success',
                    description: response.payload.message,
                    placement: 'bottomRight'
                });

                fetchPDDData()

                onClose()


            }
        } catch (error) {
            // console.error("API Error:", error);
            notification.error({
                message: 'Submission Failed',
                description: 'An error occurred while updating application statuses. Please try again.',
                placement: 'bottomRight'
            });
        }
    };


    const columns = [
        ...(appIdStatus === '1' ? [
            {
                title: "Received",
                key: "received",
                render: (text, record) => (
                    <>
                        <Select
                            defaultValue="Yes"
                            style={{ width: 80 }}
                            onChange={(value) => handleSelectChange(record.application_id, value)}
                        >
                            <Select.Option value="Yes">Yes</Select.Option>
                            <Select.Option value="No">No</Select.Option>
                        </Select>

                    </>
                ),
            }
        ] : []),


        ...(appIdStatus !== '1' ? [
            {
                title: "Verification",
                dataIndex: "verification",
                key: "verification",
                render: (text, record) => {
                    const verification = record.verification;
                    const stampNo = record.stamp_no;

                    if (!stampNo) {
                        return (
                            <ExclamationCircleOutlined
                                style={{ color: "gray", fontSize: "28px" }}
                            />
                        );
                    }

                    // Check if any document for this record is marked as "Missing"
                    const hasMissingDocs = Object.values(selectedValues[record.application_id] || {}).includes("Missing");
                    const hasOtherDocs = Object.values(selectedValues[record.application_id] || {}).includes("Other");
                    // console.log("hasMissingDocs: " , hasMissingDocs);
                    // console.log("hasOtherDocs " , hasOtherDocs);

                    // Display icons based on status, whether the row is selected, and if any document is "Missing"
                    if (selectedRows[record.application_id]) {
                        return (
                            <CheckCircleFilled
                                style={{ color: "green", fontSize: "28px" }}
                                onClick={() => handleRowSelect(record, record.application_id)}
                            />
                        );
                    }

                    if (hasMissingDocs) {
                        return (
                            <ExclamationCircleOutlined
                                style={{ color: "red", fontSize: "28px" }}
                                onClick={() => handleRowSelect(record, record.application_id)}
                            />
                        );
                    }
                    if (hasOtherDocs) {
                        return (
                            <ExclamationCircleOutlined
                                style={{ color: "red", fontSize: "28px" }}
                                onClick={() => handleRowSelect(record, record.application_id)}
                            />
                        );
                    }

                    if (verification === "5") {
                        return (
                            <CheckCircleFilled
                                style={{ color: "green", fontSize: "28px" }}
                                onClick={() => handleRowSelect(record, record.application_id)}
                            />
                        );
                    } else if (verification === "4") {
                        return (
                            <RxCrossCircled
                                style={{ color: "grey", fontSize: "28px" }}
                                onClick={() => handleRowSelect(record, record.application_id)}
                            />
                        );
                    } else if (verification === "8") {
                        return (
                            <ExclamationCircleOutlined
                                style={{ color: "red", fontSize: "28px" }}
                                onClick={() => handleRowSelect(record, record.application_id)}
                            />
                        );
                    } else {
                        return (
                            <ExclamationCircleOutlined
                                style={{ color: "orange", fontSize: "28px" }}
                                onClick={() => handleRowSelect(record, record.application_id)}
                            />
                        );
                    }
                },
            }

        ] : []),

        {
            title: "Application Id",
            dataIndex: "application_id",
            key: "application_id",
        },

        {
            title: "OXYZO ID",
            key: "partner_loan_id",
            render: (text, record) => record.applicant_details?.partner_loan_id,
        },

        {
            title: "NAME",
            key: "applicant_name",
            render: (text, record) => record.applicant_details?.applicant_name,
        },

        {
            title: "PRODUCT",
            key: "product",
            render: (text, record) => record.applicant_details?.product,
        },
        ...(appIdStatus !== '1' ? [
            {
                title: "Manual Stamp No",
                dataIndex: "stamp_no",
                key: "stamp_no",
                render: (text, record) => {

                    if (record.verification === "5") {
                        // console.log("record",record)
                        return (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {/* Display disabled input when verification is '5' */}
                                <Input
                                    value={record.stamp_no || ""}
                                    disabled
                                    style={{ width: 150, marginRight: 8, backgroundColor: "white", boxShadow: '20px solid black', color: 'black' }}
                                />
                            </div>
                        );
                    }

                    // Render default input and button when verification is not '5'
                    return (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Input
                                defaultValue={record.stamp_no || ""}
                                onChange={(e) => handleStampNoChange(record.application_id, e.target.value)}
                                style={{ width: 150, marginRight: 8 }}
                            />
                            <Button
                                type="primary"
                                onClick={() => handleStampNoSubmit(record.application_id, record.tracking_number)}
                            >
                                {record.stamp_no ? 'Update' : 'Submit'}
                            </Button>
                        </div>
                    );
                }
                ,
            }] : []),

    ];
    const showModal = (record) => {
        setSelectedRecord(record);
        setIsModalVisible(true);
    };


    const handleOk = async () => {
        if (selectedRecord) {
            try {
                const requestBody = {
                    application_id: selectedRecord.application_id,

                }
                // console.log("requestBody", requestBody)
                const response = await dispatch(deleteNotReceivedId({
                    requestBody,
                    jwtBearer
                }));

                // console.log("response", response)


                if (response?.type === "deleteNotReceivedId/fulfilled") {

                    notification.success({
                        message: "Success",
                        description: `Application ID ${selectedRecord.application_id} deleted successfully.`,
                        placement: 'bottomRight'
                    });
                    fetchPDDData()
                } else {

                    notification.error({
                        message: "Error",
                        description: `Failed to delete Application ID ${selectedRecord.application_id}.`,
                        placement: 'bottomRight'
                    });
                }
            } catch (error) {

                // console.error("Error deleting application ID:", error);

                notification.error({
                    message: "Error",
                    description: `An unexpected error occurred while deleting Application ID ${selectedRecord.application_id}.`,
                    placement: 'bottomRight'
                });
            }
        }
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };



    const notReceivedColumns = [
        {
            title: "Application Id",
            dataIndex: "application_id",
            key: "application_id",
        },

        {
            title: "NAME",
            key: "applicant_name",
            render: (text, record) =>
                record.applicant_details?.applicant_name,
        },
        {
            title: "OXYZO ID",
            key: "partner_loan_id",
            render: (text, record) =>
                record.applicant_details?.partner_loan_id,
        },
        {
            title: "PRODUCT",
            key: "product",
            render: (text, record) => record.applicant_details?.product,
        },
    ];


    const trackingNumber = data.tracking_number;

    useEffect(() => {
        const filteredData = chatData.filter(item => item.tracking_number === trackingNumber);
        setDataSource(filteredData);
    }, [chatData, trackingNumber]);
    console.log("dataSource", dataSource)

    const filteredDataSource = dataSource.filter(
        (record) => record.app_id_status === "2"
    );

    const isActionDisabled = data.status === "3" && data.verification === "5";

    // console.log("isModified??", isModified)
    const handleDrawerClose = () => {
        if (isModified) {
            setOpenDialog(true)
        }
        else {
            onClose()
        }

    };

    const handleSaveAndClose = () => {
        handleSubmitMissingDocs();
        // onClose();
        setOpenDialog(false);
    };

    const handleCancelAndClose = () => {
        setOpenDialog(false);
        onClose();
    };


    const getStatusText = (statusCode) => {
        switch (statusCode) {
            case "1":
                return "In-Transit";
            case "2":
                return "In-Query";
            case "3":
                return "Received";
            default:
                return "";
        }
    };

    const getVerificationText = (statusCode) => {
        switch (statusCode) {
            case "4":
                return "Pending at HO";
            case "5":
                return "Verified";
            case "8":
                return "Missing/Damaged Documents and Pending";
            default:
                return "";
        }
    };


    const handleButtonClick = () => {
        setIsInputVisible(true);
    };

    const handleInputChange = (e) => {
        setDocumentName(e.target.value);
    };


    // const handleSubmitAdditionalDocs = async (record) => {
    //     setLoading(true);
    //     try {
    //         const requestBody = {
    //             application_id: record.application_id,
    //             document: documentName
    //         };

    //         const response = await dispatch(addAdditionalDocuments({ requestBody, jwtBearer }));

    //         if (response?.type === 'addAdditionalDocuments/fulfilled') {
    //             notification.success({
    //                 message: 'Success',
    //                 description: response.payload.message,
    //                 placement: 'bottomRight',
    //             });

    //             // Update the state for myDataSource after document is added
    //             setDataSource(prevDataSource => {
    //                 return prevDataSource.map(item => 
    //                     item.application_id === record.application_id 
    //                     ? { 
    //                         ...item, 
    //                         additional_document: [...item.additional_document, documentName] 
    //                     }
    //                     : item
    //                 );
    //             });

    //             setDocumentName(""); // Clear the document name input
    //             setIsInputVisible(false); // Hide the input
    //         } else {
    //             notification.error({
    //                 message: 'Error',
    //                 description: 'Failed to add additional documents. Please try again.',
    //                 placement: 'bottomRight',
    //             });
    //         }
    //     } catch (error) {
    //         console.error("Error submitting document:", error);
    //     } finally {
    //         setLoading(false);
    //     }
    // };


    // const handleSubmitAdditionalDocs = async (record) => {
    //     setLoading(true);
    //     try {
    //         const requestBody = {
    //             application_id: record.application_id,
    //             document: documentName,
    //         };

    //         const response = await dispatch(addAdditionalDocuments({ requestBody, jwtBearer }));

    //         if (response?.type === 'addAdditionalDocuments/fulfilled') {
    //             notification.success({
    //                 message: 'Success',
    //                 description: response.payload.message,
    //                 placement: 'bottomRight',
    //             });

    //             // Update the state for myDataSource after document is added
    //             setDataSource(prevDataSource => {
    //                 return prevDataSource.map(item =>
    //                     item.application_id === record.application_id
    //                         ? {
    //                             ...item,
    //                             // Ensure additional_document is always an array before appending
    //                             additional_document: [
    //                                 ...(Array.isArray(item.additional_document) ? item.additional_document : []),
    //                                 documentName,
    //                             ],
    //                         }
    //                         : item
    //                 );
    //             });

    //             setDocumentName(""); // Clear the document name input
    //             setIsInputVisible(false); // Hide the input

    //         } else {
    //             notification.error({
    //                 message: 'Error',
    //                 description: 'Failed to add additional documents. Please try again.',
    //                 placement: 'bottomRight',
    //             });
    //         }
    //     } catch (error) {
    //         console.error("Error submitting document:", error);
    //     } finally {
    //         setLoading(false);
    //     }
    // };

    const handleSubmitAdditionalDocs = async (record) => {
        setLoading(true);
        try {
            const requestBody = {
                application_id: record.application_id,
                document: documentName,
            };

            const response = await dispatch(addAdditionalDocuments({ requestBody, jwtBearer }));

            if (response?.type === 'addAdditionalDocuments/fulfilled') {
                notification.success({
                    message: 'Success',
                    description: response.payload.message,
                    placement: 'bottomRight',
                });

                // Update the state for myDataSource after document is added
                setDataSource(prevDataSource => {
                    return prevDataSource.map(item =>
                        item.application_id === record.application_id
                            ? {
                                ...item,
                                additional_document: [
                                    ...(Array.isArray(item.additional_document) ? item.additional_document : []),
                                    documentName,
                                ],
                            }
                            : item
                    );
                });

                // Automatically set the dropdown value to "Verified"
                setSelectedValues(prevState => ({
                    ...prevState,
                    [record.application_id]: {
                        ...prevState[record.application_id],
                        [documentName]: "Verified", // Set the corresponding document's dropdown to "Verified"
                    },
                }));

                setDocumentName(""); // Clear the document name input
                setIsInputVisible(false); // Hide the input

            } else {
                notification.error({
                    message: 'Error',
                    description: 'Failed to add additional documents. Please try again.',
                    placement: 'bottomRight',
                });
            }
        } catch (error) {
            console.error("Error submitting document:", error);
        } finally {
            setLoading(false);
        }
    };

    const receivedData = dataSource.filter(record => record.app_id_status !== '2')

    return (

        <Drawer
            title={
                <div style={{ display: "flex", alignItems: "center" }}>
                    <Popover
                        content={`This courier has ${receivedData.length} number of Application ID (s).`}
                        title="Received"
                        trigger="hover"
                    >
                        <InfoCircleOutlined
                            style={{
                                marginRight: "8px",
                                color: "#1890ff",
                                fontSize: "28px",
                            }}
                        />
                    </Popover>

                    <Title
                        level={4}
                        style={{
                            marginBottom: "16px",
                            color: "rgba(0, 0, 0, 0.7)",
                            fontSize: "20px",
                            fontFamily: "Poppins",
                            marginLeft: "5px",
                        }}
                    >
                        Courier details
                    </Title>
                </div>
            }
            visible={visible}
            onClose={handleDrawerClose}
            width={1120}
        >
            {myLoading ? (
                <div
                    style={{
                        height: "400px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                    }}
                >
                    <Lottie
                        animationData={truckAnimation}
                        loop={true}
                        style={{ width: "400px", height: "400px" }}
                    />
                </div>
            ) : (
                <div style={{ padding: "0 20px" }}>
                    <Space
                        style={{
                            display: "flex",
                            marginBottom: "8px",
                            padding: "0 8px",
                            borderRadius: "4px",
                            backgroundColor: "#ffffff",
                            boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                            fontSize: "20px",
                            fontFamily: "Poppins",
                            display: "flex",
                            justifyContent: "space-between",
                            height: "50px"
                        }}

                    >
                        <strong style={{ color: "#555" }}>Status :</strong>

                        <Text
                            style={{
                                fontSize: "20px",
                                fontFamily: "Poppins",
                                display: "flex",
                                justifyContent: "space-between",
                                backgroundColor: "green",
                                padding: "0px 8px",
                                color: "white",
                                borderRadius: "6px",
                            }}
                        >
                            {getStatusText(data.status)}
                        </Text>

                    </Space>

                    <Space
                        style={{
                            display: "flex",
                            marginBottom: "8px",
                            padding: "8px",
                            borderRadius: "4px",
                            backgroundColor: "#ffffff",
                            boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                            fontSize: "20px",
                            fontFamily: "Poppins",
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <strong style={{ color: "#555" }}>POD Number :</strong>
                        <Text
                            style={{
                                fontSize: "18px",
                                fontFamily: "Poppins",
                                display: "flex",
                                justifyContent: "space-between",
                                fontWeight: "800",
                            }}
                        >
                            {data.tracking_number}
                        </Text>
                    </Space>

                    <Space
                        style={{
                            display: "flex",
                            marginBottom: "8px",
                            padding: "8px",
                            borderRadius: "4px",
                            backgroundColor: "#ffffff",
                            boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                            fontSize: "20px",
                            fontFamily: "Poppins",
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <strong style={{ color: "#555" }}>Vendor :</strong>
                        <Text
                            style={{
                                fontSize: "18px",
                                fontFamily: "Poppins",
                                display: "flex",
                                justifyContent: "space-between",
                                fontWeight: "800",
                            }}
                        >
                            {data.vendor}
                        </Text>
                    </Space>

                    <Space
                        style={{
                            display: "flex",
                            marginBottom: "8px",
                            padding: "8px",
                            borderRadius: "4px",
                            backgroundColor: "#ffffff",
                            boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                            fontSize: "20px",
                            fontFamily: "Poppins",
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <strong style={{ color: "#555" }}>Verification:</strong>
                        <Text

                        >
                            {data?.verification === "8" ? (
                                // Display two buttons for "Missing/Damaged Documents" and "Pending"
                                <div style={{ display: "flex", flexDirection: "row", gap: "8px" }}>
                                    <button style={{
                                        backgroundColor: "red", color: "white", display: "flex",
                                        marginBottom: "8px",
                                        padding: "8px",
                                        borderRadius: "4px",
                                        // boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                                        fontSize: "20px",
                                        fontFamily: "Poppins",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        border: "red solid 2px"

                                    }}>
                                        Missing/Damaged Documents
                                    </button>
                                    <button style={{
                                        backgroundColor: "yellow", color: "black", display: "flex",
                                        border: "yellow solid 2px",
                                        marginBottom: "8px",
                                        padding: "8px",
                                        borderRadius: "4px",
                                        // boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                                        fontSize: "20px",
                                        fontFamily: "Poppins",
                                        display: "flex",
                                        justifyContent: "space-between"
                                    }}>
                                        Pending
                                    </button>
                                </div>
                            ) : (
                                <Text
                                    style={{
                                        fontSize: "18px",
                                        fontFamily: "Poppins",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        backgroundColor:
                                            data.verification === "5"
                                                ? "green"
                                                : data.verification === "4"
                                                    ? "orange"
                                                    : data.verification === "8"
                                                        ? "red"
                                                        : "",

                                        padding: "3px 8px",
                                        color: "white",
                                        borderRadius: "6px",
                                    }}
                                >
                                    {getVerificationText(data?.verification)}
                                </Text>
                            )}
                        </Text>
                    </Space>

                    <Space
                        style={{
                            display: "flex",
                            marginBottom: "8px",
                            padding: "8px",
                            borderRadius: "4px",
                            backgroundColor: "#ffffff",
                            boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                            fontSize: "20px",
                            fontFamily: "Poppins",
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <strong style={{ color: "#555" }}>Branch :</strong>
                        <Text
                            style={{
                                fontSize: "18px",
                                fontFamily: "Poppins",
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            {data.branch}
                        </Text>
                    </Space>


                    <Space
                        style={{
                            display: "flex",
                            marginBottom: "8px",
                            padding: "8px",
                            borderRadius: "4px",
                            backgroundColor: "#ffffff",
                            boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                            fontSize: "20px",
                            fontFamily: "Poppins",
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <strong style={{ color: "#555" }}>Dispatched by :</strong>
                        <Text
                            style={{
                                fontSize: "18px",
                                fontFamily: "Poppins",
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            {data?.courier_dispatcher_name}
                        </Text>
                    </Space>

                    <MyStyledTable
                        columns={columns}
                        dataSource={dataSource.filter(record => record.app_id_status !== '2')}
                        pagination={{
                            pageSize: 6

                        }}
                        style={{ marginTop: "30px" }}
                        rowKey="application_id"
                        expandable={
                            appIdStatus !== '1' && {
                                expandedRowRender: (record) => {
                                    if (!record.stamp_no) {
                                        return null;
                                    }

                                    const checklist = getChecklist(record.PRODUCT);
                                    const additionalDocuments = record.additional_document || [];

                                    const allDocuments = [
                                        ...checklist,
                                        ...additionalDocuments,
                                    ];

                                    const renderChecklistItems = allDocuments.map((item) => {
                                        // Check if doc_details and other are defined before filtering
                                        const remarksForDoc = record.doc_details?.other?.filter(doc => doc.document === item) || [];
                                        const lastRemarkDoc = remarksForDoc.length > 0 ? remarksForDoc[remarksForDoc.length - 1] : null;
                                        const lastRemark = lastRemarkDoc?.Remark || ""; // Last remark or empty string if none

                                        return (
                                            <List.Item key={item}>
                                                <span style={{ marginRight: "20px", fontSize: "16px" }}>
                                                    {item}
                                                </span>

                                                {record.verification !== "5" && (
                                                    <Row gutter={8} align="middle">
                                                        {selectedValues[record.application_id]?.[item] === "Other" && (
                                                            <Col>
                                                                <Input
                                                                    value={selectedValues[record.application_id]?.[`${item}_other`] || lastRemark}
                                                                    onChange={(e) => handleOtherInputChange(record.application_id, item, e.target.value)}
                                                                    placeholder="Add remark here..."
                                                                    style={{ width: 300 }}
                                                                />
                                                            </Col>
                                                        )}
                                                        <Col>
                                                            <Select
                                                                value={selectedValues[record.application_id]?.[item] || "Verified"}
                                                                onChange={(value) => handleDropdownChange(record.application_id, item, value)}
                                                                style={{ width: 120 }}
                                                            >
                                                                <Select.Option value="Verified">Verified</Select.Option>
                                                                <Select.Option value="Missing">Missing</Select.Option>
                                                                <Select.Option value="Damaged">Damaged</Select.Option>
                                                                <Select.Option value="N/A">N/A</Select.Option>
                                                                <Select.Option value="Other">Other</Select.Option>
                                                            </Select>
                                                        </Col>
                                                    </Row>
                                                )}
                                            </List.Item>
                                        );
                                    });



                                    return (
                                        <div>
                                            <List
                                                size="small"
                                                bordered
                                                dataSource={[{ key: 'add-doc-button' }]}
                                                renderItem={() => (
                                                    <List.Item>

                                                        {record.verification !== "5" && isInputVisible && (
                                                            <>
                                                                <Input
                                                                    value={documentName}
                                                                    onChange={handleInputChange}
                                                                    placeholder="Enter document name"
                                                                    style={{ width: "80%", marginRight: "10px" }}
                                                                />
                                                                <Button
                                                                    type="primary"
                                                                    loading={loading}
                                                                    onClick={() => handleSubmitAdditionalDocs(record)}
                                                                    disabled={!documentName.trim()}
                                                                >
                                                                    Submit Document
                                                                </Button>
                                                            </>
                                                        )}
                                                        {record.verification !== "5" && !isInputVisible && (
                                                            <Button onClick={handleButtonClick}>Add additional documents</Button>
                                                        )}
                                                    </List.Item>
                                                )}
                                            />
                                            <List size="small" bordered dataSource={checklist}>
                                                {renderChecklistItems}
                                            </List>
                                        </div>
                                    );
                                },
                                expandIcon: ({ expanded, onExpand, record }) =>
                                    expanded ? (
                                        <IoIosArrowDropup
                                            onClick={(e) => onExpand(record, e)}
                                            style={{ fontSize: "20px", height: "20px" }}
                                        />
                                    ) : (
                                        <IoIosArrowDropdown
                                            onClick={(e) => onExpand(record, e)}
                                            style={{ fontSize: "20px", height: "20px" }}
                                        />
                                    ),
                            }
                        }
                    />
                    {data.verification !== "5" && isModified && (
                        <Popconfirm
                            title="Are you sure you want to submit the changes?"
                            onConfirm={handleSubmitMissingDocs}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button
                                type="primary"
                                loading={loading}
                                style={{ marginTop: 2 }}
                            >
                                Submit
                            </Button>
                        </Popconfirm>
                    )}


                    {filteredDataSource.length > 0 && (
                        <>
                            <h2 style={{ margin: "30px 0" }}>Missing Application Id(s)</h2>
                            <MyStyledTable
                                columns={notReceivedColumns}
                                dataSource={filteredDataSource}
                                pagination={false}
                                rowKey="application_id"
                            />
                        </>
                    )}



                    {appIdStatus === '1' && <Popconfirm
                        title="Are you sure you want to submit the changes?"
                        onConfirm={handleMarkAsReceive}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button
                            type="primary"
                            loading={loading}
                            style={{ marginTop: 20 }}
                        >
                            Submit
                        </Button>
                    </Popconfirm>}





                </div>)}

            {/* Confirmation Dialog */}
            <Modal
                visible={openDialog}
                title="Unsaved Changes"
                onOk={handleSaveAndClose}
                onCancel={handleCancelAndClose}
                cancelText="Don't Save"
                okText="Save Changes"
                centered
            >
                <Text>Do you want to save ?</Text>
            </Modal>
        </Drawer>
    );
};

export default AfterReceivedHo;
