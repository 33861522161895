import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { BASE_URL } from '../apiConfig'

export const fetchPDDMaster = createAsyncThunk(
    'fetchPDDMaster',
    async ({ jwtBearer, movement }) => {
        const response = await fetch(`${BASE_URL}pdd/get/master/`, {
            headers: {
                Authorization: `Bearer ${jwtBearer}` 
            }
        })
        return response.json()
    }
)

const pddMasterDataSlice = createSlice({
    name: 'pddMasterDataSlice',
    initialState: {
        isLoading: false,
        data: null,
        isError: false
    },
    extraReducers: builder => {
        builder.addCase(fetchPDDMaster.pending, (state, action) => {
            state.isLoading = true
        })
        builder.addCase(fetchPDDMaster.fulfilled, (state, action) => {
            state.isLoading = false
            state.data = action.payload
        })
        builder.addCase(fetchPDDMaster.rejected, (state, action) => {
            console.log('Error', action.payload)
            state.isError = true
        })
    }
})

export default pddMasterDataSlice.reducer
