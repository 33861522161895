import BranchPDD from "../views/branchPDD";
import HoPDD from "../views/hoPDD";
import jwt_decode from "jwt-decode";
import { useAuth } from "../../../context/AuthContext";
import React, { useEffect } from "react";

function PDDModule(props) {
  const { authState, logout } = useAuth();

  const jwtBearer = authState.token;
  let jwt_payload = "";
  let jwt_payload_group = "";
  let user_id = "";

  useEffect(() => {
    if (jwtBearer) {
      try {
        jwt_payload = jwt_decode(jwtBearer);
        const expirationTime = jwt_payload.exp * 1000;
        const currentTime = Date.now();

        if (currentTime >= expirationTime) {
          logout();
        }
      } catch (error) {
        console.error("Error decoding JWT:", error);
        logout();
      }
    }
  }, [jwtBearer, logout]);

  if (jwtBearer) {
    jwt_payload = jwt_decode(jwtBearer);
    user_id = jwt_payload.user_id;
    jwt_payload_group = props.userDetails?.group_id;
    console.log("jwt_payload_group", jwt_payload_group);
  }

  return (
    <>
      {(jwt_payload_group === "10" || jwt_payload_group === "5") && (
        <HoPDD userDetails={props.userDetails} />
      )}
      {jwt_payload_group === "3" && (
        <BranchPDD userDetails={props.userDetails} />
      )}
      {!(
        jwt_payload_group === "10" ||
        jwt_payload_group === "3" ||
        jwt_payload_group === "5"
      ) && <div>No matching component for this group.</div>}
    </>
  );
}

export default PDDModule;
