import { NavigationContainer, useNavigation } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";

import { StatusBar } from "expo-status-bar";
import { StyleSheet, Text, View, Button, Dimensions } from "react-native";
import { useAuth, AuthProvider, userGroup } from "./app/context/AuthContext";
import Home from "./app/screens/Home";
import Login from "./app/screens/Login";
import PasswordReset from "./app/screens/PasswordReset";
import Main from "./app/components/layout/Main";
import Reports from "./app/screens/reports/views/Reports";
import Adminusers from "./app/screens/admin/views/Adminusers";
import ZohoMIS from "./app/screens/admin/views/ZohoMIS";
import Collection from "./app/screens/collection/views/Collection";
import PDDModule from "./app/screens/pddModule/components/pddModule";
import Bank from "./app/screens/HR/views/Bank";
import MIS from "./app/screens/mis/views/Mis";
import ITInventory from "./app/screens/inventory/views/ITInventory";
import CMAUserTickets from "./app/screens/cmasupport/views/CMAUserTickets";
import CMASupport from "./app/screens/cmasupport/components/CMASupport";
import DigitalJourney from "./app/screens/digitaljourney/components/DigitalJourney";
import Leads from "./app/screens/leads/views/Leads";
import CMA from "./app/screens/cma/views/Cma";
import Notifications from "./app/screens/notifications/views/Notifications";
import { useDispatch } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./app/redux/store";
import React, { useEffect, useState } from "react";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import jwt_decode from "jwt-decode";
import PermissionsGate from "./app/components/PermissionsGate";
import { SCOPES } from "./app/components/PermissionsMap";
import { setCollapsed } from "./app/redux/slice/sidenavCollapseSlice";
import Diwali_Final from "./assets/Diwali_Final.jpg";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { fetchUserDetails } from "./app/redux/slice/getUserDetails";
import MainOnboarding from "./app/screens/onBoarding/views/MainOnboarding";
const Stack = createNativeStackNavigator();

export default function App() {
  // const dispatch = useDispatch()
  // dispatch(setCollapsed(null))
  useEffect(() => {
    document.body.style.zoom = "75%";
    return () => {
      document.body.style.zoom = "100%";
    };
  }, []);
  return (
    <Provider store={store}>
      <AuthProvider>
        <Layout />
      </AuthProvider>
    </Provider>
  );
}

export const Layout = () => {
  const { authState, onLogout } = useAuth();
  const [initialAuthCheck, setInitialAuthCheck] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const dispatch = useDispatch();

  var token_decoded = authState.token;
  var jwtBearer = authState.token;

  useEffect(() => {
    // Only execute if jwtBearer and user_id are available
    if (jwtBearer && jwt_payload?.user_id) {
      // Create an async function to handle the async operation
      const fetchData = async () => {
        try {
          // Await the user response
          const userResponse = await dispatch(
            fetchUserDetails({ jwtBearer, emp_code: jwt_payload.user_id })
          );

          // Log the extracted data
          console.log("User Details app- ", userResponse.payload?.message);
          setUserDetails(userResponse.payload?.message);
        } catch (error) {
          console.error("Error fetching user details: ", error);
        }
      };

      // Call the async function
      fetchData();
    }

    // Add dependencies that trigger this effect
  }, [jwtBearer, jwt_payload?.user_id]);
  console.log("App Token - ", token_decoded);
  var jwt_payload = "";
  var jwt_role = "";
  var jwt_first_login;
  var jwt_payload_group = [];
  var jwt_payload_expiry = [];
  if (token_decoded) {
    jwt_payload = jwt_decode(token_decoded);
    console.log("JWT Payload App", jwt_payload);
    // jwt_payload_group = jwt_payload.group
    jwt_payload_group = userDetails?.group_id;
    // jwt_role = jwt_payload.role;
    jwt_role = userDetails?.role;
    jwt_first_login = jwt_payload.first_login;
    jwt_payload_expiry = jwt_payload.exp;
  }

  // Session Timeout
  const TOKEN_KEY = "my-jwt";
  var currentTime = Math.floor(Date.now() / 1000);
  console.log("Current time - ", currentTime);
  console.log("Expiry time - ", jwt_payload_expiry);

  // console.log("Password reset current time - ", currentTime);

  // Compare current time with token expiry time
  if (jwt_payload_expiry.length != 0) {
    if (currentTime > jwt_payload_expiry) {
      console.log("In token expired condition");
      // Token has expired, perform logout
      // AsyncStorage.removeItem(TOKEN_KEY);
      onLogout();
      location.reload();
    }
  }

  console.log("JWT Group App outside", jwt_payload_group);

  const navigationRef = React.createRef();
  const [initialRoute, setInitialRoute] = useState("Reports"); // Store initial route dynamically

  useEffect(() => {
    const storedPage = localStorage.getItem("currentPage");

    if (storedPage) {
      setInitialRoute(storedPage); // Set initial route based on stored page
    }
    setTimeout(() => {
      setInitialAuthCheck(true);
    }, 1000);
  }, []);

  const handleNavigationStateChange = (state) => {
    localStorage.setItem("currentPage", state.routes[state.index].name);
  };

  return (
    <BrowserRouter>
      {/* <Provider store={store}> */}
      <NavigationContainer
        ref={navigationRef}
        onStateChange={handleNavigationStateChange}
      >
        {initialAuthCheck && (
          <View style={styles.container}>
            <>
              {authState?.authenticated ? (
                // <PermissionsGate scopes={[SCOPES.canViewCollection]}>
                jwt_first_login ? (
                  <Main>
                    <Stack.Navigator initialRouteName={initialRoute}>
                      <Stack.Screen
                        name="Home"
                        component={Home}
                        // component={(props) => <Home {...props} extraProp={userDetails} />}
                        options={{
                          headerShown: false,
                        }}
                        // options={{
                        //   headerLeft: () => null // Hide the header back button
                        // }}
                        // options={{
                        //   headerRight: () => (
                        //     <Button onPress={onLogout} title='Sign Out' />
                        //   )
                        // }}
                      />
                      {jwt_role != "Support" ? (
                        <Stack.Screen
                          name="Reports"
                          // component={Reports}
                          component={() => (
                            <PermissionsGate
                              scopes={[SCOPES.canViewReports]}
                              userDetails={userDetails}
                            >
                              <Reports userDetails={userDetails} />
                            </PermissionsGate>
                          )}
                          options={{
                            headerShown: false,
                          }}
                          // options={{
                          //   headerLeft: () => null // Hide the header back button
                          // }}
                          // options={{
                          //   headerRight: () => (
                          //     <Button onPress={onLogout} title='Sign Out' />
                          //   )
                          // }}
                        />
                      ) : null}
                      {/* <PermissionsGate scopes={[SCOPES.canViewCollection]}> */}
                      {/* {hasPermission(SCOPES.canViewAdminUsers) && ( */}
                      <Stack.Screen
                        name="Adminusers"
                        component={() => (
                          <PermissionsGate
                            scopes={[SCOPES.canViewAdmin]}
                            userDetails={userDetails}
                          >
                            <Adminusers />
                          </PermissionsGate>
                        )}
                        options={{
                          headerShown: false,
                        }}
                        // options={{
                        //   headerLeft: () => null // Hide the header back button
                        // }}
                        // options={({ navigation }) => ({
                        //   headerRight: () => (
                        //     <PermissionsGate scopes={[SCOPES.canViewCollection]}>
                        //       <Button onPress={onLogout} title='Sign Out' />
                        //     </PermissionsGate>
                        //   )
                        // })}
                      />
                      <Stack.Screen
                        name="Admin"
                        component={() => (
                          <PermissionsGate
                            scopes={[SCOPES.canViewAD]}
                            userDetails={userDetails}
                          >
                            <Adminusers />
                          </PermissionsGate>
                        )}
                        options={{
                          headerShown: false,
                        }}
                      />
                      {/* )} */}
                      {/* </PermissionsGate> */}
                      {/* <Stack.Screen name='Collection' component={Collection} /> */}
                      <Stack.Screen
                        name="Collection"
                        component={() => (
                          <PermissionsGate
                            scopes={[SCOPES.canViewCollection]}
                            userDetails={userDetails}
                          >
                            <Collection userDetails={userDetails} />
                          </PermissionsGate>
                        )}
                        options={{
                          headerShown: false,
                        }}
                        initialParams={{ extraProp: userDetails }}
                        // options={{
                        //   headerLeft: () => null // Hide the header back button
                        // }}
                      />
                      {/* {jwt_role === "Admin" ? ( */}
                      {/* <Stack.Screen
                        name="PDD"
                        component={() => (
                          <PermissionsGate scopes={[SCOPES.canViewPDD]} userDetails={userDetails}>
                            <PDDModule userDetails={userDetails} />
                          </PermissionsGate>
                        )}
                        options={{
                          headerShown: false,
                        }}
                      /> */}
                      <Stack.Screen
                        name="PDD"
                        component={() => (
                          <PermissionsGate
                            scopes={[SCOPES.canViewPDD]}
                            userDetails={userDetails}
                          >
                            <PDDModule userDetails={userDetails} />
                          </PermissionsGate>
                        )}
                        options={{
                          headerShown: false,
                        }}
                      />

                      <Stack.Screen
                        name="Bank Verification"
                        component={() => (
                          <PermissionsGate
                            scopes={[SCOPES.canViewBankVerification]}
                            userDetails={userDetails}
                          >
                            <Bank userDetails={userDetails} />
                          </PermissionsGate>
                        )}
                        options={{
                          headerShown: false,
                        }}
                      />

                      {/* <Stack.Screen
                        name="Pre-onboarding"
                        component={() => (
                          <PermissionsGate
                            scopes={[SCOPES.canViewPreOnboarding]}
                            userDetails={userDetails}
                          >
                            <MainOnboarding userDetails={userDetails} />
                          </PermissionsGate>
                        )}
                        options={{
                          headerShown: false,
                        }}
                      /> */}
                      {/* ) : null} */}
                      {/* {jwt_role === "Supervisor" ||
                        jwt_role === "Admin" ? (
                        <Stack.Screen
                          name="ZohoMIS"
                          component={() => (
                            <PermissionsGate scopes={[SCOPES.canViewZohoMIS]} userDetails={userDetails}>
                              <ZohoMIS userDetails={userDetails} />
                            </PermissionsGate>
                          )}
                          options={{
                            headerShown: false,
                          }}
                        // options={{
                        //   headerLeft: () => null // Hide the header back button
                        // }}
                        />
                      ) : null} */}

                      {jwt_role === "Supervisor" ||
                      jwt_role === "Zonal Head" ||
                      jwt_role === "Admin" ? (
                        <Stack.Screen
                          name="ZohoMIS"
                          component={() => (
                            <PermissionsGate
                              scopes={[SCOPES.canViewZohoMIS]}
                              userDetails={userDetails}
                            >
                              <ZohoMIS userDetails={userDetails} />
                            </PermissionsGate>
                          )}
                          options={{
                            headerShown: false,
                          }}
                          // options={{
                          //   headerLeft: () => null // Hide the header back button
                          // }}
                        />
                      ) : null}

                      {jwt_role === "Credit-Support" ||
                      jwt_role === "Support" ||
                      jwt_role === "Operations-Support" ||
                      jwt_role === "Admin" ||
                      jwt_role === "IT-Support" ||
                      jwt_role === "Telecaller-Executive" ||
                      jwt_role === "Telecaller-Sup" ? (
                        <Stack.Screen
                          name="CMASupport"
                          component={() => (
                            <PermissionsGate
                              scopes={[SCOPES.canViewCMATickets]}
                              userDetails={userDetails}
                            >
                              <CMASupport userDetails={userDetails} />
                            </PermissionsGate>
                          )}
                          options={{
                            headerShown: false,
                          }}
                        />
                      ) : null}
                      {/* {jwt_role === "Telecaller-Executive" ||
                        jwt_role === "Telecaller-Sup" ||
                        jwt_role === "Admin" ? ( */}
                      <Stack.Screen
                        name="DigitalJourney"
                        component={() => (
                          <PermissionsGate
                            scopes={[SCOPES.canViewDigitalJourney]}
                            userDetails={userDetails}
                          >
                            <DigitalJourney userDetails={userDetails} />
                          </PermissionsGate>
                        )}
                        options={{
                          headerShown: false,
                        }}
                      />
                      {/* ) : null} */}
                      <Stack.Screen
                        name="MIS"
                        component={() => (
                          <PermissionsGate
                            scopes={[SCOPES.canViewMIS]}
                            userDetails={userDetails}
                          >
                            <MIS userDetails={userDetails} />
                          </PermissionsGate>
                        )}
                        options={{
                          headerShown: false,
                        }}
                        // options={{
                        //   headerLeft: () => null // Hide the header back button
                        // }}
                      />
                      <Stack.Screen
                        name="ITInventory"
                        component={() => (
                          <PermissionsGate
                            scopes={[SCOPES.canViewInventory]}
                            userDetails={userDetails}
                          >
                            <ITInventory userDetails={userDetails} />
                          </PermissionsGate>
                        )}
                        options={{
                          headerShown: false,
                        }}
                        // options={{
                        //   headerLeft: () => null // Hide the header back button
                        // }}
                      />
                      <Stack.Screen
                        name="Lead"
                        component={() => (
                          <PermissionsGate
                            scopes={[SCOPES.canViewLeads]}
                            userDetails={userDetails}
                          >
                            <Leads />
                          </PermissionsGate>
                        )}
                        options={{
                          headerShown: false,
                        }}
                      />
                      {/* <Stack.Screen
                        name="Notifications"
                        component={() => (
                          <PermissionsGate scopes={[SCOPES.canViewCMA]}>
                            <Notifications />
                          </PermissionsGate>
                        )}
                        options={{
                          headerShown: false,
                        }}
                      /> */}
                      {/* <Stack.Screen
                        name="CMA"
                        component={() => (
                          <PermissionsGate scopes={[SCOPES.canViewCMA]}>
                            <CMA />
                          </PermissionsGate>
                        )}
                        options={{
                          headerShown: false,
                        }}
                      /> */}
                    </Stack.Navigator>
                  </Main>
                ) : (
                  <Stack.Navigator>
                    <Stack.Screen
                      name="PasswordReset"
                      component={PasswordReset}
                      options={{
                        headerShown: false,
                      }}
                    />
                  </Stack.Navigator>
                )
              ) : (
                //  </PermissionsGate>
                <Stack.Navigator>
                  <Stack.Screen
                    name="Login"
                    component={Login}
                    options={{
                      headerShown: false,
                    }}
                  />
                </Stack.Navigator>
              )}
            </>
          </View>
        )}
      </NavigationContainer>
      {/* </Provider> */}
    </BrowserRouter>
  );
};

const styles = StyleSheet.create({
  container: {
    height: "100%",
    width: "100%",
    // flex: 1,
    // justifyContent: 'center',
    // alignItems: 'center'
  },
  contentWrapper: {
    width: Dimensions.get("window").width * 0.4, // Set the content width to 80% of the screen width
    flex: 1,
  },
});
