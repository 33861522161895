import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Typography,
  Tabs,
  Button,
  Input,
  Tag,
  Card,
  Form,
  Space,
  Divider,
  Progress,
  Row,
  Col,
  Radio,
  Modal,
  Select,
  Steps,
  Spin,
  FloatButton,
  Slider,
  InputNumber,
  Collapse,
  Drawer,
  Alert,
  List,
  Upload,
  notification,
} from "antd";
import styled from "styled-components";
import truckAnimation from "../../../../../../assets/loaderTruck.json";
import Lottie from "lottie-react";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";
import {
  SearchOutlined,
  PlusOutlined,
  DeleteOutlined,
  CodeSandboxOutlined,
  DropboxOutlined,
  UploadOutlined,
  CloseOutlined,
  InboxOutlined,
} from "@ant-design/icons";
import jwt_decode from "jwt-decode";
import {
  StyledModal,
  StyledCard,
  StyledRow,
  StyledCol,
  StyledTable,
  RefreshButton,
  StyledButton,
  CardContainer,
  MyStyledTable,
  MyDetailedStyledTable,
} from "../../../style/styleComp";
import { useAuth } from "../../../../../context/AuthContext";
import { fetchPDDWarehouse } from "../../../../../redux/slice/getPDDDWarehouseData";
import {
  fetchPDDAppID,
  fetchNotAddedAppIdReturn,
} from "../../../../../redux/slice/getPDDAppID";
import { fetchPDDAppIDRFW } from "../../../../../redux/slice/getPDDAppIDRFW";
import { fetchPDDAppIDRFWPhy } from "../../../../../redux/slice/getPDDAppIDRFWPhy";
import { fetchPDDMaster } from "../../../../../redux/slice/getPDDMaster";
import { HashLoader } from "react-spinners";
import { addWarehouseEntry } from "../../../../../redux/slice/pddAddWarehouseEntry";
import { updateBranchEntry } from "../../../../../redux/slice/pddUpdateBranchEntry";
import { addMissingDocs } from "../../../../../redux/slice/pddAddMissingDocs";
import { RiRefreshLine } from "react-icons/ri";
import { useSpring, animated } from "react-spring";
import "../../../style/customStyles.css";
import WarehouseUploadDrawer from "./WarehouseUploadDrawer";
import DocsDetailsDrawer from "./DocsDetailsDrawer";
import moment from "moment";
import { uploadNocCalFile } from "../../../../../redux/slice/uploadNocCal";
const { Title, Text } = Typography;
const { Dragger } = Upload;
const { Panel } = Collapse;

const { Option } = Select;
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

const HoToVendor = ({ extraProp }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [openBox, setOpenBox] = useState(true);
  const [loading, setLoading] = useState(true);
  const [activeTabKey, setActiveTabKey] = useState("allTables");
  const [tableData, setTableData] = useState([]);
  const [showWarehouseUpload, setShowWarehouseUpload] = useState(false);
  const [selectedAppIDs, setSelectedAppIDs] = useState([]);
  const [selectedAppIDsPhy, setSelectedAppIDsPhy] = useState([]);
  const [searchField, setSearchField] = useState("Select");
  const [searchValue, setSearchValue] = useState("");
  const [modalVisible, setFileModalVisible] = useState(false);
  const [queryModalVisible, setPhysicalModalVisible] = useState(false);
  const [selectedCardData, setSelectedCardData] = useState(null);
  const { authState } = useAuth();
  const [vendors, setVendors] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [isAnimated, setIsAnimated] = useState(false);
  const [enableSearch, setEnableSearch] = useState(true);
  const [filteredData, setFilteredData] = useState([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedDocDetails, setSelectedDocDetails] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [currentAppId, setCurrentAppId] = useState("");
  const [nocFile, setNocFile] = useState(null);
  const [visible, setVisible] = useState(false);

  var token_decoded = authState.token;
  var jwtBearer = authState.token;
  var jwt_payload = "";
  var user_branch = "";
  if (jwtBearer) {
    jwt_payload = jwt_decode(jwtBearer);
    user_branch = extraProp.branch_id;
  }

  const [branches, setBranches] = useState([]);

  useEffect(() => {
    // Parse the user_branch string into an array of branch IDs
    const parsedBranches = user_branch.replace(/[()]/g, "").split(",");
    // console.log("parsedBranches", parsedBranches);
    setBranches(parsedBranches);
  }, [user_branch]);

  const fetchData = () => {
    // console.log("Fetching chat data and app ID...");
    setLoading(true);

    Promise.all([
      dispatch(fetchPDDWarehouse({ jwtBearer })),
      dispatch(fetchPDDAppID({ jwtBearer })),
      dispatch(fetchNotAddedAppIdReturn({ jwtBearer })),
      dispatch(fetchPDDMaster({ jwtBearer })),
    ])
      .then(() => {
        // console.log("Chat data and app ID fetched successfully.");
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching chat data or app ID:", error);
        setLoading(false);
      });
  };

  const fetchAvailableAppIDs = () => {
    // console.log("Fetching chat data and app ID...");

    // Dispatch both actions in parallel
    Promise.all([
      dispatch(fetchPDDAppIDRFW({ jwtBearer })),
      dispatch(fetchPDDAppIDRFWPhy({ jwtBearer })),
    ])
      .then(() => {
        // console.log("Chat data and app ID fetched successfully.");
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching chat data or app ID:", error);
        setLoading(false);
      });
  };

  // Initial fetch on component mount
  useEffect(() => {
    fetchData();
  }, [dispatch]); // Fetch data initially and set up interval

  const handleRefresh = () => {
    setIsAnimated(true);
    setLoading(true);
    fetchData();
  };

  const addBranchNewEntry = () => {
    // fetchAvailableAppIDs();
    setShowWarehouseUpload(true);
  };

  const { uploading, fileUrl, error } = useSelector(
    (state) => state.uploadNocCal
  );

  const extractApplicationIds = (appIDDataRFW) => {
    const applicationIds = [];

    // Check if appIDDataRFW is an array
    if (Array.isArray(appIDDataRFW)) {
      appIDDataRFW.forEach((branchData) => {
        branchData.application_ids.forEach((appId) => {
          applicationIds.push(appId);
        });
      });
    } else {
      console.error("appIDDataRFW is not an array:", appIDDataRFW);
    }

    return applicationIds;
  };

  const handleRecall = (record) => {
    Modal.confirm({
      title: "Are you sure you want to recall the documents?",
      content: `This action will recall the documents for Application ID: ${record.application_id}.`,
      okText: "Yes",
      cancelText: "No",
      onOk: () => confirmRecall(record),
    });
  };

  const confirmRecall = (record) => {
    const app_id_array = [record.application_id];
    // console.log("Documents recalled for Application ID:", app_id_array);
    const requestBody = {
      type: "1",
      app_id: app_id_array,
    };

    console.log("confirm req body", requestBody);

    dispatch(addWarehouseEntry({ jwtBearer, requestBody }))
      .then(() => {
        dispatch(fetchPDDWarehouse({ jwtBearer }));
        form.resetFields();
        setSelectedAppIDs([]);
        setSelectedAppIDsPhy([]);
        setShowAddBranchEntryModalVisible(false);
      })
      .catch((error) => {
        console.error("Error updating reschedule date:", error);
      });
  };

  const chatData = useSelector(
    (state) => state.fetchPDDWarehouse?.data?.message || []
  );

  // console.log("chatData-fetchPDDWarehouse", chatData);

  const showDrawer = () => setVisible(true);
  const closeDrawer = () => setVisible(false);

  const handleFileChange = (info) => {
    if (info.file.status === "done") {
      notification.success({
        message: "Success",
        description: `${info.file.name} file uploaded successfully`,
        placement: "bottomRight",
      });
    } else if (info.file.status === "error") {
      notification.error({
        message: "Error",
        description: `${info.file.name} file upload failed.`,
        placement: "bottomRight",
      });
    }
  };

  const handleFileUpload = async (app_id) => {
    if (!selectedFile || !jwtBearer) {
      notification.error({
        message: "Error!",
        description: "Please select a file before uploading",
        placement: "bottomRight",
      });

      return;
    }

    const reqBody = {
      applicationId: app_id,
      fileType: "noc",
    };

    console.log("reqBody", reqBody);

    dispatch(uploadNocCalFile({ file: selectedFile, ...reqBody, jwtBearer }))
      .then(() => {
        notification.success({
          message: "Success!",
          description: `${selectedFile.name} uploaded successfully`,
          placement: "bottomRight",
        });
        setNocFile(URL.createObjectURL(selectedFile));
        closeDrawer();
        dispatch(fetchPDDWarehouse({ jwtBearer }));
      })
      .catch((error) => {
        console.error("Error uploading file:", error);
        notification.Error({
          message: "Success!",
          description: `${selectedFile.name} file upload failed.`,
          placement: "bottomRight",
        });
      });
  };

  const handleViewClick = (nocFile) => {
    // Logic for viewing the file
    window.open(nocFile, "_blank");
  };

  const columns = [
    {
      title: "APPLICATION ID",
      dataIndex: "application_id",
      key: "application_id",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search Application ID"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => confirm()}
            style={{ marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="link"
              onClick={() => clearFilters && clearFilters()}
              size="small"
            >
              Clear
            </Button>
            <Button type="primary" onClick={() => confirm()} size="small">
              Filter
            </Button>
          </Space>
        </div>
      ),
      onFilter: (value, record) =>
        record.application_id
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()),
    },
    {
      title: "NAME",
      dataIndex: "applicant_name",
      key: "applicant_name",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search Name"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => confirm()}
            style={{ marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="link"
              onClick={() => clearFilters && clearFilters()}
              size="small"
            >
              Clear
            </Button>
            <Button type="primary" onClick={() => confirm()} size="small">
              Filter
            </Button>
          </Space>
        </div>
      ),
      onFilter: (value, record) =>
        record.applicant_name.toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: "OXYZO ID",
      dataIndex: "partner_loan_id",
      key: "partner_loan_id",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search Oxyzo ID"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => confirm()}
            style={{ marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="link"
              onClick={() => clearFilters && clearFilters()}
              size="small"
            >
              Clear
            </Button>
            <Button type="primary" onClick={() => confirm()} size="small">
              Filter
            </Button>
          </Space>
        </div>
      ),
      onFilter: (value, record) =>
        record.partner_loan_id.toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: "BRANCH",
      dataIndex: "branch",
      key: "branch",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search Branch"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => confirm()}
            style={{ marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="link"
              onClick={() => clearFilters && clearFilters()}
              size="small"
            >
              Clear
            </Button>
            <Button type="primary" onClick={() => confirm()} size="small">
              Filter
            </Button>
          </Space>
        </div>
      ),
      onFilter: (value, record) =>
        record.branch.toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: "PRODUCT",
      dataIndex: "product",
      key: "product",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search Product"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => confirm()}
            style={{ marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="link"
              onClick={() => clearFilters && clearFilters()}
              size="small"
            >
              Clear
            </Button>
            <Button type="primary" onClick={() => confirm()} size="small">
              Filter
            </Button>
          </Space>
        </div>
      ),
      onFilter: (value, record) =>
        record.product.toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: "STATUS",
      dataIndex: "app_id_status",
      key: "app_id_status",
      render: (value) => {
        if (value === "12") {
          return <span>In Warehouse</span>;
        } else if (value === "13") {
          return <span>Recalled</span>;
        }
        return value;
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search Status"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => confirm()}
            style={{ marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="link"
              onClick={() => clearFilters && clearFilters()}
              size="small"
            >
              Clear
            </Button>
            <Button type="primary" onClick={() => confirm()} size="small">
              Filter
            </Button>
          </Space>
        </div>
      ),
      onFilter: (value, record) =>
        record.app_id_status.toString().includes(value),
    },

    // {
    //   title: "UPDATED AT",
    //   dataIndex: "updated_at",
    //   key: "updated_at",
    //   render: (value) => new Date(value).toLocaleString(),
    // },
    {
      title: "BOX NO",
      dataIndex: "box_no",
      key: "box_no",
      render: (value) => value,
    },
    {
      title: "CARTON NO",
      dataIndex: "carton_no",
      key: "carton_no",
      render: (value) => value,
    },
    {
      title: "FILE BARCODE NO",
      dataIndex: "file_barcode_no",
      key: "file_barcode_no",
    },

    {
      title: "PROP FILE NO",
      dataIndex: "prop_file_no",
      key: "prop_file_no",
      render: (value) => value,
    },
    {
      title: "PROP FILE BARCODE NO",
      dataIndex: "prop_file_barcode_no",
      key: "prop_file_barcode_no",
      render: (value) => value,
    },
    // {
    //   title: "CREATED AT",
    //   dataIndex: "created_at",
    //   key: "created_at",
    //   render: (value) => moment(value).utc().local().format('YYYY-MM-DD HH:mm:ss A'),
    // },
    // {
    //   title: "DOC DETAILS",
    //   dataIndex: "doc_details",
    //   key: "doc_details",
    //   render: (value) => {
    //     // Return a View Docs button that opens the drawer
    //     return (
    //       <Button type="link" onClick={() => handleViewDocs(value)}>
    //         View Docs
    //       </Button>
    //     );
    //   },
    // },

    {
      title: "NOC",
      key: "noc_file",
      render: (_, record) => {
        const {
          product,
          box_no,
          carton_no,
          file_barcode_no,
          prop_file_no,
          prop_file_barcode_no,
          application_id,
          status,
          noc_file,
        } = record;

        // Only render if the conditions are met
        const shouldShowNoc =
          (product === "PL-USEC" && box_no && carton_no && file_barcode_no) ||
          (product === "PL-SEC" &&
            box_no &&
            carton_no &&
            file_barcode_no &&
            prop_file_no &&
            prop_file_barcode_no);

        if (!shouldShowNoc) {
          return null;
        }

        return (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {noc_file ? (
                <Button
                  onClick={() => {
                    setNocFile(noc_file);
                    setCurrentAppId(application_id);
                    showDrawer();
                  }}
                  type="link"
                >
                  View
                </Button>
              ) : (
                <Button
                  type="link"
                  onClick={() => {
                    setNocFile(noc_file);
                    setCurrentAppId(application_id);
                    showDrawer();
                  }}
                >
                  Upload
                </Button>
              )}
            </div>

            <Drawer
              title={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <span>{nocFile ? "View NOC File" : "Upload NOC File"}</span>
                  {/* {nocFile && (
                    <a href={nocFile} target="_blank" download style={{ marginLeft: "auto" }}>
                      <Button type="primary" style={{ marginLeft: "auto" }}>
                        Download
                      </Button>
                    </a>
                  )} */}
                </div>
              }
              width={nocFile ? "1200" : "500"}
              visible={visible}
              onClose={closeDrawer}
            >
              {nocFile ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    height: "100%",
                  }}
                >
                  {/* Checking if the file is a PDF or an Image */}
                  {nocFile.match(/\.(jpeg|jpg|png)$/i) ? (
                    <div style={{ width: "100%", textAlign: "center" }}>
                      <img
                        src={nocFile}
                        alt="NOC"
                        style={{
                          width: "100%",
                          height: "auto",
                          maxHeight: "80vh",
                          objectFit: "contain",
                        }}
                      />
                    </div>
                  ) : nocFile.match(/\.pdf$/i) ? (
                    <div style={{ width: "100%", height: "100%" }}>
                      <iframe
                        src={nocFile}
                        style={{
                          width: "100%",
                          height: "100%",
                          border: "none",
                        }}
                        title="NOC File"
                      />
                    </div>
                  ) : (
                    <div>Unsupported file type</div>
                  )}
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "stretch",
                    gap: "10px",
                  }}
                >
                  <div style={{ width: "100%" }}>
                    <Dragger
                      showUploadList={true}
                      beforeUpload={(file) => {
                        const isValid =
                          /^(image\/(jpeg|png|jpg))|application\/pdf$/.test(
                            file.type
                          );
                        if (!isValid) {
                          notification.error({
                            message: "Error",
                            description:
                              "You can only upload images or PDF files.",
                            placement: "bottomRight",
                          });
                          return false;
                        }

                        setSelectedFile(file);
                        setCurrentAppId(currentAppId);
                        return false;
                      }}
                      onChange={handleFileChange}
                      accept="image/*,application/pdf"
                      style={{ height: "180px", fontSize: "18px" }}
                    >
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">
                        Click or drag file to this area to upload
                      </p>
                    </Dragger>
                  </div>

                  <Button
                    type="primary"
                    onClick={() => handleFileUpload(currentAppId)}
                    loading={uploading[currentAppId]}
                    style={{ marginTop: "10px", width: "100%" }}
                  >
                    {uploading[currentAppId] ? "Uploading..." : "Upload"}
                  </Button>
                </div>
              )}
            </Drawer>
          </>
        );
      },
    },

    {
      title: "ACTIONS",
      key: "actions",
      render: (_, record) => {
        // console.log("record action", record);

        // Check if noc_file is available
        if (record.noc_file) {
          return (
            <Button
              type="primary"
              onClick={() => handleRecall(record)}
              disabled={record.app_id_status === "13"}
            >
              Recall
            </Button>
          );
        }

        // If noc_file is not available, return null to render nothing
        return null;
      },
    },
  ];

  const handleViewDocs = (docDetails) => {
    setSelectedDocDetails(docDetails);
    setDrawerVisible(true);
  };

  const handleCloseDrawer = () => {
    setDrawerVisible(false);
  };

  const receivedDocsData = useSelector(
    (state) => state.fetchPDDWarehouse?.data?.received_docs || []
  );
  const physicalDocs = useSelector(
    (state) => state.fetchPDDWarehouse?.data?.physical_docs || []
  );

  // console.log("Physical docs data - ", receivedDocsData);

  // const applicationIds = extractApplicationIds(receivedDocsData, appIDData);
  // console.log('Extracted app id - ', applicationIds);

  const appIDData = useSelector(
    (state) => state.fetchPDDAppID?.data?.message || []
  );
  // console.log("appIdData",appIDData)
  const appIDDataRFW = useSelector(
    (state) => state.fetchPDDAppIDRFW?.data?.message || []
  );
  // console.log("appIDDataRFW", appIDDataRFW);
  const appIDDataRFWPhy = useSelector(
    (state) => state.fetchPDDAppIDRFWPhy?.data?.message || []
  );
  // console.log("appIDDataRFWPhy", appIDDataRFWPhy);
  const masterData = useSelector(
    (state) => state.fetchPDDMaster?.data?.message || []
  );
  // console.log("masterData",masterData)

  // const applicationIds = extractApplicationIds(receivedDocsData, appIDData);
  const applicationIds = extractApplicationIds(appIDDataRFW);
  // console.log("Extracted app id - ", applicationIds);

  const availableApplicationIds = applicationIds.filter(
    (appId) => !selectedAppIDs.includes(appId)
  );

  const applicationIdsPhy = extractApplicationIds(appIDDataRFWPhy);

  const availableApplicationIdsPhy = applicationIdsPhy.filter(
    (appId) => !selectedAppIDsPhy.includes(appId)
  );

  const courierVendorData = masterData.find(
    (item) => item.field === "courier_vendor"
  );

  useEffect(() => {
    if (courierVendorData) {
      const vendorOptions = courierVendorData.value.map((vendor) => ({
        value: vendor,
        label: vendor,
      }));
      setVendors(vendorOptions);
    } else {
      // Handle the case where courierVendorData is undefined, e.g., setVendors to an empty array
      setVendors([]);
    }
  }, [courierVendorData, setVendors]);

  // console.log("Master Data - ", masterData);
  // Further processing to remove spaces, if necessary

  // Convert jwt_branch string to an array of branch codes
  const branchArray = user_branch.replace(/[()]/g, "").split(",");

  // Filter appIDData to include only entries where BR_CD matches jwt_branch
  const cleanAppIDData = appIDData.filter((app) =>
    branchArray.includes(app.BR_CD.split(" ")[0])
  );
  // console.log("App ID data - ", appIDData);

  // console.log("chat data warehouse - ", chatData);

  useEffect(() => {
    // console.log("State refreshed");
    if (chatData && chatData.length > 0) {
      const formattedData = chatData.map((item) => {
        const formattedItem = {};

        // Dynamically add all keys and values from item to formattedItem
        Object.keys(item).forEach((key) => {
          formattedItem[key] = item[key];
        });

        // console.log("formattedItem", formattedItem);

        return formattedItem;
      });

      // console.log("Table data - ", formattedData);
      setTableData(formattedData);
    }
  }, [chatData]);

  const handleRowClick = async (record) => {
    // console.log("Selected record - ", record);
  };

  useEffect(() => {
    if (!searchValue || !searchField) {
      setFilteredData(chatData); // No search applied
    } else {
      const filtered = chatData.filter((item) => {
        const fieldValue = item[searchField];
        if (Array.isArray(fieldValue)) {
          return fieldValue.some((subValue) =>
            subValue
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase())
          );
        }
        return fieldValue
          ?.toString()
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      });
      setFilteredData(filtered);
    }
  }, [chatData, searchValue, searchField]);

  // console.log("filtered data", filteredData);

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleSearchFieldChange = (value) => {
    setSearchField(value);
    setEnableSearch(false);
  };

  // Convert jwt_group string to an array of branch IDs
  const jwtGroupArray = user_branch.slice(1, -1).split(",");

  const handleCloseModal = () => {
    setShowWarehouseUpload(false);
    setFileModalVisible(false);
    setPhysicalModalVisible(false);
    setSelectedCardData(null);
  };

  const floatButtonProps = useSpring({
    to: { transform: "translateY(0px)", opacity: 1 },
    from: { transform: "translateY(100px)", opacity: 0 },
    config: { tension: 200, friction: 10 },
  });

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const onFinish = (values) => {
    // console.log("Submitted values - ", values, selectedOption);
    if (selectedOption == "addFile") {
      const requestBody = {
        type: "3",
        kyc_carton: values.boxNumber,
        app_id: selectedAppIDs,
      };
      dispatch(addWarehouseEntry({ jwtBearer, requestBody }))
        .then(() => {
          // Dispatch fetchChatData after updateRescheduleDate is successful
          dispatch(fetchPDDWarehouse({ jwtBearer }));
          // Reset the form fields after submission
          form.resetFields();
          // Clear the selected application IDs as well
          setSelectedAppIDs([]);
          setSelectedAppIDsPhy([]);
          // Optionally, close the modal if that's the desired behavior
          setShowWarehouseUpload(false);
        })
        .catch((error) => {
          console.error("Error updating reschedule date:", error);
        });
    } else {
      const requestBody = {
        type: "2",
        phy_carton: values.cartonNumber,
        boxes: values.files,
      };

      dispatch(addWarehouseEntry({ jwtBearer, requestBody }))
        .then(() => {
          // Dispatch fetchChatData after updateRescheduleDate is successful
          dispatch(fetchPDDWarehouse({ jwtBearer }));
          // Reset the form fields after submission
          form.resetFields();
          // Clear the selected application IDs as well
          setSelectedAppIDs([]);
          setSelectedAppIDsPhy([]);
          // Optionally, close the modal if that's the desired behavior
          setShowWarehouseUpload(false);
        })
        .catch((error) => {
          console.error("Error updating reschedule date:", error);
        });
    }
  };

  const onReset = () => {
    form.resetFields();
  };

  const handleSelectChange = (value) => {
    if (!selectedAppIDs.includes(value)) {
      setSelectedAppIDs([...selectedAppIDs, value]);
    }
  };

  const handleRemoveAppID = (appID) => {
    setSelectedAppIDs(selectedAppIDs.filter((id) => id !== appID));
  };

  const handleAppIDChange = (name, value) => {
    setSelectedAppIDs((prev) => {
      const updated = [...prev];
      if (value) {
        updated.push(value);
      } else {
        const index = updated.indexOf(name);
        if (index > -1) {
          updated.splice(index, 1);
        }
      }
      return updated;
    });
  };

  const boxShadowFunc = (e) => {
    // console.log("Color func - ", e);
    switch (e) {
      case "In-Transit":
        return "0px 4px 8px rgba(245, 144, 39, 0.3)";
      case "In-Query":
        return "0px 4px 8px rgba(255, 0, 0, 0.3)";
      case "Received":
        return "0px 4px 8px rgba(33, 206, 66, 0.8)";
      default:
        return "0px 4px 8px rgba(0, 0, 0, 0.1)";
    }
  };

  return (
    <>
      <>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Form layout="inline" style={{ marginLeft: "10px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "78vw",
                textAlign: "end",
              }}
            >
              <animated.div
                style={{
                  ...floatButtonProps,
                  position: "fixed",
                  top: "70px",
                  right: "10px",
                }}
              ></animated.div>
            </div>

            <animated.div
  style={{
    ...floatButtonProps,
    position: "fixed",
    bottom: "20px",
    right: "20px",
    zIndex: 1000, // Ensure this is on the parent div too
  }}
>
  <Button
    type="primary"
    shape="circle"
    icon={
      openBox ? (
        <CodeSandboxOutlined style={{ fontSize: "36px", marginLeft: "3px" }} />
      ) : (
        <DropboxOutlined style={{ fontSize: "36px", marginLeft: "3px" }} />
      )
    }
    size="large"
    onClick={addBranchNewEntry}
    onMouseEnter={() => setOpenBox(false)}
    onMouseLeave={() => setOpenBox(true)}
    style={{
      width: "70px", // Increased width
      height: "70px", // Increased height
      fontSize: "24px", // Increased font size
      backgroundColor: "red",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
      zIndex: 1000, // Ensure zIndex is applied here too
    }}
  />
</animated.div>

          </Form>

          {/* refresh icon */}
          <div
            style={{
              position: "absolute",
              top: "20px",
              right: "35px",
              marginRight: "10px"
            }}
          >
            <Button
              type="primary"
              shape="circle"
              size="large"
              onClick={handleRefresh}
              style={{
                width: "40px",
                height: "40px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
                backgroundColor: "white",
                position: "relative",
                padding: 0,
                borderRadius: "50%",
              }}
            >
              {loading ? (
                <span
                  className={loading ? "spin" : ""}
                  style={{
                    fontSize: "16px",
                    fontWeight: "900",
                    color: "blue",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                    transformOrigin: "50% 50%",
                  }}
                >
                  <RiRefreshLine
                    style={{
                      fontSize: "40px",
                      color: "blue",
                      marginLeft: "4px",
                    }}
                    className="spin"
                  />
                </span>
              ) : (
                <RiRefreshLine
                  style={{
                    fontSize: "40px",
                    color: "blue",
                    marginLeft: "4px",
                  }}
                />
              )}
            </Button>
          </div>
        </div>

        {loading ? (
          <div className="spinner-container">
            <div
              style={{
                height: "800px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              {/* <Lottie
                animationData={truckAnimation}
                loop={true}
                style={{ width: "400px", height: "400px" }}
              /> */}
              <div className="spinner-container">
                <HashLoader color="#36d7b7" />
              </div>
            </div>
          </div>
        ) : (
          <MyStyledTable
            style={{ margin: "0 40px", marginTop: "10px" }}
            columns={columns}
            dataSource={filteredData}
            rowKey="app_id"
      
            pagination={{
              pageSize: 7,
              position: ['bottomLeft'],
            }}
          />
        )}
      </>

      <WarehouseUploadDrawer
        showAddBranchEntryModalVisible={showWarehouseUpload}
        handleCloseModal={handleCloseModal}
        jwtBearer={jwtBearer}
        fetchData={fetchData}
      />

      <DocsDetailsDrawer
        docDetails={selectedDocDetails}
        visible={drawerVisible}
        onClose={handleCloseDrawer}
      />
    </>
  );
};

export default HoToVendor;
