import React from 'react';

function BottomFrameReturning({ data }) {
    if (!data || data?.length === 0) {
        return null;
    }

    console.log("bottom frame data", data);

    // Function to calculate verification based on cal_files
    const calculateVerification = () => {
        if (!Array.isArray(data?.cal_files)) {
            // console.error("Invalid cal_files data:", data?.cal_files);
            return "4"; // Default verification if cal_files are missing or invalid
        }

        // Check if any cal_file is empty ("")
        const allCalFilesValid = data?.cal_files.every((calFile) => calFile.cal_file !== "");

        // Return "5" if all cal_file entries are not empty, else return "4"
        return allCalFilesValid ? "5" : "4";
    };

    // Function to set bottom frame color based on verification
    const bottomFrameVerification = (verification) => {
        switch (verification) {
            case "4":
                return "orange";
            case "5":
                return "green";
            case "8":
                return "red";
            default:
                return "0px 4px 8px rgba(0, 0, 0, 0.1)";
        }
    };

    // Calculate the overall verification based on cal_file values
    const overallVerification = calculateVerification();

    return (
        <div>
            <div
                style={{
                    height: "13px",
                    width: "220px",
                    border: "2px solid",
                    borderTopColor: bottomFrameVerification(overallVerification),
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: bottomFrameVerification(overallVerification),
                }}
            >
                <span style={{ color: "white" }}>
                    {data?.status && (
                        <b>
                            <p
                                style={{
                                    textAlign: "left",
                                    width: "100%",
                                    paddingLeft: "10px",
                                    fontSize: "12px",
                                    fontWeight: "800",
                                    fontFamily: "Poppins",
                                    letterSpacing: "3px",
                                    marginLeft: "17px",
                                }}
                            >
                                {Array.isArray(data?.status)
                                    ? data?.status.join(", ")
                                    : data?.status === "1"
                                    ? " IN-TRANSIT"
                                    : data?.status === "3"
                                    ? " RECEIVED"
                                    : data?.status}
                            </p>
                        </b>
                    )}
                </span>
            </div>
        </div>
    );
}

export default BottomFrameReturning;
