import React from 'react';
import { Button, Dropdown, Menu } from 'antd';
import * as XLSX from 'xlsx'; // For Excel generation
import { DownloadOutlined } from '@ant-design/icons';
import moment from 'moment';
// Function to generate Excel Report with customized columns

const generateExcelReport = (data, fileName, columns) => {
    // Map data to include selected columns and apply render functions where necessary
    const formattedData = data.map(item => {
        const row = {};
        columns.forEach(col => {
            let value = item[col.key];

            // If a column has a render function, apply it to the value
            if (col.render) {
                value = col.render(value);
            }

            row[col.label] = value;
        });
        return row;
    });

    // Create worksheet and workbook
    const ws = XLSX.utils.json_to_sheet(formattedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Report');
    XLSX.writeFile(wb, `${fileName}.xlsx`);
};


function Reports({ reportData }) {
    // function Reports({ reportData,warehouseData }) {
    // console.log("reportData", reportData);
    // console.log("warehouseData",warehouseData)
    const kitStatusData = reportData.filter(item => item.app_id_status !== "12" && item.app_id_status !== "13");
    // console.log("kitStatusData", kitStatusData);
    const queryData = reportData.filter(item => item.verification == "8");
    // console.log("queryData", queryData);
    const VerifiedData = reportData.filter(item => item.verification == "5");
    // console.log("VerifiedData", VerifiedData);



    const kitStatusReportColumns = [
        { label: 'Branch', key: 'branch' },
        { label: 'Loan ID', key: 'application_id' },
        { label: 'Product', key: 'product' },
        { label: 'Customer Name', key: 'applicant_name' },
        { label: 'Oxyzo ID', key: 'partner_loan_id' },
        {
            label: 'Status',
            key: 'app_id_status',
            render: (value) => {
                switch (value) {
                    case '1':
                        return 'In-Transit';
                    case '2':
                        return 'Not Received';
                    case '3':
                        return 'Received';

                    default:
                        return 'Unknown';
                }
            }
        },

       
        { label: 'Courier Name', key: 'vendor' },
        { label: 'POD Number', key: 'tracking_number' },
        { label: 'Dispatched By', key: 'courier_dispatcher_name' },
        {
            label: 'Dispatched On',
            key: 'created_at',
            render: (value) => {
                return value ? moment(value).format('DD-MM-YYYY') : '';
            }
        },
        { label: 'Received By', key: 'receiver_name' },
        {
            label: 'Received On',
            key: 'received_on',
            render: (value) => {
                return value ? moment(value).format('DD-MM-YYYY') : '';
            }
        },
        { label: 'Verified By', key: 'verified_by' },
        {
            label: 'Verified On', key: 'verified_at',
            render: (value) => {
                return value ? moment(value).format('DD-MM-YYYY') : '';
            }
        },
        { label: 'Stamp Number', key: 'stamp_no' },
        { label: 'Previous POD Number', key: 'pre_tracking_number' },
       
    ];

    const queryReportColumns = [
        { label: 'Branch', key: 'branch' },
        { label: 'Application ID', key: 'application_id' },
        { label: 'Partner Loan ID', key: 'partner_loan_id' },
        { label: 'Applicant Name', key: 'applicant_name' },
        { label: 'Dispatched By', key: 'courier_dispatcher_name' },
        {
            label: 'Dispatched On',
            key: 'created_at',
            render: (value) => {
                return value ? moment(value).format('DD-MM-YYYY') : '';
            }
        },
        { label: 'Checker', key: 'verified_by' },
        {
            label: 'Verified On', key: 'verified_at',
            render: (value) => {
                return value ? moment(value).format('DD-MM-YYYY') : '';
            }
        },

        {
            label: 'Status',
            key: 'doc_details',
            render: (record) => {
                const hasDamaged = record.damaged_docs.length > 0;
                const hasMissing = record.missing_documents.length > 0;
                const hasOther = record.other.length > 0;

                if (hasDamaged && hasMissing && hasOther) {
                    return 'Damaged/Missing/Other';
                } else if (hasDamaged && hasMissing) {
                    return 'Damaged/Missing';
                } else if (hasDamaged) {
                    return 'Damaged';
                } else if (hasMissing) {
                    return 'Missing';
                } else if (hasOther) {
                    return 'Other';
                } else {
                    return 'Unknown';
                }
            }

        }, 
        { label: 'Received By', key: 'receiver_name' },
        { label: 'Dispatched By', key: 'courier_dispatcher_name' },
        {
            label: 'Dispatched On',
            key: 'created_at',
            render: (value) => {
                return value ? moment(value).format('DD-MM-YYYY') : '';
            }
        },
        { label: 'Courier Name', key: 'vendor' },
        { label: 'POD Number', key: 'tracking_number' },
        { label: 'Stamp Number', key: 'stamp_no' },
        { label: 'Previous POD Number', key: 'pre_tracking_number' },
        {
            label: 'Received On',
            key: 'received_on',
            render: (value) => {
                return value ? moment(value).format('DD-MM-YYYY') : '';
            }
        },
        {
            label: 'Remark',
            key: 'doc_details',
            render: (record) => {
                const otherRemarks = record.other;
                console.log("otherRemarks: ", otherRemarks)

                if (Array.isArray(otherRemarks) && otherRemarks.length > 0) {
                    return JSON.stringify(otherRemarks, null, 2);
                } else {
                    return "";
                }
            }
        },

    ];

    const verificationReportColumns = [
        { label: 'Branch', key: 'branch' },
        { label: 'Client', key: 'applicant_name' },
        { label: 'Loan ID', key: 'application_id' },
        { label: 'Oxyzo ID', key: 'partner_loan_id' },
        { label: 'Disbursed Date', key: 'disbursal_date' },
        { label: 'Product', key: 'product' },
        { label: 'Dispatched By', key: 'courier_dispatcher_name' },
        {
            label: 'Dispatched On',
            key: 'created_at',
            render: (value) => {
                return value ? moment(value).format('DD-MM-YYYY') : '';
            }
        },
        { label: 'Received By', key: 'receiver_name' },
        {
            label: 'Received On',
            key: 'received_on',
            render: (value) => {
                return value ? moment(value).format('DD-MM-YYYY') : '';
            }
        },
        { label: 'Verified By', key: 'verified_by' },
        {
            label: 'Verified On', key: 'verified_at',
            render: (value) => {
                return value ? moment(value).format('DD-MM-YYYY') : '';
            }
        },
        { label: 'Manual Stamp No.', key: 'stamp_no' },

    ];


    const warehouseReportColumns = [
        { label: 'Application ID', key: 'application_id' },
        { label: 'Client', key: 'applicant_name' },
        { label: 'Oxyzo ID', key: 'partner_loan_id' },
        { label: 'Product', key: 'product' },
        { label: 'Carton No', key: 'carton_no' },
        { label: 'Box No', key: 'box_no' },
        { label: 'File Barcode No', key: 'file_barcode_no' },
        { label: 'Property File No', key: 'prop_file_no' },
        { label: 'Property File Barcode No', key: 'prop_file_barcode_no' }
    ];

    // Download Report Functions
    const downloadKitStatusReport = () => {
        generateExcelReport(kitStatusData, 'Kit_Status_Report', kitStatusReportColumns);
    };

    const downloadQueryReport = () => {
        generateExcelReport(queryData, 'Query_Report', queryReportColumns);
    };

    const downloadVerificationReport = () => {
        generateExcelReport(VerifiedData, 'Verification_Report', verificationReportColumns);
    };
    const downloadWarehouseReport = () => {
        generateExcelReport(warehouseData, 'Warehouse_Report', warehouseReportColumns);
    };

    // Menu with different report download options
    const menu = (
        <Menu>
            <Menu.Item onClick={downloadKitStatusReport}>Kit Status Report</Menu.Item>
            <Menu.Item onClick={downloadQueryReport}>Query Report</Menu.Item>
            <Menu.Item onClick={downloadVerificationReport}>Verification Report</Menu.Item>
            {/* <Menu.Item onClick={downloadWarehouseReport}>warehouse Report</Menu.Item> */}
        </Menu>
    );

    return (
        <div style={{ textAlign: 'center' }}>
            {/* Dropdown button for downloading reports */}
            <Dropdown overlay={menu} trigger={['click']}>
                <Button type="primary" size='large' icon={<DownloadOutlined />}>
                    Download Reports
                </Button>
            </Dropdown>
        </div>
    );
}

export default Reports;
