import styled from "styled-components";
import { Modal, Button, Slider, InputNumber, Table, Card } from "antd";

// export const StyledModal = styled(Modal)`
// //   width: 80%;

//   @media screen and (max-width: 768px) {
//     width: 100%;
//   }
// `;

export const RefreshButton = styled(Button)`
  margin-left: 16px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  // height: 45px;
  // padding: 0 24px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 8px;
  background: linear-gradient(90deg, #4e54c8, #8f94fb);
  border: none;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  transition: background 0.3s, box-shadow 0.3s;

&:hover {
  background: linear-gradient(90deg, #3e44b8, #7f84eb);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
}
`;

export const StyledTable = styled(Table)`
  transition: transform 0.3s ease-in-out;

  /* Table wrapper styles */
  .ant-table {
    border-radius: 8px;
    border: 1px solid #ddd;
    overflow-x: auto;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Added shadow for depth */
    scrollbar-color: #aaa white;
    scrollbar-width: thin;
  }

  /* Table header styles */
  .ant-table-thead > tr > th {
    background-color: #f5f5f5;
    font-weight: bold;
    color: #444;
    border-bottom: 2px solid #ddd; /* Thicker border for better separation */
    font-size: 20px; /* Increased font size for headers */
    padding: 12px; /* Added padding for better spacing */
    text-align: center; /* Centered header text */
  }

  /* Table row styles */
  .ant-table-tbody > tr {
    transition: background-color 0.2s ease, box-shadow 0.2s ease;
    &:nth-child(even) {
      background-color: #fafafa; /* Zebra striping */
    }
  }

  /* Hover effect on rows */
  .ant-table-tbody > tr:hover {
    cursor: pointer;
    background-color: #e6f7ff;
    font-weight: 500;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

  }

  /* Highlighting selected row */
  .ant-table-tbody > tr.ant-table-row-selected {
    background-color: #bae7ff;
    font-weight: bold; /* Bold text for selected row */
  }

  /* Table cell styles */
  .ant-table-tbody > tr > td {
    border-bottom: 1px solid #eee;
    font-size: 20px; /* Increased font size for data cells */
    padding: 12px; /* Added padding for better spacing */
    text-align: center; /* Centered cell text */
    
    &:hover {
      background-color: #f0f0f0; /* Hover effect on cells */
      transition: background-color 0.2s ease; /* Smooth transition for hover effect */
    }
  }

  /* Scrollable table with shadow */
  .ant-table-scroll {
    box-shadow: none; /* Remove shadow from scroll area if needed */
  }

  /* Pagination styles */
  .ant-pagination-item-active {
    border-color: #1890ff;
    background-color: #e6f7ff; /* Active pagination item color */
    color: #1890ff; /* Text color for active page number */
    
    &:hover {
      background-color: #d6e9ff; /* Hover effect on active item */
      color: #0056b3; /* Darker text on hover */
    }
  }
`;
export const ReceivedStyledTable = styled(Table)`
  transition: transform 0.3s ease-in-out;

  /* Table wrapper styles */
  .ant-table {
    border-radius: 8px;
    border: 1px solid #ddd;
    overflow-x: auto;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Added shadow for depth */
    scrollbar-color: #aaa white;
    scrollbar-width: thin;
  }

  /* Table header styles */
  .ant-table-thead > tr > th {
    background-color: #f5f5f5;
    color: #444;
    border-bottom: 2px solid #ddd; /* Thicker border for better separation */
    font-size: 18px; /* Increased font size for headers */
    padding: 12px; /* Added padding for better spacing */
    text-align: center; /* Centered header text */
  }

  /* Table row styles */
  .ant-table-tbody > tr {
    transition: background-color 0.2s ease, box-shadow 0.2s ease;
    &:nth-child(even) {
      background-color: #fafafa; /* Zebra striping */
    }
  }

  /* Hover effect on rows */
  .ant-table-tbody > tr:hover {
    cursor: pointer;
    background-color: #e6f7ff;
    font-weight: 500;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }

  /* Highlighting selected row */
  .ant-table-tbody > tr.ant-table-row-selected {
    background-color: #bae7ff;
    font-weight: bold; /* Bold text for selected row */
  }

  /* Table cell styles */
  .ant-table-tbody > tr > td {
    border-bottom: 1px solid #eee;
    font-size: 20px; /* Increased font size for data cells */
    padding: 12px; /* Added padding for better spacing */
    text-align: center; /* Centered cell text */
    
    &:hover {
      background-color: #f0f0f0; /* Hover effect on cells */
      transition: background-color 0.2s ease; /* Smooth transition for hover effect */
    }
  }

  /* Scrollable table with shadow */
  .ant-table-scroll {
    box-shadow: none; /* Remove shadow from scroll area if needed */
  }

  /* Pagination styles */
  .ant-pagination-item-active {
    border-color: #1890ff;
    background-color: #e6f7ff; /* Active pagination item color */
    color: #1890ff; /* Text color for active page number */
    
    &:hover {
      background-color: #d6e9ff; /* Hover effect on active item */
      color: #0056b3; /* Darker text on hover */
    }
  }

  /* Style the expanded row's icon and background */
  .ant-table-expanded-row .ant-table-expanded-row-wrapper {
    background-color: #f5f5f5; /* Ensure the expanded row has the same background color as the table header */
  }

  /* Optionally, you can target the icon itself */
  .ant-table-expanded-row .ant-table-row-expand-icon {
    background-color: #f5f5f5; /* Match icon background color to header */
    border-radius: 50%; /* Optional: If you want the icon to be circular */
  }
`;


export const MyStyledTable = styled(Table)`
  transition: transform 0.3s ease-in-out;
  padding:0 0px;

  /* Table wrapper styles */
  .ant-table {
    border-radius: 12px; /* Rounded corners for a modern look */
    border: 1px solid #e0e0e0;
    overflow-x: auto;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    background-color: #ffffff; /* Consistent background color */
    scrollbar-color: #aaa white;
    scrollbar-width: thin;
  }

  /* Table header styles */
  .ant-table-thead > tr > th {
    background-color: #4c91f9; /* Vibrant header background */
    font-weight: bold;
    color: #ffffff; /* Contrasting text color */
    border-bottom: 2px solid #3a7dda; /* Stronger border */
    font-size: 18px; /* Balanced font size for headers */
    padding: 14px; /* Better spacing for headers */
    text-align: center; /* Centered header text */
    transition: background-color 0.3s ease;

  }

  :where(.css-dev-only-do-not-override-xu9wm8).ant-table-wrapper
  .ant-table-container
  table
  > thead
  > tr:first-child
  > *:first-child {
  border-start-start-radius: 8px;
  background-color: rgb(76, 145, 249);
}


  /* Table row styles */
  .ant-table-tbody > tr {
    transition: all 0.3s ease-in-out; /* Smooth transitions */
    &:nth-child(even) {
      background-color: #f9fbff; /* Subtle zebra striping */
    }
  }

  /* Hover effect on rows */
  .ant-table-tbody > tr:hover {
    cursor: pointer;
    background-color: #e8f4ff; /* Light blue highlight */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Depth effect */
    transform: translateY(-2px); /* Lift effect */
  }

  /* Highlighting selected row */
  .ant-table-tbody > tr.ant-table-row-selected {
    background-color: #d0eaff; /* Distinct selected row color */
    font-weight: 600; /* Slightly bolder text */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2); /* Stronger shadow for emphasis */
  }

  /* Table cell styles */
  .ant-table-tbody > tr > td {
    border-bottom: 1px solid #f0f0f0; /* Subtle cell separators */
    font-size: 16px; /* Clear and readable font size */
    padding: 14px; /* Adequate spacing for data cells */
    text-align: center; /* Centered cell text */
    color: #333; /* Neutral text color */

    &:hover {
      background-color: #f3f6fa; /* Gentle hover effect on cells */
      transition: background-color 0.3s ease; /* Smooth transition */
    }
  }

  /* Scrollable table with shadow */
  .ant-table-scroll {
    box-shadow: inset 0 -2px 6px rgba(0, 0, 0, 0.1); /* Soft shadow effect for scrollable tables */
  }

  /* Pagination styles */
  .ant-pagination-item {
    border-radius: 4px; /* Rounded corners for pagination items */
    border: 1px solid #e0e0e0;
    margin: 0 4px;
  }

  .ant-pagination-item-active {
    border-color: #4c91f9;
    background-color: #e8f4ff; /* Vibrant active page color */
    color: #4c91f9; /* Consistent text color */

    &:hover {
      background-color: #d0eaff; /* Brighter hover effect for active item */
      color: #3a7dda; /* Slightly darker text */
    }
  }

  .ant-pagination-item:hover {
    background-color: #f0f7ff; /* Light hover effect for pagination items */
    color: #4c91f9; /* Text color on hover */
  }

  .ant-pagination-options {
    margin-left: 16px; /* Added spacing for better alignment */
  }
`;
export const MyDetailedStyledTable = styled(Table)`
  transition: transform 0.3s ease-in-out;
  padding: 0 20px;

  .ant-table {
    border-radius: 12px;
    border: 1px solid #e0e0e0;
    overflow-x: auto;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    scrollbar-color: #aaa white;
    scrollbar-width: thin;
    text-align: center;
  
  }

  .ant-table-thead > tr > th {
    background-color: rgb(30, 144, 255);
    font-weight: 800;
    color: #ffffff;
    border-bottom: 2px solid #3a7dda;
    font-size: 18px;
    padding: 14px;
    transition: background-color 0.3s ease;
    text-align: center;
  }

  .jXhSEW .ant-table {
    background:rgb(30, 144, 255)
  }

  .ant-table-tbody > tr {
    transition: all 0.3s ease-in-out;

    &:nth-child(even) {
      background-color: #f9fbff;
    }
  }

  .ant-table-tbody > tr:hover {
    background-color: #e8f4ff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
  }

  .ant-table-tbody > tr.ant-table-row-selected {
    background-color: #d0eaff;
    font-weight: 600;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  }

   .ant-table-tbody > tr > td:first-child {
     border-bottom: 1px solid #f0f0f0;
     font-size: 16px;
     padding: 14px;
     text-align: start;
     color: #333;

     &:hover {
       background-color: #f3f6fa;
       transition: background-color 0.3s ease;
     }
   }

   .ant-table-tbody > tr > td:nth-child(2) {
     border-bottom: 1px solid #f0f0f0;
     font-size: 16px;
     padding: 14px;
     text-align: center;
     color: #333;

     &:hover {
       background-color: #f3f6fa;
       transition: background-color 0.3s ease;
     }
   }

   .ant-table-scroll {
     box-shadow: inset 0 -2px 6px rgba(0, 0, 0, 0.1);
   }

   .ant-pagination-item {
     border-radius: 4px; 
     border: 1px solid #e0e0e0;
     margin: 0 4px;
   }

   .ant-pagination-item-active {
     border-color: #4c91f9;
     background-color: #e8f4ff; 
     color: #4c91f9; 

     &:hover {
       background-color: #d0eaff; 
       color: #3a7dda; 
     }
   }

   .ant-pagination-item:hover {
     background-color: #f0f7ff; 
     color: #4c91f9; 
   }

   .ant-pagination-options {
     margin-left: 16px; 
   }
`;
export const MyDetailedStyledTempTable = styled(Table)`
  transition: transform 0.3s ease-in-out;
  padding: 0 5px;

  .ant-table {
    border-radius: 12px;
    border: 1px solid #e0e0e0;
    overflow-x: auto;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    scrollbar-color: #aaa white;
    scrollbar-width: thin;
    text-align: center;
  
  }

  .ant-table-thead > tr > th {
    background-color: rgb(30, 144, 255);
    font-weight: 800;
    color: #ffffff;
    border-bottom: 2px solid #3a7dda;
    font-size: 18px;
    padding: 14px;
    transition: background-color 0.3s ease;
    text-align: center;
  }

  .jXhSEW .ant-table {
    background:rgb(30, 144, 255)
  }

  .ant-table-tbody > tr {
    transition: all 0.3s ease-in-out;

    &:nth-child(even) {
      background-color: #f9fbff;
    }
  }

  .ant-table-tbody > tr:hover {
    background-color: #e8f4ff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
  }

  .ant-table-tbody > tr.ant-table-row-selected {
    background-color: #d0eaff;
    font-weight: 600;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  }

   .ant-table-tbody > tr > td:first-child {
     border-bottom: 1px solid #f0f0f0;
     font-size: 16px;
     padding: 14px;
     text-align: start;
     color: #333;

     &:hover {
       background-color: #f3f6fa;
       transition: background-color 0.3s ease;
     }
   }

   .ant-table-tbody > tr > td:nth-child(2) {
     border-bottom: 1px solid #f0f0f0;
     font-size: 16px;
     padding: 14px;
     text-align: center;
     color: #333;

     &:hover {
       background-color: #f3f6fa;
       transition: background-color 0.3s ease;
     }
   }

   .ant-table-scroll {
     box-shadow: inset 0 -2px 6px rgba(0, 0, 0, 0.1);
   }

   .ant-pagination-item {
     border-radius: 4px; 
     border: 1px solid #e0e0e0;
     margin: 0 4px;
   }

   .ant-pagination-item-active {
     border-color: #4c91f9;
     background-color: #e8f4ff; 
     color: #4c91f9; 

     &:hover {
       background-color: #d0eaff; 
       color: #3a7dda; 
     }
   }

   .ant-pagination-item:hover {
     background-color: #f0f7ff; 
     color: #4c91f9; 
   }

   .ant-pagination-options {
     margin-left: 16px; 
   }
`;


export const StyledSlider = styled(Slider)`
  .ant-slider-track {
    background: linear-gradient(90deg, #36D1DC 0%, #5B86E5 100%); // Gradient track color
    height: 10px; // Track height
    border-radius: 10px; // Rounded track
  }

  .ant-slider-rail {
    background-color: #e9e9e9; // Rail color
    height: 10px; // Rail height
    border-radius: 10px; // Rounded rail
  }

  .ant-slider-handle {
    border-color: #5B86E5; // Handle border color
    background-color: #fff; // Handle background color
    height: 24px; // Handle height
    width: 24px; // Handle width
    margin-top: -7px; // Adjust handle position
    border: 2px solid #5B86E5; // Border thickness
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); // Handle shadow
    &:focus {
      border-color: #36D1DC;
      box-shadow: 0 0 0 5px rgba(54, 209, 220, 0.2); // Focus shadow
    }
  }

  .ant-slider-mark-text {
    color: #5B86E5; // Mark text color
    font-size: 14px; // Mark font size
    font-weight: 600; // Mark font weight
  }
`;

// Styled InputNumber component
export const StyledInputNumber = styled(InputNumber)`
  .ant-input-number-input {
    color: #5B86E5;
    font-size: 16px; // Increase font size
    font-weight: 600; // Increase font weight
  }
  .ant-input-number-handler-wrap {
    display: none; // Hide the handlers
  }
  border: 2px solid #5B86E5; // Border color
  border-radius: 5px; // Rounded corners
  width: 100%; // Full width
  text-align: center; // Center align text
`;

export const StyledModal = styled(Modal)`
.ant-modal-content {
  border-radius: 10px;
}

.ant-modal-header {
  border-radius: 10px 10px 0 0;
  background-color: #f0f2f5;
}

.ant-modal-footer {
  display: none; // Hide the footer if not used
}
`;

export const StyledRow = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;

export const StyledCol = styled.div`
  flex: 1;
  max-width: 900px;
  // margin-left: 18px;
//   margin-right: 18px;
  overflow-y: auto;
  overflow-x: hidden;

  @media screen and (max-width: 768px) {
    max-width: 100%;
  }
`;

export const StyledButton = styled(Button)`
margin-left: 80px;
display: flex;
align-items: center;
justify-content: center;
height: 45px;
padding: 0 24px;
font-size: 16px;
font-weight: bold;
border-radius: 8px;
background: linear-gradient(90deg, #4e54c8, #8f94fb);
border: none;
box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
transition: background 0.3s, box-shadow 0.3s;

&:hover {
  background: linear-gradient(90deg, #3e44b8, #7f84eb);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
}
`;

export const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-top: 20px;
`;

export const StyledCard = styled(Card)`
  width: 300px;
  border-radius: 12px;  // Rounded corners
  background: #ffffff;  // White background
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);  // Subtle shadow
  transition: transform 0.3s ease, box-shadow 0.3s ease, background 0.3s ease;
  overflow: hidden;  // Ensure rounded corners are respected
  
  // Card header styling
  .ant-card-head {
    background: #f7f7f7;  // Light gray header background
    border-bottom: 1px solid #e8e8e8;  // Subtle border at the bottom of the header
    border-radius: 12px 12px 0 0;  // Match rounded corners on the top
    padding: 16px; // Add padding to header
    font-size: 18px; // Increase header font size
    font-weight: bold; // Make header bold
    color: #333; // Darker text color for contrast
    text-align: center; // Center align header text
  }
  
  // Card body styling
  .ant-card-body {
    padding: 16px; // Add padding inside the card
    font-size: 16px; // Set body text size
    color: #555; // Dark gray text color for better readability
    line-height: 1.5; // Improve line height for readability
    


    p {
      margin-bottom: 8px; // Add spacing between paragraphs
      display: flex;
      align-items: center;
      justify-content: space-between;
      
      // &:before {
      //   content: '•'; // Bullet point before each paragraph
      //   margin-right: 8px;
      //   color: #007bff; // Customize bullet color
      // }
    }
    
    /* Optional - Add a border around the body */
    border-top: 1px solid #e8e8e8; // Subtle border at the top of the body
    padding-top: 12px; // Padding adjustment after border
  }
  
  &:hover {
 
    box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.2); // Enhanced shadow on hover
    background: #f0f0f0; // Subtle background color change on hover
    cursor: pointer; // Change cursor to pointer
    transition-duration: .2s;
    
    .ant-card-head {
      background-color: #e6f7ff; // Change header background on hover
      color: #0056b3; // Change text color on hover for contrast
    }
    
    p {
      color: #333; // Darker text on hover for better visibility 
    }
    
    &:before {
      color: #0056b3; // Change bullet point color on hover 
    }
}
`;

