import React from 'react'

function CargoArea({ data }) {
  return (
    <div style={{
      display: 'flex', flexDirection: 'column', marginTop: "10px"
    }}>
      {/* Cargo Area */}
      <div
        style={{
          color: "black",
          fontSize: "16px",
          textAlign: "center",
          // lineHeight: "60px",
          fontWeight: "bold"
        }}
      >
        {data?.tracking_number.toUpperCase()}
      </div>
      <div
        style={{
          color: "black",
          fontSize: "12px",
          textAlign: "center",
          // lineHeight: "60px",
          fontWeight:"500",
          width: "100%",
          top: 0,
          left: 0,
        }}
      >
        {data?.vendor.toUpperCase()}
      </div>
    </div>
  )
}

export default CargoArea
