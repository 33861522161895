import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { BASE_URL } from '../apiConfig'
import { message, notification } from 'antd'


export const addMissingDocs = createAsyncThunk(
    'addMissingDocs',
    async ({ jwtBearer, requestBody }) => {
        console.log('Request Body - ', requestBody);
        const options = {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + jwtBearer,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestBody)
        };

        try {
            const response = await fetch(
                `${BASE_URL}pdd/add/missing/docs/`,
                options
            );

            console.log('Request Options - ', options);
            console.log('Response -- ', response);

            // Check if response is not ok
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to add branch entry');
            }

            return await response.json(); // Assuming the response is JSON
        } catch (error) {
            console.log('Error', error);

            // Extracting error message for notification
            const errorMessage = error.message || 'An unexpected error occurred.';

            // Use a notification or alert here instead of trying to render the error object
            notification.error({
                message: 'Error',
                description: errorMessage,
                placement: 'bottomRight',
                duration: 5,
            });

            throw error; // Rethrow error for rejected case
        }
    }
);

export const markAppIdRecieve = createAsyncThunk(
    'markAppIdRecieve',
    async ({ jwtBearer, requestBody }) => {
        console.log('Request Body - ', requestBody);
        const options = {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + jwtBearer,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestBody)
        };

        try {
            const response = await fetch(
                `${BASE_URL}pdd/mark/receive/`,
                options
            );

            console.log('Request Options - ', options);
            console.log('Response -- ', response);
            console.log('request body --',requestBody);

            // Check if response is not ok
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to mark as received');
            }

            return await response.json(); 
        } catch (error) {
            console.log('Error', error);

            // Extracting error message for notification
            const errorMessage = error.message || 'An unexpected error occurred.';
            throw error;
        }
    }
);
export const postStampNo = createAsyncThunk(
    'postStampNo',
    async ({ jwtBearer, requestBody }) => {
        console.log('Request Body - ', requestBody);
        const options = {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + jwtBearer,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestBody)
        };

        try {
            const response = await fetch(
                `${BASE_URL}pdd/post/stamp/no/`,
                options
            );

            console.log('Request Options - ', options);
            console.log('Response -- ', response);

            // Check if response is not ok
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to post stamp no');
            }

            return await response.json(); // Assuming the response is JSON
        } catch (error) {
            console.log('Error', error);

            // Extracting error message for notification
            const errorMessage = error.message || 'An unexpected error occurred.';


            throw error; // Rethrow error for rejected case
        }
    }
);

export const deleteNotReceivedId = createAsyncThunk(
    'deleteNotReceivedId',
    async ({ jwtBearer, requestBody }) => {
        console.log('Request Body - ', requestBody);
        const options = {
            method: 'DELETE',
            headers: {
                Authorization: 'Bearer ' + jwtBearer,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestBody)
        };

        try {
            const response = await fetch(
                `${BASE_URL}pdd/delete/not/received/id/`,
                options
            );

            console.log('Request Options - ', options);
            console.log('Response -- ', response);

            // Check if response is not ok
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to delete');
            }

            return await response.json(); // Assuming the response is JSON
        } catch (error) {
            console.log('Error', error);

            // Extracting error message for notification
            const errorMessage = error.message || 'An unexpected error occurred.';

            // Use a notification or alert here instead of trying to render the error object
            // notification.error({
            //     message: 'Error',
            //     description: errorMessage,
            //     placement: 'bottomRight',
            //     duration: 5,
            // });

            throw error; // Rethrow error for rejected case
        }
    }
);


export const addAdditionalDocuments = createAsyncThunk(
    'addAdditionalDocuments',
    async ({ jwtBearer, requestBody }) => {
        console.log('Request Body - ', requestBody);
        const options = {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + jwtBearer,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestBody)
        };

        try {
            const response = await fetch(
                `${BASE_URL}pdd/add/additional/docs/`,
                options
            );

            console.log('Request Options - ', options);
            console.log('Response -- ', response);

            // Check if response is not ok
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to add additional document');
            }

            return await response.json(); 
        } catch (error) {
            console.log('Error', error);

            const errorMessage = error.message || 'An unexpected error occurred.';


            throw error; 
        }
    }
);


const addMissingDocsSlice = createSlice({
    name: 'addMissingDocs',
    initialState: {
        isLoading: false,
        data: null,
        isError: false,
        errorMessage: null
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(addMissingDocs.pending, state => {
            state.isLoading = true
            state.isError = false
            state.errorMessage = null
        })
        builder.addCase(addMissingDocs.fulfilled, (state, action) => {
            state.isLoading = false
            state.data = action.payload
            state.isError = false
            state.errorMessage = null
        })
        builder.addCase(addMissingDocs.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.errorMessage = action.error.message
        })
    }
})

export default addMissingDocsSlice.reducer
