import React, { useState } from 'react';
import { Button, Form, Input, notification, Alert, Row, Col } from 'antd';
import { useDispatch } from 'react-redux';
import { postBankVerification, postVerifyIfsc } from '../../../redux/slice/postBankVerification';
import { useAuth } from "../../../context/AuthContext";

const BankVerification = (props) => {
  const [isVerifyLoading, setIsVerifyLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isIfscVerified, setIsIfscVerified] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertType, setAlertType] = useState(null);
  const [alertDescription, setAlertDescription] = useState(null);
  const [bankDetails, setBankDetails] = useState({ bank: '', branch: '' }); 
  const [ifscResponse,setIfscResponse] = useState({})
  const [isCompleted,setIsCompleted] = useState(false)

  const { authState } = useAuth();
  const dispatch = useDispatch();
  const jwtBearer = authState.token;

  const accountNumberRegex = /^[0-9]+$/;
  // const ifscCodeRegex = /^[A-Z]{4}[0][A-Z0-9]{6}$/;
  const ifscCodeRegex = /^[A-Za-z]{4}[0][A-Za-z0-9]{6}$/;

  const [form] = Form.useForm();


  const handleVerifyIFSC = async () => {
    if (!form.getFieldValue('ifscCode')) {
      notification.error({
        message: 'Missing Information',
        description: 'Please enter IFSC code.',
        placement: 'bottomRight',
      });
      return;
    }

    setIsVerifyLoading(true);
    setAlertMessage(null);
    setAlertType(null);
    setAlertDescription(null);

    try {
      const requestBody = {
        // acc_no: form.getFieldValue('accountNumber'),
        ifsc: form.getFieldValue('ifscCode'),
      };

      console.log("requestBody",requestBody)

      const response = await dispatch(postVerifyIfsc({
        jwtBearer,
        requestBody,
      }));
      console.log("response ifsc",response)
      setIfscResponse(response.payload.ifsc_verification)

      if (response.meta.requestStatus === 'fulfilled') {
        const { ifsc_verification } = response.payload;

        if (ifsc_verification) {
          const { BANK, BRANCH } = ifsc_verification;

          // setAlertMessage('Success');
          // setAlertDescription(`IFSC Code Verified Successfully`);
          // setAlertType('success');
          setIsIfscVerified(true);

          setBankDetails({ bank: BANK, branch: BRANCH });
          form.setFieldsValue({ bank: BANK, branch: BRANCH }); 

          notification.success({
            message: 'Success',
            description: 'IFSC Code Verified Successfully.',
            placement: 'bottomRight',
          });
          
        } else {
          // setAlertMessage('Error');
          // setAlertDescription('The entered IFSC code is invalid or not recognized.');
          // setAlertType('error');
          notification.error({
            message: 'Error',
            description: 'The entered IFSC code is invalid or not recognized.',
            placement: 'bottomRight',
          });
        }
      } else if (response.meta.requestStatus === 'rejected') {
        // const errorDetail = "The entered IFSC code is invalid or not found.";

        // setAlertMessage('Error');
        // setAlertDescription(errorDetail);
        // setAlertType('error');

        notification.error({
          message: 'Error',
          description: 'The entered IFSC code is invalid or not recognized.',
          placement: 'bottomRight',
        });
      }
    } catch (error) {
      // setAlertMessage('Verification Failed');
      // setAlertDescription('An error occurred while verifying the IFSC code. Please try again.');
      // setAlertType('error');
      notification.error({
        message: 'Error',
        description: 'An error occurred while verifying the IFSC code. Please try again.',
        placement: 'bottomRight',
      });
    } finally {
      setIsVerifyLoading(false);
    }
  };

  const handleSubmit = async (values) => {
    const requestBody = {
      acc_no: values.accountNumber,
      ifsc: values.ifscCode,
      name: values.holderName,
      ifsc_response:ifscResponse
    };

    setIsSubmitLoading(true);
    setAlertMessage(null);
    setAlertType(null);
    setAlertDescription(null);

    try {
      const response = await dispatch(postBankVerification({
        jwtBearer,
        requestBody,
      }));

      if (response.meta.requestStatus === 'fulfilled') {
        const { data, success, errorMessage, errorCode } = response.payload.response;

        if (data?.verified && data.statusAsPerSource === "VALID" && data.bankResponse === "SUCCESSFUL TRANSACTION") {
          setAlertMessage('Success');
          setAlertDescription('Your bank account verification was successful.');
          setAlertType('success');
          // notification.success({
          //   message: 'Success',
          //   description: 'Your bank account verification was successful.',
          //   placement: 'topRight',
          // });
          setIsCompleted(true)
        }

        else if (data?.verified === false && data?.bankResponse.includes("does not match with the response")) {
          setAlertMessage('Account Holder Name Mismatch');
          setAlertDescription(data.bankResponse);
          setAlertType('info');
          setIsCompleted(true)
          // notification.info({
          //   message: 'Account Holder Name Mismatch',
          //   description: data.bankResponse,
          //   placement: 'topRight',
          // });
        }

        else if (data?.verified === false && data?.statusAsPerSource === "INVALID" && data?.bankResponse === "INVALID TRANSACTION") {
          setAlertMessage('Invalid Bank Account Number');
          setAlertDescription('The bank account number is invalid.');
          setAlertType('warning');
          // notification.warning({
          //   message: 'Invalid Bank Account Number',
          //   description: 'The bank account number is invalid.',
          //   placement: 'topRight',
          // });
        }

        else if (!data?.verified) {
          const errorText = errorMessage || 'An unexpected error occurred. Please try again.';
          setAlertMessage('Error');
          setAlertDescription(errorText);
          setAlertType('error');
          // notification.error({
          //   message: 'Verification Failed',
          //   description: errorText,
          //   placement: 'topRight',
          // });
        }
      }
    } catch (error) {
      setAlertMessage('Verification Failed');
      setAlertDescription('An unexpected error occurred. Please try again.');
      setAlertType('error');
      // notification.error({
      //   message: 'Verification Failed',
      //   description: 'An unexpected error occurred. Please try again.',
      //   placement: 'topRight',
      // });
    } finally {
      setIsSubmitLoading(false);
    }
  };

 console.log("ifscResponse",ifscResponse)

const handleIfscCodeChange = (e) => {
  // Convert the input to uppercase
  const uppercaseIfscCode = e.target.value.toUpperCase();

  // Set the uppercase value to the form field
  form.setFieldsValue({ ifscCode: uppercaseIfscCode });

  // Manually update the value in the input field
  e.target.value = uppercaseIfscCode;
  
  // Reset the verification status
  setIsIfscVerified(false);
};


  const handleReset = () => {
    form.resetFields(); 
    setIfscResponse({})
    setIsIfscVerified(false);
    setBankDetails({ bank: '', branch: '' });
    setAlertMessage(null);
    setAlertType(null);
    setAlertDescription(null);
    setIsCompleted(false);
    
  };

  console.log("isCompleted", isCompleted);

  return (
    <div
      style={{
        minHeight: '113vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'linear-gradient(135deg, #6E7E8D, #B3C1C5)',
        padding: '30px 0'
      }}
    >

<Form
  form={form}
  style={{
    width: '100%',
    maxWidth: '780px',
    padding: '40px',
    borderRadius: '12px',
    boxShadow: '0 6px 15px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#ffffff',
    overflow: 'hidden',
  }}
  layout="vertical"
  onFinish={handleSubmit}
>
  <h2
    style={{
      textAlign: 'center',
      marginBottom: '30px',
      fontSize: '24px',
      fontWeight: 'bold',
      color: '#4a90e2',
      fontFamily: "'Roboto', sans-serif",
    }}
  >
    Bank Account Verification
  </h2>



  {/* IFSC Code */}
  <Form.Item
    label={<span style={{ fontFamily: "Poppins", fontSize: "18px" }}>IFSC Code</span>}
    name="ifscCode"
    rules={[{ required: true, message: 'Please input IFSC code!' }, { pattern: ifscCodeRegex, message: 'Please enter a valid IFSC code.' }]}
  >
    <Row gutter={8}>
      <Col span={18}>
        <Input
          placeholder="Enter IFSC code"
          style={{
            borderRadius: '8px',
            padding: '10px',
            fontSize: '16px',
            fontFamily: "'Roboto', sans-serif",
          }}
          onChange={handleIfscCodeChange}
          disabled={isIfscVerified} 
        />
      </Col>
      <Col span={6}>
        <Button
          type="default"
          style={{
            width: '100%',
            borderRadius: '8px',
            padding: '10px',
            fontSize: '16px',
            backgroundColor: '#4a90e2',
            color: '#fff',
            fontFamily: "'Roboto', sans-serif",
          }}
          onClick={handleVerifyIFSC}
          loading={isVerifyLoading}
          disabled={isIfscVerified} 
        >
          Verify
        </Button>
      </Col>
    </Row>
  </Form.Item>


{ isIfscVerified &&  (
  <>
  <Form.Item
    label={<span style={{ fontFamily: "Poppins", fontSize: "18px" }}>Bank Name</span>}
    name="bank"
    initialValue={bankDetails.bank}
  >
    <Input
      placeholder="Bank name"
      value={bankDetails.bank}
      disabled={true} 
      style={{
        borderRadius: '8px',
        padding: '10px',
        fontSize: '16px',
        fontFamily: "'Roboto', sans-serif",
      }}
    />
  </Form.Item>

  <Form.Item
    label={<span style={{ fontFamily: "Poppins", fontSize: "18px" }}>Branch</span>}
    name="branch"
    initialValue={bankDetails.branch}
  >
    <Input
      placeholder="Branch name"
      disabled={true} 
      value={bankDetails.branch}
      style={{
        borderRadius: '8px',
        padding: '10px',
        fontSize: '16px',
        fontFamily: "'Roboto', sans-serif",
      }}
    />
  </Form.Item>
  </>
)}

{/* Bank Account Number */}
{/* <Form.Item
  label={<span style={{ fontFamily: "Poppins", fontSize: "18px" }}>Bank Account Number</span>}
  name="accountNumber"
  rules={[
    { required: true, message: 'Please input account number!' },
    { pattern: accountNumberRegex, message: 'Account number must be numeric.' }
  ]}
>
  <Input
    placeholder="Enter account number"
    style={{
      borderRadius: '8px',
      padding: '10px',
      fontSize: '16px',
      fontFamily: "'Roboto', sans-serif",
    }}
    disabled={!isIfscVerified}
  />
</Form.Item> */}

<Form.Item
  label={<span style={{ fontFamily: "Poppins", fontSize: "18px" }}>Bank Account Number</span>}
  name="accountNumber"
  rules={[
    { required: true, message: 'Please input account number!' },
    { pattern: accountNumberRegex, message: 'Account number must be numeric.' }
  ]}
>
  <Input.Password
    placeholder="Enter account number"
    style={{
borderRadius: '8px',
      paddingBottom:"40px",
      fontSize: '16px',
      fontFamily: "'Roboto', sans-serif",
      lineHeight:"20px",
      height:"40px"
    }}
    disabled={!isIfscVerified}
    iconRender={() => null}  // This will hide the "eye" icon
  />
</Form.Item>

{/* Re-enter Bank Account Number */}
<Form.Item
  label={<span style={{ fontFamily: "Poppins", fontSize: "18px" }}>Re-Enter Bank Account Number</span>}
  name="recheckAccountNumber"
  dependencies={['accountNumber']} 
  rules={[
    { required: true, message: 'Please re-enter account number!' },
    { pattern: accountNumberRegex, message: 'Account number must be numeric.' },
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (!value || getFieldValue('accountNumber') === value) {
          return Promise.resolve();
        }
        return Promise.reject(new Error('The account numbers do not match!'));
      },
    }),
  ]}
>
  <Input
    placeholder="Re-enter account number"
    style={{
      borderRadius: '8px',
      padding: '10px',
      fontSize: '16px',
      fontFamily: "'Roboto', sans-serif",
    }}
    disabled={!isIfscVerified}
  />
</Form.Item>


  {/* <Form.Item
    label={<span style={{ fontFamily: "Poppins", fontSize: "18px" }}>Account Holder Name</span>}
    name="holderName"
    rules={[{ required: true, message: 'Please enter the account holder name!' }]}
  >
    <Input
      placeholder="Enter the account holder's name"
      style={{
        borderRadius: '8px',
        padding: '10px',
        fontSize: '16px',
        fontFamily: "'Roboto', sans-serif",
      }}
      disabled={!isIfscVerified} 
    />
  </Form.Item> */}

  <Form.Item
  label={<span style={{ fontFamily: "Poppins", fontSize: "18px" }}>Account Holder Name</span>}
  name="holderName"
  rules={[
    { required: true, message: 'Please enter the account holder name!' },
    { 
      pattern: /^[^\d]*$/, 
      message: 'Account holder name cannot contain numbers.' 
    }
  ]}
>
  <Input
    placeholder="Enter the account holder's name"
    style={{
      borderRadius: '8px',
      padding: '10px',
      fontSize: '16px',
      fontFamily: "'Roboto', sans-serif",
    }}
    disabled={!isIfscVerified} 
  />
</Form.Item>

  {alertMessage && (
    <Alert
      message={alertMessage}
      description={alertDescription}
      type={alertType}
      showIcon
      style={{ marginBottom: '20px' }}
    />
  )}

  <Form.Item label={null}>
    <Button
      type="primary"
      htmlType="submit"
      block
      style={{
        borderRadius: '8px',
        padding: '12px',
        fontSize: '18px',
        backgroundColor: '#4a90e2',
        borderColor: '#4a90e2',
        transition: 'all 0.3s ease',
        fontFamily: "'Roboto', sans-serif",
      }}
      loading={isSubmitLoading}
      disabled={isCompleted}
    >
      Verify Account
    </Button>
  </Form.Item>


  <Form.Item label={null}>
    <Button
      type="default"
      block
      onClick={handleReset}
      style={{
        borderRadius: '8px',
        padding: '12px',
        fontSize: '18px',
        backgroundColor: '#ff4d4f',
        color: '#fff',
        transition: 'all 0.3s ease',
        fontFamily: "'Roboto', sans-serif",
      }}
    >
      Reset
    </Button>
  </Form.Item>
</Form>

  
    </div>
  );
};

export default BankVerification;
