import React, { useState } from "react";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";
import {
  Modal,
  Button,
  Input,
  Typography,
  Space,
  Popconfirm,
  Select,
  Drawer,
  notification,
  Popover,
} from "antd";
import {
  MyDetailedStyledTable,
  MyStyledTable,
  StyledTable,
} from "../../../style/styleComp";
import {
  PlusOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
  SaveOutlined,
} from "@ant-design/icons";

const { Text, Title } = Typography;

const DetailModalHO = ({
  visible,
  onClose,
  data,
  onStatusChange,
  chatData,
}) => {
  // console.log("Callback data modal - ", data);
  const [openDialog, setOpenDialog] = useState(false);
  const [istempMark, setIsTempMark] = useState(false);

  const handleFinalMarkReceived = () => {
    // console.log("mark as received - ", data.application_ids);
    if (onStatusChange) {
      const callbackData = {
        app_id: data.application_ids,
        status: "Received",
      };
      // console.log("Callback data - ", callbackData);
      onStatusChange(callbackData);
    }
  };

  const filteredData = chatData?.filter(
    (item) => item.trk_no === data?.tracking_number
  );
  // console.log("filteredData - ", filteredData);
  const columns = [
    {
      title: "Application Id",
      dataIndex: "app_id",
      key: "app_id",
    },

    {
      title: "NAME",
      dataIndex: "app_name",
      key: "app_name",
    },
    {
      title: "OXYZO ID",
      key: "partner_loan_id",
      dataIndex: "partner_loan_id",
    },
    {
      title: "PRODUCT",
      key: "product",
      dataIndex: "product",
    },
  ];

  const getStatusText = (statusCode) => {
    switch (statusCode) {
      case "1":
        return "In-Transit";
      case "2":
        return "In-Query";
      case "3":
        return "Received";
      default:
        return "";
    }
  };

  const getVerificationText = (statusCode) => {
    switch (statusCode) {
      case "4":
        return "Pending at HO";
      case "5":
        return "Verified";
      case "8":
        return "Missing/Damaged Documents";
      default:
        return "";
    }
  };

  const handleDrawerClose = () => {
    if (istempMark) {
      setOpenDialog(true);
      // console.log("open dialog", openDialog);
    } else {
      onClose();
    }
  };

  const handleSaveAndClose = () => {
    handleFinalMarkReceived();
    // onClose();
    setOpenDialog(false);
  };

  const handleTempMarkReceived = () => {
    setIsTempMark(!istempMark);
  };

  const handleCancelAndClose = () => {
    setOpenDialog(false);
    onClose();
    setIsTempMark(false);
  };

  // console.log("data - ", data);

  // console.log("inTransitData", inTransitData)
  return (
    <Drawer
      title={
        <div style={{ display: "flex", alignItems: "center" }}>
          <Popover
            content={`This courier has ${chatData?.length} number of Application ID (s).`}
            title="In-Transit"
            trigger="hover"
          >
            <InfoCircleOutlined
              style={{
                marginRight: "8px",
                color: "#1890ff",
                fontSize: "28px",
              }}
            />
          </Popover>

          <Title
            level={4}
            style={{
              marginBottom: "16px",
              color: "rgba(0, 0, 0, 0.7)",
              fontSize: "20px",
              fontFamily: "Poppins",
              marginLeft: "5px",
            }}
          >
            Courier details
          </Title>
        </div>
      }
      visible={visible}
      width={850}
      placement="right"
      onClose={handleDrawerClose}
      visible={visible}
    >
      <div style={{ padding: "0 20px" }}>
        <Space
          style={{
            display: "flex",
            marginBottom: "8px",
            padding: "0 8px",
            borderRadius: "4px",
            backgroundColor: "#ffffff",
            boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
            fontSize: "20px",
            fontFamily: "Poppins",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <strong style={{ color: "#555" }}>Status :</strong>
          {istempMark ? (
            <Text
              style={{
                fontSize: "18px",
                fontFamily: "Poppins",
                display: "flex",
                justifyContent: "space-between",
                backgroundColor: "green",
                padding: "3px 8px",
                color: "white",
                borderRadius: "6px",
              }}
            >
              Received
            </Text>
          ) : (
            <Text
              style={{
                fontSize: "18px",
                fontFamily: "Poppins",
                display: "flex",
                justifyContent: "space-between",
                backgroundColor: "orange",
                padding: "3px 8px",
                color: "white",
                borderRadius: "6px",
              }}
            >
              {getStatusText(data?.status)}
            </Text>
          )}
        </Space>

        <Space
          style={{
            display: "flex",
            marginBottom: "8px",
            padding: "8px",
            borderRadius: "4px",
            backgroundColor: "#ffffff",
            boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
            fontSize: "20px",
            fontFamily: "Poppins",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <strong style={{ color: "#555" }}>POD Number :</strong>
          <Text
            style={{
              fontSize: "18px",
              fontFamily: "Poppins",
              display: "flex",
              justifyContent: "space-between",
              fontWeight: "800",
            }}
          >
            {data?.tracking_number}
          </Text>
        </Space>

        <Space
          style={{
            display: "flex",
            marginBottom: "8px",
            padding: "8px",
            borderRadius: "4px",
            backgroundColor: "#ffffff",
            boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
            fontSize: "20px",
            fontFamily: "Poppins",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <strong style={{ color: "#555" }}>Branch :</strong>
          <Text
            style={{
              fontSize: "18px",
              fontFamily: "Poppins",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {data?.branch}
          </Text>
        </Space>
        <Space
          style={{
            display: "flex",
            marginBottom: "8px",
            padding: "8px",
            borderRadius: "4px",
            backgroundColor: "#ffffff",
            boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
            fontSize: "20px",
            fontFamily: "Poppins",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <strong style={{ color: "#555" }}>Vendor :</strong>
          <Text
            style={{
              fontSize: "18px",
              fontFamily: "Poppins",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {data?.vendor}
          </Text>
        </Space>
        <Space
          style={{
            display: "flex",
            marginBottom: "8px",
            padding: "8px",
            borderRadius: "4px",
            // backgroundColor: "#ffffff",
            boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
            fontSize: "20px",
            fontFamily: "Poppins",
            display: "flex",
            justifyContent: "space-between",
            color: "white",
          }}
        >
          <strong style={{ color: "#555" }}>Recieved :</strong>

          {/* rendering received btn according to temp mark  */}

          {istempMark ? (
            <Button
              type="danger"
              onClick={handleTempMarkReceived}
              disabled={data?.status === "Received" || data?.status === "3"}
              style={{
                textAlign: "start",
                marginLeft: "25px",
                backgroundColor: "orange",
                color: "white",
                fontFamily: "Poppins",
                fontSize: "18px",
              }}
            >
              {data?.status === "Received"
                ? "All Items Received"
                : "Click Here To Mark As In-Transit"}
            </Button>
          ) : (
            <Button
              type="primary"
              onClick={handleTempMarkReceived}
              disabled={data?.status === "Received" || data?.status === "3"}
              style={{
                textAlign: "start",
                marginLeft: "25px",
                backgroundColor: "red",
                color: "white",
                fontFamily: "Poppins",
                fontSize: "18px",
              }}
            >
              {data?.status === "Received"
                ? "All Items Received"
                : "Click Here To Mark As Received"}
            </Button>
          )}
        </Space>
      </div>

      {/* <DotLottieReact
          style={{height:"400px"}}
          src="https://lottie.host/192b7b87-551c-4613-963a-38a4f5e287b0/Cb0ycCrqFf.lottie"
          loop
          autoplay
        /> */}

      <MyStyledTable
        columns={columns}
        style={{ marginTop: "40px", marginLeft: "20px", marginRight: "20px" }}
        dataSource={filteredData}
        pagination={{ pageSize: 5 }}
      />

      {istempMark && (
        <Button
          onClick={handleDrawerClose}
          type="primary"
          style={{ marginLeft: "26px", fontSize: "18px" }}
          // icon={<SaveOutlined />}
        >
          Mark As Received
        </Button>
      )}

      {/* Confirmation Dialog */}
      <Modal
        visible={openDialog}
        title="Courier received"
        onOk={handleSaveAndClose}
        onCancel={handleCancelAndClose}
        cancelText="Don't Save"
        okText="Save Changes"
        centered
      >
        <Text>Do you want to save ?</Text>
      </Modal>
    </Drawer>
  );
};

export default DetailModalHO;
