import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { BASE_URL } from '../apiConfig';

const ERROR_MESSAGES = {
  POST_FAILURE: 'Failed to verify bank account',
  GET_FAILURE: 'Failed to fetch pre-onboarding data',
};

export const postOnBoardingFormThunk = createAsyncThunk(
  'postOnBoardingFormThunk',
  async ({ jwtBearer, requestBody }, { rejectWithValue }) => {
    const options = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + jwtBearer,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    };

    try {
      const response = await fetch(`${BASE_URL}/hr/pre-onboarding/form/`, options);
      const responseData = await response.json();

      if (!response.ok) {
        return rejectWithValue(responseData);
      }

      return responseData;
    } catch (error) {
      return rejectWithValue({ errorMessage: ERROR_MESSAGES.POST_FAILURE });
    }
  }
);

export const getOnBoardingDataThunk = createAsyncThunk(
  'getOnBoardingDataThunk',
  async ({ jwtBearer }, { rejectWithValue }) => { // Removed requestBody
    const options = {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + jwtBearer,
        'Content-Type': 'application/json',
      },
    };

    try {
      const response = await fetch(`${BASE_URL}hr/get/preOnboarding/data/`, options);
      const responseData = await response.json();

      if (!response.ok) {
        return rejectWithValue(responseData);
      }

      return responseData;
    } catch (error) {
      return rejectWithValue({ errorMessage: ERROR_MESSAGES.GET_FAILURE });
    }
  }
);

const initialState = {
  preOnBoardingFormdata: null,
  onBoardingFormdata: null,
  isLoading: false,
  isError: null,
};

const preOnBoardingSlice = createSlice({
  name: 'preOnBoardingSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postOnBoardingFormThunk.pending, (state) => {
        state.isLoading = true;
        state.isError = null;
      })
      .addCase(postOnBoardingFormThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.preOnBoardingFormdata = action.payload;
      })
      .addCase(postOnBoardingFormThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = action.payload?.errorMessage || ERROR_MESSAGES.POST_FAILURE;
      })
      
      .addCase(getOnBoardingDataThunk.pending, (state) => {
        state.isLoading = true;
        state.isError = null;
      })
      .addCase(getOnBoardingDataThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.onBoardingFormdata = action.payload.data;
        console.log("state.onBoardingFormdata: ", state.onBoardingFormdata)
      })
      .addCase(getOnBoardingDataThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = action.payload?.errorMessage || ERROR_MESSAGES.GET_FAILURE;
      });
  },
});

export default preOnBoardingSlice.reducer;
