import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Input,
  Typography,
  Space,
  List,
  Popconfirm,
  Select,
  Drawer,
  Divider,
  Popover,
  notification,
} from "antd";
import {
  PlusOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { MyDetailedStyledTable } from "../../../style/styleComp";
import {
  deleteReturnAppId,
  updateReturningInTransit,
} from "../../../../../redux/slice/pddUpdateIntransitEntry";
import { fetchPDDHoToBranch } from "../../../../../redux/slice/getPDDHoToBranchData";
import { useDispatch, useSelector } from "react-redux";
import { updateBrchTrkAppEntry } from "../../../../../redux/slice/pddUpdateBranchTrkApp";

import { fetchNotAddedAppIdReturn } from "../../../../../redux/slice/getPDDAppID";
const { Text, Title } = Typography;
const { Option } = Select;

const ReturningInTransitDrawer = ({
  visible,
  onClose,
  data,
  app_id,
  onSave,
  jwtBearer,
}) => {
  if (!data) {
    return null;
}
  const dispatch = useDispatch();
  // console.log("returning transit data - ", data);
  // console.log("returning transit app_id - ", app_id);
  const [isEditingTracking, setIsEditingTracking] = useState(false);
  const [isEditingAppId, setIsEditingAppId] = useState(false);
  const [editedTrackingNumber, setEditedTrackingNumber] = useState("");
  const [editedAppIds, setEditedAppIds] = useState([]);
  const [loadingSave, setLoadingSave] = useState(false);
  const [myAppIdArr, setMyAppIdArr] = useState([]);
  const [dataSourceAppIdArr, setDataSourceAppIdArr] = useState([]);
  const [selectedAppId, setSelectedAppId] = useState(null);
  const [selectedAppIds, setSelectedAppIds] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      await dispatch(fetchNotAddedAppIdReturn({ jwtBearer }));
      await dispatch(fetchPDDHoToBranch({ jwtBearer, movement: "ho" }));
    };

    fetchData();
  }, [data]);

  useEffect(() => {
    if (data) {
      // console.log("Final list of formatted App IDs triggered");
      // console.log("Data prop has changed:", data);
      setEditedTrackingNumber(data?.tracking_number || "");
      // console.log("Initial array -  ", data.application_ids);
      setMyAppIdArr(data.application_ids || []);
    }
  }, [data]);

  // console.log("edited", editedTrackingNumber);

  const handleEditTrackingClick = () => {
    setIsEditingTracking(true);
  };

  useEffect(() => {
    if (data) {
      // console.log("Data", data);
      setEditedTrackingNumber(data?.tracking_number || "");
      // console.log("Initial array -  ", data.application_ids);
      setMyAppIdArr(data?.application_ids || []);
    }
  }, [data]);

  // console.log("myAppIdArr: ", myAppIdArr);

  const notAddedIds = useSelector(
    (state) => state.pddDataSlice.notAddedData?.not_added_ids
  );

  // console.log("notAdded", notAddedIds);
  const handleSaveTrackingClick = async () => {
    // console.log("data=", data);

    const requestBody = {
      branch: data.branch,
      trk_no: data.tracking_number,
      field: "tracking_number",
      value: editedTrackingNumber,
    };

    console.log("req", requestBody);

    try {
      const response = await dispatch(
        updateBrchTrkAppEntry({ jwtBearer, requestBody })
      );
      fetchPDDHoToBranch({ jwtBearer, movement: "ho" });
      setIsEditingTracking(false);
      // await dispatch(fetchPDDHoToBranch({ jwtBearer, movement: "Branch" }));
    } catch (error) {
      console.error(error);
      if (updateBrchTrkAppEntry.rejected.match(response)) {
        notification.error({
          message: "Error",
          description:
            error.message || "Failed to update POD number and fetch data.",
          placement: "bottomRight",
        });
      }
    }
  };

  const handleCancelTrackingClick = () => {
    setEditedTrackingNumber(data?.trk_no);
    setIsEditingTracking(false);
  };

  const handleTrackingInputChange = (e) => {
    setEditedTrackingNumber(e.target.value);
  };

  const handleAppIdChange = (index, value) => {
    const updatedAppIds = [...editedAppIds];
    updatedAppIds[index] = value;
    setEditedAppIds(updatedAppIds);
  };

  const handleSelectAppId = (value) => {
    setSelectedAppId(value);
  };

  const handleAddAppId = () => {
    if (selectedAppId && selectedAppId.length > 0) {
      // console.log("app_id", app_id);
      // console.log("selectedAppId", selectedAppId);

      // Flattening the application_ids array and find the item with the selectedAppId
      const selectedApp = app_id
        .flatMap((item) => item.application_ids)
        .find((item) => item.application_id === selectedAppId); 

      // console.log("selectedApp:", selectedApp);

      if (selectedApp) {
        const formattedAppId = `${selectedApp.application_id} - ${selectedApp.applicant_name} - ${selectedApp.product}`;
        // console.log("formattedAppId:", formattedAppId);
        setSelectedAppIds((prevArray) => [...prevArray, formattedAppId]);
      }

      // console.log("selectedAppIds:", selectedAppIds);

      setSelectedAppId(null);
    }
  };

  const handleDeleteTempAppId = (key) => {
    const updatedAppIds = selectedAppIds.filter((appId) => appId !== key);
    setSelectedAppIds(updatedAppIds);
  };

  // console.log("selectedAppIds: " + selectedAppIds);
  const handleDeleteAppId = async (index) => {
    try {
      const appIdToDelete = myAppIdArr[index];
      // console.log("appIdToDelete", appIdToDelete);

      // Remove the appId from the array
      const updatedAppIds = myAppIdArr.filter((_, i) => i !== index);
      setMyAppIdArr(updatedAppIds);

      const payload = { application_id: appIdToDelete };
      console.log("Payload being sent:", payload);

      const response = await dispatch(
        deleteReturnAppId({ jwtBearer, requestBody: payload })
      );

      // console.log("response", response);

      if (response.meta.requestStatus === "fulfilled") {
        // Success
        notification.success({
          message: "Success",
          description: "Application ID deleted Successfully",
          placement: "bottomRight",
        });
      } else {
        console.error(
          "Error deleting appId:",
          response.error.message || "Unknown error"
        );
      }
    } catch (error) {
      console.error("Error deleting appId:", error);
    }
  };

  const formatAppIds = (appIds) => {
    return appIds.map((appId) => {
      if (appId.includes(" - ")) {
        return appId;
      }

      const selectedApp = app_id.find((item) => item.APP_ID === appId);
      if (selectedApp) {
        const { APPLICANT_NAME, PRODUCT } = selectedApp;
        // Return the application ID in the desired format
        return `${appId} - ${APPLICANT_NAME} -  ${PRODUCT}`;
      }

      return appId; 
    });
  };



  const handleSaveAppIdsClick = async () => {
    const formattedSelectedAppIds = formatAppIds(selectedAppIds);
    // console.log("selectedAppIds: ", selectedAppIds);

    const formattedMyAppIdArr = formatAppIds(myAppIdArr);

    const allFormattedAppIds = [
      ...formattedMyAppIdArr,
      ...formattedSelectedAppIds,
    ];

    const appIdsOnly = allFormattedAppIds.map((item) => item.split(" - ")[0]);
    const selectedAppIdsOnly=formattedSelectedAppIds.map((item)=>item.split(" - ")[0]);
    // console.log("appIdsOnly", allFormattedAppIds);
    // console.log("data.trk_no", data?.trk_no);
    // Prepare the request body
    const requestBody = {
      application_id: selectedAppIdsOnly,
      tracking_number: data?.tracking_number,
    };

    console.log("requestBody", requestBody);

    setLoadingSave(true);

    try {
      const response=await dispatch(updateReturningInTransit({ jwtBearer, requestBody }));
      console.log("response",response)
     
       if(response.payload.code=='100'){
        setMyAppIdArr(appIdsOnly);
        setSelectedAppIds([]);

        notification.success({
          message: "Success",
          description: "Application ID(s) Added Successfully",
          placement: "bottomRight",
        });

       }

   await dispatch(fetchPDDHoToBranch({ jwtBearer, movement: "ho" }));

   
  
    } catch (error) {
      console.error("Error updating in-transit:", error);
      notification.error({
        message: "Error",
        description: "Failed to update application IDs",
        placement: "bottomRight",
      });
    } finally {
      setLoadingSave(false);
    }
  };

  const handleCancelAppIdsClick = () => {
    setEditedAppIds(data?.application_ids || []);
    setIsEditingAppId(false);
  };

  const getStatusText = (statusCode) => {
    switch (statusCode) {
      case "1":
        return "In-Transit";
      case "2":
        return "In-Query";
      case "3":
        return "Received";
      default:
        return "";
    }
  };

  const getVerificationText = (statusCode) => {
    switch (statusCode) {
      case "4":
        return "Pending at Branch";
      case "5":
        return "Verified";
      case "8":
        return "Missing/Damaged Documents";
      default:
        return "";
    }
  };

  return (
    <Drawer
      title={
        <div style={{ display: "flex", alignItems: "center" }}>
          <Popover title="In-Transit" trigger="hover">
            <InfoCircleOutlined
              style={{
                marginRight: "8px",
                color: "#1890ff",
                fontSize: "28px",
              }}
            />
          </Popover>

          <Title
            level={4}
            style={{
              marginBottom: "16px",
              color: "rgba(0, 0, 0, 0.7)",
              fontSize: "20px",
              fontFamily: "Poppins",
              marginLeft: "5px",
            }}
          >
            Courier details
          </Title>
        </div>
      }
      visible={visible}
      onClose={onClose}
      width={700}
    >
      <div style={{ padding: "0 20px" }}>
        <Space
          style={{
            display: "flex",
            marginBottom: "8px",
            padding: "0 8px",
            borderRadius: "4px",
            backgroundColor: "#ffffff",
            boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
            fontSize: "20px",
            fontFamily: "Poppins",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <strong style={{ color: "#555" }}>Status:</strong>
          <Text
            style={{
              fontSize: "18px",
              fontFamily: "Poppins",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {getStatusText(data?.status)}
          </Text>
        </Space>

        <Space
          style={{
            display: "flex",
            marginBottom: "8px",
            padding: "8px",
            borderRadius: "4px",
            backgroundColor: "#ffffff",
            boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
            fontSize: "20px",
            fontFamily: "Poppins",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <strong style={{ color: "#555" }}>POD Number:</strong>
          <Text
            style={{
              fontSize: "18px",
              fontFamily: "Poppins",
              display: "flex",
              justifyContent: "space-between",
              fontWeight: "800",
            }}
          >
            {data?.tracking_number}
          </Text>
        </Space>

        <Space
          style={{
            display: "flex",
            marginBottom: "8px",
            padding: "8px",
            borderRadius: "4px",
            backgroundColor: "#ffffff",
            boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
            fontSize: "20px",
            fontFamily: "Poppins",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <strong style={{ color: "#555" }}>Verification:</strong>
          <Text
            style={{
              fontSize: "18px",
              fontFamily: "Poppins",
              display: "flex",
              justifyContent: "space-between",
              backgroundColor: "orange",
              padding: "3px 8px",
              color: "white",
              borderRadius: "6px",
            }}
          >
            {getVerificationText(data?.verification)}
          </Text>
        </Space>

        <Space
          style={{
            display: "flex",
            marginBottom: "8px",
            padding: "8px",
            borderRadius: "4px",
            backgroundColor: "#ffffff",
            boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
            fontSize: "20px",
            fontFamily: "Poppins",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <strong style={{ color: "#555" }}>Branch:</strong>
          <Text
            style={{
              fontSize: "18px",
              fontFamily: "Poppins",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {data?.branch}
          </Text>
        </Space>
      </div>

      {/* Tracking Number Section */}
      <div
        style={{
          marginTop: "16px",
          marginBottom: "30px",
          fontSize: "20px",
          display: "flex",
          justifyContent: "space-around",
          width: "100%",
        }}
      >
        <h4>POD Number:</h4>
        <Space style={{ marginTop: "8px", fontWeight: "800" }}>
          <Input
            value={editedTrackingNumber}
            onChange={handleTrackingInputChange}
            disabled={!isEditingTracking}
            style={{ flex: 1, backgroundColor: "#F4F6FF", color: "black" }}
          />
          {!isEditingTracking ? (
            <Button
              type="primary"
              onClick={handleEditTrackingClick}
              style={{ border: "2px solid #1E90FF" }}
            >
              Update POD Number
            </Button>
          ) : (
            <>
              <Button onClick={handleSaveTrackingClick} type="primary">
                Save
              </Button>
              <Button onClick={handleCancelTrackingClick}>Cancel</Button>
            </>
          )}
        </Space>
      </div>


      {/* Existing App IDs Table */}
      <MyDetailedStyledTable
        bordered
        dataSource={myAppIdArr.map((appId, index) => ({
          key: index,
          appId,
        }))}
        pagination={myAppIdArr.length > 5 ? { pageSize: 5 } : false}
        columns={[
          {
            title: "Application id",
            dataIndex: "appId",
            render: (text) => {
              const getDisplayValue = (appId) => {
                const item = app_id
                  .flatMap((branch) => branch.application_ids)
                  .find((application) => application.application_id === appId);

                return item
                  ? `${item.application_id} - ${item.applicant_name} - ${item.product}`
                  : "";
              };

              return getDisplayValue(text);
            },
          },
          {
            title: "Delete",
            render: (text, record) => (
              <Space>
                <Popconfirm
                  placement="leftTop"
                  title={` Delete ${record.appId} ?`}
                  onConfirm={() => handleDeleteAppId(record.key)}
                  okText="Yes"
                  cancelText="No"
                  style={{ height: "100px", width: "100px" }}
                >
                  <DeleteOutlined style={{ color: "red" }} />
                </Popconfirm>
              </Space>
            ),
          },
        ]}
      />

      {/* Add new application id section */}
      <div
        style={{
          padding: "16px 0",
          backgroundColor: "#f9f9f9",
          borderRadius: "8px",
          boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
          marginTop: "30px",
          overflowX: "hidden",
          margin: "20px 25px",
        }}
      >
        <h2 style={{ marginLeft: "5px", margin: "15px 30px" }}>
          Add {selectedAppIds.length > 0 && selectedAppIds.length} New
          Application ID(s)
        </h2>
        <Space
          style={{
            marginTop: "8px",
            width: "100%",
            margin: "15px 30px",
            marginLeft: "5px",
          }}
        >
          <Select
            value={selectedAppId}
            onChange={handleSelectAppId}
            placeholder="Select Application ID"
            style={{
              flex: 1,
              minWidth: "300px",
              width: "100%",
              height: "40px",
            }}
          >
            {app_id
              .filter((item) => item.branch === data?.branch)
              .flatMap((item) => item.application_ids)
              .filter((id) => notAddedIds?.includes(id.application_id))
              .filter((id) => {
                const label = `${id.application_id} - ${id.applicant_name} - ${id.product}`;
                return !selectedAppIds.includes(label);
              })
              .map((id) => {
                const label = `${id.application_id} - ${id.applicant_name} - ${id.product}`;
                return (
                  <Option key={id.application_id} value={id.application_id}>
                    {label}
                  </Option>
                );
              })}
          </Select>

          {selectedAppId ? (
            <Button
              onClick={handleAddAppId}
              type="dashed"
              icon={<PlusOutlined />}
              style={{ border: "2px solid dodgerblue" }}
            >
              Add
            </Button>
          ) : (
            <Button
              // onClick={handleAddAppId}
              type="primary"
              icon={<PlusOutlined />}
              disabled={!selectedAppId}
              // style={{border:"2px solid dodgerblue"}}
            >
              Add
            </Button>
          )}

          {selectedAppIds.length > 0 && (
            <Button
              onClick={handleSaveAppIdsClick}
              type="primary"
              style={{ marginRight: "8px" }}
              loading={loadingSave}
            >
              Save Changes
            </Button>
          )}
        </Space>

        {/* newly added app id */}
        {selectedAppIds.length > 0 && (
          <MyDetailedStyledTable
            bordered
            // style={{marginRight:"50px"}}
            dataSource={selectedAppIds.map((appId, index) => ({
              key: appId,
              appId: appId,
            }))}
            pagination={selectedAppIds.length > 5 ? { pageSize: 5 } : false}
            columns={[
              {
                dataIndex: "appId",
                render: (text) => text || "",
              },
              {
                render: (text, record) => (
                  <Space>
                    <DeleteOutlined
                      onClick={() => handleDeleteTempAppId(record.key)}
                      style={{ color: "red" }}
                    />
                  </Space>
                ),
              },
            ]}
            showHeader={false} //
          />
        )}
      </div>
    </Drawer>
  );
};

export default ReturningInTransitDrawer;
